import { compose, apply, pluck } from "ramda"
import { localized, showErrorMessage, showSuccessMessage, fetchJson } from "js/includes/common/utils"
import { getNodeRoles } from "js/includes/common/client"
import { getConfigurationTypeMappings } from "./configurations/configurations"
import { getProductMappings } from "./products/products"
import { getAccountOrganizationsMapping } from "./accounts"
import { getPsaConfigContent, getConnection } from "../connection"

export function getNodeRoleList() {
  return async dispatch => {
    try {
      const nodeRoleList = await getNodeRoles()
      dispatch({
        type: "SET_CW_NODE_ROLE_LIST",
        payload: nodeRoleList,
      })
    } catch (error) {
      showErrorMessage(localized("Error fetching node roles"))
      throw error
    }
  }
}

export function saveSettings() {
  return async (dispatch, getState) => {
    try {
      const {
        defaultTicketTemplateId,
        ConnectWise: { settings, mappings, connection, cwHelpDeskSettings },
      } = getState().psa
      const body = JSON.stringify({
        content: getPsaConfigContent(connection, settings, mappings, defaultTicketTemplateId, cwHelpDeskSettings),
        mappings: {
          accountClientMappings: mappings.accounts.accountOrganizationMappings,
          productMappings: mappings.products.productMappings,
          configurationTypeMappings: mappings.configurations.configurationTypeMappings,
        },
      })
      await fetchJson("/psacw/v2/settings", { options: { method: "PUT", body } })
      await Promise.all([
        dispatch(getConnection()),
        dispatch(getProductMappings()),
        dispatch(getConfigurationTypeMappings()),
        dispatch(getAccountOrganizationsMapping()),
      ])
      showSuccessMessage(localized("Saving..."))
    } catch (error) {
      showErrorMessage(localized("Error saving settings"))
      throw error
    }
  }
}

export function getTempId(list) {
  const maxId = compose(apply(Math.max), pluck("id"))(list)
  return list.length ? maxId + 1 : 1
}
