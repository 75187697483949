import { useEffect } from "react"
import { faCloudUpload, faExclamation, faFolders, faHdd } from "@fortawesome/pro-solid-svg-icons"
import { faNinjaBackup } from "media/icons"
import qs from "qs"
import {
  all,
  always,
  ascend,
  compose,
  cond,
  descend,
  equals,
  F,
  flatten,
  fromPairs,
  groupBy,
  is,
  isEmpty,
  map,
  modifyPath,
  pathEq,
  pickBy,
  prop,
  sortBy,
  sortWith,
  T,
  when,
} from "ramda"
import { v4 as uuidv4 } from "uuid"

import { cancelJob } from "js/includes/common/_jobs"
import { useMountedState } from "js/includes/common/hooks"
import {
  contains,
  downloadFile,
  fetch,
  fetchJson,
  isFeatureEnabled,
  isMacPolicy,
  isNotNilOrEmpty,
  isWindowsPolicy,
  localizationKey,
  localized,
  ninjaReportError,
  reportErrorAndShowMessage,
  reportErrorWhenNotPolling,
  showErrorMessage,
  showPermanentMessage,
  user,
  userProp,
} from "js/includes/common/utils"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import { dateValues } from "js/includes/systemDashboard/backups/common/options"
import { statusValues, SYNC_FAILURE_VALUE } from "js/includes/systemDashboard/backups/History/options"
import {
  DELETE_PLAN_REVISIONS_ACTION_ID,
  DELETE_REVISIONS_ACTION_ID,
  DOWNLOAD_ACTION_ID,
  DOWNLOAD_IMAGE_CONTENT_ACTION_ID,
  IMAGE_BACKUP_TOOL_ACTION_ID,
  RESTORE_ACTION_ID,
  RESTORE_KEY_ACTION_ID,
} from "js/includes/systemDashboard/backups/Manage/actions"
import { translateLockhartMode } from "js/state/reducers/backups/initialState"

export const getBackupDivisionConfig = async backupEngineId => {
  const response = await fetchJson(`/divisionconfig/${backupEngineId}`)
  return response.resultCode === "SUCCESS" ? { backupEngineId, ...response.content } : {}
}

export const submitBackupIntegrationSetup = (backupEngineId, cloudStorage) => {
  if (backupEngineId === "cloudberry") {
    return fetchJson(`/cloudberry/config`, {
      options: {
        method: "POST",
        body: JSON.stringify({
          firstName: window.application.get("user").get("firstName"),
          lastName: window.application.get("user").get("lastName"),
          region: cloudStorage,
        }),
      },
    })
  }
  if (backupEngineId === "lockhart") {
    return fetchJson(`/divisionconfig/lockhart`, {
      options: {
        method: "PUT",
        body: JSON.stringify({
          content: {
            enabled: true,
            bucket: cloudStorage,
          },
        }),
      },
    })
  }
}

export const updateLockhartFeatureChanges = ({ enabled, bucket }) => {
  return fetchJson(`/divisionconfig/lockhart`, {
    options: {
      method: "PATCH",
      body: JSON.stringify({
        content: {
          enabled,
          bucket,
        },
      }),
    },
  })
}

export const toggleBackupIntegration = (backupEngineId, enabled) => {
  return fetchJson(`/divisionconfig/${backupEngineId}`, {
    options: {
      method: "PATCH",
      body: JSON.stringify({
        content: {
          enabled,
        },
      }),
    },
  })
}

export const toggleLockhartIntegration = enabled => {
  return fetchJson(`/divisionconfig/lockhart${enabled ? "" : "/disable"}`, {
    options: {
      method: "PATCH",
      body: JSON.stringify({
        content: {
          enabled,
        },
      }),
    },
  })
}

export const fetchActiveBucketsList = async () => {
  try {
    const url = "/backup/lockhart/buckets/active"
    return await fetchJson(url)
  } catch (error) {
    reportErrorAndShowMessage(error, localized("Error fetching buckets list"))
    return []
  }
}

export const fetchBucketsList = async () => {
  try {
    const url = "/backup/lockhart/buckets"
    return await fetchJson(url)
  } catch (error) {
    showErrorMessage(localized("Error fetching buckets list"))
    ninjaReportError(error)
    return []
  }
}

export const getStats = async ({ client, node }) => {
  const nodeRoutePath = node ? `/nodes/${node.id}` : ""
  const clientRoutePath = client ? `/clients/${client.id}` : ""
  const url = `/backup/lockhart${nodeRoutePath || clientRoutePath}/stats`
  return await fetchJson(url)
}

export const getBackupJobsInProcess = async ({ client, node }) => {
  const nodeRoutePath = node ? `/nodes/${node.id}` : ""
  const clientRoutePath = client ? `/clients/${client.id}` : ""
  const url = `/v2${nodeRoutePath || clientRoutePath}/jobs?jobType=LOCKHART&jobStatus=IN_PROCESS`
  return await fetchJson(url)
}

const getStatus = (status, failureTypeList) => {
  const { [SYNC_FAILURE_VALUE]: syncFailure = [], errorCodes = [] } = compose(
    groupBy(when(is(Number), always("errorCodes"))),
    flatten,
  )(failureTypeList)

  return {
    filterCompletedJob: status.includes(statusValues.COMPLETED),
    filterFailedUpsyncJob: isNotNilOrEmpty(syncFailure),
    errorCodes: sortBy(ascend, errorCodes),
  }
}

const getLogs = compose(
  fromPairs,
  map(logValue => [logValue, true]),
)

const paramsFilter = val => isNotNilOrEmpty(val) && val !== "ALL"

export const getBackupHistory = async ({
  client,
  node,
  page = 0,
  size = 100,
  sortBy: [{ id: sortField, desc: descSorting } = {}],
  dateRange,
  hasWarnings,
  planTypes = [],
  status = [],
  destinations = [],
  organizationIds,
  nodeIds,
  locationIds,
  logs,
  failureTypeList = [],
  lastResultFilter,
}) => {
  const nodeRoutePath = node ? `/nodes/${node.id}` : ""
  const clientRoutePath = client ? `/clients/${client.id}` : ""

  const params = pickBy(paramsFilter)({
    organizationIds,
    nodeIds,
    locationIds,
    dateRangeFilter: { range: dateRange },
    hasWarnings,
    planTypes,
    statusFilter: getStatus(status, failureTypeList),
    destinations,
    logFilter: getLogs(logs),
    sortField: sortField?.toUpperCase(),
    sortDirection: descSorting ? "DESC" : "ASC",
    lastResultFilter: {
      enabled: !!lastResultFilter,
    },
  })

  const url = `/backup/lockhart${nodeRoutePath || clientRoutePath}/backup-jobs-history?${qs.stringify({
    page,
    size,
  })}`
  return await fetchJson(url, {
    options: {
      method: "POST",
      body: JSON.stringify(params),
    },
  })
}

export const exportBackupHistory = async ({
  client,
  node,
  sortBy: [{ id: sortField, desc: descSorting } = {}],
  dateRange,
  hasWarnings,
  planTypes = [],
  status = [],
  destinations = [],
  organizationIds,
  nodeIds,
  locationIds,
  logs,
  failureTypeList = [],
  lastResultFilter,
}) => {
  const nodeRoutePath = node ? `/nodes/${node.id}` : ""
  const clientRoutePath = client ? `/clients/${client.id}` : ""

  const params = pickBy(paramsFilter)({
    organizationIds,
    nodeIds,
    locationIds,
    dateRangeFilter: { range: dateRange },
    hasWarnings,
    planTypes,
    statusFilter: getStatus(status, failureTypeList),
    destinations,
    logFilter: getLogs(logs),
    sortField: sortField.toUpperCase(),
    sortDirection: descSorting ? "DESC" : "ASC",
    lastResultFilter: {
      enabled: !!lastResultFilter,
    },
  })

  const url = `/backup/lockhart${nodeRoutePath || clientRoutePath}/backup-jobs-history/csv`

  if (isEmpty(dateRange)) showPermanentMessage(localized("Exporting data for the last 90 days"))
  try {
    const response = await fetch(url, {
      options: {
        method: "POST",
        body: JSON.stringify(params),
      },
    })
    const data = await response.text()

    const nodeFileName = node ? `-${node.displayName ?? node.friendlyName ?? node.name ?? node.systemName ?? ""}` : ""
    const clientFileName = client ? `-${client.attributes.name}` : ""
    const fileName = `backup-${localized("export")}${nodeFileName || clientFileName}-${new Date().toJSON()}.csv`

    downloadFile(`data:text/csv;charset=utf-8,${encodeURIComponent(data)}`, fileName)
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error exporting data"))
  }
}

export const getJobLogDetails = async ({ jobId, page = 0, size = 100 }) => {
  const jobRoutePath = jobId ? `/${jobId}` : ""
  const url = `/backup/lockhart/backup-jobs${jobRoutePath}/log?page=${page}&size=${size}`
  return await fetchJson(url)
}

export const getIntegrityCheckJobLogDetails = async ({ jobId, page = 0, size = 100 }) => {
  const jobRoutePath = jobId ? `/${jobId}` : ""
  return await fetchJson(`/backup/lockhart/integrity-check-jobs${jobRoutePath}/logs?page=${page}&size=${size}`)
}

export const getRecentBackupJobsStats = async ({ client, node, dateRange = dateValues.TODAY }) => {
  const nodeRoutePath = node ? `/nodes/${node.id}` : ""
  const clientRoutePath = client ? `/clients/${client.id}` : ""
  const url = `/backup/lockhart${nodeRoutePath || clientRoutePath}/backup-jobs-stats?${qs.stringify({
    dateRange,
  })}`
  return await fetchJson(url)
}

const defaultStorageUsageStatuses = [
  "DISABLED",
  "ENABLED",
  "ARCHIVED",
  "REJECTED",
  "DISABLED_PENDING_DELETION",
  "DELETED_PENDING_DELETION",
  "REJECTED_PENDING_DELETION",
]

export const getStorageUsageStats = async ({
  page,
  size,
  client,
  organizationIds = [],
  locationIds = [],
  statuses = [],
  jobFilter = {
    lastSuccess: 0,
    lastFailure: 0,
  },
  dataFilter = {
    cloud: 0,
    local: 0,
  },
  nodeClasses = [],
  activePlans = 0,
  deviceExists = null,
  nodeFilter = {
    contactTime: 0,
  },
  sortField,
  sortDirection,
}) => {
  const url = `/backup/lockhart${client ? `/clients/${client.id}` : ""}/node-stats?${qs.stringify({
    page,
    size,
  })}`
  return await fetchJson(url, {
    options: {
      method: "POST",
      body: JSON.stringify({
        organizationIds,
        locationIds,
        statuses: statuses.length ? statuses : defaultStorageUsageStatuses,
        jobFilter,
        dataFilter,
        nodeClasses,
        activePlans,
        deviceExists,
        nodeFilter,
        sortField,
        sortDirection,
      }),
    },
  })
}

export const exportStorageUsageStats = async ({
  client,
  organizationIds = [],
  locationIds = [],
  statuses = [],
  jobFilter = {
    lastSuccess: 0,
    lastFailure: 0,
  },
  dataFilter = {
    cloud: 0,
    local: 0,
  },
  nodeClasses = [],
  activePlans = 0,
  deviceExists = null,
  sortField,
  sortDirection,
}) => {
  try {
    const isOrganization = !!client
    const url = `/backup/lockhart${isOrganization ? `/clients/${client.id}` : "/v2"}/node-stats/csv`
    const response = await fetch(url, {
      options: {
        method: "POST",
        body: JSON.stringify({
          organizationIds,
          locationIds,
          statuses: statuses.length ? statuses : defaultStorageUsageStatuses,
          jobFilter,
          dataFilter,
          nodeClasses,
          activePlans,
          deviceExists,
          sortField,
          sortDirection,
        }),
      },
    })

    const data = await response.text()
    const fileName = `Backup-${localized("export")}-${new Date().toJSON()}${
      isOrganization ? "-" + client?.get("name") : ""
    }.csv`

    downloadFile(`data:text/csv;charset=utf-8,${encodeURIComponent(data)}`, fileName)
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error exporting storage usage stats"))
  }
}

export const getStorageUsagePerOrganizationStats = ({
  page,
  size,
  organizationIds = [],
  jobFilter = {
    lastSuccess: 0,
    lastFailure: 0,
  },
  dataFilter = {
    cloud: 0,
    local: 0,
  },
  sortField,
  sortDirection,
}) =>
  fetchJson(
    `/backup/lockhart/overview/organizations?${qs.stringify({
      page,
      size,
    })}`,
    {
      options: {
        method: "POST",
        body: JSON.stringify({
          organizationIds,
          jobFilter,
          dataFilter,
          sortField,
          sortDirection,
        }),
      },
    },
  )

export const exportStorageUsagePerOrganizationStats = async ({
  organizationIds = [],
  jobFilter = {
    lastSuccess: 0,
    lastFailure: 0,
  },
  dataFilter = {
    cloud: 0,
    local: 0,
  },
  sortField,
  sortDirection,
}) => {
  try {
    const response = await fetch("/backup/lockhart/overview/organizations/csv", {
      options: {
        method: "POST",
        body: JSON.stringify({
          organizationIds,
          jobFilter,
          dataFilter,
          sortField,
          sortDirection,
        }),
      },
    })

    const data = await response.text()
    const fileName = `Backup-${localized("export")}-${new Date().toJSON()}.csv`

    downloadFile(`data:text/csv;charset=utf-8,${encodeURIComponent(data)}`, fileName)
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error exporting storage overview stats"))
  }
}

export const getLockhartDivisionEnabledStatus = async () => {
  const lockhartDivisionConfig = await getBackupDivisionConfig("lockhart")
  return lockhartDivisionConfig.enabled
}

export const setLockhartDivisionEnabledStatus = async callBackFn => {
  try {
    const response = await getBackupDivisionConfig("lockhart")
    callBackFn(response)
  } catch (error) {
    reportErrorAndShowMessage(error)
  }
}

//TODO: Remove this if it's necessary after restore keys enhancements are finished
export const fetchImageRestoreKeyStatus = async nodeId => {
  if (!user("canViewAndRestoreBackupData")) return false

  try {
    const url = `/backup/lockhart/node/${nodeId}/restore-key`
    const status = await fetchJson(url)
    return typeof status === "boolean" && status
  } catch (error) {
    showErrorMessage(localized("Error while fetching data"))
    ninjaReportError(error)
    return false
  }
}

export const isCloudBerryEnabled = async () => {
  try {
    const isCloudBerryFeatureAvailable =
      isFeatureEnabled("cloudberry") && window.application.get("user").canConfigureCloudberry()
    const cloudberryDivisionConfig = isCloudBerryFeatureAvailable ? await getBackupDivisionConfig("cloudberry") : {}
    const isCloudberryInDivisionConfig = cloudberryDivisionConfig.enabled
    return isCloudBerryFeatureAvailable && isCloudberryInDivisionConfig
  } catch (error) {
    ninjaReportError(error)
  }
}

const getStatusUrl = ({ nodeId, customerId, fetchHasData }) =>
  `/backup/lockhart${(nodeId && `/nodes/${nodeId}`) ||
    (customerId && `/clients/${customerId}`) ||
    ""}/lockhart-status?fetchHasData=${fetchHasData}`

const getDivEnabledStatus = async () => {
  const enabled =
    window.application?.attributes?.session?.attributes?.isLockhartEnabled ??
    (await fetchJson(getStatusUrl({ fetchHasData: false }))).enabled
  return { enabled }
}

const DEFAULT_DIVISION_CONFIG = { enabled: false, bucket: "" }
const DEFAULT_STATUS_RESPONSE = {
  enabled: false,
  hasData: false,
  hasCloudData: false,
  hasLocalData: false,
  divisionConfig: DEFAULT_DIVISION_CONFIG,
}

const INSUFFICIENT_PERMISSIONS_ERROR_CODE = 403

export const getLockhartStatus = async ({
  getDivStatus,
  nodeId,
  customerId,
  isRefresh = false,
  skipCheckForDivConfig = false,
  checkIfEnabledFirst = true,
}) => {
  try {
    //lockhart-status call fails if division is not configured for lockhart. To prevent errors,
    //check division config first before making call to lockhart-status; if not configured, return defaultResponse
    const divisionConfig = skipCheckForDivConfig ? DEFAULT_DIVISION_CONFIG : await getBackupDivisionConfig("lockhart")
    const cloudStorageConfigured = skipCheckForDivConfig || !!divisionConfig?.bucket
    if ((!nodeId && !customerId && !getDivStatus) || !cloudStorageConfigured) {
      return { ...DEFAULT_STATUS_RESPONSE, divisionConfig }
    }

    const urlWithoutFetchHasData = getStatusUrl({ nodeId, customerId, fetchHasData: false })
    const urlWithFetchHasData = getStatusUrl({ nodeId, customerId, fetchHasData: true })

    //To optimize how often Orchard is being hit, in most cases we should only check for data if enabled is false
    //because most callers of this function implement enabled || hasData logic
    if (checkIfEnabledFirst) {
      const { enabled } = await (getDivStatus ? getDivEnabledStatus() : fetchJson(urlWithoutFetchHasData))
      if (enabled) {
        return { enabled, divisionConfig }
      } else {
        const { enabled, hasCloudData, hasLocalData } = await fetchJson(urlWithFetchHasData)
        return { enabled, hasData: hasCloudData || hasLocalData, hasCloudData, hasLocalData, divisionConfig }
      }
    } else {
      const { enabled, hasCloudData, hasLocalData } = await fetchJson(urlWithFetchHasData)
      return { enabled, hasData: hasCloudData || hasLocalData, hasCloudData, hasLocalData, divisionConfig }
    }
  } catch (error) {
    if (error?.response?.status !== INSUFFICIENT_PERMISSIONS_ERROR_CODE) {
      reportErrorWhenNotPolling(error, isRefresh, localizationKey("Backup loading error"))
    }
    return DEFAULT_STATUS_RESPONSE
  }
}

export const isLockhartEnabled = async param => {
  return (await getLockhartStatus(param)).enabled
}

export const sortByCreated = sortWith([descend(prop("created"))])

export const getRestoreModalTitle = (isFile, nodes) => {
  if (isFile) {
    return localized("Restore File")
  }

  if (nodes.length > 1) {
    return localized("Restore Batch")
  }

  return localized("Restore Folder")
}

export const getFilesAndFoldersKeyData = (batch, key = "id") => {
  return batch.reduce(
    (acc, item) => {
      if (item.type === "folder" || item.type === "mountedFolder") {
        acc.folders.push(item[key])
      } else {
        acc.files.push(item[key])
      }
      return acc
    },
    {
      files: [],
      folders: [],
    },
  )
}

export const getRestoreFileJob = ({
  nodeId,
  nodeName,
  revisionId,
  sourcePath,
  sourceNodeId,
  sourcePlanId,
  sourceNodeName,
  sourcePlanName,
  destinationPath,
  collisionHandling,
  restorePermissions,
  restoreType = "FILE",
  originalLocation,
  filesAndFoldersKey = "id",
  batch,
}) => {
  const jobUid = uuidv4()
  const user = window.application.get("user").attributes
  const userName = user.firstName + " " + user.lastName
  return {
    nodeId,
    jobs: [
      {
        jobUid,
        jobType: "LOCKHART",
        jobStatus: "START_REQUESTED",
        activityStatus: "LOCKHART_RESTOREJOB_START_REQUESTED",
        appUserId: user.id,
        data: {
          message: {
            code: "lockhart_restore_job_start_requested",
            params: {
              appUserName: userName,
              sourcePlanId,
              sourcePlanName,
            },
          },
        },
        jobName: localized("File Restore"),
        message: localized(
          "User {{userName}} has requested file {{sourcePath}} from device {{sourceNodeName}} to be restored to device {{destinationNodeName}} in destination path {{destinationPath}}",
          {
            sourcePath,
            sourceNodeName,
            destinationPath,
            destinationNodeName: nodeName,
            userName,
          },
        ),
        content: {
          ...(restoreType === "EXTRACT" && {
            extractContent: getFilesAndFoldersKeyData(batch, filesAndFoldersKey),
          }),
          revisionId,
          sourcePath,
          sourceNodeId,
          sourcePlanId,
          sourceNodeName,
          sourcePlanName,
          destinationPath,
          collisionHandling,
          restoreType,
          actionType: "LOCKHART_RESTORE",
          restorePermissions,
        },
        originalLocation,
      },
    ],
  }
}

export const getRestoreBatchJob = ({
  batch,
  nodeId,
  nodeName,
  sourcePath,
  backupJobId,
  sourceNodeId,
  sourcePlanId,
  sourceNodeName,
  sourcePlanName,
  destinationPath,
  collisionHandling,
  restorePermissions,
  revisionId,
  filesAndFoldersKey = "id",
  restoreType = "BATCH",
  originalLocation,
  restoreDeletedFiles,
}) => {
  const jobUid = uuidv4()
  const user = window.application.get("user").attributes
  const userName = user.firstName + " " + user.lastName
  const filesAndFoldersKeyData = getFilesAndFoldersKeyData(batch, filesAndFoldersKey)
  return {
    nodeId,
    jobs: [
      {
        jobUid,
        jobType: "LOCKHART",
        jobStatus: "START_REQUESTED",
        activityStatus: "LOCKHART_RESTOREJOB_START_REQUESTED",
        appUserId: user.id,
        data: {
          message: {
            code: "lockhart_restore_job_start_requested",
            params: {
              appUserName: userName,
              sourcePlanId,
              sourcePlanName,
            },
          },
        },
        jobName: localized("Batch Restore"),
        message: localized(
          "User {{userName}} has requested batch of (files/folders) from {{sourcePath}} in device {{sourceNodeName}} to be restored to device {{destinationNodeName}} in destination path {{destinationPath}}",
          {
            sourcePath,
            sourceNodeName,
            destinationPath,
            destinationNodeName: nodeName,
            userName,
          },
        ),
        content: {
          ...(restoreType === "EXTRACT"
            ? {
                extractContent: filesAndFoldersKeyData,
              }
            : filesAndFoldersKeyData),
          revisionId,
          sourcePath,
          backupJobId,
          sourceNodeId,
          sourcePlanId,
          sourceNodeName,
          sourcePlanName,
          destinationPath,
          collisionHandling,
          restoreType,
          actionType: "LOCKHART_RESTORE",
          restorePermissions,
          restoreDeletedFiles,
        },
        originalLocation,
      },
    ],
  }
}

export const getSortedBackupFiles = async ({ sourceNode, planId }) => {
  try {
    const response = await fetchJson(`/backup/lockhart/nodes/${sourceNode.id}/plans/${planId}/volumes`)

    return sortByCreated(response)
  } catch (error) {
    ninjaReportError(error)
  }
}

export const submitJob = nodes => {
  return fetchJson("/webapp/submit/job", {
    options: {
      method: "POST",
      body: JSON.stringify({ nodes }),
    },
  })
}

export const getBackupJob = ({ nodeId, nodeName, planId, planName, isFullBackup }) => {
  const jobUid = uuidv4()
  const appUserName = user("getName")
  return {
    nodeId,
    jobs: [
      {
        jobUid,
        jobType: "LOCKHART",
        jobStatus: "START_REQUESTED",
        activityStatus: "LOCKHART_BACKUPJOB_START_REQUESTED",
        appUserId: userProp("id"),
        data: {
          message: {
            code: "lockhart_backup_job_start_requested",
            params: {
              appUserName,
              planId,
              planName,
            },
          },
        },
        jobName: localized("Run backup on demand"),
        message: localized("User {{appUserName}} requested to run a backup from plan {{planName}}", {
          appUserName,
          planName,
        }),
        content: {
          nodeId,
          planId,
          nodeName,
          planName,
          actionType: "LOCKHART_BACKUP",
          ...(isFullBackup && { bld_type: "full" }),
        },
      },
    ],
  }
}

export const getIntegrityCheckJob = ({ nodeId, planId, planName, type }) => {
  const jobUid = uuidv4()
  const appUserName = user("getName")
  return {
    nodeId,
    jobs: [
      {
        jobUid,
        jobType: "LOCKHART",
        jobStatus: "START_REQUESTED",
        activityStatus: "LOCKHART_INTEGRITY_CHECK_START_REQUESTED",
        appUserId: userProp("id"),
        data: {
          message: {
            code: "lockhart_integrity_check_start_requested",
            params: {
              appUserName,
              backupPlanId: planId,
              backupPlanName: planName,
            },
          },
        },
        jobName: localized("Integrity Check"),
        message: localized("User {{appUserName}} has requested integrity check for '{{planName}}' plan", {
          appUserName,
          planName,
        }),
        content: {
          nodeId,
          planId,
          planName,
          actionType: "LOCKHART_INTEGRITY_CHECK",
          type,
        },
      },
    ],
  }
}

const getCancelJobsToken = cond([
  [
    all(pathEq(["content", "actionType"], "LOCKHART_INTEGRITY_CHECK")),
    always("configuration.integrations.lockhart.cancelIntegrityCheckJobAlert"),
  ],
  [
    all(pathEq(["content", "actionType"], "LOCKHART_RESTORE")),
    always("configuration.integrations.lockhart.cancelRestoreJobAlert"),
  ],
  [T, always("configuration.integrations.lockhart.cancelBackupJobAlert")],
])

export const cancelLockhartJobs = async jobs => {
  const buttonClicked = await ShowMessageDialog({
    icon: { icon: faExclamation, type: "critical" },
    title: () => <span className="text-danger">{localized("Warning").toUpperCase()}</span>,
    MessageComponent: () => <h3 className="text-danger">{localized(getCancelJobsToken(jobs))}</h3>,
    buttons: [
      { id: "CONTINUEJOB", label: localizationKey("Continue Job") },
      { id: "CANCELJOB", label: localizationKey("Cancel Job"), type: "critical" },
    ],
  })
  if (buttonClicked === "CANCELJOB") {
    jobs.forEach(job => cancelJob(job, job.nodeId))
    return true
  }
}

export const defaultToZeroIfNotPositiveInt = _int => {
  const int = parseInt(_int)
  const isNaNValue = isNaN(int)
  const isNegativeValue = !isNaNValue && int < 0
  if (isNaNValue || isNegativeValue) {
    return 0
  }
  return int
}

export const filterBackupManagerActionsBasedOnPermissions = action => {
  switch (action.id) {
    case RESTORE_ACTION_ID:
    case DOWNLOAD_ACTION_ID:
    case DOWNLOAD_IMAGE_CONTENT_ACTION_ID:
    case RESTORE_KEY_ACTION_ID:
    case IMAGE_BACKUP_TOOL_ACTION_ID:
      return user("canViewAndRestoreBackupData")
    case DELETE_REVISIONS_ACTION_ID:
    case DELETE_PLAN_REVISIONS_ACTION_ID:
      return user("canViewAndManageBackupData")
    default:
      return true
  }
}

export const canCancelLockhartJob = cond([
  [contains(["LOCKHART_BACKUP", "LOCKHART_INTEGRITY_CHECK"]), () => user("canViewAndManageBackupData")],
  [equals("LOCKHART_RESTORE"), () => user("canViewAndRestoreBackupData")],
  [T, F],
])

export const getIconByPlanType = planType => {
  switch (planType) {
    case "FILE_FOLDER":
      return faFolders
    case "IMAGE":
      return faHdd
    default:
      return faNinjaBackup
  }
}

export const getNinjaBackupJobIcon = (jobType, planType) => {
  switch (jobType) {
    case "BACKUP":
      return getIconByPlanType(planType)
    case "UPSYNC":
      return faCloudUpload
    default:
      return faNinjaBackup
  }
}

export const planTypesMap = {
  FILE_FOLDER: "fileFolder",
  IMAGE: "image",
  ARROW_IMAGE: "arrowImage",
}

const getPlanTypeLabelList = () => ({
  image: localized("Legacy"),
  arrowImage: localized("Image"),
  fileFolder: localized("File/folder"),
})

const getLegacyPlanTypeLabelList = () => ({
  fileFolder: localized("File/folder"),
  image: localized("Image"),
})

export const getPlanTypeLabel = planType => {
  if (!planType) return null
  const typeList = isFeatureEnabled("backup_arrow_image") ? getPlanTypeLabelList() : getLegacyPlanTypeLabelList()
  return typeList[planTypesMap[planType] ?? planType]
}

export const isAnyImagePlan = contains(["image", "arrowImage", "IMAGE", "ARROW_IMAGE"])
export const isArrowImagePlan = contains(["arrowImage", "ARROW_IMAGE"])
export const isLegacyImagePlan = contains(["image", "IMAGE"])

export const getLockhartExclusionDefaults = async nodeRole => {
  const getOs = cond([
    [() => isWindowsPolicy(nodeRole), always("WINDOWS")],
    [() => isMacPolicy(nodeRole), always("MAC")],
    [T, always("WINDOWS")],
  ])

  try {
    const lockhartDivisionConfig = await getBackupDivisionConfig("lockhart")
    const isLockhartFeatureAvailable = lockhartDivisionConfig?.enabled
    if (!isLockhartFeatureAvailable) return {}
    const osQueryParam = nodeRole ? `?os=${getOs()}` : ""
    const { systemExclusions } = await fetchJson(`/webapp/lockhart-definitions${osQueryParam}`)
    return groupBy(prop("type"), systemExclusions.patterns)
  } catch (e) {
    ninjaReportError(e)
    return {}
  }
}

export function useTryCatch(initialValue, callBackFn) {
  const [value, setValue] = useMountedState(initialValue)

  useEffect(() => {
    const executeCallBack = async () => {
      try {
        const response = await callBackFn()
        setValue(response)
      } catch (error) {
        reportErrorAndShowMessage(error)
      }
    }
    executeCallBack()
  }, [setValue, callBackFn])

  return value
}

export const deleteDivLockhartData = () => {
  try {
    return fetchJson("/backup/lockhart/revisions", { options: { method: "DELETE" } })
  } catch (e) {
    reportErrorAndShowMessage(e)
  }
}

export const getErrorCodes = ({ client, node }) => {
  const errorCodesEndpoint = node?.id
    ? `/lockhart/filter/devices/${node.id}/job-error-codes`
    : client?.id
    ? `/lockhart/filter/clients/${client.id}/job-error-codes`
    : `/lockhart/filter/job-error-codes`

  return fetchJson(errorCodesEndpoint)
}

export const getScheduledDeletes = ({ client, node, page, size, locationIds }) => {
  const isOrg = !!client
  const nodeRoutePath = node ? `/node/${node.id}` : ""
  const clientRoutePath = isOrg ? `/client/${client.id}` : ""

  return fetchJson(
    `/backup/lockhart${nodeRoutePath || clientRoutePath}/scheduled-deletion?${qs.stringify({
      page,
      size,
    })}`,
  )
}

export const getScheduledDeleteDetail = id => fetchJson(`/backup/lockhart/scheduled-deletions/${id}`)

export const getScheduledDeletesStatus = async (activeRoute, id) => {
  const endpoints = {
    deviceDashboard: `/backup/lockhart/node/${id}/has-scheduled-deletion`,
    customerDashboard: `/backup/lockhart/client/${id}/has-scheduled-deletion`,
    systemDashboard: "/backup/lockhart/has-scheduled-deletion",
  }

  const endpointType = Object.keys(endpoints).find(keyword => activeRoute.includes(keyword))
  if (!endpoints[endpointType]) return false

  const { result } = await fetchJson(endpoints[endpointType])
  return result
}

export const isLegacyImageEnabled = async isLockhartEnabled => {
  if (!isLockhartEnabled) return false

  try {
    const backupUsage = await fetchJson("/backup/lockhart/stats")
    const legacyImageCloudUsage = backupUsage?.usageByBackupType?.cloud?.imageTotalSize ?? 0
    const legacyImageLocalUsage = backupUsage?.usageByBackupType?.local?.imageTotalSize ?? 0
    const hasLegacyBackupUsage = legacyImageCloudUsage + legacyImageLocalUsage > 0

    return isFeatureEnabled("backup_legacy_image") || hasLegacyBackupUsage
  } catch (error) {
    reportErrorAndShowMessage(error)
    return false
  }
}

export const forceDeleteScheduledDelete = id =>
  fetchJson(`/backup/lockhart/scheduled-deletion/${id}/force`, { options: { method: "POST" } })

export const cancelScheduledDelete = id =>
  fetchJson(`/backup/lockhart/scheduled-deletion/${id}/cancellation`, { options: { method: "POST" } })

export const simulateDevicesToEnableBackup = nodeIds =>
  fetchJson("/lockhart/deployment/simulation", {
    options: {
      method: "POST",
      body: JSON.stringify({ nodeIds }),
    },
  })

export const enableBackupOnDevices = nodeIds =>
  fetchJson("/lockhart/deployment/apply", {
    options: {
      method: "POST",
      body: JSON.stringify({ nodeIds }),
    },
  })

export const getLegacyBackupDeletionSummary = nodeIds =>
  fetchJson("/lockhart/legacy-data/deletion-summary", {
    options: {
      method: "POST",
      body: JSON.stringify({ nodeIds }),
    },
  })

export const deleteLegacyBackup = nodeIds =>
  fetchJson("/lockhart/legacy-data/deletion", {
    options: {
      method: "POST",
      body: JSON.stringify({ nodeIds }),
    },
  })

export const mapLockhartConfig = modifyPath(["content", "backup", "lockhart"], translateLockhartMode)

export const getCustomerAndMapLockhartConfig = async ({ clientId }) => {
  try {
    const customerData = await fetchJson(`/client/${clientId}`)
    return mapLockhartConfig(customerData?.client ?? {})
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error getting organization info"))
    return {}
  }
}
