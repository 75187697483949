import { useCallback, useMemo } from "react"
import { isEmpty, isNil, trim } from "ramda"
import { v4 as uuidv4 } from "uuid"
import { Input, Modal } from "@ninjaone/components"
import { Label } from "@ninjaone/components/src/Form/Label"
import { validateXML } from "@ninjaone/components/src/WYSIWYG"
import { spacing } from "@ninjaone/tokens"
import {
  applyMultipleValidations,
  decodeBase64,
  encodeBase64,
  localizationKey,
  localized,
  showErrorMessage,
  showSuccessMessage,
  validations,
} from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"
import { WysiwygXMLEditor } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/common/WysiwygXMLEditor"
import { useForm } from "js/includes/common/hooks"
import { defaultInheritance } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"
import { cleanHtml } from "js/includes/ticketing/editor/shared/components"
import { getFormattingErrorMessage, payloadPolicyAction, showGlobalErrorMessage } from "./utils"

const formValidations = {
  name: applyMultipleValidations([validations.required, validations.maxLength(100)]),
  contentXML: validations.required,
}

export const PayloadEditorModal = ({ unmount, selectedPayload, onSave }) => {
  const initialXml = useMemo(
    () =>
      decodeBase64(selectedPayload?.content ?? "", {
        defaultValue: localized("Error decoding the XML."),
      }),
    [selectedPayload?.content],
  )
  const {
    values: { name, contentXML },
    validation,
    onChange,
    validateForm,
  } = useForm({
    fields: {
      name: selectedPayload?.name ?? "",
      contentXML: initialXml,
    },
    validate: formValidations,
  })

  const isAddModal = isNil(selectedPayload)
  const isInsert = selectedPayload?.action ? selectedPayload?.action === payloadPolicyAction.INSERT : isAddModal

  const onXMLParseError = useCallback(error => showErrorMessage(getFormattingErrorMessage(error)), [])

  const onChangeXml = useCallback(xml => onChange("contentXML", xml), [onChange])

  const onSubmit = () => {
    if (!validateForm()) return
    try {
      validateXML(contentXML)
    } catch (error) {
      onXMLParseError(error)
      return
    }
    if (
      onSave(selectedPayload?.guid ?? uuidv4(), {
        name: trim(name),
        content: isEmpty(contentXML) ? null : encodeBase64(cleanHtml(contentXML)),
        active: selectedPayload?.active ?? true,
        action: isInsert ? payloadPolicyAction.INSERT : payloadPolicyAction.UPDATE,
        ...(isInsert ? defaultInheritance : { id: selectedPayload.id, inheritance: selectedPayload.inheritance }),
      })
    ) {
      unmount()
      showSuccessMessage(
        isAddModal
          ? localized("The custom payload has successfully been created")
          : localized("The custom payload has successfully been updated"),
      )
    }
  }

  return (
    <Modal
      size="md"
      unmount={unmount}
      titleGroup={{ titleText: isAddModal ? localized("Add payload") : localized("Edit payload") }}
      buttons={[
        {
          type: "save",
          labelToken: isAddModal ? localizationKey("Add") : localizationKey("Update"),
          onClick: onSubmit,
        },
      ]}
    >
      <Flex flexDirection="column" gap={spacing[3]}>
        <Input
          name="name"
          labelText={localized("Name")}
          placeholder={localized("Enter name")}
          value={name}
          onChange={onChange}
          errorMessage={validation.message.name}
          required
        />
        <Box>
          <Label
            labelText={localized("Content")}
            tooltipText={localized("The plist format text for the “Content” field must be entered manually.")}
            labelFor="editor-input"
            required
          />
          <Box overflowY="auto" maxHeight="500px">
            <WysiwygXMLEditor
              initialStringContent={isEmpty(initialXml) ? null : initialXml}
              onChange={onChangeXml}
              onInitialParseError={onXMLParseError}
              onError={showGlobalErrorMessage}
              errorMessage={validation.message.contentXML}
            />
          </Box>
        </Box>
      </Flex>
    </Modal>
  )
}
