import { useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { assoc, find, isNil, path, pathOr, propEq } from "ramda"

import { Switch, Select, Tabs, TitleGroup, Stepper, AlertMessage, Text } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { Label } from "@ninjaone/components/src/Form/Label"

import { useMountedState } from "js/includes/common/hooks"
import { localizationKey, localized } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import { updatePolicyItem as _updatePolicyItem } from "js/state/actions/policyEditor/editor"
import InheritableRowPolicyItem, {
  INHERITANCE_WIDTH,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/common/InheritableRowPolicyItem"
import { PasswordPolicyScope, PasswordQuality } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/android/enums"
import { passwordScopeOptions } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/android/options"
import {
  defaultInheritance,
  hasOverridden,
  isPasswordUnspecifiedScopeInUse,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"
import {
  FlexRowWrapper,
  LabelWrapper,
  SelectWrapper,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/common/styled.js"

import { passwordQualityOptions } from "./PasscodeSelector"

const { spacing } = tokens

const RowPolicyItem = ({ children, withBottomBorder }) => (
  <FlexRowWrapper
    width={`calc(100% - ${INHERITANCE_WIDTH} - ${spacing[2]})`}
    padding={[spacing[3], spacing[2]]}
    {...(withBottomBorder && {
      borderBottomStyle: "solid",
      borderBottomWidth: "1px",
      borderBottomColor: "ninjaLight",
    })}
  >
    {children}
  </FlexRowWrapper>
)

const root = "passcodeRules"

const keys = {
  expirationSettings: "expirationSettings",
  expirationTimeout: "expirationTimeout",
  passwordSettings: "passwordSettings",
  passwordQuality: "passwordQuality",
  minimumLength: "minimumLength",
  minimumLetters: "minimumLetters",
  minimumLowerCase: "minimumLowerCase",
  minimumNonLetter: "minimumNonLetter",
  minimumNumeric: "minimumNumeric",
  minimumSymbols: "minimumSymbols",
  minimumUpperCase: "minimumUpperCase",
  fallbackSettings: "fallbackSettings",
}

const requirePasswordUnlockOptions = [
  {
    labelToken: localizationKey("Unspecified"),
    value: "REQUIRE_PASSWORD_UNLOCK_UNSPECIFIED",
  },
  {
    labelToken: localizationKey("Use default device timeout"),
    value: "USE_DEFAULT_DEVICE_TIMEOUT",
  },
  {
    labelToken: localizationKey("Require every day"),
    value: "REQUIRE_EVERY_DAY",
  },
]

const PASSWORD_QUALITY_DEFAULT_OPTION = PasswordQuality.PASSWORD_QUALITY_UNSPECIFIED

const defaultValues = {
  generalSettings: { enabled: false, ...defaultInheritance },
  unlockSettings: { requirePasswordUnlock: "USE_DEFAULT_DEVICE_TIMEOUT", ...defaultInheritance },
  passwordSettings: {
    passwordQuality: PASSWORD_QUALITY_DEFAULT_OPTION,
    minimumLength: 0,
    minimumLetters: 0,
    minimumLowerCase: 0,
    minimumNonLetter: 0,
    minimumNumeric: 0,
    minimumSymbols: 0,
    minimumUpperCase: 0,
    ...defaultInheritance,
  },
  fallbackSettings: { ...defaultInheritance },
  historySettings: { historyLength: 0, ...defaultInheritance },
  failedSettings: { maximumFailedPasswordsForWipe: 0, ...defaultInheritance },
  expirationSettings: { expirationTimeout: "0s", ...defaultInheritance },
}

const passwordQualityOptionsRules = {
  PASSWORD_QUALITY_UNSPECIFIED: [],
  BIOMETRIC_WEAK: [],
  SOMETHING: [],
  NUMERIC: [keys.minimumLength],
  NUMERIC_COMPLEX: [keys.minimumLength],
  ALPHABETIC: [keys.minimumLength],
  ALPHANUMERIC: [keys.minimumLength],
  COMPLEX: [
    keys.minimumLength,
    keys.minimumLetters,
    keys.minimumLowerCase,
    keys.minimumNonLetter,
    keys.minimumNumeric,
    keys.minimumSymbols,
    keys.minimumUpperCase,
  ],
  COMPLEXITY_LOW: [],
  COMPLEXITY_MEDIUM: [],
  COMPLEXITY_HIGH: [],
}

const MAXIMUM_EXPIRATION_TIMEOUT_IN_DAYS = 10000 * 365

const SECONDS_IN_A_DAY = 86399

const passwordQualityComplexityBucket = [
  PasswordQuality.COMPLEXITY_LOW,
  PasswordQuality.COMPLEXITY_MEDIUM,
  PasswordQuality.COMPLEXITY_HIGH,
]

const complexityBucketFallbacks = {
  [PasswordQuality.COMPLEXITY_LOW]: {
    [keys.passwordQuality]: PasswordQuality.SOMETHING,
  },
  [PasswordQuality.COMPLEXITY_MEDIUM]: {
    [keys.passwordQuality]: PasswordQuality.NUMERIC_COMPLEX,
    [keys.minimumLength]: 4,
  },
  [PasswordQuality.COMPLEXITY_HIGH]: {
    [keys.passwordQuality]: PasswordQuality.ALPHANUMERIC,
    [keys.minimumLength]: 8,
  },
}

const passwordQualityOptionsWithoutComplexityBucket = passwordQualityOptions.filter(
  ({ value }) => !passwordQualityComplexityBucket.includes(value),
)

export const formatExpirationTimeout = intValueDays => `${intValueDays * SECONDS_IN_A_DAY}s`

const getPasswordRulesLabels = () => ({
  minimumLength: localized("Minimum length"),
  minimumLetters: localized("Minimum letters"),
  minimumLowerCase: localized("Minimum lowercase"),
  minimumNonLetter: localized("Minimum non-letter"),
  minimumNumeric: localized("Minimum numeric"),
  minimumSymbols: localized("Minimum symbols"),
  minimumUpperCase: localized("Minimum uppercase"),
  historyLength: localized("History length"),
  maximumFailedPasswordsForWipe: localized("Maximum failed passwords for wipe"),
  fallbackPasswordQuality: localized("Fallback password quality"),
})

export const PasscodeScopeTab = ({ scope, passcodeRules, updatePolicyItem, parentPolicy }) => {
  const isUnspecifiedScope = scope === PasswordPolicyScope.SCOPE_UNSPECIFIED
  const isProfileScope = scope === PasswordPolicyScope.SCOPE_PROFILE

  const passwordRulesLabels = useMemo(getPasswordRulesLabels, [])

  const disabled = !path([scope, "generalSettings", "enabled"], passcodeRules) || isUnspecifiedScope

  const passwordQuality =
    path([scope, "passwordSettings", "passwordQuality"], passcodeRules) ?? PASSWORD_QUALITY_DEFAULT_OPTION

  const qualityOptions = passwordQualityOptionsRules[passwordQuality]

  const fallbackSettings = passcodeRules[scope]?.fallbackSettings ?? defaultValues.fallbackSettings

  const needsFallbackPasswordRules = passwordQuality ? passwordQualityComplexityBucket.includes(passwordQuality) : false

  const fallbackQualityOptions = fallbackSettings?.passwordSettings?.passwordQuality
    ? passwordQualityOptionsRules[fallbackSettings.passwordSettings.passwordQuality]
    : []

  const expirationTimeoutToDays = timeoutString => {
    if (isNil(timeoutString)) return 0

    const secs = Number.parseInt(String(timeoutString).replace("s", ""), 10)

    // Policies before seconds to days change might have a timeout between 1s and < 1 day,
    // these should be set to 1 day
    if (secs < SECONDS_IN_A_DAY && secs > 0) {
      updatePolicy(keys.expirationSettings, keys.expirationTimeout, `${SECONDS_IN_A_DAY}s`)
      return 1
    }

    return secs / SECONDS_IN_A_DAY
  }

  const updatePolicy = (key, valueFieldKey, value) => {
    let targetPath = key
    const keyList = key.split(".")

    if (value === path([scope, ...keyList, valueFieldKey], passcodeRules)) return

    const curentValue = pathOr(path([...keyList], defaultValues), [scope, ...keyList], passcodeRules)

    let newPasscodeRules = assoc(valueFieldKey, value, curentValue)

    if (keyList.length > 1 && key.includes(keys.fallbackSettings)) {
      newPasscodeRules = {
        ...fallbackSettings,
        passwordSettings: { ...fallbackSettings.passwordSettings, [valueFieldKey]: value },
      }
      targetPath = keys.fallbackSettings
    }

    updatePolicyItem(`passcodeRules.${scope}.${targetPath}`, parentPolicy, newPasscodeRules)
  }

  const unspecifiedScopeDeprecationMessage = () => {
    const parentHasDefinedUnspecified = isPasswordUnspecifiedScopeInUse(parentPolicy?.content?.passcodeRules)

    // Parent policy or child with parent that doesn't have unspecified scope
    if (parentPolicy === null || !parentHasDefinedUnspecified) {
      return localized(
        "Please use the device or profile scopes. This unspecified scope will be removed once there’s a change in the policy.",
      )
    }

    // Child with parent that has a set unspecified scope
    return localized(
      "Please use the device or profile scopes. Any overridden values in this unspecified scope will be removed once there's a change in the policy. Please update the parent policy to remove the deprecated scope from it and prevent children policies from inheriting it.",
    )
  }

  // Handle side-effects of main / fallback password quality changes
  // Can happen via update policy or inheritance revert
  useEffect(() => {
    // Fallback password: only applicable to device scope
    if (!isProfileScope) {
      const inheritance = fallbackSettings?.inheritance ?? defaultValues.fallbackSettings.inheritance

      const fallback = complexityBucketFallbacks[passwordQuality]
        ? { passwordSettings: fallbackSettings.passwordSettings ?? complexityBucketFallbacks[passwordQuality] }
        : {}

      updatePolicyItem(`${root}.${scope}.${keys.fallbackSettings}`, parentPolicy, {
        ...fallback,
        inheritance,
      })
    }
  }, [
    passwordQuality,
    fallbackSettings.passwordSettings,
    fallbackSettings?.inheritance,
    isProfileScope,
    scope,
    updatePolicyItem,
    parentPolicy,
  ])

  return (
    <Box overflowY="auto" maxHeight="800px" paddingBottom="360px">
      {isUnspecifiedScope && (
        <Box marginTop={spacing[3]} marginRight={spacing[3]}>
          <AlertMessage titleToken={localizationKey("Unspecified scopes are deprecated")}>
            {unspecifiedScopeDeprecationMessage()}
          </AlertMessage>
        </Box>
      )}

      <InheritableRowPolicyItem
        pathToItem={`passcodeRules.${scope}.generalSettings`}
        inheritableItem={path([scope, "generalSettings"], passcodeRules) ?? defaultValues.generalSettings}
        wrapperProps={{ padding: `${spacing[7]} ${spacing[2]}` }}
      >
        <Switch
          disabled={isUnspecifiedScope}
          labelToken={localizationKey("Enabled")}
          checked={path([scope, "generalSettings", "enabled"], passcodeRules) ?? false}
          onChange={checked => updatePolicy("generalSettings", "enabled", checked)}
        />
      </InheritableRowPolicyItem>

      <InheritableRowPolicyItem
        pathToItem={`passcodeRules.${scope}.unlockSettings`}
        inheritableItem={path([scope, "unlockSettings"], passcodeRules) ?? defaultValues.unlockSettings}
      >
        <FlexRowWrapper>
          <LabelWrapper>
            <Label labelFor="unlockSettings" labelText={localizationKey("Require password to unlock")} />
          </LabelWrapper>
          <SelectWrapper>
            <Select
              {...{ disabled }}
              triggerAriaLabel={localized("Require password to unlock")}
              labelId="unlockSettings"
              options={requirePasswordUnlockOptions}
              value={
                path([scope, "unlockSettings", "requirePasswordUnlock"], passcodeRules) ??
                requirePasswordUnlockOptions[0].value
              }
              onChange={selected => updatePolicy("unlockSettings", "requirePasswordUnlock", selected)}
            />
          </SelectWrapper>
        </FlexRowWrapper>
      </InheritableRowPolicyItem>

      {[
        { key: "historySettings", valueFieldKey: "historyLength" },
        { key: "failedSettings", valueFieldKey: "maximumFailedPasswordsForWipe" },
      ].map(({ key, valueFieldKey }) => (
        <InheritableRowPolicyItem
          key={valueFieldKey}
          pathToItem={`passcodeRules.${scope}.${key}`}
          inheritableItem={path([scope, key], passcodeRules) ?? defaultValues[key]}
        >
          <FlexRowWrapper>
            {/* TODO: epic/NJ-87732-mdm-amapi-solution-set-part-3 use Stepper Label once design changes are confirmed */}
            <LabelWrapper>
              <Label labelFor={valueFieldKey} labelText={passwordRulesLabels[valueFieldKey]} />
            </LabelWrapper>
            <Stepper
              {...{ disabled }}
              ariaLabel={passwordRulesLabels[valueFieldKey]}
              min={0}
              max={100}
              value={path([scope, key, valueFieldKey], passcodeRules) ?? 0}
              onChange={value => updatePolicy(key, valueFieldKey, value)}
              compact
            />
          </FlexRowWrapper>
        </InheritableRowPolicyItem>
      ))}

      <InheritableRowPolicyItem
        pathToItem={`passcodeRules.${scope}.expirationSettings`}
        inheritableItem={path([scope, "expirationSettings"], passcodeRules) ?? defaultValues.expirationSettings}
      >
        <FlexRowWrapper>
          {/* TODO: epic/NJ-87732-mdm-amapi-solution-set-part-3 use Stepper Label once design changes are confirmed */}
          <LabelWrapper>
            <Label labelFor="expirationTimeout" labelText={localized("Maximum passcode age (days)")} />
          </LabelWrapper>
          <Stepper
            {...{ disabled }}
            ariaLabel={localized("Maximum passcode age (days)")}
            min={0}
            max={MAXIMUM_EXPIRATION_TIMEOUT_IN_DAYS}
            value={expirationTimeoutToDays(path([scope, "expirationSettings", "expirationTimeout"], passcodeRules))}
            onChange={value => updatePolicy("expirationSettings", "expirationTimeout", formatExpirationTimeout(value))}
            compact
          />
        </FlexRowWrapper>
      </InheritableRowPolicyItem>

      <InheritableRowPolicyItem
        testId="passwordQuality"
        pathToItem={`passcodeRules.${scope}.passwordSettings`}
        inheritableItem={path([scope, "passwordSettings"], passcodeRules) ?? defaultValues.passwordSettings}
        hasMultipleConfigs
        noBorder={qualityOptions.length > 0}
      >
        <FlexRowWrapper>
          <LabelWrapper>
            <Label labelFor="passwordSettings" labelText={localizationKey("Password quality")} />
          </LabelWrapper>
          <SelectWrapper>
            <Select
              {...{ disabled }}
              labelId="passwordSettings"
              triggerAriaLabel={localized("Password quality")}
              options={isProfileScope ? passwordQualityOptionsWithoutComplexityBucket : passwordQualityOptions}
              value={passwordQuality}
              onChange={value => {
                // Clear previous fallback to ensure new pre-selection according to new password quality
                if (fallbackSettings.passwordSettings) {
                  const { inheritance } = fallbackSettings ?? defaultValues.fallbackSettings
                  updatePolicyItem(`${root}.${scope}.${keys.fallbackSettings}`, parentPolicy, {
                    inheritance,
                  })
                }

                updatePolicy(keys.passwordSettings, keys.passwordQuality, value)
              }}
              labelRenderer={() => {
                const { LabelComponent } = find(
                  propEq("value", passwordQuality ?? PASSWORD_QUALITY_DEFAULT_OPTION),
                  passwordQualityOptions,
                )
                return <LabelComponent />
              }}
            />
          </SelectWrapper>
        </FlexRowWrapper>
      </InheritableRowPolicyItem>

      {qualityOptions.map((valueFieldKey, index) => (
        <RowPolicyItem
          key={valueFieldKey}
          withBottomBorder={qualityOptions.length - 1 === index && needsFallbackPasswordRules}
        >
          {/* TODO: epic/NJ-87732-mdm-amapi-solution-set-part-3 use Stepper Label once design changes are confirmed */}
          <LabelWrapper>
            <Label labelFor={valueFieldKey} labelText={passwordRulesLabels[valueFieldKey]} />
          </LabelWrapper>
          <Stepper
            {...{ disabled }}
            min={0}
            max={100}
            ariaLabel={passwordRulesLabels[valueFieldKey]}
            value={path([scope, "passwordSettings", valueFieldKey], passcodeRules) ?? 0}
            onChange={value => updatePolicy("passwordSettings", valueFieldKey, value)}
            compact
          />
        </RowPolicyItem>
      ))}

      {needsFallbackPasswordRules && !!fallbackSettings?.passwordSettings?.passwordQuality && (
        <>
          <InheritableRowPolicyItem
            testId="fallbackPasswordSettings"
            pathToItem={`${root}.${scope}.${keys.fallbackSettings}`}
            inheritableItem={fallbackSettings}
            noBorder
          >
            <FlexRowWrapper>
              <LabelWrapper>
                <Label labelFor="fallbackPasswordQuality" labelText={passwordRulesLabels.fallbackPasswordQuality} />
                <Text
                  token={localizationKey("For devices that don’t support complexity password quality options")}
                  type="body"
                  textWrapLineLimit={5}
                  textWrap
                />
              </LabelWrapper>

              <SelectWrapper>
                <Select
                  {...{ disabled }}
                  triggerAriaLabel={passwordRulesLabels.fallbackPasswordQuality}
                  options={passwordQualityOptionsWithoutComplexityBucket}
                  value={fallbackSettings?.passwordSettings?.passwordQuality}
                  onChange={value =>
                    updatePolicy(`${keys.fallbackSettings}.${keys.passwordSettings}`, keys.passwordQuality, value)
                  }
                  labelRenderer={() => {
                    const currentValue = find(propEq("value", fallbackSettings?.passwordSettings?.passwordQuality))(
                      passwordQualityOptions,
                    )
                    return <currentValue.LabelComponent />
                  }}
                />
              </SelectWrapper>
            </FlexRowWrapper>
          </InheritableRowPolicyItem>

          {fallbackQualityOptions.map((field, index) => (
            <RowPolicyItem key={field} withBottomBorder={false}>
              {/* TODO: epic/NJ-87732-mdm-amapi-solution-set-part-3 use Stepper Label once design changes are confirmed */}
              <LabelWrapper>
                <Label labelFor={field} labelText={passwordRulesLabels[field]} />
              </LabelWrapper>
              <Stepper
                {...{ disabled }}
                ariaLabel={passwordRulesLabels[field]}
                min={0}
                max={100}
                value={path([scope, keys.fallbackSettings, keys.passwordSettings, field], passcodeRules) ?? 0}
                onChange={value =>
                  updatePolicy(`${keys.fallbackSettings}.${keys.passwordSettings}`, keys[field], value)
                }
                compact
              />
            </RowPolicyItem>
          ))}
        </>
      )}
    </Box>
  )
}

const ConnectedPasscodeScopeTab = connect(
  ({ policyEditor }) => ({
    passcodeRules: path(["policy", "content", "passcodeRules"], policyEditor),
    parentPolicy: policyEditor.parentPolicy,
  }),
  {
    updatePolicyItem: _updatePolicyItem,
  },
)(PasscodeScopeTab)

function PasscodeForm({ passcodeRules, parentPolicy }) {
  const hasDefinedUnspecifiedScope = isPasswordUnspecifiedScopeInUse(passcodeRules)

  const isChild = !!passcodeRules[PasswordPolicyScope.SCOPE_DEVICE]?.generalSettings?.inheritance?.sourcePolicyId

  // Child policy with no overriddens for unspecified scope and a parent with unspecified scope
  const isChildThatFullyInheritsUnspecifiedScope =
    isChild &&
    !hasOverridden(passcodeRules[PasswordPolicyScope.SCOPE_UNSPECIFIED]) &&
    isPasswordUnspecifiedScopeInUse(parentPolicy?.content?.passcodeRules)

  // Parent policy is null, needs to finish loading
  const needsToWaitForParentPolicy = isChild && parentPolicy === null

  const availableTabs = useMemo(
    () =>
      passwordScopeOptions.reduce((acc, { scope, labelToken }, index) => {
        const isUnpsecifiedScopeTab = scope === PasswordPolicyScope.SCOPE_UNSPECIFIED

        // Handle deprecated unspecified scope: only include if the policy has it set or due to inheritance reasons
        if (!isUnpsecifiedScopeTab || hasDefinedUnspecifiedScope || isChildThatFullyInheritsUnspecifiedScope) {
          acc.push({
            index,
            key: scope,
            labelToken,
            renderer: () =>
              isUnpsecifiedScopeTab && isChildThatFullyInheritsUnspecifiedScope ? (
                <Box marginTop={spacing[3]} marginRight={spacing[3]}>
                  <AlertMessage titleToken={localizationKey("Unspecified scopes are deprecated")}>
                    {localized(
                      "This child policy doesn't have an unspecified scope, but the parent still does. Please update parent policy to completely remove the deprecated unspecified scope.",
                    )}
                  </AlertMessage>
                </Box>
              ) : (
                <ConnectedPasscodeScopeTab {...{ scope }} />
              ),
          })
        }

        return acc
      }, []),
    [hasDefinedUnspecifiedScope, isChildThatFullyInheritsUnspecifiedScope],
  )

  const [currentTab, setCurrentTab] = useMountedState(availableTabs[0])

  const tabIndex = useMemo(() => {
    const currentTabIndex = availableTabs.findIndex(tab => tab.key === currentTab.key)

    return currentTabIndex === -1 ? 0 : currentTabIndex
  }, [availableTabs, currentTab])

  return (
    <>
      <Box paddingLeft={spacing[6]} paddingBottom={spacing[6]}>
        <TitleGroup
          titleToken={localizationKey("Passcode")}
          descriptionToken={localizationKey("Manage and define passcode settings.")}
        />
      </Box>
      <Box marginLeft={spacing[6]}>
        {!needsToWaitForParentPolicy && (
          <Tabs index={tabIndex} tabs={availableTabs} onChange={tab => setCurrentTab(tab)} />
        )}
      </Box>
    </>
  )
}

export default connect(({ policyEditor }) => ({
  passcodeRules: policyEditor.policy?.content?.passcodeRules ?? {},
  parentPolicy: policyEditor.parentPolicy,
}))(PasscodeForm)
