import { Fragment } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { getTextSize, sizer } from "@ninjaone/utils"
import tokens from "@ninjaone/tokens"
import NavActionIcons from "./NavActionIcons"

const StyledButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 2px;
  overflow: hidden;

  &:hover {
    background-color: ${({ theme }) => theme.colorThemeBackgroundHover};
  }
`

export const getMainNavStyle = ({ collapsed }) => theme => css`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  height: ${sizer(8)};

  && {
    color: ${theme.colorThemeText};

    svg {
      &.main-icon {
        font-size: ${getTextSize("md")};
        flex-shrink: 0;
      }

      &.secondary-icon {
        font-size: ${getTextSize("sm")};
      }
    }
  }

  ${!collapsed &&
    `
    padding-right: ${sizer(4)};
    padding-left: ${sizer(4)};
  `}
`

export const mainNavTextStyle = css`
  font-size: ${getTextSize("sm")};
  flex: 1;
  margin-left: ${sizer(3)};
`

const getButtonStyle = ({ active }) => theme => css`
  width: 100%;
  background-color: transparent;
  border: none;
  text-align: left;

  ${active &&
    `
    color: ${theme.colorThemeTextStrong};
    background-color: ${theme.colorThemeBackgroundSelected};
  `}

  ${!active &&
    `
    &:hover {
      background-color: ${theme.colorThemeBackgroundSelected};
    }
  `}

  &:focus-visible {
    background-color: ${theme.colorThemeBackgroundSelected};
    outline: 2px solid ${theme.colorForegroundFocus};
    outline-offset: -2px;
  }
`

const StyledIconWrapper = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  right: ${({ right }) => right || tokens.spacing[10]};
  display: flex;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.colorThemeText};
  }
`

const MainNavButton = ({
  icon,
  actionIcons,
  secondaryIcon,
  label,
  collapsed,
  onClick,
  onClickAction,
  active,
  expanded,
}) => {
  const mainNavStyle = getMainNavStyle({ collapsed })
  const buttonStyle = getButtonStyle({ active })

  return (
    <StyledButtonWrapper>
      <button
        {...{
          onClick,
          "data-test-sidebar-link": "dashboard-title-link",
          "aria-label": label,
          css: [mainNavStyle, buttonStyle],
          ...(!collapsed && { "aria-expanded": expanded }),
        }}
      >
        <FontAwesomeIcon icon={icon} className="main-icon" data-test-sidebar-link="dashboard-title-icon" fixedWidth />
        {!collapsed && (
          <>
            <span css={mainNavTextStyle}>{label}</span>
            {secondaryIcon && <FontAwesomeIcon className="secondary-icon" icon={secondaryIcon} />}
          </>
        )}
      </button>
      {!collapsed && (
        <StyledIconWrapper>
          <NavActionIcons {...{ actionIcons }} />
        </StyledIconWrapper>
      )}
    </StyledButtonWrapper>
  )
}

export default MainNavButton
