import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { sizer } from "@ninjaone/utils"
import { Heading } from "@ninjaone/components"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"
import { StyledFontAwesomeIcon, Box } from "@ninjaone/webapp/src/js/includes/components/Styled"

const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${sizer(3)};
  color: ${({ theme }) => theme.color.black["075"]};
`

const StyledText = styled.div`
  max-width: 730px;
`

export default function DashboardTitle({ icon, titleText, titleToken, tokenArgs, children }) {
  const showIcon = icon || children

  return (
    <Box display="inline-flex" alignItems="center">
      {showIcon && (
        <StyledIconContainer>
          {children ?? <StyledFontAwesomeIcon icon={icon} fontSize="20px" fixedWidth />}
        </StyledIconContainer>
      )}
      <StyledText>
        <Heading level={1}>{titleToken ? localized(titleToken, tokenArgs) : titleText}</Heading>
      </StyledText>
    </Box>
  )
}

DashboardTitle.propTypes = {
  icon: PropTypes.object,
  titleText: PropTypes.string,
  titleToken: PropTypes.string,
  tokenArgs: PropTypes.object,
  children: PropTypes.element,
}
