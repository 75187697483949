import PropTypes from "prop-types"
import { localizationKey, localized } from "js/includes/common/utils"
import { keys, reduce } from "ramda"

export const productTypes = {
  USER_PRODUCT: "USER_PRODUCT",
  SOFTWARE: "SOFTWARE",
  HARDWARE: "HARDWARE",
  PRODUCT_GROUP: "PRODUCT_GROUP",
  MANAGED_DEVICES: "MANAGED_DEVICES",
  LABOR_BILLED: "LABOR_BILLED",
  LABOR_TICKET_TIME_ENTRY: "LABOR_TICKET_TIME_ENTRY",
  CUSTOM_PRODUCT: "CUSTOM_PRODUCT",
}

export const productTypeTokenMapper = {
  [productTypes.USER_PRODUCT]: localizationKey("User"),
  [productTypes.SOFTWARE]: localizationKey("Software"),
  [productTypes.HARDWARE]: localizationKey("Hardware"),
  [productTypes.PRODUCT_GROUP]: localizationKey("Product Group"),
  [productTypes.MANAGED_DEVICES]: localizationKey("Managed Devices"),
  [productTypes.LABOR_BILLED]: localizationKey("Labor Billed"),
  [productTypes.LABOR_TICKET_TIME_ENTRY]: localizationKey("Labor Ticket Time Entry"),
  [productTypes.CUSTOM_PRODUCT]: localizationKey("Custom"),
}

export const getProductTypeMapper = () =>
  reduce(
    (acc, productType) => {
      acc[productType] = localized(productTypeTokenMapper[productType])
      return acc
    },
    {},
    keys(productTypeTokenMapper),
  )

export const productsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    type: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    createTime: PropTypes.number.isRequired,
    updateTime: PropTypes.number,
  }),
)

export const getBillingStatusData = billingStatus => {
  const statuses = {
    BILLED: {
      id: "BILLED",
      labelText: localized("Billed"),
      variant: "enabled",
    },
    NOT_BILLABLE: {
      id: "NOT_BILLABLE",
      labelText: localized("Not Billable"),
      variant: "disabled",
    },
    PENDING: {
      id: "PENDING",
      labelText: localized("Pending"),
      variant: "default",
    },
  }
  return statuses[billingStatus]
}

export const getBillingStatusFilterOptions = () => [
  { value: "BILLED", label: localized("Billed") },
  { value: "NOT_BILLABLE", label: localized("Not Billable") },
  { value: "PENDING", label: localized("Pending") },
]
