import { isSystemAdmin } from "js/includes/common/utils"
import { isGranted } from "js/includes/common/services/userPermissions"
import { entityType } from "js/includes/editors/User/Permissions/Ticketing/entities"
import { devicesBoardId, organizationsBoardId } from "js/includes/configuration/integrations/ticketing/utils"

/**
 * A - is ticketing CRUD enabled from legacy permissions ( Also checks for sys admin )
 * B - is ticketing admin
 * C - is sys admin alone
 */

/**
 * With new Permissions only check if
 * the [ feature is enabled ( flag and settings ) ] AND [ new permission check ]
 *
 * For old framework
 * - To show anything ticketing related: A || B
 * - To show administrative section: B || C
 * - To view and manage pending emails: B || C
 */

export const isTicketingEnabledFromSettings = () => window.store.getState()?.ticketing?.configurations?.enabled

export const hasTicketingAdministrativeOnlyAccess = () =>
  isSystemAdmin() || isUserAllowedUseTicketingAdministrativeActions()

export const isUserAllowedToViewBoards = () => {
  return isTicketingEnabledFromSettings() && isGranted({ entityType, permissionName: "Board.View" })
}

export const isUserAllowedToViewBoardById = entityId => {
  return isTicketingEnabledFromSettings() && isGranted({ entityType, permissionName: "Board.View", entityId })
}

export const isUserAllowedToCreateTicket = () => {
  return isTicketingEnabledFromSettings() && isGranted({ entityType, permissionName: "Ticket.Create" })
}

export const isUserAllowedToViewTicket = () => {
  return isTicketingEnabledFromSettings() && isGranted({ entityType, permissionName: "Ticket.View" })
}

export const isUserAllowedToViewTicketNavigation = () => {
  return isTicketingEnabledFromSettings() && isGranted({ entityType, permissionName: "Ticket.View" })
}

export const isUserAllowedToUpdateTicket = () => {
  return isTicketingEnabledFromSettings() && isGranted({ entityType, permissionName: "Ticket.Update" })
}

export const isUserAllowedToDeleteTicket = () => {
  if (!isTicketingEnabledFromSettings()) return false

  return isGranted({ entityType, permissionName: "Ticket.Delete" })
}

export const isUserAllowedUseTicketingAdministrativeActions = () => {
  return isGranted({ entityType, permissionName: "Configuration.Ticketing.Access" })
}

export const isUserAllowedToViewPendingEmails = () => {
  if (!isTicketingEnabledFromSettings()) return false

  return isGranted({ entityType, permissionName: "PendingEmailBoard.View" })
}

export const isUserAllowedToManagePendingEmails = () => {
  if (!isTicketingEnabledFromSettings()) return false

  return isGranted({ entityType, permissionName: "PendingEmailBoard.Manage" })
}

export const canUserUpdateOwnedTrackedTimeOfClosedTickets = () =>
  isTicketingEnabledFromSettings() && isGranted({ entityType, permissionName: "TimeTracked.Update.Own" })

export const canUserUpdateAnyTrackedTimeOfClosedTickets = () =>
  isTicketingEnabledFromSettings() && isGranted({ entityType, permissionName: "TimeTracked.Update.All" })

export const canUserViewDeviceTicketsBoard = () =>
  isUserAllowedToViewTicket() && isUserAllowedToViewBoardById(devicesBoardId)

export const canUserViewOrganizationTicketsBoard = () =>
  isUserAllowedToViewTicket() && isUserAllowedToViewBoardById(organizationsBoardId)

export const ticketingWampOperations = {
  pendingEmails: ["PENDING_EMAIL_RECEIVED", "PENDING_EMAIL_APPROVED", "PENDING_EMAIL_REJECTED"],
  tickets: ["TICKET_UPDATED", "TICKET_CREATED", "TICKET_DELETED", "TICKET_RESTORED", "TICKET_PERMANENT_DELETED"],
}

export const TICKETING_WAMP_REFRESH_DELAY_IN_MILLIS = 60000
