import { localized } from "js/includes/common/utils"

//Agreement Intervals
export const ANNUAL = "ANNUAL"
export const SEMIANNUAL = "SEMIANNUAL"
export const QUARTERLY = "QUARTERLY"
export const MONTHLY = "MONTHLY"
export const BIMONTHLY = "BIMONTHLY"
export const WEEKLY = "WEEKLY"
export const BIWEEKLY = "BIWEEKLY"
export const ONE_TIME = "ONE_TIME"

//Billing cycles
export const ON_EVERY_BILL_DATE = "ON_EVERY_BILL_DATE"
export const ON_CONTRACT_START = "ON_CONTRACT_START"
export const ON_CONTRACT_END = "ON_CONTRACT_END"

export const intervalOptions = [
  { value: ANNUAL, label: localized("Annual") },
  { value: SEMIANNUAL, label: localized("Semiannual") },
  { value: QUARTERLY, label: localized("Quarterly") },
  { value: MONTHLY, label: localized("Monthly") },
  { value: BIMONTHLY, label: localized("Bimonthly") },
  { value: WEEKLY, label: localized("Weekly") },
  { value: BIWEEKLY, label: localized("Biweekly") },
  { value: ONE_TIME, label: localized("One Time") },
]

export const intervalLabelMapper = {
  [ANNUAL]: localized("Annual"),
  [SEMIANNUAL]: localized("Semiannual"),
  [QUARTERLY]: localized("Quarterly"),
  [MONTHLY]: localized("Monthly"),
  [BIMONTHLY]: localized("Bimonthly"),
  [WEEKLY]: localized("Weekly"),
  [BIWEEKLY]: localized("Biweekly"),
  [ONE_TIME]: localized("One Time"),
}

export const billingCycleOptions = [
  { value: ON_EVERY_BILL_DATE, label: localized("On Every Bill Date") },
  { value: ON_CONTRACT_START, label: localized("On Contract Start") },
  { value: ON_CONTRACT_END, label: localized("On Contract End") },
]
