import { unix } from "moment"
import { propOr } from "ramda"

import styled from "@emotion/styled"
import { Text } from "@ninjaone/components"

import { date, localizeSource, localized } from "js/includes/common/utils"
import { localizeStatuses } from "js/includes/ticketing/commonMethods"

const TextWrapper = styled.span`
  ${({ isOld, theme }) =>
    isOld
      ? `
    text-decoration: line-through;
    text-decoration-color: ${theme.colorTextWeakest};
  `
      : ""}
`

export const renderAsText = ({ value, isOld = false }) => {
  const stringValue = Array.isArray(value) ? value.join(", ") : value
  return (
    <TextWrapper isOld={isOld}>
      <Text type="body" color={isOld ? "colorTextWeakest" : "colorTextWeak"}>
        {stringValue}
      </Text>
    </TextWrapper>
  )
}

const renderArrayAsText = ({ array, noValueLabel, isOld }) => {
  return array?.length ? renderAsText({ value: array, isOld }) : renderAsText({ value: noValueLabel, isOld })
}

export const renderTags = ({ tags, isOld }) =>
  renderArrayAsText({ array: tags, noValueLabel: localized("No Tags"), isOld })

export const getCCListValues = data => [...(data?.uids || []).map(({ name }) => name), ...(data?.emails || [])]

export const renderCCList = ({ data, isOld }) => {
  const values = getCCListValues(data)
  return renderArrayAsText({ array: values, noValueLabel: localized("No CC List"), isOld })
}

export const renderAttribute = ({ value, isDate, isOld }) =>
  renderAsText({ value: isDate ? date(unix(value)) : value, isOld })

export const renderTypePriorityOrSeverity = ({ value, parent, isOld }) => {
  const isIncident = value === "INCIDENT"
  return renderAsText({
    value: isIncident ? `${localizeStatuses(value)} (#${parent})` : localizeStatuses(value),
    isOld,
  })
}

export const renderStatus = ({ data, isOld }) => renderAsText({ value: propOr(data, "name", data), isOld })
export const renderSummary = ({ summary, isOld }) => renderAsText({ value: summary, isOld })
export const renderObjectName = ({ object, isOld }) => renderAsText({ value: object.name || "-", isOld })
export const renderSource = ({ source, isOld }) => renderAsText({ value: localizeSource(source), isOld })
