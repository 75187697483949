import { Fragment, memo, useMemo } from "react"
import { propEq } from "ramda"
import { Accordion, Heading, Body } from "@ninjaone/components"
import { spacing } from "@ninjaone/tokens"
import { StyledBox as WidgetStyledBox } from "js/includes/configuration/apps/components/ConfigurationWidget"
import { capitalizeFirstLetter, isNilOrEmpty, localized, yesNo } from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"
import { ManagedConfigType } from "./utils"

export const renderValue = ({ type, value, entries }) => {
  if (isNilOrEmpty(value)) return "-"

  switch (type) {
    case ManagedConfigType.BOOL:
      return yesNo(value)
    case ManagedConfigType.CHOICE:
      const optionProperties = entries?.find(propEq("value", value))
      return optionProperties ? optionProperties.name : value
    case ManagedConfigType.MULTISELECT:
      if (!entries) return value
      const labels = (value ?? []).map(val => {
        const { name } = entries?.find(propEq("value", val)) ?? {}
        return name ?? val
      })
      return labels.join(", ")
    default:
      return value
  }
}

export const BundleArrayGroupValues = memo(({ groupValue = {}, properties = [], addMargins = false, level = 1 }) => {
  const labelValuePairs = useMemo(() => {
    return properties.map(({ key, title, type, nestedProperties, entries }) => ({
      labelText: capitalizeFirstLetter(title),
      value: groupValue[key],
      nestedProperties,
      type,
      entries,
      key,
    }))
  }, [groupValue, properties])

  return (
    <Flex flexDirection="column" gap={spacing[2]}>
      {labelValuePairs.map(({ labelText, value, nestedProperties, type, entries, key }) => (
        <Fragment {...{ key }}>
          {nestedProperties ? (
            <Box {...(addMargins && { margin: [spacing[2], 0, spacing[2], spacing[7]] })}>
              {level === 1 ? (
                <>
                  <Heading level={4} textWrap>
                    {labelText}
                  </Heading>
                  <Box marginTop={spacing[2]}>
                    <BundleArrayGroupValues
                      groupValue={value}
                      properties={nestedProperties}
                      level={level + 1}
                      addMargins
                    />
                  </Box>
                </>
              ) : (
                <Accordion
                  items={[
                    {
                      id: labelText,
                      rendererTitle: () => (
                        <Heading level={4} textWrap>
                          {labelText}
                        </Heading>
                      ),
                      rendererSubMenu: () =>
                        type === ManagedConfigType.BUNDLE_ARRAY ? (
                          isNilOrEmpty(value) ? (
                            <Body color="colorTextWeak">{localized("No items added in this sections")}</Body>
                          ) : (
                            value.map((groupValue, index) => (
                              <WidgetStyledBox
                                key={`${key}-${index}`}
                                padding={[spacing[3], spacing[4]]}
                                marginBottom={spacing[2]}
                              >
                                <BundleArrayGroupValues {...{ groupValue }} properties={nestedProperties} />
                              </WidgetStyledBox>
                            ))
                          )
                        ) : (
                          <BundleArrayGroupValues
                            level={level}
                            groupValue={value}
                            properties={nestedProperties}
                            addMargins
                          />
                        ),
                    },
                  ]}
                />
              )}
            </Box>
          ) : (
            <Flex data-testid={key}>
              <Box width="260px" marginRight={spacing[3]}>
                <Body wordWrap="break-word" textWrap>
                  {labelText}
                  <span aria-hidden="true">:</span>
                </Body>
              </Box>
              <Box width="420px">
                <Body wordWrap="break-word" textWrap>
                  {renderValue({ type, value, entries })}
                </Body>
              </Box>
            </Flex>
          )}
        </Fragment>
      ))}
    </Flex>
  )
})
