import { useDispatch, useSelector } from "react-redux"
import { any, compose, forEach, prop, propEq, reject } from "ramda"
import { useMemo } from "react"

import { DataTable, Heading } from "@ninjaone/components"
import { spacing } from "@ninjaone/tokens"

import { Box, Flex } from "js/includes/components/Styled"

import { deletePolicyItem, revertPolicySection, updatePolicyItem } from "js/state/actions/policyEditor/editor"
import { defaultToDash, isNotNil, localizationKey, localized } from "js/includes/common/utils"
import showModal from "js/includes/common/services/showModal"

import { LabelWrapper } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/common/styled"
import {
  getInlineTags,
  getOverrideStateColumn,
  isInheritedRow,
  isNotOverriddenRow,
  objectWithUUIDToArray,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"

import PrivateKeyModal from "./PrivateKeyModal"
import DeleteKeyRuleModal from "./DeleteKeyRuleModal"

const choosePrivateKeyRulesPath = "security.choosePrivateKeyRules"

const getPrivateKeyRuleColumns = isChildPolicy => [
  {
    id: "name",
    Header: localized("Name"),
    accessor: "name",
    minWidth: "160px",
    maxWidth: "160px",
  },
  {
    id: "alias",
    Header: localized("Alias"),
    accessor: ({ alias }) => defaultToDash(alias),
    minWidth: "77px",
  },
  {
    id: "urlPattern",
    Header: localized("URL pattern"),
    accessor: ({ urlPattern }) => (!!urlPattern ? urlPattern : null),
    minWidth: "250px",
  },
  {
    id: "packageNames",
    Header: localized("Package names"),
    accessor: ({ packageNames = [] }) => packageNames[0],
    getCellInlineTags: ({ packageNames = [] }) => getInlineTags(packageNames, "packageName-items"),
  },
  ...(isChildPolicy ? [getOverrideStateColumn()] : []),
]

const PrivateKeyRulesTable = ({ packageNameOptions, choosePrivateKeyRules }) => {
  const { parentPolicy } = useSelector(({ policyEditor }) => ({
    parentPolicy: policyEditor?.parentPolicy,
  }))
  const dispatch = useDispatch()
  const isChildPolicy = isNotNil(parentPolicy)

  const rows = useMemo(() => objectWithUUIDToArray(choosePrivateKeyRules, "guid"), [choosePrivateKeyRules])

  const columns = useMemo(() => getPrivateKeyRuleColumns(isChildPolicy), [isChildPolicy])

  const showAddKeyModal = () => {
    showModal(<PrivateKeyModal {...{ packageNameOptions, updatePrivateKeyRule, allKeyRules: rows }} />)
  }

  const showEditKeyModal = selectedRule => {
    showModal(
      <PrivateKeyModal
        {...{
          packageNameOptions,
          updatePrivateKeyRule,
          allKeyRules: reject(propEq("guid", selectedRule.guid), rows),
          editKeyRule: selectedRule,
        }}
      />,
    )
  }

  const removeKeyRule = selectedKeyRules => {
    showModal(
      <DeleteKeyRuleModal
        {...{ selectedKeyRules }}
        removeKeyRule={keyRules => {
          for (const { guid } of keyRules) {
            dispatch(deletePolicyItem(`${choosePrivateKeyRulesPath}.${guid}`))
          }
        }}
      />,
    )
  }

  const updatePrivateKeyRule = (key, values) => {
    dispatch(updatePolicyItem(`${choosePrivateKeyRulesPath}.${key}`, parentPolicy, values))
  }

  return (
    <Flex gap={spacing[6]} paddingTop={spacing[4]} marginLeft={spacing[3]} flexDirection="column">
      <LabelWrapper>
        <Heading type="headingXs" level={3} color="colorTextWeak">
          {localized("Private key rules")}
        </Heading>
      </LabelWrapper>
      <Box paddingTop={spacing[3]} height="350px">
        <DataTable
          {...{
            tableId: "security-private-key-rules-table",
            columns,
            rows: rows,
            searchPlaceholderText: localized("Search"),
            globalActionsButton: {
              buttonProps: {
                labelToken: localizationKey("Add Key"),
                action: showAddKeyModal,
              },
            },
            actions: {
              primary: [
                {
                  labelToken: localizationKey("Revert overrides"),
                  action: compose(
                    forEach(keyRule =>
                      dispatch(revertPolicySection(`${choosePrivateKeyRulesPath}.${keyRule.guid}`, keyRule)),
                    ),
                    prop("selected"),
                  ),
                  hideMultiAction: any(isNotOverriddenRow),
                  hideRowAction: isNotOverriddenRow,
                },
                {
                  labelToken: localizationKey("Edit"),
                  action: ({ selected: [keyRule] }) => showEditKeyModal(keyRule),
                  splitAfter: true,
                  hideMultiAction: rows => rows.length > 1,
                },
                {
                  labelToken: localizationKey("Remove"),
                  isRed: true,
                  action: ({ selected }) => removeKeyRule(selected),
                  variant: "danger",
                  hideMultiAction: any(isInheritedRow),
                  hideRowAction: isInheritedRow,
                },
              ],
            },
          }}
        />
      </Box>
    </Flex>
  )
}

export default PrivateKeyRulesTable
