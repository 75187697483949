import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFolders,
  faFolder,
  faFolderOpen,
  faCaretDown,
  faHdd,
  faChartPieAlt,
  faHistory,
} from "@fortawesome/pro-solid-svg-icons"
import { faCaretRight } from "@fortawesome/pro-light-svg-icons"
import { getExtensionIcon } from "./extensions"
import {
  ninjaReportError,
  sortByCaseInsensitive,
  capitalizeFirstLetter,
  localized,
  isMacPolicy,
  hasWampError,
  getLocalizedWampError,
  showSuccessMessage,
} from "js/includes/common/utils"
import { getReadableBytes } from "js/includes/common/_conversions"
import { values } from "ramda"
import {
  validatePath,
  isOnExclusionList,
  getWildcardPathsAsRegex,
} from "js/includes/editors/Policy/Sections/Backups/common/validation"

export const switcherIcon = ({ expanded, isLeaf }) => {
  return isLeaf ? null : <FontAwesomeIcon icon={expanded ? faCaretDown : faCaretRight} />
}

const _getIcon = dir => {
  const { folder, folders, imagePlan, volume, path, eventKey, expanded, isPointInTime } = dir

  if (imagePlan) {
    return faHdd
  } else if (isPointInTime) {
    return faHistory
  } else if (volume) {
    return faChartPieAlt
  } else if (folders) {
    return faFolders
  } else if (folder) {
    return expanded ? faFolderOpen : faFolder
  } else {
    return getExtensionIcon(path || eventKey)
  }
}

export const getIcon = dir => {
  const { folder, folders, disabled, iconColor } = dir
  const icon = _getIcon(dir)
  const className = `${folders ? "folders-icon" : folder ? "folder-icon" : "file-icon"} ${
    disabled ? "disabled-dir" : ""
  }`

  return (
    <FontAwesomeIcon
      {...{
        icon,
        className,
        ...(iconColor && {
          style: {
            color: iconColor,
          },
        }),
      }}
    />
  )
}

export const enforceTrailingSlashesInPath = (str, separator = "/") => {
  if (str.endsWith(separator)) {
    return str
  }

  if (str.endsWith("\\") || str.endsWith("/")) {
    return str.replace(/[/|\\]+$/, separator)
  }

  return str + separator
}

export const isRoot = f => f.name !== "." && f.name !== ".."

export const replaceSeparatorAndRemoveTrailingSlash = (path, separator = "/") => {
  if (path === "[disk_images]") {
    return localized("Disk Images")
  }
  const formattedPath = path.replace(/\/+|\\+/g, separator).replace(/[/|\\]+$/, "")
  const isRoot = /^\w+:$/.test(formattedPath)
  return isRoot ? formattedPath + separator : formattedPath
}

export const getPartitionFromPath = path => {
  const match = path.match(/(^.+:[\\/])/)
  return match ? match[0] : path
}

export const isPathIncluded = (list, path) => {
  const formattedPath = enforceTrailingSlashesInPath(path)
  const lowerCasePath = formattedPath.toLowerCase()
  return list.some(_path => {
    const listPath = _path.toLowerCase()
    return listPath !== lowerCasePath && listPath.startsWith(lowerCasePath)
  })
}

const getAgentBrowsingMethods = ({
  deviceId,
  nodeRole,
  disabledKeys = [],
  showFiles = true,
  disableExclusionListValidation = false,
  nodeClass,
  isLockhart = true,
  wildCardKeys,
}) => ({
  onGetRootDirectories: async () => {
    try {
      const options = { receive_progress: false, showFiles, showHidden: true }
      const response = await window.wamp.call(deviceId, "rmm.os.filesystem.volumes", [], options)
      const volumes =
        response.kwargs && response.kwargs.volumes
          ? response.kwargs.volumes.map(({ name, displayName, bytesTotal, bytesFree }) => {
              const key = enforceTrailingSlashesInPath(capitalizeFirstLetter(name))
              const volumeName = name !== displayName ? `${name} ${displayName}` : name
              const usedSpace = `${getReadableBytes(bytesTotal - bytesFree)} / ${getReadableBytes(bytesTotal)}`
              const isDisabled = disabledKeys.includes(key)

              return {
                key,
                title: `${volumeName} - ${usedSpace}`,
                type: "volume",
                isRoot: true,
                folder: true,
                isLeaf: false,
                checkable: false,
                disabled: isDisabled,
              }
            })
          : []

      return volumes
    } catch (error) {
      ninjaReportError(error)
    }
  },

  onGetFolderContent: async (node, parentKey, offset = 0, limit = 1000) => {
    try {
      const params = [parentKey, offset, limit, ""]
      const options = { showFiles: true, showHidden: true }
      const response = await window.wamp.call(deviceId, "rmm.os.filesystem.dirlist", params, options)
      const parent = enforceTrailingSlashesInPath(parentKey)
      const wildcardsRegex = getWildcardPathsAsRegex(wildCardKeys)

      let children = []

      if (response.kwargs && response.kwargs.folders) {
        const folderResponse = response.kwargs.folders.filter(isRoot)
        const folders = folderResponse.map(folder => {
          const key = `${parent}${folder.name}`
          const isMatchingWildcard = wildcardsRegex.some(wildcard => wildcard.test(key))
          const isDisabled =
            isOnExclusionList(key, nodeRole, disableExclusionListValidation) ||
            disabledKeys.includes(key) ||
            isMatchingWildcard

          return {
            key,
            parentKey,
            title: folder.name,
            type: "folder",
            folder: true,
            isLeaf: false,
            checked: isMatchingWildcard,
            disableCheckbox: isMatchingWildcard,
            disabled:
              !validatePath({
                _path: key,
                paths: [],
                isNew: false,
                nodeRole,
                common: [],
                disableExclusionListValidation,
                nodeClass,
                isLockhart,
              }).success || isDisabled,
          }
        })

        children = children.concat(folders)
      }

      if (showFiles && response.kwargs && response.kwargs.files) {
        const fileResponse = response.kwargs.files.filter(isRoot)
        const files = fileResponse.map(file => {
          const key = `${parent}${file.name}`
          const isMatchingWildcard = wildcardsRegex.some(wildcard => wildcard.test(key))
          const isDisabled =
            isOnExclusionList(key, nodeRole, disableExclusionListValidation) ||
            disabledKeys.includes(key) ||
            isMatchingWildcard

          return {
            key,
            parentKey,
            title: file.name,
            type: "file",
            isLeaf: true,
            folder: false,
            checked: isMatchingWildcard,
            disableCheckbox: isMatchingWildcard,
            disabled: isDisabled,
          }
        })

        children = children.concat(files)
      }

      return children
    } catch (error) {
      ninjaReportError(error)
    }
  },
})

export const getCloudberryFileFolderBrowsingMethods = ({
  deviceId,
  nodeRole,
  nodeClass,
  disabledKeys = [],
  showFiles = true,
  disableExclusionListValidation = false,
}) =>
  getAgentBrowsingMethods({
    deviceId,
    nodeRole,
    disabledKeys,
    showFiles,
    disableExclusionListValidation,
    nodeClass,
    isLockhart: false,
  })

export const getLockhartFileFolderBrowsingMethods = ({
  deviceId,
  nodeClass,
  nodeRole,
  disabledKeys = [],
  showFiles = true,
  disableExclusionListValidation = false,
  checkable = false,
  wildCardKeys,
}) => ({
  ...getAgentBrowsingMethods({
    deviceId,
    nodeRole,
    disabledKeys,
    showFiles,
    disableExclusionListValidation,
    nodeClass,
    wildCardKeys,
  }),
  onGetRootDirectories: async () => {
    try {
      const options = { receive_progress: false, showFiles, showHidden: true }
      const response = await window.wamp.call(deviceId, "rmm.os.filesystem.volumes", [], options)
      const volumes = response?.kwargs?.volumes ?? []
      const mappedVolumes = (isMacPolicy(nodeRole) ? volumes.filter(v => v.name === "/") : volumes).map(
        ({ name, displayName, bytesTotal, bytesFree }) => {
          const key = enforceTrailingSlashesInPath(capitalizeFirstLetter(name))
          const volumeName = name !== displayName ? `${name} ${displayName}` : name
          const freeSpace = localized("{{freeSpace}} free of {{totalSpace}}", {
            freeSpace: getReadableBytes(bytesFree),
            totalSpace: getReadableBytes(bytesTotal),
          })
          const isDisabled = disabledKeys.includes(key)

          return {
            key,
            title: `${volumeName} - ${freeSpace}`,
            type: "volume",
            isRoot: true,
            folder: true,
            isLeaf: false,
            checkable,
            disabled: isDisabled,
          }
        },
      )

      return mappedVolumes
    } catch (error) {
      ninjaReportError(error)
    }
  },
})

export const getCloudberryImageBrowsingMethods = ({ deviceId, disabledKeys = [] }) => ({
  onGetRootDirectories: async () => {
    try {
      const response = await window.wamp.call(deviceId, "rmm.os.filesystem.cbbvolumes", ["a"], {})

      const volumes = response?.kwargs?.volumes
        ? response.kwargs.volumes.map(({ SymbolName, UsedSpace, Length, WindowsVolumeIdentity }) => {
            const usedSpace = `${getReadableBytes(Length - UsedSpace)} / ${getReadableBytes(Length)}`

            return {
              key: WindowsVolumeIdentity,
              title: `${SymbolName} - ${usedSpace}`,
              type: "volume",
              isRoot: true,
              folder: true,
              isLeaf: true,
            }
          })
        : []

      return volumes
    } catch (error) {
      ninjaReportError(error)
    }
  },

  onGetFolderContent: () => [],
})

export const getLockhartImageBrowsingMethods = ({ deviceId, disabledKeys = [] }) => ({
  onGetRootDirectories: async () => {
    try {
      const response = await window.wamp.call(deviceId, "rmm.os.filesystem.volumes", ["a"], {})

      const volumes = response?.kwargs?.volumes
        ? response.kwargs.volumes.map(({ name, displayName, bytesTotal, bytesFree }) => {
            const key = enforceTrailingSlashesInPath(capitalizeFirstLetter(name))
            const volumeName = name !== displayName ? `${name} ${displayName}` : name
            const freeSpace = localized("{{freeSpace}} free of {{totalSpace}}", {
              freeSpace: getReadableBytes(bytesFree),
              totalSpace: getReadableBytes(bytesTotal),
            })

            return {
              key,
              title: `${volumeName} - ${freeSpace}`,
              type: "volume",
              isRoot: true,
              folder: true,
              isLeaf: true,
            }
          })
        : []

      return volumes
    } catch (error) {
      ninjaReportError(error)
    }
  },

  onGetFolderContent: () => [],
})

const getPath = (tree, segments, separator) => {
  let path = ""

  const exist = segments.some((segment, index) => {
    path += segment + separator
    return tree[path.toLowerCase()]
  })

  return { path, exist }
}

export const getParentKeys = (allKeys, separator = "/") => {
  const keys = sortByCaseInsensitive()([...allKeys])
  const tree = {}

  keys.forEach(key => {
    const lastSeparator = new RegExp(separator + "+$")
    const segments = capitalizeFirstLetter(key)
      .replace(lastSeparator, "")
      .split(separator)

    const { path, exist } = getPath(tree, segments, separator)

    if (!exist) tree[path.toLowerCase()] = replaceSeparatorAndRemoveTrailingSlash(path)
  })

  return values(tree)
}

export const saveNewFolder = async ({ nodeId, path = "", folderName }) => {
  const folderPath = `${path}${folderName}`
  if (folderPath.startsWith(".") || folderName.startsWith("."))
    return localized("Invalid folder path or folder name. Path or name cannot start with '.' or '..'")

  const createFolderWampParams = [folderPath]
  const options = { receive_progress: false }

  try {
    const response = await window.wamp.call(
      nodeId,
      "rmm.os.filesystem.createfolder",
      createFolderWampParams,
      {},
      options,
    )
    if (hasWampError(response)) throw response
    showSuccessMessage(localized("Success"))
  } catch (error) {
    return getLocalizedWampError(error)
  }
}
