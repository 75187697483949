import {
  map,
  toPairs,
  filter,
  head,
  mapObjIndexed,
  compose,
  identity,
  prop,
  when,
  curry,
  sortWith,
  descend,
  ascend,
  toLower,
  groupBy,
  sum,
  pluck,
  defaultTo,
  values,
  pick,
} from "ramda"
import {
  ArrowCircleUpDuotoneSvg,
  ArrowDownIcon,
  BookIcon,
  BugIconSolid,
  ComputerIcon,
  ConditionAlertsDuotoneSvg,
  FileImportIconSolid,
  LoaderIconLight,
  RefreshIcon,
  ShieldIconSolid,
  ThreeRackServerIconSvg,
  WindowIcon,
  WrenchIconSolid,
} from "@ninjaone/icons"

import { localized, isNotNil, user, clickThroughCriteria } from "js/includes/common/utils"

export const getNodeHealthColor = item => {
  if (!item) return "none"
  return getNodeHealthColorForHealhStatus(item.healthStatus)
}

export const getNodeHealthColorForHealhStatus = healthStatus => {
  switch (healthStatus) {
    case "UNKNOWN":
      return "none"
    case "NEEDS_ATTENTION":
      return "warning"
    case "UNHEALTHY":
      return "danger"
    case "HEALTHY":
      return "success"
    default:
      throw new Error(`Invalid value: ${healthStatus}`)
  }
}

export const getHealthStatusThemeColor = (healthStatus, theme) => {
  return {
    UNKNOWN: theme.colorAlertNeutral,
    NEEDS_ATTENTION: theme.colorAlertWarning,
    UNHEALTHY: theme.colorAlertError,
    HEALTHY: theme.colorAlertSuccess,
  }[healthStatus]
}

export const getHealthStatusThemeDuotoneColors = (healthStatus, theme) => {
  const statuses = {
    UNKNOWN: {
      primaryColor: theme.colorAlertNeutral,
      secondaryColor: theme.colorTextStrongInverse,
    },
    NEEDS_ATTENTION: {
      primaryColor: theme.colorAlertWarning,
      secondaryColor: theme.colorTextStrongStatic,
    },
    UNHEALTHY: {
      primaryColor: theme.colorAlertError,
      secondaryColor: theme.colorTextStrongInverse,
    },
    HEALTHY: {
      primaryColor: theme.colorAlertSuccess,
      secondaryColor: theme.colorTextStrongInverse,
    },
  }
  return statuses[healthStatus] ?? statuses.UNKNOWN
}

export const healthStatusSortWeights = {
  UNHEALTHY: 4,
  NEEDS_ATTENTION: 3,
  HEALTHY: 2,
  UNKNOWN: 1,
}

export const sortByHealthStatus = sortWith([
  descend(i => healthStatusSortWeights[i.healthStatus]),
  ascend(compose(toLower, prop("name"))),
])

export const getNodeHealthText = item => {
  if (!item) return localized("Unknown")

  const { healthStatus } = item

  switch (healthStatus) {
    case "UNKNOWN":
      return localized("Unknown")
    case "NEEDS_ATTENTION":
      return localized("Needs Attention")
    case "UNHEALTHY":
      return localized("Unhealthy")
    case "HEALTHY":
      return localized("Healthy")
    default:
      throw new Error(`Invalid value: ${healthStatus}`)
  }
}

export const getHealthIconClassFor = curry((healthStatus, systemHealthStatusConfig) =>
  getNodeHealthColorForHealhStatus(systemHealthStatusConfig.find(e => e.name === healthStatus).healthStatus),
)

export function getWorstOfHealthStatusesClass(healthStatusMap, systemHealthStatusConfig) {
  return compose(
    when(isNotNil, getNodeHealthColorForHealhStatus),
    prop("healthStatus"),
    head,
    sortByHealthStatus,
    map(([name, healthStatus]) => ({ name, healthStatus })),
    toPairs,
    mapObjIndexed((v, k) => systemHealthStatusConfig.find(c => c.name === k).healthStatus),
    filter(identity),
  )(healthStatusMap)
}

export const getHealthStatusFieldCategory = (field, systemHealthStatusConfig) =>
  systemHealthStatusConfig.find(e => e.name === field).healthStatus

export const getHealthStatusFieldsMap = ({
  item,
  systemHealthStatusConfig,
  isLockhartEnabled,
  isDocumentationEnabled,
  isCloudberryEnabled,
  updateCriteriaForClickThroughSearch,
}) => {
  const { clientId, locationId } = item
  const {
    devicesWithTriggeredConditions,
    needsReboot,
    installIssues,
    serverDown,
    nmsDown,
    downVmHosts,
    downHyperVHosts,
    maintenanceMode,
    activeThreats,
    quarantinedThreats,
    failedOsPatches,
    pendingOsPatches,
    failedSoftwarePatches,
    pendingSoftwarePatches,
    lowVulnerabilities,
    mediumVulnerabilities,
    highVulnerabilities,
    criticalVulnerabilities,
  } = clickThroughCriteria
  const canViewBackupData = user("canViewBackupData")

  const fieldsAttributesMap = {
    activeJobs: {
      count: item.activeJobCount,
      category: "INFO",
      Icon: LoaderIconLight,
      labelText: localized("Active Jobs"),
    },
    hasActiveThreats: {
      count: item.activeThreatsCount,
      category: getHealthStatusFieldCategory("hasActiveThreats", systemHealthStatusConfig),
      Icon: ShieldIconSolid,
      labelText: localized("Active threats"),
      action: () => {
        updateCriteriaForClickThroughSearch(activeThreats(clientId, locationId))
        window.location.href = "#/deviceSearch"
      },
    },
    hasQuarantinedThreats: {
      count: item.quarantinedThreatsCount,
      category: getHealthStatusFieldCategory("hasQuarantinedThreats", systemHealthStatusConfig),
      Icon: ShieldIconSolid,
      labelText: localized("Quarantined threats"),
      action: () => {
        updateCriteriaForClickThroughSearch(quarantinedThreats(clientId, locationId))
        window.location.href = "#/deviceSearch"
      },
    },
    hasLowCVSSSeverity: {
      count: item.lowVulnerabilityCount,
      category: getHealthStatusFieldCategory("hasLowCVSSSeverity", systemHealthStatusConfig),
      Icon: BugIconSolid,
      labelText: localized("Low vulnerabilities"),
      action: () => {
        updateCriteriaForClickThroughSearch(lowVulnerabilities(clientId, locationId))
        window.location.href = "#/deviceSearch"
      },
    },
    hasMediumCVSSSeverity: {
      count: item.mediumVulnerabilityCount,
      category: getHealthStatusFieldCategory("hasMediumCVSSSeverity", systemHealthStatusConfig),
      Icon: BugIconSolid,
      labelText: localized("Medium vulnerabilities"),
      action: () => {
        updateCriteriaForClickThroughSearch(mediumVulnerabilities(clientId, locationId))
        window.location.href = "#/deviceSearch"
      },
    },
    hasHighCVSSSeverity: {
      count: item.highVulnerabilityCount,
      category: getHealthStatusFieldCategory("hasHighCVSSSeverity", systemHealthStatusConfig),
      Icon: BugIconSolid,
      labelText: localized("High vulnerabilities"),
      action: () => {
        updateCriteriaForClickThroughSearch(highVulnerabilities(clientId, locationId))
        window.location.href = "#/deviceSearch"
      },
    },
    hasCriticalCVSSSeverity: {
      count: item.criticalVulnerabilityCount,
      category: getHealthStatusFieldCategory("hasCriticalCVSSSeverity", systemHealthStatusConfig),
      Icon: BugIconSolid,
      labelText: localized("Critical vulnerabilities"),
      action: () => {
        updateCriteriaForClickThroughSearch(criticalVulnerabilities(clientId, locationId))
        window.location.href = "#/deviceSearch"
      },
    },
    hasFailedPatches: {
      count: item.failedPatchesCount,
      category: getHealthStatusFieldCategory("hasFailedPatches", systemHealthStatusConfig),
      Icon: ArrowCircleUpDuotoneSvg,
      isDuotoneIcon: true,
      labelText: localized("Failed OS patches"),
      action: () => {
        updateCriteriaForClickThroughSearch(failedOsPatches(clientId, locationId))
        window.location.href = "#/deviceSearch"
      },
    },
    hasPendingPatches: {
      count: item.pendingPatchesCount,
      category: getHealthStatusFieldCategory("hasPendingPatches", systemHealthStatusConfig),
      Icon: ArrowCircleUpDuotoneSvg,
      isDuotoneIcon: true,
      labelText: localized("Pending OS patches"),
      action: () => {
        updateCriteriaForClickThroughSearch(pendingOsPatches(clientId, locationId))
        window.location.href = "#/deviceSearch"
      },
    },
    hasFailedSoftwarePatches: {
      count: item.failedSoftwarePatchesCount,
      category: getHealthStatusFieldCategory("hasFailedSoftwarePatches", systemHealthStatusConfig),
      Icon: WindowIcon,
      labelText: localized("Failed software patches"),
      action: () => {
        updateCriteriaForClickThroughSearch(failedSoftwarePatches(clientId, locationId))
        window.location.href = "#/deviceSearch"
      },
    },
    hasPendingSoftwarePatches: {
      count: item.pendingSoftwarePatchesCount,
      category: getHealthStatusFieldCategory("hasPendingSoftwarePatches", systemHealthStatusConfig),
      Icon: WindowIcon,
      labelText: localized("Pending software patches"),
      action: () => {
        updateCriteriaForClickThroughSearch(pendingSoftwarePatches(clientId, locationId))
        window.location.href = "#/deviceSearch"
      },
    },
    hasTriggeredConditions: {
      count: item.triggeredConditionsCount,
      category: getHealthStatusFieldCategory("hasTriggeredConditions", systemHealthStatusConfig),
      Icon: ConditionAlertsDuotoneSvg,
      isDuotoneIcon: true,
      labelText: localized("Condition alerts"),
      action: () => {
        updateCriteriaForClickThroughSearch(devicesWithTriggeredConditions(clientId, locationId))
        window.location.href = "#/deviceSearch"
      },
    },
    cloudberryFailedBackupJobs: {
      count: isCloudberryEnabled && item.cloudberryFailedBackupsCount,
      category: getHealthStatusFieldCategory("hasFailedBackup", systemHealthStatusConfig),
      Icon: ThreeRackServerIconSvg,
      labelText: localized("Failed CloudBerry backup jobs"),
    },
    lockhartFailedBackupJobs: {
      count:
        isLockhartEnabled && canViewBackupData
          ? (item.lockhartFailedBackupsCount || 0) +
            (item.lockhartFailedUpsyncJobsCount || 0) +
            (item.lockhartFailedRestoreCount || 0) +
            (item.lockhartFailedIntegrityCheckJobsCount || 0)
          : 0,
      category: getHealthStatusFieldCategory("hasFailedBackup", systemHealthStatusConfig),
      Icon: ThreeRackServerIconSvg,
      labelText: localized("Failed backup jobs"),
    },
    needReboot: {
      count: item.clientNeedRebootDevicesCount,
      category: getHealthStatusFieldCategory("needReboot", systemHealthStatusConfig),
      Icon: RefreshIcon,
      labelText: localized("Reboot issues"),
      action: () => {
        updateCriteriaForClickThroughSearch(needsReboot(clientId, locationId))
        window.location.href = "#/deviceSearch"
      },
    },
    hasInstallationIssues: {
      count: item.installationIssuesCount,
      category: getHealthStatusFieldCategory("hasInstallationIssues", systemHealthStatusConfig),
      Icon: FileImportIconSolid,
      labelText: localized("Antivirus installation issues"),
      action: () => {
        updateCriteriaForClickThroughSearch(installIssues(clientId, locationId))
        window.location.href = "#/deviceSearch"
      },
    },
    systemActionsRunningInstall: {
      count: item.runningInstallationCount ?? 0,
      category: "NEEDS_ATTENTION",
      Icon: FileImportIconSolid,
      labelText: localized("Devices running AV install"),
    },
    serversDown: {
      count: item.clientDownServersCount,
      category: getHealthStatusFieldCategory("isDownServer", systemHealthStatusConfig),
      Icon: ArrowDownIcon,
      labelText: localized("Servers down"),
      action: () => {
        updateCriteriaForClickThroughSearch(serverDown(clientId, locationId))
        window.location.href = "#/deviceSearch"
      },
    },
    nmsServersDown: {
      count: (item.clientDownNmsServersCount || 0) + (item.clientDownNmsTargetCount || 0),
      category: getHealthStatusFieldCategory("isNMSServerOffline", systemHealthStatusConfig),
      Icon: ArrowDownIcon,
      labelText: localized("NMS down"),
      action: () => {
        updateCriteriaForClickThroughSearch(nmsDown(clientId, locationId))
        window.location.href = "#/deviceSearch"
      },
    },
    vmHostsDown: {
      count: item.clientDownVMHostsCount,
      category: getHealthStatusFieldCategory("isVMwareHostOffline", systemHealthStatusConfig),
      Icon: ArrowDownIcon,
      labelText: localized("VMWare hosts down"),
      action: () => {
        updateCriteriaForClickThroughSearch(downVmHosts(clientId, locationId))
        window.location.href = "#/deviceSearch"
      },
    },
    hyperVHostDown: {
      count: item.clientDownHyperVHostsCount,
      category: getHealthStatusFieldCategory("isHyperVHostOffline", systemHealthStatusConfig),
      Icon: ArrowDownIcon,
      labelText: localized("Hyper-V hosts down"),
      action: () => {
        updateCriteriaForClickThroughSearch(downHyperVHosts(clientId, locationId))
        window.location.href = "#/deviceSearch"
      },
    },
    underMaintenance: {
      count: item.devicesInMaintenanceMode,
      category: getHealthStatusFieldCategory("isUnderActiveMaintenance", systemHealthStatusConfig),
      Icon: WrenchIconSolid,
      labelText: localized("Under maintenance"),
      action: () => {
        updateCriteriaForClickThroughSearch(maintenanceMode(clientId, locationId))
        window.location.href = "#/deviceSearch"
      },
    },
    pendingNodeApprovals: {
      count: item.pendingDevicesCount,
      category: "NEEDS_ATTENTION",
      Icon: ComputerIcon,
      labelText: localized("Pending device approvals"),
      action: () => {
        window.location.href = `#/customerDashboard/${clientId}/approvals/pending`
      },
      href: `#/customerDashboard/${clientId}/approvals/pending`,
    },
    pendingDocuments: {
      count: isDocumentationEnabled ? item.pendingMandatoryDocumentsCount : 0,
      category: "NEEDS_ATTENTION",
      Icon: BookIcon,
      labelText: localized("Pending documents"),
      action: () => (window.location.href = `#/customerDashboard/${clientId}/documentation`),
      href: `#/customerDashboard/${clientId}/documentation`,
    },
  }
  return fieldsAttributesMap
}

export const getHealthStatusFieldsGroupedByCategory = ({
  item,
  systemHealthStatusConfig,
  isLockhartEnabled,
  isDocumentationEnabled,
  isCloudberryEnabled,
  fieldsAttributesToPick = ["count", "category"],
}) => {
  const statusFields = compose(
    values,
    mapObjIndexed((field, key) => ({ ...pick(fieldsAttributesToPick, field), id: key })),
  )(
    getHealthStatusFieldsMap({
      item,
      systemHealthStatusConfig,
      isLockhartEnabled,
      isDocumentationEnabled,
      isCloudberryEnabled,
    }),
  )

  const fieldsByCategory = groupBy(option => option.category, statusFields)

  const categoriesWithTotals = map(fields => {
    const total = compose(sum, map(defaultTo(0)), pluck("count"))(fields)
    return {
      total,
      fields,
    }
  }, fieldsByCategory)

  return categoriesWithTotals
}
