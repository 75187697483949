import {
  all,
  allPass,
  always,
  any,
  append,
  assoc,
  both,
  compose,
  concat,
  cond,
  curry,
  defaultTo,
  equals,
  filter,
  find,
  flatten,
  has,
  ifElse,
  is,
  join,
  keys,
  map,
  pluck,
  prop,
  propEq,
  propOr,
  reject,
  T,
  tail,
  when,
  without,
} from "ramda"

import { isFeatureEnabled, isNotEmpty, isNotNil } from "js/includes/common/utils"
import { localizationKey, localized } from "js/includes/common/utils/ssrAndWebUtils"
import showModal from "js/includes/common/services/showModal"
import PermissionActionModal from "js/includes/editors/User/Permissions/PermissionActionModal"
import { isNinjaRemoteAppEnabled } from "js/includes/common/utils/ninjaRemote"

export const DEFAULT_ENTITY_ID = 0
export const DEFAULT_FLAG = "-default"
export const NONE = "None"
export const CUSTOM = "Custom"
export const ACCESS = "Access"
export const CREATE = "Create"
export const VIEW = "View"
export const UPDATE = "Update"
export const DELETE = "Delete"
export const SHARE = "Share"
export const SCHEDULE = "Schedule"
export const CLAIM_OWNERSHIP = "Claim"
export const FULL_ACCESS = "FullAccess"
export const RESTORE = "Restore"
export const MANAGE = "Manage"
export const RUN = "Run"
export const ALL = "All"
export const OWN = "Own"
export const VIEW_UPDATE = `${VIEW}${UPDATE}`
export const VIEW_UPDATE_CREATE = `${VIEW}${UPDATE}${CREATE}`
export const VIEW_UPDATE_DELETE = `${VIEW}${UPDATE}${DELETE}`
export const VIEW_UPDATE_CREATE_DELETE = `${VIEW}${UPDATE}${CREATE}${DELETE}`
export const VIEW_RESTORE = `${VIEW}${RESTORE}`
export const VIEW_RESTORE_MANAGE = `${VIEW}${RESTORE}${MANAGE}`
export const VIEW_MANAGE = `${VIEW}${MANAGE}`
export const VIEW_RUN = `${VIEW}${RUN}`
export const VIEW_RUN_UPDATE = `${VIEW}${RUN}${UPDATE}`
export const VIEW_RUN_UPDATE_DELETE = `${VIEW}${RUN}${UPDATE}${DELETE}`
export const VIEW_SCHEDULE = `${VIEW}${SCHEDULE}`
export const VIEW_CLAIM_SCHEDULE = `${VIEW}${CLAIM_OWNERSHIP}${SCHEDULE}`

export const EntityType = {
  MDM: "MDM",
}

export const EntitySubType = {
  // MDM
  AndroidConnection: "Android.Connection",
  AppleAPNConnection: "AppleAPN.Connection",
  AppleADEConnection: "AppleADE.Connection",
  AppleAppsAndBooksConnection: "AppleAppsAndBooks.Connection",
}

export const getPermissions = (roleType = "technician") => {
  return propOr({}, roleType, {
    technician: {
      SYSTEM: {
        Reports: [VIEW_SCHEDULE, VIEW_CLAIM_SCHEDULE, FULL_ACCESS],
        MobileApp: [ACCESS],
        EndUserSharing: [ACCESS],
        Contact: [CREATE, UPDATE, DELETE],
        UninstallSoftware: [ACCESS],
        UninstallPatch: [ACCESS],
        ...(isFeatureEnabled("custom_snmp") ? { CustomSNMP: [VIEW, UPDATE] } : {}),
        Backup: [VIEW, RESTORE, MANAGE],
        "MaintenanceMode.Editor": [ACCESS],
        "Configuration.Activities": [ACCESS],
        "Configuration.PSA": [ACCESS],
        "Cloudberry.Configure": [ACCESS],
        ActiveDirectoryManagement: [ACCESS],
        ActiveDirectoryDiscovery: [ACCESS],
        ...(isNinjaRemoteAppEnabled()
          ? {
              "NinjaConnect.Configure": [ACCESS],
              "NinjaConnect.BackgroundMode": [ACCESS],
              "QuickConnect.Configure": [ACCESS],
            }
          : {}),
        ...(isFeatureEnabled("remotesupport") ? { RemoteSupport: [ACCESS] } : {}),
        ...(isFeatureEnabled("gravityzone") && { GravityZone: [ACCESS] }),
        ...(isFeatureEnabled("gravityzone") && { "GravityZone.Scan": [ACCESS] }),
        ...(isFeatureEnabled("sentinel_one") && { SentinelOne: [ACCESS] }),
        ...(isFeatureEnabled("crowdstrike") && { CrowdStrike: [ACCESS] }),
        ...(isFeatureEnabled("team_viewer") && { "TeamViewer.Configure": [ACCESS] }),
        "Connectwise.Configure": [ACCESS],
        ...(isFeatureEnabled("splashtop") && { "Splashtop.Configure": [ACCESS] }),
        "Device.Administration": [ACCESS],
        "Itam.Tag": [CREATE, VIEW, UPDATE, DELETE],
        "Itam.Tag.Management": [VIEW, UPDATE],
      },

      POLICY: [CREATE, VIEW, UPDATE, DELETE],
      ORGANIZATION: [CREATE, VIEW, UPDATE, DELETE],

      [EntityType.MDM]: {
        [EntitySubType.AndroidConnection]: [CREATE, VIEW, UPDATE, DELETE],
        [EntitySubType.AppleAPNConnection]: [CREATE, VIEW, UPDATE, DELETE],
        [EntitySubType.AppleADEConnection]: [CREATE, VIEW, UPDATE, DELETE],
        [EntitySubType.AppleAppsAndBooksConnection]: [CREATE, VIEW, UPDATE, DELETE],
      },

      SCRIPT_CATEGORY: [CREATE, RUN, VIEW, UPDATE, DELETE],
      "SCRIPT_CATEGORY.Scheduled.Task": [CREATE, VIEW, UPDATE, DELETE],

      NODE: [CREATE, VIEW, UPDATE, DELETE],
      "NODE.Role": [VIEW, UPDATE, DELETE],
      "NODE.Class": [VIEW, UPDATE, DELETE],

      "NODE.SecureFields": [ACCESS],
      "NODE.TOTP.Code": [ACCESS],

      ...(!isFeatureEnabled("disable_remote_tools_file_browser") ? { "NODE.RemoteTools.FileExplorer": [ACCESS] } : {}),
      ...(!isFeatureEnabled("disable_remote_tools_registry") ? { "NODE.RemoteTools.RemoteRegistry": [ACCESS] } : {}),
      ...(!isFeatureEnabled("disable_remote_tools_command_prompt") ? { "NODE.RemoteTools.CommandLine": [ACCESS] } : {}),

      "NODE.RemoteTools.ServicesManager": [ACCESS],
      "NODE.RemoteTools.TaskManager": [ACCESS],

      ...(isFeatureEnabled("team_viewer") && { "NODE.RemoteAccess.TeamViewer": [ACCESS] }),
      ...(isFeatureEnabled("splashtop") && { "NODE.RemoteAccess.Splashtop": [ACCESS] }),
      "NODE.RemoteAccess.Connectwise": [ACCESS],
      ...(isFeatureEnabled("ninja_control") ? { "NODE.RemoteAccess.NinjaConnect": [ACCESS] } : {}),
      ...(isFeatureEnabled("rdp") ? { "NODE.RemoteAccess.RDP": [ACCESS] } : {}),

      TICKETING: {
        "Configuration.Ticketing": [ACCESS],
        Ticket: [CREATE, VIEW, UPDATE, DELETE],
        PendingEmailBoard: [VIEW, MANAGE],
        "Ticketing.Mobile": [ACCESS],
        Board: [VIEW],
        "Manage.Tickets.NoOrg": [ACCESS],
        "Private.Comments": [VIEW],
        "TimeTracked.Update": [ALL, OWN],
      },

      NINJA_PSA: {
        "Configuration.NinjaPSA": [ACCESS],
        "Configuration.NinjaPSA.TicketProducts": [VIEW, UPDATE, CREATE, DELETE],
        "Configuration.NinjaPSA.TicketProducts.Price": [ACCESS],
      },

      DOCUMENTATION: {
        Configuration: [ACCESS],
        Template: [CREATE, VIEW, UPDATE, DELETE],
        Management: [CREATE, VIEW, UPDATE, DELETE],
        "Management.SecureFields": [ACCESS],
        RelatedItem: [CREATE, VIEW, UPDATE, DELETE],
        "RelatedItem.Credentials": [ACCESS],
        "Global.KB.Management": [CREATE, VIEW, UPDATE, DELETE],
        "KB.Management.Public.Links": [CREATE, VIEW, UPDATE, DELETE],
        "Global.KB.EndUser.Folder.Share": [ACCESS],
        "KB.Management.Access.Restriction": [ACCESS],
        "TOTP.Code": [ACCESS],
        "Checklist.Management": [CREATE, VIEW, UPDATE, DELETE],
        "Checklist.Template": [CREATE, VIEW, UPDATE, DELETE],
      },
      SEARCH: {
        Search: [VIEW, UPDATE, CREATE, FULL_ACCESS],
      },
    },
    endUser: {
      NODE: {
        ...(isFeatureEnabled("team_viewer") && { "RemoteAccess.TeamViewer.Windows": [ACCESS] }),
        "RemoteAccess.NinjaConnect.Windows": [ACCESS],
        ...(isFeatureEnabled("splashtop") &&
          isFeatureEnabled("splashtop_for_end_users") && { "RemoteAccess.Splashtop.Windows": [ACCESS] }),
        "RemoteAccess.RDP.Windows": [ACCESS],
        ...(isFeatureEnabled("team_viewer") && { "RemoteAccess.TeamViewer.Mac": [ACCESS] }),
        "RemoteAccess.NinjaConnect.Mac": [ACCESS],
        ...(isFeatureEnabled("splashtop") &&
          isFeatureEnabled("splashtop_for_end_users") && { "RemoteAccess.Splashtop.Mac": [ACCESS] }),
        ...(!isFeatureEnabled("disable_remote_tools_command_prompt")
          ? {
              "RemoteTools.UserCommandLine.Windows": [ACCESS],
              "RemoteTools.SystemCommandLine.Windows": [ACCESS],
              "RemoteTools.UserCommandLine.Mac": [ACCESS],
              "RemoteTools.SystemCommandLine.Mac": [ACCESS],
              "RemoteTools.UserCommandLine.Linux": [ACCESS],
              "RemoteTools.SystemCommandLine.Linux": [ACCESS],
            }
          : {}),
      },
      SYSTEM: {
        "Backup.Plan.FileFolder": [ACCESS],
      },
      TICKETING: {
        Ticket: [VIEW, UPDATE, CREATE],
        "Ticketing.Organization.Wide": [ACCESS],
      },
    },
  })
}

const permissionTokenOverrides = {
  SYSTEM: {
    "MaintenanceMode.Editor": {
      [NONE]: localizationKey("View"),
      [ACCESS]: localizationKey("View, Update, Create, Delete"),
    },
    EndUserSharing: {
      [ACCESS]: localizationKey("View, Update, Create"),
    },
    Contact: {
      [NONE]: "permissions.view",
    },
    "Cloudberry.Configure": {
      [NONE]: localizationKey("View"),
      [ACCESS]: localizationKey("Configure Backups"),
    },
  },
}

const permissionKeysOverrides = {
  SYSTEM: {
    Contact: {
      [VIEW_UPDATE]: [UPDATE],
      [VIEW_UPDATE_CREATE]: [UPDATE, CREATE],
      [VIEW_UPDATE_CREATE_DELETE]: [UPDATE, CREATE, DELETE],
    },
  },
}

const listsShallowEquals = curry((l1, l2) => equals(l1.sort(), l2.sort()))

const getPermissionName = ({ entitySubType, permission }) =>
  `${entitySubType ?? ""}${entitySubType && permission ? "." : ""}${permission ?? ""}`

export const matchesPermission = ({ entityId, entityIds, entityType, permissionNames }) =>
  allPass([
    ifElse(() => isNotNil(entityId), propEq("entityId", entityId), T),
    ifElse(
      () => !!entityIds,
      ({ entityId }) => entityIds.includes(entityId),
      T,
    ),
    ifElse(
      () => !!permissionNames?.length,
      ({ name }) => permissionNames.includes(name),
      T,
    ),
    propEq("entityType", entityType),
  ])

function getKeys({ entityType, entitySubType, roleType }) {
  const permissions = getPermissions(roleType)
  return entitySubType
    ? permissions[entityType]?.[entitySubType] ?? permissions[`${entityType}.${entitySubType}`]
    : permissions[entityType]
}

export function getPermissionValue({
  createOnly,
  entityId = DEFAULT_ENTITY_ID,
  data,
  entityType,
  entitySubType,
  roleType,
}) {
  const getPermissionValue = ({ entityId, entityType, entitySubType, createOnly, roleType }) => {
    const keys = getKeys({ entityType, entitySubType, roleType })
    const getPermissionNames = map(permission =>
      getPermissionName({
        entitySubType,
        permission,
      }),
    )

    const permissionNames = compose(
      when(() => entityId !== DEFAULT_ENTITY_ID || !createOnly, without([CREATE])),
      getPermissionNames,
    )(createOnly ? [CREATE] : keys)

    const keysOverrides =
      (entitySubType ? permissionKeysOverrides[entityType]?.[entitySubType] : permissionKeysOverrides[entityType]) ?? {}

    const hasAllPrefixedValues = curry(defaultKeys =>
      compose(listsShallowEquals, getPermissionNames)(keysOverrides[defaultKeys.join("")] ?? defaultKeys),
    )

    return compose(
      ifElse(
        both(isNotEmpty, all(propEq("allowed", false))),
        always(NONE),
        compose(
          cond([
            [hasAllPrefixedValues([ACCESS]), always(ACCESS)],
            [hasAllPrefixedValues([RUN]), always(RUN)],
            [hasAllPrefixedValues([CREATE]), always(CREATE)],
            [hasAllPrefixedValues([VIEW]), always(VIEW)],
            [hasAllPrefixedValues([ALL]), always(ALL)],
            [hasAllPrefixedValues([OWN]), always(OWN)],
            [hasAllPrefixedValues([ALL, OWN]), always(ALL)],
            [hasAllPrefixedValues([VIEW, UPDATE]), always(VIEW_UPDATE)],
            [hasAllPrefixedValues([VIEW, UPDATE, CREATE]), always(VIEW_UPDATE_CREATE)],
            [hasAllPrefixedValues([VIEW, UPDATE, CREATE, DELETE]), always(VIEW_UPDATE_CREATE_DELETE)],
            [hasAllPrefixedValues([VIEW, UPDATE, DELETE]), always(VIEW_UPDATE_DELETE)],
            [hasAllPrefixedValues([VIEW, UPDATE, FULL_ACCESS]), always(FULL_ACCESS)],
            [hasAllPrefixedValues([VIEW, RESTORE]), always(VIEW_RESTORE)],
            [hasAllPrefixedValues([VIEW, RESTORE, MANAGE]), always(VIEW_RESTORE_MANAGE)],
            [hasAllPrefixedValues([VIEW, MANAGE]), always(VIEW_MANAGE)],
            [hasAllPrefixedValues([VIEW, RUN]), always(VIEW_RUN)],
            [hasAllPrefixedValues([VIEW, RUN, UPDATE]), always(VIEW_RUN_UPDATE)],
            [hasAllPrefixedValues([VIEW, RUN, UPDATE, DELETE]), always(VIEW_RUN_UPDATE_DELETE)],
            [hasAllPrefixedValues([VIEW_SCHEDULE]), always(VIEW_SCHEDULE)],
            [hasAllPrefixedValues([VIEW_SCHEDULE, VIEW_CLAIM_SCHEDULE]), always(VIEW_CLAIM_SCHEDULE)],
            [hasAllPrefixedValues([VIEW_SCHEDULE, VIEW_CLAIM_SCHEDULE, FULL_ACCESS]), always(FULL_ACCESS)],
            [T, ifElse(() => equals(entityId, DEFAULT_ENTITY_ID), always(NONE), always(false))],
          ]),
          pluck("name"),
          filter(propEq("allowed", true)),
        ),
      ),
      filter(matchesPermission({ entityId, entityType, permissionNames })),
      defaultTo([]),
      prop("permissions"),
    )(data)
  }

  return (
    getPermissionValue({ entityId, entityType, entitySubType, createOnly, roleType }) ||
    `${getPermissionValue({
      entityId: DEFAULT_ENTITY_ID,
      entityType,
      entitySubType,
    })}${DEFAULT_FLAG}`
  )
}

export function getPermissionOptions({ createOnly, includeCreate, entityType, entitySubType, roleType }) {
  const permissions = getPermissions(roleType)
  const keys = getKeys({ entityType, entitySubType, roleType })
  const tokenOverrides =
    (entitySubType
      ? permissionTokenOverrides[entityType]?.[entitySubType] ?? permissions[`${entityType}.${entitySubType}`]
      : permissionTokenOverrides[entityType]) ?? {}

  const keysOverrides =
    (entitySubType
      ? permissionKeysOverrides[entityType]?.[entitySubType] ?? permissions[`${entityType}.${entitySubType}`]
      : permissionKeysOverrides[entityType]) ?? {}

  const getOptionKeys = defaultKeys => keysOverrides[defaultKeys.join("")] ?? defaultKeys

  return [
    {
      value: NONE,
      labelToken: tokenOverrides[NONE] ?? localizationKey("No Access"),
    },
    ...(createOnly
      ? [
          {
            value: CREATE,
            labelToken: tokenOverrides[CREATE] ?? localizationKey("Allowed"),
          },
        ]
      : [
          {
            value: ACCESS,
            labelToken: tokenOverrides[ACCESS] ?? localizationKey("Allowed"),
            optionKeys: getOptionKeys([ACCESS]),
          },
          {
            value: RUN,
            labelToken: tokenOverrides[RUN] ?? localizationKey("Run"),
            optionKeys: getOptionKeys([RUN]),
          },
          {
            value: VIEW,
            labelToken: tokenOverrides[VIEW] ?? localizationKey("View"),
            optionKeys: getOptionKeys([VIEW]),
          },
          {
            value: VIEW_MANAGE,
            labelToken: tokenOverrides[VIEW_MANAGE] ?? localizationKey("View and Manage"),
            optionKeys: getOptionKeys([VIEW, MANAGE]),
          },
          {
            value: VIEW_UPDATE,
            labelToken: tokenOverrides[VIEW_UPDATE] ?? localizationKey("View, Update"),
            optionKeys: getOptionKeys([VIEW, UPDATE]),
          },
          {
            value: VIEW_RESTORE,
            labelToken: tokenOverrides[VIEW_RESTORE] ?? localizationKey("View and Restore"),
            optionKeys: getOptionKeys([VIEW, RESTORE]),
          },
          {
            value: VIEW_RESTORE_MANAGE,
            labelToken: tokenOverrides[VIEW_RESTORE_MANAGE] ?? localizationKey("View, Restore, and Manage"),
            optionKeys: getOptionKeys([VIEW, RESTORE, MANAGE]),
          },
          {
            value: VIEW_RUN,
            labelToken: tokenOverrides[VIEW_RUN] ?? localizationKey("View, Run"),
            optionKeys: getOptionKeys([VIEW, RUN]),
          },
          {
            value: VIEW_RUN_UPDATE,
            labelToken: tokenOverrides[VIEW_RUN_UPDATE] ?? localizationKey("View, Run, Update"),
            optionKeys: getOptionKeys([VIEW, RUN, UPDATE]),
          },
          {
            value: ALL,
            labelToken: tokenOverrides[ALL] ?? localizationKey("All"),
            optionKeys: getOptionKeys([ALL]),
          },
          {
            value: OWN,
            labelToken: tokenOverrides[OWN] ?? localizationKey("Own"),
            optionKeys: getOptionKeys([OWN]),
          },
          {
            value: VIEW_SCHEDULE,
            labelToken: tokenOverrides[VIEW_SCHEDULE] ?? localizationKey("View and Schedule"),
            optionKeys: getOptionKeys([VIEW_SCHEDULE]),
          },
          {
            value: VIEW_CLAIM_SCHEDULE,
            labelToken: tokenOverrides[VIEW_CLAIM_SCHEDULE] ?? localizationKey("View, Claim and Schedule"),
            optionKeys: getOptionKeys([VIEW_CLAIM_SCHEDULE]),
          },
          {
            value: FULL_ACCESS,
            labelToken: tokenOverrides[FULL_ACCESS] ?? localizationKey("Full Access"),
            optionKeys: getOptionKeys([FULL_ACCESS]),
          },
          ...(includeCreate
            ? [
                {
                  value: VIEW_UPDATE_CREATE,
                  labelToken: tokenOverrides[VIEW_UPDATE_CREATE] ?? localizationKey("View, Update, Create"),
                  optionKeys: getOptionKeys([VIEW, UPDATE, CREATE]),
                },
                {
                  value: VIEW_UPDATE_CREATE_DELETE,
                  labelToken:
                    tokenOverrides[VIEW_UPDATE_CREATE_DELETE] ?? localizationKey("View, Update, Create, Delete"),
                  optionKeys: getOptionKeys([VIEW, UPDATE, CREATE, DELETE]),
                },
              ]
            : [
                {
                  value: VIEW_UPDATE_DELETE,
                  labelToken: tokenOverrides[VIEW_UPDATE_DELETE] ?? localizationKey("View, Update, Delete"),
                  optionKeys: getOptionKeys([VIEW, UPDATE, DELETE]),
                },
                {
                  value: VIEW_RUN_UPDATE_DELETE,
                  labelToken: tokenOverrides[VIEW_RUN_UPDATE_DELETE] ?? localizationKey("View, Run, Update, Delete"),
                  optionKeys: getOptionKeys([VIEW, RUN, UPDATE, DELETE]),
                },
              ]),
        ].filter(
          compose(
            all(key => find(equals(key), keys)),
            prop("optionKeys"),
          ),
        )),
  ]
}

const linkedPermissionsMap = () => ({
  "Global.KB.EndUser.Folder.Share": {
    modal: {
      titleToken: localizationKey("Share with end users"),
      actionToken: localizationKey("Continue"),
      description: localized(
        "Enabling this permission will also enable the System level “End User Sharing” permission.",
      ),
    },
    linkedPermissions: [
      {
        name: "EndUserSharing.Access",
        entityType: "SYSTEM",
      },
    ],
  },
})

const getLinkedPermissions = ({ entitySubType }) => ({
  hasLinkedPermissions: !!linkedPermissionsMap()[entitySubType],
  linkedPermissions: linkedPermissionsMap()[entitySubType]
    ? linkedPermissionsMap()[entitySubType].linkedPermissions
    : [],
  linkedPermissionNames: linkedPermissionsMap()[entitySubType]
    ? linkedPermissionsMap()[entitySubType].linkedPermissions.map(linkedPermission => linkedPermission.name)
    : [],
})

export function getPermissionChangeHandler({
  createOnly,
  entityIds = [DEFAULT_ENTITY_ID],
  data,
  setData,
  entityType,
  entitySubType,
  roleType,
  overrideRequiredCreatePermissions,
}) {
  let keys = getKeys({ entityType, entitySubType, roleType })
  const getPermissionNames = map(permission => getPermissionName({ entitySubType, permission }))
  const isDefault = equals(entityIds, [DEFAULT_ENTITY_ID])

  const { hasLinkedPermissions, linkedPermissions, linkedPermissionNames } = getLinkedPermissions({ entitySubType })

  if (isDefault && createOnly) {
    keys =
      getPermissionValue({ createOnly: true, entityId: DEFAULT_ENTITY_ID, data, entityType, entitySubType }) === CREATE
        ? [CREATE]
        : overrideRequiredCreatePermissions || [CREATE, VIEW, UPDATE]
  }

  const permissionNames = compose(
    when(() => !isDefault || !createOnly, without([CREATE])),
    getPermissionNames,
  )(keys)

  const purgedPermissions = reject(
    matchesPermission({ entityIds, entityType, permissionNames }),
    data.permissions ?? [],
  )

  const usePermissionTemplate = allowPermissions =>
    permissionNames.map(name => {
      const allowed = getPermissionNames(allowPermissions).includes(name)

      if (isDefault && !allowed) return []

      return entityIds.map(entityId => ({ entityId, entityType, name, allowed }))
    })

  return value => {
    const templates = {
      [CREATE]: usePermissionTemplate(overrideRequiredCreatePermissions || [CREATE, VIEW, UPDATE]),
      [VIEW]: usePermissionTemplate([VIEW]),
      [RUN]: usePermissionTemplate([RUN]),
      [ALL]: usePermissionTemplate([ALL]),
      [OWN]: usePermissionTemplate([OWN]),
      [VIEW_UPDATE]: usePermissionTemplate([VIEW, UPDATE]),
      [VIEW_UPDATE_DELETE]: usePermissionTemplate([VIEW, UPDATE, DELETE]),
      [VIEW_UPDATE_CREATE]: usePermissionTemplate([VIEW, UPDATE, CREATE]),
      [VIEW_UPDATE_CREATE_DELETE]: usePermissionTemplate([VIEW, UPDATE, CREATE, DELETE]),
      [VIEW_RESTORE]: usePermissionTemplate([VIEW, RESTORE]),
      [VIEW_RESTORE_MANAGE]: usePermissionTemplate([VIEW, RESTORE, MANAGE]),
      [VIEW_MANAGE]: usePermissionTemplate([VIEW, MANAGE]),
      [VIEW_RUN]: usePermissionTemplate([VIEW, RUN]),
      [VIEW_RUN_UPDATE]: usePermissionTemplate([VIEW, RUN, UPDATE]),
      [VIEW_RUN_UPDATE_DELETE]: usePermissionTemplate([VIEW, RUN, UPDATE, DELETE]),
      [ACCESS]: usePermissionTemplate([ACCESS]),
      [VIEW_SCHEDULE]: usePermissionTemplate([VIEW_SCHEDULE]),
      [VIEW_CLAIM_SCHEDULE]: usePermissionTemplate([VIEW_SCHEDULE, VIEW_CLAIM_SCHEDULE]),
      [FULL_ACCESS]: usePermissionTemplate([VIEW_SCHEDULE, VIEW_CLAIM_SCHEDULE, FULL_ACCESS]),
      [NONE]: isDefault ? [] : usePermissionTemplate([]),
    }

    let permissions = compose(concat(purgedPermissions), flatten, defaultTo([]), prop(value))(templates)

    const existingLinkedPermissions = permissions.filter(permission => linkedPermissionNames.includes(permission.name))
    const areExistingLinkedPermissionsEnabled = existingLinkedPermissions.every(permission => permission.allowed)

    const shouldUpdateLinkedPermissions =
      hasLinkedPermissions &&
      templates[value].length > 0 &&
      (!areExistingLinkedPermissionsEnabled || !existingLinkedPermissions.length)

    const enableNotAllowedLinkedPermissions = linkedPermissionNames =>
      permissions.map(permission => {
        if (linkedPermissionNames.includes(permission.name) && !permission.allowed) {
          return { ...permission, allowed: true }
        }
        return permission
      })

    const setPermissionsData = () => {
      if (shouldUpdateLinkedPermissions) {
        const linkedPermissionExists = permissions.some(permission => linkedPermissionNames.includes(permission.name))

        if (!linkedPermissionExists) {
          const newLinkedPermissions = linkedPermissions.map(linkedPermission => ({
            allowed: true,
            entityId: 0,
            entityType: linkedPermission.entityType,
            name: linkedPermission.name,
          }))

          permissions = [...permissions, ...newLinkedPermissions]
        } else {
          permissions = enableNotAllowedLinkedPermissions(linkedPermissionNames)
        }
      }

      if (isDefault) {
        const matchAllForEntityType = matchesPermission({ entityType })
        const areOnlyDefaultAndDeny = compose(
          all(both(propEq("entityId", DEFAULT_ENTITY_ID), propEq("allowed", false))),
          filter(matchAllForEntityType),
        )(permissions)

        if (areOnlyDefaultAndDeny) {
          setData(assoc("permissions", reject(matchAllForEntityType, permissions)))
          return
        }
      }

      setData(assoc("permissions", permissions))
    }

    if (shouldUpdateLinkedPermissions) {
      showModal(
        <PermissionActionModal
          titleToken={linkedPermissionsMap()[entitySubType].modal.titleToken}
          actionToken={linkedPermissionsMap()[entitySubType].modal.actionToken}
          description={linkedPermissionsMap()[entitySubType].modal.description}
          onConfirm={setPermissionsData}
        />,
      )
    } else {
      setPermissionsData()
    }
  }
}

export function areEntityPermissionsEnabled({ data, entityType }) {
  return compose(isNotEmpty, filter(propEq("entityType", entityType)), defaultTo([]))(data.permissions)
}

export function hasDefaultPermission({ data, entityType, entitySubType }) {
  const keys = getKeys({ entityType, entitySubType })
  const permissionNames = map(permission => getPermissionName({ entitySubType, permission }), keys)

  return any(matchesPermission({ entityId: DEFAULT_ENTITY_ID, entityType, permissionNames }), data.permissions)
}

export function clearEntityPermissions({ entityType, entityTypes, data, setData }) {
  const purgedPermissions = reject(
    ifElse(
      () => !!entityTypes,
      ({ entityType }) => entityTypes.includes(entityType),
      propEq("entityType", entityType),
    ),
    data.permissions ?? [],
  )
  setData({ ...data, permissions: purgedPermissions })
}

/**
 * Update permissions in bulk within an entity type.
 * E.g.: To set all entity sub-types within MDM to View, Update -> bulkSetPermissions({ setTo: VIEW_UPDATE, entityType: "MDM", data, setData })
 *
 * @param {Object} options - Object param
 * @param {string} options.setTo - Desired permission to use, e.g.: VIEW_UPDATE ("ViewUpdate").
 * @param {string} options.entityType - Entity type to target, e.g.: EntityType.MDM ("MDM").
 * @param {Object} options.data - Permissions data object.
 * @param {Function} options.setData - To update data
 * @param {string} [options.roleType="technician"] - Used to get the sub-types within entity type, defaults to 'technician'
 * @returns {void}
 */
export function bulkSetPermissions({ setTo, entityType, data, setData, roleType = "technician" }) {
  const keysObject = getKeys({ roleType, entityType })
  const keys = Object.keys(keysObject)
  const permissions = setTo.split(/(?=[A-Z])/)

  const updatedPermissions = keys.reduce((acc, key) => {
    permissions.forEach(permission => {
      // Only add permission if its part of entity sub-type
      if (keysObject[key].includes(permission)) {
        acc.push({ allowed: true, entityId: 0, entityType, name: `${key}.${permission}` })
      }
    })

    return acc
  }, [])

  setData({ ...data, permissions: [...data.permissions, ...updatedPermissions] })
}

export function getGlobalAccessOptions({ entityCreateValue, nonCreateOptions }) {
  return entityCreateValue === CREATE
    ? nonCreateOptions.map(when(({ value }) => [NONE, VIEW, RUN, VIEW_RUN].includes(value), assoc("disabled", true)))
    : nonCreateOptions
}

export const getTemplates = (roleType = "technician") => {
  const permissions = getPermissions(roleType)
  return {
    fullPermissions: compose(
      flatten,
      map(permissionKey => {
        const value = when(has("Search"), assoc("Search", [CREATE]))(permissions[permissionKey])
        const permissionKeySplit = permissionKey.split(".")
        const entityType = permissionKeySplit[0]
        if (is(Array, value)) {
          return value.map(name => ({
            allowed: true,
            entityId: 0,
            entityType,
            name: permissionKeySplit.length > 1 ? compose(join("."), append(name), tail)(permissionKeySplit) : name,
          }))
        } else {
          return compose(
            map(entitySubType =>
              value[entitySubType].map(name => ({
                allowed: true,
                entityId: 0,
                entityType,
                name: getPermissionName({ entitySubType, permission: name }),
              })),
            ),
            keys,
          )(value)
        }
      }),
      without(["NODE.Class", "NODE.Role"]),
      keys,
    )(permissions),
  }
}
