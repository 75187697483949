import { defaultConditionUids } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/defaults"
import { defaultConditionUids as defaultAgentConditionUids } from "js/includes/editors/Policy/Models/Conditions/DefaultConditions"
import { defaultNmsConditionUids } from "js/includes/editors/Policy/Models/NmsConditions/DefaultNmsConditions"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { flip, divide, multiply, pick, isEmpty, map, reduce } from "ramda"
import {
  operatorOptionsKeys,
  conditionAbrevToWord,
  stateKeysToObject,
  modeKeysToObject,
  unitBpsOptionsKeys,
  rwOptionsKeys,
  byteOptionsKeys,
  processStateMapper,
  minuteHourUnitPickerOptionsKeys,
  daemonStateOptions,
  minuteHourUnitPicker,
  rebootPendingEveryUnitPicker,
} from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/conditionData"
import { ResetThresholdOptionsWithoutManual } from "js/includes/common/_options"
import { convertBytes } from "js/includes/common/utils"
import {
  BATTERY_CHARGE,
  BATTERY_CAPACITY,
  BATTERY_CYCLES,
} from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/modals/utils/batteryMonitoringUtils"

export const valueFromSeconds = flip(divide)(3600)
export const valueToSeconds = multiply(3600)
const MINUTES_IN_HOUR = 60
const MINUTES_IN_DAY = 1440

const getLocalizationStringFor = conditionIdentifier => {
  switch (conditionIdentifier) {
    case defaultConditionUids.AVHealth:
      return localizationKey("Antivirus Health")
    case defaultConditionUids.CPU:
      return localizationKey("Aggregate CPU Usage")
    case defaultConditionUids.BatteryMonitoring:
      return localizationKey("Battery Monitoring")
    case defaultConditionUids.Memory:
      return localizationKey("Memory Usage")
    case defaultConditionUids.DiskUsage:
    case defaultAgentConditionUids.DiskUsage:
      return localizationKey("Disk Usage")
    case defaultAgentConditionUids.DiskFreeSpace:
      return localizationKey("Disk Free Space")
    case defaultConditionUids.DataStoreFreeSpace:
      return localizationKey("Datastore Free Space")
    case defaultConditionUids.BadSensorCount:
      return localizationKey("Bad Sensor Count")
    case defaultConditionUids.DeviceDown:
    case defaultAgentConditionUids.DeviceDown:
      return localizationKey("Device Down")
    case defaultConditionUids.SensorHealth:
      return localizationKey("Sensor Health")
    case defaultConditionUids.ProcessorUsage:
      return localizationKey("Processor Usage")
    case defaultConditionUids.GuestOperationMode:
      return localizationKey("Guest Operation Mode")
    case defaultConditionUids.SnapshotSize:
      return localizationKey("Snapshot Size")
    case defaultConditionUids.SnapshotLifeSpan:
      return localizationKey("Snapshot Lifespan")
    case defaultConditionUids.CheckpointSize:
      return localizationKey("Checkpoint Size")
    case defaultConditionUids.CheckpointLifeSpan:
      return localizationKey("Checkpoint Lifespan")
    case defaultConditionUids.GuestToolsNotRunning:
      return localizationKey("Guest Tools not running")
    case defaultAgentConditionUids.NetworkUtilization:
      return localizationKey("Network Utilization")
    case BATTERY_CHARGE:
      return localizationKey("Battery Charge")
    case BATTERY_CAPACITY:
      return localizationKey("Battery Capacity")
    case BATTERY_CYCLES:
      return localizationKey("Battery Cycles")
    default:
      throw new Error("Invalid")
  }
}

const getUnitType = unit => {
  switch (unit) {
    case "MINUTE":
      return localizationKey("Minute(s)")
    case "HOUR":
      return localizationKey("Hour(s)")
    case "DAY":
      return localizationKey("day(s)")
    case "WEEK":
      return localizationKey("Weeks")
    case "MONTH":
      return localizationKey("Months")
    default:
      throw new Error("Invalid")
  }
}

const getStatusString = (state, keysToObject) => {
  return reduce(
    (a, b) => {
      return a + b.label + ", "
    },
    "",
    arrayFromKeys(state, keysToObject),
  ).slice(0, -2)
}

const getLinuxDaemonConditionText = (parameters, conditionName) => {
  const {
    state: _state,
    include,
    duration,
    durationTimeUnit,
    alertingDelay,
    alertingDelayTimeUnit: timeUnit,
    ignoreIfServiceIsDisabled,
    triggerConditionAgainIfStillTrueAfterReset,
  } = parameters

  const { value, text } = daemonStateOptions.items.find(({ value }) => value === _state)
  const alertingDelayTimeUnit = minuteHourUnitPicker.items.find(({ value }) => value === timeUnit).text()
  const durationTimeUnitLabel = minuteHourUnitPicker.items.find(({ value }) => value === durationTimeUnit).text()
  const daemonStateString = ["EXIST", "NOTEXIST"].includes(value)
    ? localized("Daemon {{include}} {{state}}", { include, state: text() })
    : localized("Daemon {{include}} is {{state}}", { include, state: text() })
  const daemonDurationString = duration
    ? localized(", duration {{duration}} {{durationTimeUnitLabel}}", {
        duration,
        durationTimeUnitLabel,
      })
    : ""
  const daemonAlertString = alertingDelay
    ? localized(", start alerting after {{alertingDelay}} {{alertingDelayTimeUnit}}", {
        alertingDelay,
        alertingDelayTimeUnit,
      })
    : ""

  return (
    [
      conditionName,
      daemonStateString,
      ignoreIfServiceIsDisabled ? localized(" and is not disabled") : "",
      daemonDurationString,
      daemonAlertString,
      triggerConditionAgainIfStillTrueAfterReset ? localized(" and trigger again") : "",
    ].join("") + "."
  )
}

export const generateConditionName = (parameters, conditionUid) => {
  const { unit } = parameters
  var name = ""
  var conditionName = ""

  switch (conditionUid) {
    case defaultConditionUids.AVHealth:
      conditionName = localized(getLocalizationStringFor(conditionUid)) + ". " + localized("AV is")
      conditionName += (parameters.avMissing && " " + localized("missing") + ",") || ""
      conditionName += (parameters.avDisabled && " " + localized("disabled") + ",") || ""
      conditionName += (parameters.avOutdated && " " + localized("outdated") + ",") || ""
      conditionName += (parameters.avMultiple && " " + localized("multiple AVs installed") + ",") || ""
      conditionName += (parameters.avIgnoreMsDefender && " " + localized("ignore Microsoft Defender") + ",") || ""
      conditionName +=
        " " +
        localized("trigger after {{durationValue}} {{durationUnit}}", {
          durationValue: parameters.duration,
          durationUnit: localized(getUnitType(parameters.unit)).toLowerCase(),
        })
      return conditionName
    case defaultConditionUids.CPU:
      return (
        localized(getLocalizationStringFor(conditionUid)) +
        " " +
        localized("is") +
        " " +
        operatorOptionsKeys[parameters.operator]() +
        " " +
        parameters.threshold +
        " % " +
        localized("for") +
        " " +
        parameters.duration +
        " " +
        localized("Minutes").toLowerCase()
      )
    case defaultConditionUids.BatteryMonitoring:
      return localized(`{{_condition}}: {{_appliedCondition}} is {{_operator}} {{_threshold}}{{_unit}}`, {
        _condition: getLocalizationStringFor(conditionUid),
        _appliedCondition: getLocalizationStringFor(parameters.appliedCondition),
        _operator: operatorOptionsKeys[parameters.operator](),
        _threshold: parameters.threshold,
        _unit: unit === "PERCENT" ? "%" : "",
      })
    case defaultAgentConditionUids.LinuxDaemon:
      return getLinuxDaemonConditionText(parameters, conditionName)
    case defaultAgentConditionUids.MacDaemon:
      const daemonStateMapper = {
        EXIST: localized("editors.policy.conditions.exists"),
        NOTEXIST: localized("editors.policy.conditions.doesntExist"),
        STARTED: localized("general.up"),
        STOPPED: localized("general.down"),
      }
      return (
        localized("editors.policy.conditions.daemon") +
        " '" +
        parameters.include +
        "' " +
        localized("editors.policy.conditions.is") +
        " " +
        daemonStateMapper[parameters.state]
      )
    case defaultAgentConditionUids.MacProcess:
      return ["EXIST", "NOTEXIST"].includes(parameters.state)
        ? localized("Process '{{processName}}' {{processStatus}}", {
            processName: parameters.include,
            processStatus: localized(processStateMapper[parameters.state] ?? parameters.state),
          })
        : localized("Process '{{processName}}' is {{processStatus}}", {
            processName: parameters.include,
            processStatus: localized(processStateMapper[parameters.state] ?? parameters.state),
          })
    case defaultConditionUids.RebootPending: {
      let pendingRebootTitle = ""
      let userIdleTitle = ""
      if (parameters?.pendingRebootDuration) {
        const { duration, durationType } = parameters.pendingRebootDuration
        pendingRebootTitle = localized("Reboot Pending for {{duration}} {{unit}}", {
          duration: duration,
          unit: rebootPendingEveryUnitPicker.items.find(({ value }) => value === durationType).text(),
        })
      }
      if (parameters?.userIdleDuration) {
        const { duration, durationType } = parameters.userIdleDuration
        userIdleTitle = localized("All logged in users have been idle for longer than {{duration}} {{unit}}", {
          duration: duration,
          unit: rebootPendingEveryUnitPicker.items.find(({ value }) => value === durationType).text(),
        })
      }
      return (
        pendingRebootTitle +
          (pendingRebootTitle && userIdleTitle ? ". " : userIdleTitle ? localized("Reboot Pending") + ". " : "") +
          userIdleTitle || localized("Reboot Pending")
      )
    }
    case defaultAgentConditionUids.SystemReboot:
      return (
        localized("editors.policy.conditions.deviceHasNotReboot") +
        " " +
        parameters.days +
        " " +
        localized("editors.policy.conditions.days")
      )
    case defaultAgentConditionUids.WindowsSMARTStatusDegraded:
      return localized("editors.policy.conditions.winSMART")
    case defaultAgentConditionUids.BackupJobDurationAlert:
      return localized("Backup Job Running for {{duration}} hours", { duration: parameters.duration })
    case defaultAgentConditionUids.BackupJobLastSuccess:
      return localized("Backup Job Last success {{duration}} hours ago", { duration: parameters.duration })
    case defaultAgentConditionUids.Software:
      return parameters.softwarePresence === "include"
        ? localized("editors.policy.conditions.softwareExists") +
            `: '${parameters.softwareInventoryOptions.join(" AND ")}'`
        : localized("editors.policy.conditions.softwareDoesNotExist") +
            `: '${parameters.softwareInventoryOptions.join(" AND ")}'`
    case defaultAgentConditionUids.RaidHealthStatus:
      return (
        localized("editors.policy.conditions.raidHealthStatusWhen") +
        ` ${localized("editors.policy.conditions.controller")} ${localized("general.is")}  '${parameters.controller}'` +
        " " +
        localized("general.or") +
        " " +
        `${localized("editors.policy.conditions.virtualDrives")} ${localized("general.is")} '${
          parameters.virtualDrive
        }'` +
        " " +
        localized("general.or") +
        " " +
        `${localized("editors.policy.conditions.physicalDrives")} ${localized("general.is")} '${
          parameters.physicalDrive
        }'` +
        " " +
        localized("general.or") +
        " " +
        `${localized("editors.policy.conditions.batteryBackup")} ${localized("general.is")} '${
          parameters.batteryBackupUnit
        }'` +
        `
      ${
        parameters.bbuTemperature.monitor
          ? ` ${localized("general.or")} ${localized("editors.policy.conditions.bbuTemperatureGreaterThan")} ${
              parameters.bbuTemperature.threshold
            } °C`
          : ""
      }
      `
      )
    case defaultAgentConditionUids.EventLogCondition:
      return (
        localized("editors.policy.conditions.event") +
        ' "' +
        parameters.source +
        '"' +
        " " +
        localized("editors.policy.conditions.isTriggered")
      )
    case defaultAgentConditionUids.Process:
    case defaultAgentConditionUids.LinuxProcess:
      conditionName = ["EXIST", "NOTEXIST"].includes(parameters.state)
        ? localized("Process '{{joinedOrParameters}}' {{processStatus}}", {
            joinedOrParameters: parameters.include.join(" OR "),
            processStatus: localized(processStateMapper[parameters.state]),
          })
        : localized("Process '{{joinedOrParameters}}' is {{processStatus}}", {
            joinedOrParameters: parameters.include.join(" OR "),
            processStatus: localized(processStateMapper[parameters.state]),
          })
      if (parameters.duration > 0) {
        conditionName +=
          ". " +
          localized("Duration {{duration}} {{minuteHourUnit}}", {
            duration: parameters.duration,
            minuteHourUnit: minuteHourUnitPickerOptionsKeys[parameters.durationTimeUnit](),
          })
      }
      if (parameters.alertingDelay > 0) {
        conditionName +=
          ". " +
          localized("Start alerting after {{alertingDelay}} {{minuteHourUnit}}", {
            alertingDelay: parameters.alertingDelay,
            minuteHourUnit: minuteHourUnitPickerOptionsKeys[parameters.alertingDelayTimeUnit](),
          })
      }

      return conditionName

    case defaultAgentConditionUids.ProcessResourcesCPU:
    case defaultAgentConditionUids.ProcessResourcesMemory:
    case defaultAgentConditionUids.LinuxProcessResourcesCPU:
    case defaultAgentConditionUids.LinuxProcessResourcesMemory:
    case defaultAgentConditionUids.MacProcessResourcesCPU:
    case defaultAgentConditionUids.MacProcessResourcesMemory:
      if (parameters.unit === "PERCENT") {
        name = parameters.thresholdPercent
      }
      if (parameters.unit === "BYTES") {
        name = parameters.thresholdBytes
      }

      conditionName = localized(
        "{{processName}} {{processResource}} is {{processOperator}} {{bytesOrPercentThreshold}}",
        {
          processName: parameters.include,
          processResource: parameters.resource,
          processOperator: operatorOptionsKeys[parameters.operator](),
          bytesOrPercentThreshold: name,
        },
      )

      if (unit === "BYTES") {
        /**
         * TODO: Refactor the code after making changes to the 'conditionAbrevToWord' function
         * It will be like this: conditionName += localized(conditionAbrevToWord[parameters.unitBytes])
         *  */

        conditionName += conditionAbrevToWord[parameters.unitBytes] + "Byte "
      }
      if (unit === "PERCENT") {
        conditionName += "% "
      }

      if (parameters.duration) {
        conditionName += localized("for {{durationInMinutes}} minutes", {
          durationInMinutes: parameters.duration,
        })
      }

      return conditionName

    case defaultAgentConditionUids.DiskFreeSpace:
    case defaultAgentConditionUids.DiskUsage:
    case defaultConditionUids.DiskFreeSpace:
    case defaultConditionUids.DiskUsage:
    case defaultAgentConditionUids.MacDiskUsage:
    case defaultAgentConditionUids.LinuxDiskUsage:
      if (parameters.unit === "PERCENT") {
        name = parameters.thresholdPercent
      }
      if (parameters.unit === "BYTES") {
        name = parameters.thresholdBytes
      }
      conditionName =
        localized(getLocalizationStringFor(conditionUid)) +
        " " +
        localized("is") +
        " " +
        operatorOptionsKeys[parameters.operator]() +
        " " +
        name

      if (unit === "BYTES") {
        conditionName += " " + conditionAbrevToWord[parameters.unitBytes] + "Byte"
      }
      if (unit === "PERCENT") {
        conditionName += "%"
      }

      if (parameters.duration) {
        const durationOneMinuteText = localized("for 1 minute")
        const durationMultipleMinutesText = localized("for {{duration}} minutes", {
          duration: parameters.duration,
        })
        const durationOneHourText = localized("for 1 hour")
        const durationMultipleHoursText = localized("for {{duration}} hours", {
          duration: parameters.duration / 60,
        })
        const durationMinutesText = parameters.duration === 1 ? durationOneMinuteText : durationMultipleMinutesText
        const durationHoursText = parameters.duration === 60 ? durationOneHourText : durationMultipleHoursText
        const durationText = parameters.duration > 59 ? durationHoursText : durationMinutesText
        conditionName += ` ${durationText}`
      }

      if (parameters.excludeBoot) {
        conditionName += " " + localized("Exclude Boot Volume")
      }

      if (parameters.excludeRecovery) {
        conditionName += " " + localized("Exclude recovery volume for window device(s)")
      }

      if (parameters.excludeRemovableDisk) {
        conditionName += " " + localized("Exclude removable disk for window device(s)")
      }
      return conditionName

    case defaultConditionUids.Memory:
    case defaultConditionUids.DataStoreFreeSpace:
    case defaultConditionUids.CheckpointSize:
    case defaultConditionUids.SnapshotSize:
      if (parameters.unit === "PERCENT") {
        name = parameters.thresholdPercent
      }
      if (parameters.unit === "BYTES") {
        name = parameters.thresholdBytes
      }
      conditionName =
        localized(getLocalizationStringFor(conditionUid)) +
        " " +
        localized("is") +
        " " +
        operatorOptionsKeys[parameters.operator]() +
        " " +
        name +
        " "

      if (unit === "BYTES") {
        // TODO: Same as 234 line
        conditionName += conditionAbrevToWord[parameters.unitBytes] + "Byte "
      }
      if (unit === "PERCENT") {
        conditionName += " % "
      }

      if (parameters.duration) {
        conditionName += localized("for") + " " + parameters.duration + " " + localized("Minutes").toLowerCase()
      }

      if (parameters.excludeBoot) {
        conditionName += " " + localized("Exclude Boot Volume")
      }

      if (parameters.excludeRecovery) {
        conditionName += " " + localized("Exclude recovery volume for window device(s)")
      }

      if (parameters.excludeRemovableDisk) {
        conditionName += " " + localized("Exclude removable disk for window device(s)")
      }

      return conditionName
    case defaultConditionUids.VMHostUptime:
      return localized("VM Host is up") + " " + localized("for") + " " + parameters.days + " " + localized("day(s)")
    case defaultConditionUids.BadSensorCount:
      return (
        localized(getLocalizationStringFor(conditionUid)) +
        " " +
        localized("is") +
        " " +
        operatorOptionsKeys[parameters.operator]() +
        " " +
        parameters.threshold
      )
    case defaultConditionUids.DeviceDown:
    case defaultAgentConditionUids.DeviceDown:
      return (
        localized(getLocalizationStringFor(conditionUid)) +
        " " +
        localized("for") +
        " " +
        parameters.duration +
        " " +
        localized(getUnitType(parameters.unit)).toLowerCase()
      )
    case defaultConditionUids.SensorHealth:
      return (
        parameters.name.length +
        " " +
        localized("sensor(s) with") +
        " " +
        getStatusString(parameters.state, stateKeysToObject) +
        " " +
        localized("State").toLowerCase()
      )
    case defaultConditionUids.ProcessorUsage:
      return (
        localized(getLocalizationStringFor(conditionUid)) +
        " " +
        localized("is") +
        " " +
        operatorOptionsKeys[parameters.operator]() +
        " " +
        parameters.threshold +
        " % " +
        localized("for") +
        " " +
        parameters.duration +
        " " +
        localized("Minutes").toLowerCase()
      )
    case defaultConditionUids.GuestOperationMode:
      return (
        localized(getLocalizationStringFor(conditionUid)) +
        " " +
        localized("with name(s)") +
        " " +
        getStatusString(parameters.mode, modeKeysToObject)
      )
    case defaultConditionUids.CheckpointLifeSpan:
    case defaultConditionUids.SnapshotLifeSpan:
      return (
        localized(getLocalizationStringFor(conditionUid)) +
        " " +
        localized("is") +
        " " +
        operatorOptionsKeys[parameters.operator]() +
        " " +
        parameters.duration +
        " " +
        localized(getUnitType(parameters.unit))
      )
    case defaultConditionUids.GuestToolsNotRunning:
      return localized(getLocalizationStringFor(conditionUid))
    case defaultAgentConditionUids.NetworkUtilization:
      return `${localized(getLocalizationStringFor(conditionUid))} ${byteOptionsKeys[parameters.type]()} ${localized(
        "are",
      )} ${operatorOptionsKeys[parameters.operator]()} ${parameters.thresholdBytes} ${
        unitBpsOptionsKeys[parameters.unitBytes]
      } ${localized("for")} ${parameters.duration} ${localized("Minutes").toLowerCase()}`
    case defaultAgentConditionUids.DiskVolumeIO:
      // Disk active time
      if (parameters.unit === "PERCENT") {
        return (
          localized("Disk Active Time is") +
          " " +
          operatorOptionsKeys[parameters.operator]() +
          " " +
          parameters.threshold +
          "% " +
          localized("for") +
          " " +
          parameters.duration +
          " " +
          localized("Minutes").toLowerCase()
        )
      }

      // Disk transfer rate
      return `${localized("Disk")} ${rwOptionsKeys[parameters.type]()} ${localized("is")} ${operatorOptionsKeys[
        parameters.operator
      ]()} ${parameters.thresholdBytes} ${unitBpsOptionsKeys[parameters.unitBytes]} ${localized("for")} ${
        parameters.duration
      } ${localized("Minutes").toLowerCase()}`
    case defaultConditionUids.PatchCVSSScore:
      return localized(
        "At least one patch is available with a CVSS score {{compareOperator}} {{cvssScore}} for {{daysDuration}} days",
        {
          compareOperator: operatorOptionsKeys[parameters.operator](),
          cvssScore: parseFloat(parameters.threshold).toFixed(1),
          daysDuration: parameters.duration,
        },
      )

    case defaultAgentConditionUids.PatchLastInstalled:
      return parameters.patchType === "OS"
        ? localized("Device has not installed an Operating System patch in {{days}} day(s)", { days: parameters.days })
        : localized("Device has not installed a 3rd Party Software patch in {{days}} day(s)", { days: parameters.days })
    case defaultNmsConditionUids.SystemReboot:
      return localized(`Device has not rebooted in {{duration}} {{unit}}`, {
        duration: parameters.duration,
        unit: localized(getUnitType(parameters.unit)).toLowerCase(),
      })
    default:
      throw new Error("Invalid policy id")
  }
}

export const generatedDescription = condition => {
  let generatedDescription = ""

  const resetThresholdManual = condition.resetThreshold === 2147480000
  const hasResetIfNotPresentSeconds = condition.resetIfNotPresentSeconds
  const resetIfNotPresentSeconds = hasResetIfNotPresentSeconds && condition.resetIfNotPresentSeconds !== 0
  const resetIfNotPresentSecondsManual = hasResetIfNotPresentSeconds && condition.resetIfNotPresentSeconds === 0
  if (
    (resetThresholdManual && resetIfNotPresentSecondsManual) ||
    (!hasResetIfNotPresentSeconds && valueFromSeconds(condition.resetThreshold) > 24)
  ) {
    generatedDescription += localized("Manual")
  } else {
    if (!resetThresholdManual) {
      generatedDescription +=
        localized("Reset in") + " " + valueFromSeconds(condition.resetThreshold) + " " + localized("Hours")
      if (resetIfNotPresentSeconds) {
        generatedDescription += " " + localized("and")
      }
    }
    if (resetIfNotPresentSeconds) {
      if (resetThresholdManual) {
        generatedDescription += localized("Reset")
      }
      if (condition.resetIfNotPresentSeconds === 1) {
        generatedDescription += " " + localized("When no longer met")
      } else {
        generatedDescription +=
          " " +
          localized("when not met for") +
          " " +
          valueFromSeconds(condition.resetIfNotPresentSeconds) +
          " " +
          localized("Hours")
      }
    }
  }
  return generatedDescription
}

export const generateConditionForServer = (conditionData, nodeRole) => {
  let data = pick(
    [
      "enabled",
      "displayName",
      "channels",
      "conditionName",
      "conditionUid",
      "parameters",
      "scripts",
      "inheritance",
      "ticketingAction",
      "ticketTemplateId",
      "ticketRulesetId",
      "createNinjaTicket",
      "severity",
      "priority",
      "notificationAction",
    ],
    conditionData,
  )

  let allowNotifications = conditionData.notificationAction !== "NONE"

  let resetThreshold = 2147480000
  let resetIfNotPresentSeconds = 0
  const resetAfterChecked = conditionData.resetAfterChecked
  const resetWhenConditionNotMetForChecked = conditionData.resetIfNotMetForChecked

  if (resetAfterChecked) {
    resetThreshold = valueToSeconds(conditionData.resetAfter)
  }
  if (resetWhenConditionNotMetForChecked) {
    resetIfNotPresentSeconds = valueToSeconds(conditionData.resetIfNotPresent)
  }

  data.resetThreshold = resetThreshold
  data.resetIfNotPresentSeconds = resetIfNotPresentSeconds
  data.notifyOnReset =
    allowNotifications && (resetAfterChecked || resetWhenConditionNotMetForChecked)
      ? conditionData.notifyOnReset
      : false

  return data
}

export const resetStatuses = condition => {
  if (isEmpty(condition)) return {}

  const resetThresholdManual = condition.resetThreshold === 2147480000
  const resetThreshold = valueFromSeconds(condition.resetThreshold)
  const resetAfterChecked = !resetThresholdManual
  const resetIfNotMetForChecked = Boolean(
    condition.resetIfNotPresentSeconds && condition.resetIfNotPresentSeconds !== 1,
  )
  const resetAfter = resetThreshold
  const resetIfNotPresent =
    valueFromSeconds(condition.resetIfNotPresentSeconds) || ResetThresholdOptionsWithoutManual.value

  return {
    resetThreshold,
    resetAfterChecked,
    resetIfNotMetForChecked,
    resetAfter,
    resetIfNotPresent,
  }
}

export const getAdditionalFields = (data, conditionUid) => {
  const { unit, threshold } = data
  switch (conditionUid) {
    case defaultConditionUids.Memory:
    case defaultConditionUids.DiskUsage:
    case defaultAgentConditionUids.MacDiskUsage:
    case defaultConditionUids.DataStoreFreeSpace:
    case defaultConditionUids.CheckpointSize:
    case defaultConditionUids.SnapshotSize:
    case defaultAgentConditionUids.DiskVolumeIO:
    case defaultAgentConditionUids.NetworkUtilization:
    case defaultAgentConditionUids.MacProcessResourcesMemory:
    case defaultAgentConditionUids.ProcessResourcesMemory:
    case defaultAgentConditionUids.LinuxProcessResourcesMemory:
    case defaultAgentConditionUids.MacProcessResourcesCPU:
    case defaultAgentConditionUids.ProcessResourcesCPU:
    case defaultAgentConditionUids.LinuxProcessResourcesCPU:
      let thresholdPercent = 80
      let thresholdBytes = 1
      let unitBytes = "GB"
      if (unit === "BYTES") {
        const { prefix, value } = convertBytes(threshold)
        thresholdBytes = value
        unitBytes = prefix
      } else {
        thresholdPercent = threshold
      }
      return {
        thresholdPercent,
        thresholdBytes,
        unitBytes,
      }
    case defaultAgentConditionUids.DiskUsage:
    case defaultAgentConditionUids.DiskFreeSpace:
      if (unit === "BYTES") {
        const { prefix, value } = convertBytes(threshold)

        return {
          unitBytes: prefix,
          thresholdBytes: value,
          thresholdPercent: value,
        }
      }

      return {
        unitBytes: null,
        thresholdBytes: threshold,
        thresholdPercent: threshold,
      }
    default:
      return {}
  }
}

export const keysFromSeleted = selected => {
  const selectedValue = Array.isArray(selected) ? selected : [selected]
  return map(obj => obj.value, selectedValue)
}

export const arrayFromKeys = (keys, obj) => {
  return map(key => {
    return { ...obj[key], label: obj[key].label() }
  }, keys)
}

export const getPathToCondition = conditionUid => {
  return `conditions.${conditionUid}`
}

export const getCompletePathToItem = pathToItem => ["content", ...pathToItem.split(".")]

export const convertDurationFromMinutes = ({ duration, durationTimeUnit }) => {
  return {
    MINUTE: duration,
    HOUR: duration / MINUTES_IN_HOUR,
    DAY: duration / MINUTES_IN_DAY,
  }[durationTimeUnit]
}

export const convertDurationToMinutes = ({ duration, unit }) => {
  return {
    MINUTE: duration,
    HOUR: duration * MINUTES_IN_HOUR,
    DAY: duration * MINUTES_IN_DAY,
  }[unit]
}

export const validateDiskUsageFreeSpace = ({ unit, threshold, thresholdBytes, unitBytes }) => {
  const MAX_ALLOWED_THRESHOLD = {
    PERCENT: 100,
    KB: 1023,
    MB: 1023,
    GB: 1023,
    TB: 512,
  }

  if (threshold < 1) {
    return {
      success: false,
      message: localized("Minimum value is 1"),
    }
  }

  const maxValue = MAX_ALLOWED_THRESHOLD[unit === "PERCENT" ? unit : unitBytes]
  const thresholdValue = unit === "PERCENT" ? threshold : thresholdBytes

  if (thresholdValue > maxValue) {
    return {
      success: false,
      message: localized("Maximum value is {{maxValue}}", { maxValue }),
    }
  }

  return {
    success: true,
    message: "",
  }
}
