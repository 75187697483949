import styled from "@emotion/styled"
import PropTypes from "prop-types"

import { sizer } from "@ninjaone/utils"
import TitleGroup from "./TitleGroup"

export const StyledCardHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "title action";
  padding-bottom: ${({ hasPadding }) => (hasPadding ? sizer(4) : 0)};
`

export const StyledActions = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-area: action;
`

export const StyledEmptyCard = styled.div`
  position: relative;
  border-radius: ${sizer(1)};
  border: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  background-color: ${({ theme }) => theme.colorBackground};
`

export const StyledWidgetCard = styled(StyledEmptyCard)`
  padding: ${sizer(5)};
  ${({ height }) => (height ? `height: ${height}` : "")}
`

const StyledCard = styled(StyledEmptyCard)`
  padding: ${sizer(6)};
`

export default function Card({ children, titleGroup, actionRenderer, className, id }) {
  return (
    <StyledCard {...{ id, className }}>
      <StyledCardHeader hasPadding={!!titleGroup || !!actionRenderer}>
        {titleGroup && <TitleGroup {...titleGroup} />}
        {actionRenderer && <StyledActions>{actionRenderer()}</StyledActions>}
      </StyledCardHeader>
      {children}
    </StyledCard>
  )
}

Card.propTypes = {
  actionRenderer: PropTypes.func,
  children: PropTypes.any,
  titleGroup: PropTypes.object,
}
