import styled from "@emotion/styled"
import { Checkbox } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { localized } from "js/includes/common/utils/ssrAndWebUtils"
import withInheritance from "js/includes/components/withInheritance"
import { Flex } from "js/includes/components/Styled"
import { getSettings } from "./util"

const StyledFlex = styled(Flex)`
  margin-top: ${tokens.spacing[2]};
  label {
    margin-bottom: 0;
  }
`

const StandardInstallSelector = ({ inheritableItem: allowStandardUserOSUpdatesSettings, onChange }) => {
  return (
    <StyledFlex>
      <Checkbox
        label={localized("Allow standard user accounts to self-install OS updates (macOS 15+)")}
        checked={getSettings(allowStandardUserOSUpdatesSettings, "allowStandardUserOSUpdates")}
        onChange={({ isChecked }) => {
          onChange({
            ...allowStandardUserOSUpdatesSettings,
            allowStandardUserOSUpdates: isChecked,
          })
        }}
      />
    </StyledFlex>
  )
}

export default withInheritance(StandardInstallSelector)
