import { memo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { compose, map, prop, sortBy, toLower } from "ramda"
import styled from "@emotion/styled"
import { faStar } from "@fortawesome/pro-light-svg-icons"
import { faStar as faStarSolid } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import tokens from "@ninjaone/tokens"
import { localized } from "js/includes/common/utils/ssrAndWebUtils"
import { removeFavoriteTab } from "js/state/actions/general/favoriteTabs"
import { getIcon, getOverviewLink } from "./common"
import SubNavLink from "./SubNavLink"
import SubNavNotice from "./SubNavNotice"
import SubNavDrawer from "./SubNavDrawer"
import SubNav from "./SubNav"
import SubNavHoverDropdownItem from "./SubNavHoverDropdownItem"
import { selectFilteredFavoriteTabs } from "js/state/selectors/general/favoriteTabs"

export const StyledNoticeWrapper = styled.div`
  margin-left: ${tokens.spacing[5]};
  margin-right: ${tokens.spacing[2]};
  margin-bottom: ${tokens.spacing[1]};
  padding-left: 3px;
  border-left: 1px solid ${({ theme }) => theme.colorThemeBorder};
  padding-top: ${tokens.spacing[1]};
  padding-bottom: ${tokens.spacing[1]};
`

const FavoriteTabs = memo(({ collapsed }) => {
  const dispatch = useDispatch()
  const favoriteTabs = useSelector(selectFilteredFavoriteTabs)
  const hasNoFavorites = !favoriteTabs.length
  const actionIconTooltip = localized("Remove from Favorites")
  const emptyNotice = <SubNavNotice text={localized("No favorites")} />

  const mapSortedTabs = callback =>
    compose(
      map(tab =>
        callback({
          name: tab.name,
          href: getOverviewLink(tab),
          icon: getIcon(tab),
          actionIcons: [
            {
              tooltip: actionIconTooltip,
              sideOffset: collapsed ? 36 : 20,
              onClick: () => dispatch(removeFavoriteTab(tab)),
              element: <FontAwesomeIcon icon={faStarSolid} size="sm" />,
            },
          ],
        }),
      ),
      sortBy(compose(toLower, prop("name"))),
    )(favoriteTabs)

  const navRenderer = () => {
    if (hasNoFavorites) return <StyledNoticeWrapper>{emptyNotice}</StyledNoticeWrapper>

    return (
      <SubNav ariaLabel={localized("Favorites navigation")}>
        <ul>
          {mapSortedTabs(({ href, icon, name, actionIcons }) => (
            <li key={href}>
              <SubNavLink
                {...{
                  href,
                  icon,
                  name,
                  actionIcons,
                  usesNavLink: false,
                }}
              />
            </li>
          ))}
        </ul>
      </SubNav>
    )
  }

  const hoverNavItemsRenderer = () => (
    <div>
      {hasNoFavorites
        ? emptyNotice
        : mapSortedTabs(({ href: route, icon, name, actionIcons }) => (
            <SubNavHoverDropdownItem
              {...{
                key: route,
                route,
                name,
                icon,
                collapsed,
                actionIcons,
                usesActiveClass: false,
              }}
            />
          ))}
    </div>
  )

  return (
    <div id="favorite" data-testid="favorite">
      <SubNavDrawer
        {...{
          collapsed,
          navRenderer,
          hoverNavItemsRenderer,
          icon: faStar,
          label: localized("Favorites"),
          id: "favorites",
        }}
      />
    </div>
  )
})

export default FavoriteTabs
