import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { sizer } from "@ninjaone/utils"

const StyledNav = styled.nav`
  margin-left: ${sizer(5)};
  margin-right: ${sizer(2)};
  margin-bottom: ${sizer(1)};
  padding-left: 3px;
  border-left: 1px solid ${({ theme }) => theme.colorThemeBorder};
  padding-top: ${sizer(1)};
  padding-bottom: ${sizer(1)};

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  ${({ theme, brandedBackground }) => brandedBackground && `background-color: ${theme.colorThemeBackground};`}
`

const SubNav = ({ ariaLabel, brandedBackground, children }) => (
  <StyledNav aria-label={ariaLabel} {...{ brandedBackground }}>
    {children}
  </StyledNav>
)

SubNav.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
}

export default SubNav
