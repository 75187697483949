import { always, assoc, compose, cond, either, join, map, prop, slice, split, uniqBy } from "ramda"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { isAndroidPolicy, isAppleMobilePolicy, isMacPolicy, MDMOwnership } from "js/includes/common/utils"

const appleEnrollmentTypes = [
  { labelToken: localizationKey("Supervised"), value: "SUPERVISED" },
  { labelToken: localizationKey("Unsupervised"), value: "UNSUPERVISED" },
]
const androidEnrollmentTypes = [
  {
    labelToken: localizationKey("Personally Owned"),
    value: "personally-owned",
  },
  {
    labelToken: localizationKey("Company Owned"),
    value: "company-owned",
  },
]

export const defaultAllOption = { value: "All", labelToken: localizationKey("All") }

export const getEnrollmentTypes = cond([
  [isAndroidPolicy, always(androidEnrollmentTypes)],
  [either(isAppleMobilePolicy, isMacPolicy), always(appleEnrollmentTypes)],
])

export const getEnrollmentTypeLabel = enrollmentOrOwnership => {
  const token = MDMOwnership[enrollmentOrOwnership]
  return token ? localized(token) : enrollmentOrOwnership
}

const restrictionsCategoriesTokenMapper = {
  functionality: localizationKey("Functionality"),
  applications: localizationKey("Application"),
  networkAndInternet: localizationKey("Network & Internet"),
  securityAndPrivacy: localizationKey("Security & Privacy"),
  media: localizationKey("Media"),
  classroom: localizationKey("Classroom"),
  icloud: localizationKey("iCloud"),
}

export const getCategories = compose(
  map(({ category }) => ({
    value: category,
    labelToken: restrictionsCategoriesTokenMapper[category] ?? localizationKey("Unknown category"),
  })),
  uniqBy(prop("category")),
)

export const androidRestrictionTokenMapper = {
  screenCaptureDisabled: {
    labelToken: localizationKey("Screen capture disabled"),
    descriptionToken: localizationKey("Controls whether screen capture is disabled for all users."),
  },
  adjustVolumeDisabled: {
    labelToken: localizationKey("Adjust volume disabled"),
    descriptionToken: localizationKey(
      "Controls whether adjusting the master volume is disabled for all users. Also mutes the device.",
    ),
  },
  modifyAccountsDisabled: {
    labelToken: localizationKey("Modify accounts disabled"),
    descriptionToken: localizationKey("Whether adding or removing accounts is disabled."),
  },
  addUserDisabled: {
    labelToken: localizationKey("Add user disabled"),
    descriptionToken: localizationKey("Whether adding new users and profiles is disabled."),
  },
  removeUserDisabled: {
    labelToken: localizationKey("Remove user disabled"),
    descriptionToken: localizationKey("Whether removing other users is disabled."),
  },
  setUserIconDisabled: {
    labelToken: localizationKey("Set user icon disabled"),
    descriptionToken: localizationKey("Whether changing the user icon is disabled."),
  },
  cameraDisabled: {
    labelToken: localizationKey("Camera disabled"),
    descriptionToken: localizationKey("If Enabled, the camera is disabled on the personal profile."),
  },
  usbFileTransferDisabled: {
    labelToken: localizationKey("USB file transfer disabled"),
    descriptionToken: localizationKey(
      "Whether transferring files over USB is disabled. This is supported only on company-owned devices.",
    ),
  },
  outgoingCallsDisabled: {
    labelToken: localizationKey("Outgoing calls disabled"),
    descriptionToken: localizationKey("Whether outgoing calls are disabled."),
  },
  smsDisabled: {
    labelToken: localizationKey("SMS disabled"),
    descriptionToken: localizationKey("Whether sending and receiving SMS messages is disabled."),
  },
  shareLocationDisabled: {
    labelToken: localizationKey("Share location disabled"),
    descriptionToken: localizationKey(
      "Whether location sharing is disabled. Supported for both fully managed devices and personally owned work profiles.",
    ),
  },
  factoryResetDisabled: {
    labelToken: localizationKey("Factory reset disabled"),
    descriptionToken: localizationKey("Whether factory resetting from settings is disabled."),
  },
  unmuteMicrophoneDisabled: {
    labelToken: localizationKey("Unmute microphone disabled"),
    descriptionToken: localizationKey(
      "Controls whether microphones are disabled. This is available only on fully managed devices.",
    ),
  },
  setWallpaperDisabled: {
    labelToken: localizationKey("Set wallpaper disabled"),
    descriptionToken: localizationKey("Whether changing the wallpaper is disabled."),
  },
  funDisabled: {
    labelToken: localizationKey("Fun disabled"),
    descriptionToken: localizationKey(
      "Whether the user is allowed to have fun. Controls whether the Easter egg game in Settings is disabled.",
    ),
  },
  mountPhysicalMediaDisabled: {
    labelToken: localizationKey("Mount physical media disabled"),
    descriptionToken: localizationKey("Whether the user mounting physical external media is disabled."),
  },
  createWindowsDisabled: {
    labelToken: localizationKey("Create windows disabled"),
    descriptionToken: localizationKey("Whether creating windows besides app windows is disabled."),
  },
  skipFirstUseHintsEnabled: {
    labelToken: localizationKey("Skip first use hints enabled"),
    descriptionToken: localizationKey(
      "Whether to skip hints on the first use. Enterprise admin can enable the system recommendation for apps to skip their user tutorial and other introductory hints on first start-up.",
    ),
  },
  privateKeySelectionEnabled: {
    labelToken: localizationKey("Private key selection enabled"),
    descriptionToken: localizationKey(
      "Allows showing UI on a device for a user to choose a private key alias. For devices below Android P, setting this may leave enterprise keys vulnerable.",
    ),
  },
  locationMode: {
    labelToken: localizationKey("Location mode"),
    descriptionToken: localizationKey("The degree of location detection enabled."),
    checkLocationTrackingPolicyEnabled: true,
  },
  maximumTimeToLock: {
    labelToken: localizationKey("Maximum time to lock"),
    descriptionToken: localizationKey(
      "Maximum time in milliseconds for user activity until the device locks. A value of 0 means there is no restriction.",
    ),
  },
  autoDateAndTimeZone: {
    labelToken: localizationKey("Auto date and timezone"),
    descriptionToken: localizationKey("Whether auto date, time, and time zone are enabled on a company-owned device."),
  },
  printingPolicy: {
    labelToken: localizationKey("Printing policy"),
    descriptionToken: localizationKey(
      "Controls whether printing is allowed. This is supported on devices running Android 9 and above.",
    ),
  },
  installAppsDisabled: {
    labelToken: localizationKey("Install apps disabled"),
    descriptionToken: localizationKey("Whether installation of apps is disabled."),
  },
  uninstallAppsDisabled: {
    labelToken: localizationKey("Uninstall apps disabled"),
    descriptionToken: localizationKey(
      "Whether uninstallation of applications is disabled. This prevents apps from being uninstalled, even those removed using applications policy",
    ),
  },
  workProfileWidgetsDefault: {
    labelToken: localizationKey("Allow access to widgets in work profile"),
    descriptionToken: localizationKey(
      "Controls if work profile applications are allowed to add widgets to the home screen, where no app-specific policy is defined. Otherwise, the app-specific policy will have priority over this.",
    ),
  },
  kioskCustomLauncherEnabled: {
    labelToken: localizationKey("Kiosk custom launcher enabled"),
    descriptionToken: localizationKey(
      "Whether the kiosk custom launcher is enabled. This replaces the home screen with a launcher that locks down the device to the apps installed via the applications setting.",
    ),
  },
  bluetoothDisabled: {
    labelToken: localizationKey("Bluetooth disabled"),
    descriptionToken: localizationKey("Whether bluetooth is disabled."),
  },
  mobileNetworksConfigDisabled: {
    labelToken: localizationKey("Mobile networks configuration disabled"),
    descriptionToken: localizationKey("Whether configuring mobile networks is disabled."),
  },
  tetheringConfigDisabled: {
    labelToken: localizationKey("Tethering configuration disabled"),
    descriptionToken: localizationKey("Whether configuring tethering and portable hotspots is disabled."),
  },
  vpnConfigDisabled: {
    labelToken: localizationKey("VPN configuration disabled"),
    descriptionToken: localizationKey("Whether configuring VPN is disabled."),
  },
  bluetoothContactSharingDisabled: {
    labelToken: localizationKey("Bluetooth contact sharing disabled"),
    descriptionToken: localizationKey("Whether bluetooth contact sharing is disabled."),
  },
  bluetoothConfigDisabled: {
    labelToken: localizationKey("Bluetooth configuration disabled"),
    descriptionToken: localizationKey("Whether configuring bluetooth is disabled."),
  },
  cellBroadcastsConfigDisabled: {
    labelToken: localizationKey("Cell broadcasts configuration disabled"),
    descriptionToken: localizationKey("Whether configuring cell broadcast is disabled."),
  },
  wifiConfigDisabled: {
    labelToken: localizationKey("Wi-Fi configuration disabled"),
    descriptionToken: localizationKey(
      "Whether configuring Wi-Fi networks is disabled. For work profiles on company-owned devices, existing configured networks are not affected and the user is not allowed to add, remove, or modify Wi-Fi networks.",
    ),
  },
  networkResetDisabled: {
    labelToken: localizationKey("Network reset disabled"),
    descriptionToken: localizationKey("Whether resetting network settings is disabled."),
  },
  outgoingBeamDisabled: {
    labelToken: localizationKey("Outgoing beam disabled"),
    descriptionToken: localizationKey("Whether using NFC to beam data from apps is disabled."),
  },
  dataRoamingDisabled: {
    labelToken: localizationKey("Data roaming disabled"),
    descriptionToken: localizationKey("Whether roaming data services are disabled."),
  },
  networkEscapeHatchEnabled: {
    labelToken: localizationKey("Network escape hatch enabled"),
    descriptionToken: localizationKey(
      "Whether the network escape hatch is enabled. If a network connection can't be made at boot time, the escape hatch prompts the user to temporarily connect to a network in order to refresh the device policy. After applying policy, the temporary network will be forgotten and the device will continue booting. This prevents being unable to connect to a network if there is no suitable network in the last policy and the device boots into an app in lock task mode, or the user is otherwise unable to reach device settings.",
    ),
  },
  preferentialNetworkService: {
    labelToken: localizationKey("Preferential network service"),
    descriptionToken: localizationKey(
      "Controls whether preferential network service is enabled on the work profile. For example, an organization may have an agreement with a carrier that all of the work data from its employees' devices will be sent via a network service dedicated for enterprise use. An example of a supported preferential network service is the enterprise slice on 5G networks. This has no effect on fully managed devices.",
    ),
  },
  keyguardDisabled: {
    labelToken: localizationKey("Keyguard disabled"),
    descriptionToken: localizationKey(
      "If enabled, this disables the Lock Screen for primary and/or secondary displays.",
    ),
  },
  usbDataAccess: {
    labelToken: localizationKey("USB transfer"),
    descriptionToken: localizationKey(
      "Defines permissions for USB file and/or data transfer. Supported on company-owned devices.",
    ),
  },
  configureWifi: {
    labelToken: localizationKey("Configure Wi-Fi"),
    descriptionToken: localizationKey(
      "Controls Wi-Fi configuring privileges. Based on the option set, user will have either full or limited or no control in configuring Wi-Fi networks.",
    ),
  },
  wifiDirectSettings: {
    labelToken: localizationKey("Wi-Fi direct settings"),
    descriptionToken: localizationKey(
      "Controls configuring and using Wi-Fi direct settings. Supported on company-owned devices running Android 13 and above.",
    ),
  },
  tetheringSettings: {
    labelToken: localizationKey("Tethering settings"),
    descriptionToken: localizationKey(
      "Controls tethering settings. Based on the value set, the user is partially or fully disallowed from using different forms of tethering.",
    ),
  },
  screenBrightnessMode: {
    labelToken: localizationKey("Screen brightness mode"),
    descriptionToken: localizationKey(
      "Controls the screen brightness mode and must be either Automatic or Fixed to set this. Supported on Android 9 and above on fully managed devices.",
    ),
  },
  screenBrightness: {
    labelToken: localizationKey("Screen brightness"),
    descriptionToken: localizationKey(
      "The screen brightness between 1 and 255 where 1 is the lowest and 255 is the highest brightness. Supported on Android 9 and above on fully managed devices.",
    ),
  },
  screenTimeoutMode: {
    labelToken: localizationKey("Screen timeout mode"),
    descriptionToken: localizationKey(
      "Controls whether the user is allowed to configure the screen timeout. Supported on Android 9 and above on fully managed devices.",
    ),
  },
  screenTimeout: {
    labelToken: localizationKey("Screen timeout"),
    descriptionToken: localizationKey(
      "Controls the screen timeout duration. The screen timeout duration must be greater than 0, otherwise it is rejected. Maximum time to lock in security may override this restriction. Supported on Android 9 and above on fully managed, company owned work profile enrolled devices.",
    ),
  },
  wifiState: {
    labelToken: localizationKey("Wi-Fi state"),
    descriptionToken: localizationKey(
      "Controls whether the Wi-Fi is on or off as a state and if the user can change said state. Supported on Android 13 and above on company owned devices.",
    ),
  },
  airplaneModeState: {
    labelToken: localizationKey("Airplane mode state"),
    descriptionToken: localizationKey(
      "Controls the state of airplane mode and whether the user can toggle it on or off. Supported on Android 9 and above on fully managed, and company owned work profile enrolled devices.",
    ),
  },
  ultraWidebandState: {
    labelToken: localizationKey("Ultra Wideband state"),
    descriptionToken: localizationKey(
      "Controls the state of the ultra wideband setting and whether the user can toggle it on or off. Supported on Android 14 and above on fully managed, and company owned work profile enrolled devices.",
    ),
  },
  cellularTwoGState: {
    labelToken: localizationKey("Cellular 2G state"),
    descriptionToken: localizationKey(
      "Controls the state of cellular 2G setting and whether the user can toggle it on or off. Supported on Android 14 and above on fully managed, and company owned work profile enrolled devices.",
    ),
  },
  minimumWifiSecurityLevel: {
    labelToken: localizationKey("Minimum Wi-Fi security level"),
    descriptionToken: localizationKey(
      "Defines the different minimum Wi-Fi security levels required to connect to Wi-Fi networks. Supported on Android 13 and above on fully managed, and company owned work profile enrolled devices.",
    ),
  },
  credentialProviderPolicyDefault: {
    labelToken: localizationKey("Credential provider policy default"),
    descriptionToken: localizationKey(
      "Controls which apps are allowed to act as credential providers. Supported on Android 14 and above on fully managed, and company owned work profile enrolled devices.",
    ),
  },
}

const appleMobileRestrictionsTokenMapper = {
  allowAirPrint: {
    labelToken: localizationKey("Allow AirPrint"),
    descriptionToken: localizationKey("Requires a supervised device. Available in iOS 11 and later"),
    defaultValue: true,
  },
  allowAirPrintCredentialsStorage: {
    labelToken: localizationKey("Allow Storage AirPrint credentials in Keychain"),
    descriptionToken: localizationKey(
      "Enables keychain storage of user name and password for AirPrint. Requires a supervised device.",
    ),
    defaultValue: true,
  },
  allowAirPrintiBeaconDiscovery: {
    labelToken: localizationKey("Allow discover AirPrint printers using iBeacons"),
    descriptionToken: localizationKey(
      "Enables iBeacon discovery of AirPrint printers, which prevents spurious AirPrint Bluetooth beacons from phishing for network traffic. Requires a supervised device. Available in iOS 11 and later.",
    ),
    defaultValue: true,
  },
  allowAppCellularDataModification: {
    labelToken: localizationKey("Allow Modifying cellular data app settings"),
    descriptionToken: localizationKey(
      "Enables changing settings for cellular data usage for apps. Requires a supervised device.",
    ),
    defaultValue: true,
  },
  allowAppClips: {
    labelToken: localizationKey("Allow App Clips"),
    descriptionToken: localizationKey(
      "If disabled, prevents a user from adding any App Clips, and removes any existing App Clips on the device. Requires a supervised device.",
    ),
    defaultValue: true,
  },
  allowAppInstallation: {
    labelToken: localizationKey("Allow Installation Apps"),
    descriptionToken: localizationKey(
      "If disabled, blocks the App Store, and its icon is removed from the Home screen. Users are unable to install or update their apps.",
    ),
    defaultValue: true,
  },
  allowAppRemoval: {
    labelToken: localizationKey("Allow removing apps"),
    descriptionToken: localizationKey("Requires a supervised device."),
    defaultValue: true,
  },
  allowAssistantUserGeneratedContent: {
    labelToken: localizationKey("Show user-generated contents in Siri"),
    descriptionToken: localizationKey(
      "If disabled, prevents Siri from querying user-generated content from the web. Requires a supervised device.",
    ),
    defaultValue: true,
  },
  allowAssistantWhileLocked: {
    labelToken: localizationKey("Allow Siri when device is locked"),
    descriptionToken: localizationKey(
      "Enable Siri when the device is locked. This restriction is ignored if the device doesn’t have a passcode set.",
    ),
    defaultValue: true,
  },
  allowAutoCorrection: {
    labelToken: localizationKey("Allow autocorrection"),
    descriptionToken: localizationKey("Enables keyboard autocorrection. Requires a supervised device."),
    defaultValue: true,
  },
  allowAutomaticAppDownloads: {
    labelToken: localizationKey("Allow automatic app download"),
    descriptionToken: localizationKey(
      "If disabled, prevents automatic downloading of apps purchased on other devices. This setting doesn’t affect updates to existing apps.",
    ),
    defaultValue: true,
  },
  allowAutomaticScreenSaver: {
    labelToken: localizationKey("Allow automatic Screen Saver"),
    descriptionToken: localizationKey("Enables Apple TV’s automatic screen saver."),
    defaultValue: true,
  },
  allowCellularPlanModification: {
    labelToken: localizationKey("Allow modifying cellular plan settings"),
    descriptionToken: localizationKey(
      "If disabled, users can't change any settings related to their cellular plan. Requires a supervised device.",
    ),
    defaultValue: true,
  },
  allowChat: {
    labelToken: localizationKey("Allow iMessage"),
    descriptionToken: localizationKey(
      "Enables the use of the iMessage with supervised devices. If the device supports text messaging, the user can still send and receive text messages. Requires a supervised device.",
    ),
    defaultValue: true,
  },
  allowCloudBackup: {
    labelToken: localizationKey("Allow iCloud backup"),
    descriptionToken: localizationKey("Enables backing up the device to iCloud"),
    defaultValue: true,
  },
  allowContinuousPathKeyboard: {
    labelToken: localizationKey("Allow continuous path keyboard"),
    descriptionToken: localizationKey("Enables QuickPath keyboard. Requires a supervised device."),
    defaultValue: true,
  },
  allowDeviceSleep: {
    labelToken: localizationKey("Allow Device Sleep"),
    descriptionToken: localizationKey(
      "If disabled, prevents device from automatically sleeping. Requires a supervised device.",
    ),
    defaultValue: true,
  },
  allowDiagnosticSubmissionModification: {
    labelToken: localizationKey("Allow modifying diagnostic settings"),
    descriptionToken: localizationKey(
      "Enables changing the diagnostic submission and app analytics settings in the Diagnostics & Usage UI in Settings. Requires a supervised device.",
    ),
    defaultValue: true,
  },
  allowEnablingRestrictions: {
    labelToken: localizationKey("Allow Screen time"),
    descriptionToken: localizationKey(
      "If disabled, blocks the “Enable Restrictions” option in the Restrictions UI in Settings. In iOS 12 or later, if disabled, blocks the “Enable ScreenTime” option in the ScreenTime UI in Settings and disables ScreenTime if already enabled. Requires a supervised device.",
    ),
    defaultValue: true,
  },
  allowEnterpriseAppTrust: {
    labelToken: localizationKey("Allow trusting new enterprise app authors"),
    descriptionToken: localizationKey(
      "If disabled, removes the Trust Enterprise Developer button in Settings > General > Profiles & Device Management, preventing apps from being provisioned by universal provisioning profiles. This restriction applies to free developer accounts. However, it doesn’t apply to enterprise app developers who are trusted because their apps were pushed through MDM. It also doesn’t revoke previously granted trust.",
    ),
    defaultValue: true,
  },
  allowEnterpriseBookBackup: {
    labelToken: localizationKey("Allow backup of enterprise books"),
    descriptionToken: localizationKey(
      "If turned off, disables backup of Enterprise books. Also available for user enrollment.",
    ),
    defaultValue: true,
  },
  allowEnterpriseBookMetadataSync: {
    labelToken: localizationKey("Allow notes and highlights sync for enterprise books"),
    defaultValue: true,
  },
  allowESIMModification: {
    labelToken: localizationKey("Allow modifying eSIM settings"),
    descriptionToken: localizationKey(
      "If turned off, disables modifications to carrier plan related settings (only available on select carriers). Requires a supervised device.",
    ),
    defaultValue: true,
  },
  allowFilesNetworkDriveAccess: {
    labelToken: localizationKey("Allow Files Network Drive Access"),
    descriptionToken: localizationKey(
      "If disabled, prevents connecting to network drives in the Files app. Requires a supervised device. Available in iOS 13.1 and later.",
    ),
    defaultValue: true,
  },
  allowFilesUSBDriveAccess: {
    labelToken: localizationKey("Allow Files USB Drive Access"),
    descriptionToken: localizationKey(
      "If disabled, prevents connecting to any connected USB devices in the Files app. Requires a supervised device.",
    ),
    defaultValue: true,
  },
  allowFindMyFriendsModification: {
    labelToken: localizationKey("Allow modifying Find My Friends settings"),
    defaultValue: true,
  },
  allowMarketplaceAppInstallation: {
    labelToken: localizationKey("Allow app installation from alternative marketplaces"),
    descriptionToken: localizationKey(
      "If disabled, prevents installation of alternative marketplace apps from the web and prevents any installed alternative marketplace apps from installing apps. This restriction does not impact the native App Store app. Requires a supervised device. Available in iOS 17.4 and later.",
    ),
    defaultValue: true,
  },
  allowGlobalBackgroundFetchWhenRoaming: {
    labelToken: localizationKey("Allow automatic sync while roaming"),
    descriptionToken: localizationKey("Enables global background fetch activity when an iOS phone is roaming."),
    defaultValue: true,
  },
  allowHostPairing: {
    labelToken: localizationKey("Allow host pairing"),
    descriptionToken: localizationKey(
      "If turned off, deactivates host pairing with the exception of the supervision host. If no supervision host certificate has been configured, all pairing is disabled. Host pairing lets the administrator control if an iOS device can pair with a host Mac or PC. Requires a supervised device.",
    ),
    defaultValue: true,
  },
  allowInAppPurchases: {
    labelToken: localizationKey("Allow in app purchases"),
    descriptionToken: localizationKey("If turned off, prohibits in-app purchasing"),
    defaultValue: true,
  },
  allowiTunes: {
    labelToken: localizationKey("Allow iTunes Store"),
    descriptionToken: localizationKey(
      "If turned off, disables the iTunes Music Store, and its icon is removed from the Home screen. Users cannot preview, purchase, or download content.",
    ),
    defaultValue: true,
  },
  allowKeyboardShortcuts: {
    labelToken: localizationKey("Allow keyboard shortcuts"),
    descriptionToken: localizationKey("If turned off, disables keyboard shortcuts. Requires a supervised device."),
    defaultValue: true,
  },
  allowLockScreenControlCenter: {
    labelToken: localizationKey("Allow lock screen Control Center"),
    descriptionToken: localizationKey("If disabled, prevents Control Center from appearing on the Lock screen."),
    defaultValue: true,
  },
  allowLockScreenNotificationsView: {
    labelToken: localizationKey("Show Notification Center on Lock screen"),
    descriptionToken: localizationKey(
      "If turned off, disables the Notifications history view on the lock screen, so users can't view past notifications. However, they can still see notifications when they arrive.",
    ),
    defaultValue: true,
  },
  allowLockScreenTodayView: {
    labelToken: localizationKey("Allow lock screen today view"),
    descriptionToken: localizationKey(
      "If turned off, disables the Today view in Notification Center on the lock screen.",
    ),
    defaultValue: true,
  },
  allowMailPrivacyProtection: {
    labelToken: localizationKey("Allow Mail Privacy Protection"),
    defaultValue: true,
  },
  allowManagedAppsCloudSync: {
    labelToken: localizationKey("Allow managed apps to store data in iCloud"),
    defaultValue: true,
  },
  allowManagedToWriteUnmanagedContacts: {
    labelToken: localizationKey("Allow managed apps to write contacts to unmanaged contacts accounts"),
    descriptionToken: localizationKey(
      "If enabled, managed apps can write contacts to unmanaged contacts accounts. If Allow Open From Managed To Unmanaged is enabled, this restriction has no effect. If this restriction is set to true, you must install the payload through MDM.",
    ),
    defaultValue: false,
  },
  allowNews: {
    labelToken: localizationKey("Allow News"),
    defaultValue: true,
  },
  allowNFC: {
    labelToken: localizationKey("Allow NFC"),
    descriptionToken: localizationKey("Allow Near Field Communication"),
    defaultValue: true,
  },
  allowNotificationsModification: {
    labelToken: localizationKey("Allow modifying notification settings"),
    descriptionToken: localizationKey(
      "If turned off, disables modification of notification settings. Requires a supervised device.",
    ),
    defaultValue: true,
  },
  allowOpenFromManagedToUnmanaged: {
    labelToken: localizationKey("Allow documents from managed sources in unmanaged destinations"),
    defaultValue: true,
  },
  allowOpenFromUnmanagedToManaged: {
    labelToken: localizationKey("Allow documents from unmanaged sources in managed destinations"),
    defaultValue: true,
  },
  allowOTAPKIUpdates: {
    labelToken: localizationKey("Allow over-the-air PKI updates"),
    descriptionToken: localizationKey(
      "If turned off, disables over-the-air PKI updates. Setting this restriction to false doesn’t disable CRL and OCSP checks.",
    ),
    defaultValue: true,
  },
  allowPairedWatch: {
    labelToken: localizationKey("Allow pairing with Apple Watch"),
    descriptionToken: localizationKey(
      "If turned off, disables pairing with an Apple Watch. Any currently paired Apple Watch is unpaired and the watch’s content is erased. Requires a supervised device.",
    ),
    defaultValue: true,
  },
  allowPassbookWhileLocked: {
    labelToken: localizationKey("Allow Apple Wallet notifications on lock screen"),
    defaultValue: true,
  },
  allowPersonalHotspotModification: {
    labelToken: localizationKey("Allow Personal Hotspot modification"),
    defaultValue: true,
  },
  allowPhotoStream: {
    labelToken: localizationKey("Allow My Photo Stream"),
    defaultValue: true,
  },
  allowPodcasts: {
    labelToken: localizationKey("Allow podcasts"),
    defaultValue: true,
  },
  allowPredictiveKeyboard: {
    labelToken: localizationKey("Allow predictive keyboard"),
    defaultValue: true,
  },
  allowProximitySetupToNewDevice: {
    labelToken: localizationKey("Allow setting up new nearby iOS devices"),
    defaultValue: true,
  },
  allowRadioService: {
    labelToken: localizationKey("Allow Radio"),
    defaultValue: true,
  },
  allowRemoteAppPairing: {
    labelToken: localizationKey("Allow pairing with Remote app"),
    descriptionToken: localizationKey(
      "If turned off, disables pairing Apple TV for use with the Remote app or Control Center widget. Requires a supervised device.",
    ),
    defaultValue: true,
  },
  allowSafari: {
    labelToken: localizationKey("Allow use of Safari"),
    descriptionToken: localizationKey(
      "If turned off, disables the Safari web browser app, and its icon is removed from the Home screen. This setting also prevents users from opening web clips.",
    ),
    defaultValue: true,
  },
  allowSharedDeviceTemporarySession: {
    labelToken: localizationKey("Allow Shared iPad temporary session"),
    descriptionToken: localizationKey("If turned off, temporary sessions aren’t available on Shared iPad."),
    defaultValue: true,
  },
  allowSharedStream: {
    labelToken: localizationKey("Allow iCloud Photo Sharing"),
    defaultValue: true,
  },
  allowSpellCheck: {
    labelToken: localizationKey("Allow spell check"),
    descriptionToken: localizationKey("If turned off, disables keyboard spell-check."),
    defaultValue: true,
  },
  allowSystemAppRemoval: {
    labelToken: localizationKey("Allow removing system apps"),
    descriptionToken: localizationKey("If turned off, disables the removal of system apps from the device."),
    defaultValue: true,
  },
  allowUIAppInstallation: {
    labelToken: localizationKey("Allow installing apps using App Store"),
    descriptionToken: localizationKey(
      "If turned off, disables the App Store, and its icon is removed from the Home screen. However, users may continue to use host apps (iTunes, Configurator) to install or update their apps.",
    ),
    defaultValue: true,
  },
  allowUnmanagedToReadManagedContacts: {
    labelToken: localizationKey("Allow unmanaged apps to read contacts from managed contacts accounts"),
    defaultValue: false,
  },
  allowUnpairedExternalBootToRecovery: {
    labelToken: localizationKey("Allow booting into recovery by unpaired devices"),
    defaultValue: false,
  },
  allowUntrustedTLSPrompt: {
    labelToken: localizationKey("Allow users to accept untrusted TLS certificates"),
    defaultValue: true,
  },
  allowVideoConferencing: {
    labelToken: localizationKey("Allow FaceTime"),
    defaultValue: true,
  },
  allowVoiceDialing: {
    labelToken: localizationKey("Allow voice dialing while the device is locked"),
    defaultValue: true,
  },
  allowVPNCreation: {
    labelToken: localizationKey("Allow adding VPN configurations"),
    defaultValue: true,
  },
  forceAirDropUnmanaged: {
    labelToken: localizationKey("Treat AirDrop as unmanaged destination"),
    descriptionToken: localizationKey("If enabled, causes AirDrop to be considered an unmanaged drop target."),
    defaultValue: false,
  },
  forceAirPlayIncomingRequestsPairingPassword: {
    labelToken: localizationKey("Require passcode on first incoming AirPlay pairing"),
    descriptionToken: localizationKey(
      "If enabled, forces all devices sending AirPlay requests to this device to use a pairing password.",
    ),
    defaultValue: false,
  },
  forceAirPlayOutgoingRequestsPairingPassword: {
    labelToken: localizationKey("Require passcode on first outgoing AirPlay pairing"),
    descriptionToken: localizationKey(
      "If enabled, forces all devices receiving AirPlay requests from this device to use a pairing password.",
    ),
    defaultValue: false,
  },
  forceAirPrintTrustedTLSRequirement: {
    labelToken: localizationKey("Disallow AirPrint to destinations with untrusted certificates"),
    defaultValue: false,
  },
  forceAuthenticationBeforeAutoFill: {
    labelToken: localizationKey("Require Touch ID / Face ID authentication before Autofill"),
    descriptionToken: localizationKey(
      "If enabled, the user must authenticate before passwords or credit card information can be autofilled in Safari and Apps. If this restriction isn’t enforced, the user can toggle this feature in Settings. Only supported on devices with Face ID or Touch ID.",
    ),
    defaultValue: false,
  },
  forceAutomaticDateAndTime: {
    labelToken: localizationKey("Force automatic date and time"),
    defaultValue: false,
  },
  forceEncryptedBackup: {
    labelToken: localizationKey("Force encrypted backups"),
    descriptionToken: localizationKey("If enabled, encrypts all backups"),
    defaultValue: false,
  },
  forceITunesStorePasswordEntry: {
    labelToken: localizationKey("Require iTunes Store password for all purchases"),
    descriptionToken: localizationKey(
      "If enabled, forces the user to enter their iTunes password for each transaction.",
    ),
    defaultValue: false,
  },
  forceLimitAdTracking: {
    labelToken: localizationKey("Force limited ad tracking"),
    descriptionToken: localizationKey(
      "If enabled, limits ad tracking. Additionally, it disables app tracking and the Allow Apps To Request To Track setting.",
    ),
    defaultValue: false,
  },
  forceOnDeviceOnlyTranslation: {
    labelToken: localizationKey("Force on-device only translation"),
    descriptionToken: localizationKey(
      "If enabled, the device won’t connect to Siri servers for the purposes of translation.",
    ),
    defaultValue: false,
  },
  forceWatchWristDetection: {
    labelToken: localizationKey("Force Apple Watch wrist detection"),
    descriptionToken: localizationKey("If enabled, forces a paired Apple Watch to use Wrist Detection."),
    defaultValue: false,
  },
  forceWiFiToAllowedNetworksOnly: {
    labelToken: localizationKey("Join only Wi-Fi networks installed by a Wi-Fi payload"),
    descriptionToken: localizationKey(
      "If enabled, limits device to only join Wi-Fi networks set-up via configuration profile. Requires a supervised device.",
    ),
    defaultValue: false,
  },
  forceWiFiPowerOn: {
    labelToken: localizationKey("Force Wi-Fi Power On"),
    descriptionToken: localizationKey(
      "If enabled, prevents Wi-Fi from being turned off in Settings or Control Center, even by entering or leaving Airplane Mode. It doesn’t prevent selecting which Wi-Fi network to use. Requires a supervised device.",
    ),
    defaultValue: false,
  },
  ratingRegion: {
    labelToken: localizationKey("Ratings region"),
    descriptionToken: localizationKey(
      "The two-letter key that profile tools use to display the proper ratings for the given region.",
    ),
  },
  requireManagedPasteboard: {
    labelToken: localizationKey("Require managed pasteboard"),
    defaultValue: false,
  },
  safariAllowJavaScript: {
    labelToken: localizationKey("Enable JavaScript on Safari"),
    descriptionToken: localizationKey("If turned off, Safari doesn’t execute JavaScript."),
    defaultValue: true,
  },
  safariAllowPopups: {
    labelToken: localizationKey("Block pop-ups on Safari"),
    descriptionToken: localizationKey("If turned off, Safari doesn’t allow pop-up windows"),
    defaultValue: true,
  },
  safariForceFraudWarning: {
    labelToken: localizationKey("Force fraud warning on Safari"),
    defaultValue: false,
  },
  allowRapidSecurityResponseInstallation: {
    labelToken: localizationKey("Allow rapid security response installation"),
    defaultValue: true,
  },
  allowRapidSecurityResponseRemoval: {
    labelToken: localizationKey("Allow rapid security response removal"),
    defaultValue: true,
  },
  allowiPhoneWidgetsOnMac: {
    labelToken: localizationKey("Allow iOS widgets on a Mac signed in with the same Apple ID"),
    descriptionToken: localizationKey(
      "If turned off, the system disallows iPhone widgets on a Mac that has signed in the same Apple ID for iCloud. Requires a supervised device. Available on iOS 17 and later.",
    ),
    defaultValue: true,
  },
  allowLiveVoicemail: {
    labelToken: localizationKey("Allow live voicemail"),
    descriptionToken: localizationKey(
      "If turned off, the system disables live voicemail on the device. Requires a supervised device. Available in iOS 17.2 and later.",
    ),
    defaultValue: true,
  },
  forcePreserveESIMOnErase: {
    labelToken: localizationKey("Force preserve eSIM on erase"),
    descriptionToken: localizationKey(
      "If turned on, the system preserves eSIM when it erases the device due to too many failed password attempts or the Erase All Content and Settings option in Settings > General > Reset. Requires a supervised device. Available in iOS 17.2 and later. Note: The system doesn’t preserve eSIM if Find My initiates erasing the device.",
    ),
    defaultValue: false,
  },
  allowWebDistributionAppInstallation: {
    labelToken: localizationKey("Allow apps to be installed directly from the web"),
    descriptionToken: localizationKey(
      "If turned off, the device prevents installation of apps directly from the web. Requires a supervised device. Available in iOS 17.5 and later.",
    ),
    defaultValue: true,
  },
  allowAutoDim: {
    labelToken: localizationKey("Allow auto dim"),
    descriptionToken: localizationKey(
      "If turned off, disables auto dim on iPads with OLED displays. Requires a supervised device. Available in iOS 17.4 and later.",
    ),
    defaultValue: true,
  },
  allowESIMOutgoingTransfers: {
    labelToken: localizationKey("Allow eSIM outgoing transfers"),
    descriptionToken: localizationKey(
      "If turned off, prevents the transfer of an eSIM from the device on which the restriction is installed to a different device. Requires a supervised device. Available in iOS 18 and later.",
    ),
    defaultValue: true,
  },
  allowImageWand: {
    labelToken: localizationKey("Allow Image Wand"),
    descriptionToken: localizationKey(
      "If turned off, prohibits the use of Image Wand. Requires a supervised device. Available in iOS 18 and later.",
    ),
    defaultValue: true,
  },
  allowPersonalizedHandwritingResults: {
    labelToken: localizationKey("Allow personalized handwriting results"),
    descriptionToken: localizationKey(
      "If turned off, prevents the system from generating text in the user's handwriting. Requires a supervised device. Available in iOS 18 and later.",
    ),
    defaultValue: true,
  },
  allowVideoConferencingRemoteControl: {
    labelToken: localizationKey("Allow video conferencing remote control"),
    descriptionToken: localizationKey(
      "If turned off, disables the ability for a remote FaceTime session to request control of the device. Requires a supervised device. Available in iOS 18 and later.",
    ),
    defaultValue: true,
  },
  allowAppsToBeHidden: {
    labelToken: localizationKey("Allow hiding apps"),
    descriptionToken: localizationKey(
      "If false, disables the ability for the user to hide apps. It does not affect the user's ability to leave it in the App Library, while removing it from the home screen. Requires a supervised device. Available in iOS 18.0 and later.",
    ),
    defaultValue: true,
  },
  allowAppsToBeLocked: {
    labelToken: localizationKey("Allow locking apps"),
    descriptionToken: localizationKey(
      "If false, disables the ability for the user to lock apps. Because hiding apps also requires locking them, disallowing locking also disallows hiding. Requires a supervised device. Available in iOS 18.0 and later.",
    ),
    defaultValue: true,
  },
  allowCallRecording: {
    labelToken: localizationKey("Allow call recording"),
    descriptionToken: localizationKey(
      "If false, call recording is disabled. Requires a supervised device. Available in iOS 18.1 and later.",
    ),
    defaultValue: true,
  },
  allowRCSMessaging: {
    labelToken: localizationKey("Allow RCS messaging"),
    descriptionToken: localizationKey(
      "If false, prevents the use of RCS messaging. Requires a supervised device. Available in iOS 18.1 and later.",
    ),
    defaultValue: true,
  },
  allowDefaultBrowserModification: {
    labelToken: localizationKey("Allow default browser modification"),
    descriptionToken: localizationKey(
      "If false, disables default browser preference modification. Available in iOS 18.2 and later.",
    ),
    defaultValue: true,
  },
  safariAcceptCookies: {
    labelToken: localizationKey("Accept cookies"),
    descriptionToken: localizationKey("Defines the behavior for Cross-Site Tracking and Cookies."),
    defaultValue: "2",
  },
}

const appleSharedRestrictionsTokenMapper = {
  allowAccountModification: {
    labelToken: localizationKey("Allow modifying account settings"),
    descriptionToken: localizationKey("If disabled, prohibits account modification. Requires a supervised device."),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system disables modification of accounts such as Apple Accounts and Internet-based accounts such as Mail, Contacts, and Calendar.",
      ),
    },
  },
  allowActivityContinuation: {
    labelToken: localizationKey("Allow Handoff"),
    descriptionToken: localizationKey("If disabled, prohibits activity continuation"),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system disables activity continuation. Available in macOS 10.15 and later.",
      ),
    },
  },
  allowAirDrop: {
    labelToken: localizationKey("Allow AirDrop"),
    descriptionToken: localizationKey("Requires a supervised device."),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system disables AirDrop. Available in macOS 10.13 and later.",
      ),
    },
  },
  allowApplePersonalizedAdvertising: {
    labelToken: localizationKey("Allow Apple personalized advertising"),
    descriptionToken: localizationKey(
      "If disabled, limits Apple personalized advertising. Available in iOS 14 and later.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system limits Apple personalized advertising. Available in macOS 12 and later.",
      ),
    },
  },
  allowCamera: {
    labelToken: localizationKey("Allow Camera"),
    descriptionToken: localizationKey(
      "If disabled, blocks the camera, and its icon is removed from the Home screen. Users are unable to take photographs.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the systems disables the camera and removes its icon from the Home screen, and users are unable to take photographs.  Available in macOS 10.11 and later.",
      ),
    },
  },
  allowDefinitionLookup: {
    labelToken: localizationKey("Allow Definition Lookup"),
    descriptionToken: localizationKey("Enables definition lookup. Requires a supervised device on iOS."),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the systems disables definition lookup. Available macOS 10.11 and later.",
      ),
    },
  },
  allowDeviceNameModification: {
    labelToken: localizationKey("Allow modifying device name"),
    descriptionToken: localizationKey(
      "If disabled, prevents the user from changing the device name. Requires a supervised device.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system prevents the user from changing the device name. Available in macOS 14 and later.",
      ),
    },
  },
  allowDiagnosticSubmission: {
    labelToken: localizationKey("Allow submitting diagnostic and usage data to Apple"),
    descriptionToken: localizationKey(
      "If disabled, prevents the device from automatically submitting diagnostic reports to Apple. Available for user enrollment.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system prevents the device from automatically submitting diagnostic reports to Apple. Available in macOS 10.13 and later.",
      ),
    },
  },
  allowDictation: {
    labelToken: localizationKey("Allow dictation"),
    descriptionToken: localizationKey("Enables dictation input. Requires a supervised device."),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system disallows dictation input. Available in macOS 10.13 and later.",
      ),
    },
  },
  allowEraseContentAndSettings: {
    labelToken: localizationKey("Allow Erase All Content and Settings"),
    descriptionToken: localizationKey(
      "If turned off, disables the Erase All Content And Settings option in the Reset UI. Requires a supervised device.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system disables the Erase All Content and Settings option in the Reset UI. Available in macOS 12 and later.",
      ),
    },
  },
  allowFindMyDevice: {
    labelToken: localizationKey("Allow Find My Device"),
    descriptionToken: localizationKey(
      "Enables Find My Device in the Find My app. Requires a supervised device. Available in iOS 13 and later.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system disables Find My Device in the Find My app. Available in macOS 10.15 and later.",
      ),
    },
  },
  allowFindMyFriends: {
    labelToken: localizationKey("Allow Find My Friends"),
    descriptionToken: localizationKey(
      "Enables Find My Friends in the Find My app. Requires a supervised device. Available in iOS 13 and later.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system disables Find My Friends in the Find My app. Available in macOS 10.15 and later.",
      ),
    },
  },
  allowScreenShot: {
    labelToken: localizationKey("Allow screenshots and screen recording"),
    descriptionToken: localizationKey(
      "If turned off, disables saving a screenshot of the display and capturing a screen recording. It also disables the Classroom app from observing remote screens.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system disables saving a screenshot of the display and capturing a screen recording. It also disables the Classroom app from observing remote screens. Available in macOS 10.14.4 and later.",
      ),
    },
  },
  allowSpotlightInternetResults: {
    labelToken: localizationKey("Allow Siri Suggestions"),
    descriptionToken: localizationKey("If turned off, disables Spotlight Internet search results in Siri Suggestions."),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system disables Spotlight Internet search results in Siri Suggestions. Available in macOS 10.11 and later.",
      ),
    },
  },
  allowUIConfigurationProfileInstallation: {
    labelToken: localizationKey("Allow user installation of configuration profiles"),
    descriptionToken: localizationKey(
      "If turned off, prohibits the user from installing configuration profiles and certificates interactively.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system prohibits the user from installing configuration profiles and certificates interactively. Available in macOS 13 and later.",
      ),
    },
  },
  allowUSBRestrictedMode: {
    labelToken: localizationKey("Allow USB accessories while device is locked"),
    descriptionToken: localizationKey(
      "If turned off, allows the device to always connect to USB accessories while locked. On macOS, allows new USB accessories to connect without authorization. If the system has Lockdown mode enabled, the system ignores this value.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the systems allows new USB and Thunderbolt accessories and SD cards to connect without authorization. If the system has Lockdown mode checked, it ignores this value. Available in macOS 13 and later.",
      ),
    },
  },
  allowWallpaperModification: {
    labelToken: localizationKey("Allow modifying Wallpaper"),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system prevents changing the wallpaper. Available in macOS 10.13 and later.",
      ),
    },
  },
  allowBluetoothModification: {
    labelToken: localizationKey("Allow modifying Bluetooth settings"),
    descriptionToken: localizationKey(
      "Allows modification of Bluetooth settings. Requires a supervised device. Available in iOS 11 and later.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system prevents modification of Bluetooth settings. Available in macOS 13.0 and later.",
      ),
    },
  },
  forceOnDeviceOnlyDictation: {
    labelToken: localizationKey("Force on-device only dictation"),
    descriptionToken: localizationKey(
      "If enabled, disables connections to Siri servers for the purposes of dictation.",
    ),
    defaultValue: false,
    MACOS: {
      descriptionToken: localizationKey(
        "If checked, the system disables connections to Siri servers for the purposes of dictation. Available in macOS 14 and later.",
      ),
    },
  },
  allowExternalIntelligenceIntegrations: {
    labelToken: localizationKey("Allow external intelligence integrations"),
    defaultValue: true,
    descriptionToken: localizationKey(
      "If false, disables the use of external, cloud-based intelligence services with Siri. Available in iOS 18.2 and later.",
    ),
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, prevents the use of external, cloud-based intelligence services with Siri. Available in macOS 15.2 and later.",
      ),
    },
  },
  allowExternalIntelligenceIntegrationsSignIn: {
    labelToken: localizationKey("Allow external intelligence integrations sign-in"),
    defaultValue: true,
    descriptionToken: localizationKey(
      "If false, forces external intelligence providers into anonymous mode. If a user is already signed in to an external intelligence provider, applying this restriction will cause them to be signed out. Available in iOS 18.2 and later.",
    ),
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, forces external intelligence providers into anonymous mode. If a user is already signed in to an external intelligence provider, applying this restriction will cause them to be signed out when the next request is attempted. Available in macOS 15.2 and later.",
      ),
    },
  },
  allowGenmoji: {
    labelToken: localizationKey("Allow Genmoji"),
    descriptionToken: localizationKey(
      "If turned off, prohibits creating new Genmoji. Requires a supervised device. Available in iOS 18 and later.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, prohibits creating new Genmoji. Available in macOS 15 and later.",
      ),
    },
  },
  allowImagePlayground: {
    labelToken: localizationKey("Allow Image Playground"),
    descriptionToken: localizationKey(
      "If turned off, prohibits the use of image generation. Requires a supervised device. Available in iOS 18 and later.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, prohibits the use of image generation. Available in macOS 15 and later.",
      ),
    },
  },
  allowiPhoneMirroring: {
    labelToken: localizationKey("Allow iPhone mirroring"),
    descriptionToken: localizationKey(
      "If turned off, prohibits the use of iPhone Mirroring. This prevents the iPhone from mirroring to any Mac. Requires a supervised device. Available in iOS 18 and later.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, prevents the system from mirroring any iPhone.  Available in macOS 15 and later.",
      ),
    },
  },
  allowMailSummary: {
    labelToken: localizationKey("Allow mail summary"),
    descriptionToken: localizationKey(
      "If false, disables the ability to create summaries of email messages manually. This does not affect automatic summary generation. Requires a supervised device. Available in iOS 18.1 and later.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, prevents the ability to create summaries of email messages manually. This doesn't affect automatic summary generation. Available in macOS 15.1 and later.",
      ),
    },
  },
  allowWritingTools: {
    labelToken: localizationKey("Allow Writing Tools"),
    descriptionToken: localizationKey(
      "If turned off, disables Apple Intelligence writing tools. Requires a supervised device. Available in iOS 18 and later.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, prevents the use of Apple Intelligence writing tools. Available in macOS 15 and later.",
      ),
    },
  },
  allowGameCenter: {
    labelToken: localizationKey("Allow Game Center"),
    descriptionToken: localizationKey(
      "If disabled, blocks Game Center, and its icon is removed from the Home screen. Requires a supervised device.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system disables Game Center, and the system removes its icon from the Home screen. Available in macOS 10.13 and later.",
      ),
    },
  },
  allowAddingGameCenterFriends: {
    labelToken: localizationKey("Allow adding Game Center friends"),
    descriptionToken: localizationKey(
      "If disabled, prohibits adding friends to Game Center. As of iOS 13, requires a supervised device.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system prohibits adding friends to Game Center. Available in macOS 10.13 and later.",
      ),
    },
  },
  allowMultiplayerGaming: {
    labelToken: localizationKey("Allow multiplayer gaming"),
    descriptionToken: localizationKey("If disabled, prohibits multiplayer gaming. Requires a supervised device."),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system prohibits multiplayer gaming. Available in macOS 10.13 and later.",
      ),
    },
  },
  allowAssistant: {
    labelToken: localizationKey("Allow Siri"),
    descriptionToken: localizationKey("Also available for user enrollment."),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey("If unchecked, the system disables Siri.  Available in macOS 12 and later."),
    },
  },
  forceAssistantProfanityFilter: {
    labelToken: localizationKey("Enable Siri profanity filter assistant"),
    defaultValue: false,
    MACOS: {
      descriptionToken: localizationKey(
        "If checked, the system forces the use of the profanity filter for Siri and dictation. Available in macOS 10.13 and later.",
      ),
    },
  },
  allowBookstore: {
    labelToken: localizationKey("Allow iBooks Store"),
    descriptionToken: localizationKey(
      "If disabled, removes the Book Store tab from the Books app. Requires a supervised device.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system removes the Book Store tab from the Books app. Available in macOS 15 and later.",
      ),
    },
  },
  allowMusicService: {
    labelToken: localizationKey("Allow Apple Music"),
    descriptionToken: localizationKey(
      "If turned off, disables the Music service, and the Music app reverts to classic mode. Requires a supervised device.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system disables the Music service, and the Music app reverts to classic mode.  Available in macOS 10.12 and later.",
      ),
    },
  },
  allowAutoUnlock: {
    labelToken: localizationKey("Allow auto unlock"),
    descriptionToken: localizationKey("Available in iOS 14.5 and later."),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system disallows auto unlock. Available in macOS 10.12 and later.",
      ),
    },
  },
  allowFingerprintForUnlock: {
    labelToken: localizationKey("Allow Touch ID / Face ID to unlock device"),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system prevents Touch ID or Face ID from unlocking a device. Available in macOS 10.12.4 and later.",
      ),
    },
  },
  allowPasscodeModification: {
    labelToken: localizationKey("Allow modifying passcode"),
    descriptionToken: localizationKey(
      "If turned off, prevents the device passcode from being added, changed, or removed. This restriction is ignored by Shared iPads. Requires a supervised device.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system prevents adding, changing, or removing the passcode. Available in macOS 10.13 and later.",
      ),
    },
  },
  allowFingerprintModification: {
    labelToken: localizationKey("Allow modifying Touch ID / Face ID"),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system prevents the user from modifying Touch ID or Face ID. Available in macOS 14 and later.",
      ),
    },
  },
  allowPasswordAutoFill: {
    labelToken: localizationKey("Allow password autofill"),
    descriptionToken: localizationKey(
      "If turned off, disables the AutoFill Passwords feature in iOS (with Keychain and third-party password managers) and the user isn’t prompted to use a saved password in Safari or in apps. This restriction also disables Automatic Strong Passwords, and strong passwords are no longer suggested to users. It doesn’t prevent AutoFill for contact info and credit cards in Safari. Requires a supervised device.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system disables the AutoFill Passwords feature in iOS, with Keychain and third-party password managers, prompting the user to use a saved password in Safari or in apps, automatic strong passwords, and suggesting strong passwords to users.  However, the system doesn't prevent AutoFill for contact info and credit cards in Safari.  Available in macOS 10.14 and later.",
      ),
    },
  },
  safariAllowAutoFill: {
    labelToken: localizationKey("Enable Autofill on Safari"),
    descriptionToken: localizationKey(
      "If turned off, disables Safari AutoFill for passwords, contact info, and credit cards and also prevents the Keychain from being used for AutoFill. Though third-party password managers are allowed and apps can use AutoFill.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system disables Safari AutoFill for passwords, contact info, and credit cards and also prevents using the Keychain for AutoFill. The system still allows third-party password managers, and apps can use AutoFill.  Available in macOS 10.13 and later.",
      ),
    },
  },
  allowPasswordProximityRequests: {
    labelToken: localizationKey("Allow proximity based password sharing requests"),
    descriptionToken: localizationKey(
      "If turned off, disables requesting passwords from nearby devices. Requires a supervised device.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system disables requesting passwords from nearby devices. Available in macOS 10.14 and later.",
      ),
    },
  },
  allowPasswordSharing: {
    labelToken: localizationKey("Allow password sharing"),
    descriptionToken: localizationKey(
      "If turned off, disables sharing passwords with the Airdrop Passwords feature. Requires a supervised device.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system disables sharing passwords with the Airdrop Passwords feature. Available in macOS 10.14 and later.",
      ),
    },
  },
  allowCloudKeychainSync: {
    labelToken: localizationKey("Allow iCloud Keychain sync"),
    descriptionToken: localizationKey("Enables iCloud keychain synchronization"),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system disables iCloud keychain synchronization for personal Apple Accounts. Available in macOS 10.12 and later.",
      ),
    },
  },
  allowCloudPhotoLibrary: {
    labelToken: localizationKey("Allow iCloud Photo Library"),
    descriptionToken: localizationKey(
      "Enables iCloud Photo Library. Any photos not fully downloaded from iCloud Photo Library to the device are removed from local storage.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system disables iCloud Photo Library for personal Apple Accounts. The system removes any photos from local storage that aren't fully downloaded from iCloud Photo Library to the device. Available in macOS 10.12 and later.",
      ),
    },
  },
  allowCloudPrivateRelay: {
    labelToken: localizationKey("Allow iCloud Private Relay"),
    descriptionToken: localizationKey(
      "Enables iCloud Private Relay. For iOS devices, this restriction requires a supervised device.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system disables iCloud Private Relay for personal Apple Accounts. Available in macOS 12 and later.",
      ),
    },
  },
  allowCloudDocumentSync: {
    labelToken: localizationKey("Allow cloud document sync"),
    descriptionToken: localizationKey(
      "Enables document and key-value syncing to iCloud. As of iOS 13, this restriction requires a supervised device and Shared iPad doesn’t support it.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system disables document and key-value syncing to iCloud for personal Apple Accounts. Available in macOS 10.11 and later.",
      ),
    },
  },
  allowBookstoreErotica: {
    labelToken: localizationKey("Allow explicit sexual content in Apple Books"),
    descriptionToken: localizationKey(
      "If disabled, the user can't download Apple Books media that is tagged as erotica.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system prevents the user from downloading Apple Books media that's tagged as erotica. Available in macOS 15 and later.",
      ),
    },
  },
  allowExplicitContent: {
    labelToken: localizationKey("Allow playback of explicit music, podcast & iTunes U media"),
    descriptionToken: localizationKey(
      "If disabled, hides explicit music or video content purchased from the iTunes Store. Explicit content is marked as such by content providers, such as record labels, when sold through the iTunes Store.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system hides explicit music or video content purchased from the iTunes Store. The system marks explicit content as such by content providers, such as record labels, when sold through the iTunes Store. Explicit content in the News and Podcast apps is also hidden. Available in macOS 15 and later.",
      ),
    },
  },
  ratingApps: {
    labelToken: localizationKey("Allowed content ratings - Apps"),
    descriptionToken: localizationKey("The maximum level of app content allowed on the device."),
    defaultValue: "1000",
    MACOS: {
      descriptionToken: localizationKey(
        "The maximum level of app content allowed on the device. Preinstalled (first party) apps ignore this restriction. Available in macOS 15 and later.",
      ),
    },
  },
  ratingMovies: {
    labelToken: localizationKey("Allowed content ratings - Movies"),
    descriptionToken: localizationKey("The maximum level of movie content allowed on the device."),
    defaultValue: "1000",
    MACOS: {
      descriptionToken: localizationKey(
        "The maximum level of movie content allowed on the device. Preinstalled (first party) apps ignore this restriction. Available in macOS 15 and later.",
      ),
    },
  },
  ratingTVShows: {
    labelToken: localizationKey("Allowed content ratings - TV Shows"),
    descriptionToken: localizationKey("The maximum level of TV content allowed on the device."),
    defaultValue: "1000",
    MACOS: {
      descriptionToken: localizationKey(
        "The maximum level of TV content allowed on the device. Preinstalled (first party) apps ignore this restriction. Available in macOS 15 and later.",
      ),
    },
  },
  allowRemoteScreenObservation: {
    labelToken: localizationKey("Allow AirPlay screen view"),
    descriptionToken: localizationKey(
      "If turned off, disables remote screen observation by the Classroom app. If ScreenShot is disabled, the Classroom app doesn’t observe remote screens.",
    ),
    defaultValue: true,
    MACOS: {
      descriptionToken: localizationKey(
        "If unchecked, the system disables remote screen observation by the Classroom app. Nest this key beneath 'allowScreenShot' as a subrestriction. If 'allowScreenShot' is unchecked, the Classroom app doesn't observe remote screens. Available in macOS 10.14.4 and later.",
      ),
    },
  },
  forceClassroomAutomaticallyJoinClasses: {
    labelToken: localizationKey("Automatically join Classroom classes without prompting"),
    descriptionToken: localizationKey(
      "If enabled, automatically gives permission to the teacher’s requests without prompting the student.",
    ),
    defaultValue: false,
    MACOS: {
      descriptionToken: localizationKey(
        "If checked, the system automatically gives permission to the teacher's requests without prompting the student. Requires a supervised device. Available in macOS 10.14.4 and later.",
      ),
    },
  },
  forceClassroomUnpromptedScreenObservation: {
    labelToken: localizationKey("Allow Classroom screen view without prompting"),
    defaultValue: false,
    MACOS: {
      descriptionToken: localizationKey(
        "If checked, a student enrolled in a managed course through the Classroom app automatically gives permission to that course teacher's requests to observe the student's screen without prompting the student. Available in macOS 10.14.4 and later.",
      ),
    },
  },
  forceClassroomRequestPermissionToLeaveClasses: {
    labelToken: localizationKey("Require teacher permission to leave Classroom app unmanaged classes"),
    descriptionToken: localizationKey(
      "If enabled, a student enrolled in an unmanaged course through Classroom requests permission from the teacher when attempting to leave the course.",
    ),
    defaultValue: false,
    MACOS: {
      descriptionToken: localizationKey(
        "If checked, a student enrolled in an unmanaged course through Classroom needs to request permission from the teacher to leave the course. Available in macOS 10.14.4 and later.",
      ),
    },
  },
  forceClassroomUnpromptedAppAndDeviceLock: {
    labelToken: localizationKey("Allow Classroom to lock apps or the device without prompting"),
    defaultValue: false,
    MACOS: {
      descriptionToken: localizationKey(
        "If checked, the system allows the teacher to lock apps or the device without prompting the student. Available in macOS 10.14.4 and later.",
      ),
    },
  },
}

const macRestrictionsTokenMapper = {
  allowAirPlayIncomingRequests: {
    labelToken: localizationKey("Allow AirPlay"),
    descriptionToken: localizationKey(
      "If unchecked, the system disables incoming AirPlay requests. Available in macOS 12.3 and later.",
    ),
    defaultValue: true,
  },
  allowARDRemoteManagementModification: {
    labelToken: localizationKey("Allow Remote Management Sharing modification"),
    descriptionToken: localizationKey(
      "If unchecked, the system prevents modifying the Remote Management Sharing setting in System Settings. Available in macOS 14 and later.",
    ),
    defaultValue: true,
  },
  allowBluetoothSharingModification: {
    labelToken: localizationKey("Allow bluetooth sharing modification"),
    descriptionToken: localizationKey(
      "If unchecked, the system prevents modifying Bluetooth settings in System Settings. Available in macOS 14 and later.",
    ),
    defaultValue: true,
  },
  allowCloudAddressBook: {
    labelToken: localizationKey("Allow iCloud Address Book"),
    descriptionToken: localizationKey(
      "If unchecked, the system disables iCloud Address Book services. Available in macOS 10.12 and later.",
    ),
    defaultValue: true,
  },
  allowCloudBookmarks: {
    labelToken: localizationKey("Allow iCloud Bookmark"),
    descriptionToken: localizationKey(
      "If unchecked, the system disables iCloud Bookmark sync. Available in macOS 10.12 and later.",
    ),
    defaultValue: true,
  },
  allowCloudCalendar: {
    labelToken: localizationKey("Allow iCloud Calendar"),
    descriptionToken: localizationKey(
      "If unchecked, the system disables iCloud Calendar services. Available in macOS 10.12 and later.",
    ),
    defaultValue: true,
  },
  allowCloudDesktopAndDocuments: {
    labelToken: localizationKey("Allow iCloud Desktop and Document"),
    descriptionToken: localizationKey(
      "If unchecked, the system disables iCloud Desktop and Document services. Available in macOS 10.12.4 and later.",
    ),
    defaultValue: true,
  },
  allowCloudFreeform: {
    labelToken: localizationKey("Allow iCloud Freeform"),
    descriptionToken: localizationKey(
      "If unchecked, the system disallows iCloud Freeform services. Available in macOS 14 and later.",
    ),
    defaultValue: true,
  },
  allowCloudMail: {
    labelToken: localizationKey("Allow iCloud Mail"),
    descriptionToken: localizationKey(
      "If unchecked, the system disables iCloud Mail services. Available in macOS 10.12 and later.",
    ),
    defaultValue: true,
  },
  allowCloudNotes: {
    labelToken: localizationKey("Allow iCloud Notes"),
    descriptionToken: localizationKey(
      "If unchecked, the system disables iCloud Notes services. Available in macOS 10.12 and later.",
    ),
    defaultValue: true,
  },
  allowCloudReminders: {
    labelToken: localizationKey("Allow iCloud Reminder"),
    descriptionToken: localizationKey(
      "If unchecked, the system disables iCloud Reminder services. Available in macOS 10.12 and later.",
    ),
    defaultValue: true,
  },
  allowContentCaching: {
    labelToken: localizationKey("Allow Content Catching"),
    descriptionToken: localizationKey(
      "If unchecked, the system disables content caching. Available in macOS 10.13 and later.",
    ),
    defaultValue: true,
  },
  allowFileSharingModification: {
    labelToken: localizationKey("Allow File Sharing modification"),
    descriptionToken: localizationKey(
      "If unchecked, the system prevents modifying File Sharing setting in System Settings. Available in macOS 14 and later.",
    ),
    defaultValue: true,
  },
  allowInternetSharingModification: {
    labelToken: localizationKey("Allow Internet Sharing modification"),
    descriptionToken: localizationKey(
      "If unchecked, the system prevents modifying the Internet Sharing setting in System Settings. Available in macOS 14 and later.",
    ),
    defaultValue: true,
  },
  allowiTunesFileSharing: {
    labelToken: localizationKey("Allow iTunes file sharing"),
    descriptionToken: localizationKey(
      "If unchecked, the system disables iTunes file sharing services. Available in macOS 10.13 and later.",
    ),
    defaultValue: true,
  },
  allowLocalUserCreation: {
    labelToken: localizationKey("Allow local user creation"),
    descriptionToken: localizationKey(
      "If unchecked, the system prevents creating new users in System Settings. Available in macOS 14 and later.",
    ),
    defaultValue: true,
  },
  allowPrinterSharingModification: {
    labelToken: localizationKey("Allow Printer Sharing modification"),
    descriptionToken: localizationKey(
      "If unchecked, the system prevents modifying Printer Sharing settings in System Settings. Available in macOS 14 and later.",
    ),
    defaultValue: true,
  },
  allowRemoteAppleEventsModification: {
    labelToken: localizationKey("Allow Remote Apple Events Sharing modification"),
    descriptionToken: localizationKey(
      "If unchecked, the system prevents modifying Remote Apple Events Sharing settings in System Settings. Available in macOS 14 and later.",
    ),
    defaultValue: true,
  },
  allowStartupDiskModification: {
    labelToken: localizationKey("Allow Startup Disk modification"),
    descriptionToken: localizationKey(
      "If unchecked, the system prevents modification of Startup Disk settings in System Settings. Available in macOS 14 and later.",
    ),
    defaultValue: true,
  },
  allowTimeMachineBackup: {
    labelToken: localizationKey("Allow Time Machine backup"),
    descriptionToken: localizationKey(
      "If unchecked, the system prevents modification of Time Machine settings in System Settings. Available in macOS 14 and later.",
    ),
    defaultValue: true,
  },
  allowUniversalControl: {
    labelToken: localizationKey("Allow Universal Control"),
    descriptionToken: localizationKey(
      "If unchecked, the system disables Universal Control. Available in macOS 13 and later.",
    ),
    defaultValue: true,
  },
  enforcedFingerprintTimeout: {
    labelToken: localizationKey("Enforced Fingerprint Timeout (seconds)"),
    descriptionToken: localizationKey(
      "The value, in seconds, after which the fingerprint unlock requires a password to authenticate. The default value is 48 hours. Available in macOS 12 and later.",
    ),
    defaultValue: 172800, // 2 days
  },
  allowMediaSharingModification: {
    labelToken: localizationKey("Allow Media Sharing modification"),
    descriptionToken: localizationKey(
      "If unchecked, prevents modification of Media Sharing settings. Available in macOS 15.1 and later.",
    ),
    defaultValue: true,
  },
  forceBypassScreenCaptureAlert: {
    labelToken: localizationKey("Bypass user notification when using screen capture"),
    defaultValue: false,
    descriptionToken: localizationKey(
      "If checked, then the system bypasses the presentation of a screen capture alert. Available in macOS 15.1 and later.",
    ),
  },
}

/**
 * Provides mapper for shared Apple restrictions, giving custom attributes according to the type.
 * @param {{ type: 'mobile'|'MACOS' }} options - Required.
 * @returns {Object}
 */
const getAppleSharedRestrictionsTokenMapper = ({ type }) => {
  return Object.entries(appleSharedRestrictionsTokenMapper).reduce((acc, [key, value]) => {
    const { labelToken, defaultValue, descriptionToken } = value
    acc[key] = {
      labelToken,
      defaultValue,
      descriptionToken,
      // Overrides according to type
      ...(value[type] ?? {}),
    }
    return acc
  }, {})
}

export const getRestrictionsTokens = cond([
  [isAndroidPolicy, always(androidRestrictionTokenMapper)],
  [
    isAppleMobilePolicy,
    always({ ...appleMobileRestrictionsTokenMapper, ...getAppleSharedRestrictionsTokenMapper({ type: "mobile" }) }),
  ],
  [isMacPolicy, always({ ...macRestrictionsTokenMapper, ...getAppleSharedRestrictionsTokenMapper({ type: "MACOS" }) })],
])

export const enumOptionsTokenMapper = {
  locationMode: {
    locationUserChoice: localizationKey("Location setting is not restricted on the device"),
    locationEnforced: localizationKey("Enable location setting on the device"),
    locationDisabled: localizationKey("Disable location setting on the device"),
  },
  ratingApps: {
    "ratingapps.all": localizationKey("All"),
    "ratingapps.17": "17+",
    "ratingapps.12": "12+",
    "ratingapps.9": "19+",
    "ratingapps.4": "4+",
    "ratingapps.none": localizationKey("None"),
  },
  ratingMovies: {
    "ratingmovies.all": localizationKey("All"),
    "ratingmovies.nc17": "NC-17",
    "ratingmovies.r": "R",
    "ratingmovies.pg13": "PG-13",
    "ratingmovies.pg": "PG",
    "ratingmovies.g": "G",
    "ratingmovies.none": localizationKey("None"),
  },
  ratingTVShows: {
    "ratingtvshows.all": localizationKey("All"),
    "ratingtvshows.tvma": "TV-MA",
    "ratingtvshows.tv14": "TV-14",
    "ratingtvshows.tvpg": "TV-PG",
    "ratingtvshows.tvg": "TV-G",
    "ratingtvshows.tvy7": "TV-Y7",
    "ratingtvshows.tvy": "TV-Y",
    "ratingtvshows.none": localizationKey("None"),
  },
  ratingRegion: {
    "ratingregion.de": "de",
    "ratingregion.us": "us",
    "ratingregion.au": "au",
    "ratingregion.ca": "ca",
    "ratingregion.fr": "fr",
    "ratingregion.ie": "ie",
    "ratingregion.jp": "jp",
    "ratingregion.nz": "nz",
    "ratingregion.gb": "gb",
  },
  safariAcceptCookies: {
    "safariacceptcookies.cookies.preventBoth": localizationKey("Prevent both"),
    "safariacceptcookies.cookies.preventOnlyCrossSite": localizationKey("Prevent Only Cross-Site Tracking"),
    "safariacceptcookies.cookies.userManaged": localizationKey("User Managed"),
  },
  usbDataAccess: {
    "unspecified": localizationKey("Unspecified"),
    "allowed": localizationKey("Allowed"),
    "fileTransferDisallowed": localizationKey("File transfer disallowed"),
    "dataTransferDisallowed": localizationKey("Data transfer disallowed"),
  },
  configureWifi: {
    "unspecified": localizationKey("Unspecified"),
    "allowed": localizationKey("Allowed"),
    "disallowAddWifi": localizationKey("Disallow add Wi-Fi"),
    "disallowConfigureWifi": localizationKey("Disallow configure Wi-Fi"),
  },
  tetheringSettings: {
    "unspecified": localizationKey("Unspecified"),
    "allowAll": localizationKey("Allow all"),
    "disallowWifiTethering": localizationKey("Disallow Wi-Fi tethering"),
    "disallowAllTethering": localizationKey("Disallow all tethering"),
  },
  wifiDirectSettings: {
    "unspecified": localizationKey("Unspecified"),
    "allowed": localizationKey("Allowed"),
    "disallowed": localizationKey("Disallowed"),
  },
  screenBrightnessMode: {
    "unspecified": localizationKey("Unspecified"),
    "choice": localizationKey("User choice"),
    "automatic": localizationKey("Automatic"),
    "fixed": localizationKey("Fixed"),
  },
  screenTimeoutMode: {
    "unspecified": localizationKey("Unspecified"),
    "choice": localizationKey("User choice"),
    "enforced": localizationKey("Enforced"),
  },
  screenTimeout: {
    "timeout.unspecified": localizationKey("Unspecified"),
    "timeout.15s": localizationKey("15 seconds"),
    "timeout.30s": localizationKey("30 seconds"),
    "timeout.1m": localizationKey("1 minute"),
    "timeout.2m": localizationKey("2 minutes"),
    "timeout.5m": localizationKey("5 minutes"),
    "timeout.10m": localizationKey("10 minutes"),
    "timeout.15m": localizationKey("15 minutes"),
    "timeout.30m": localizationKey("30 minutes"),
  },
  printingPolicy: {
    "unspecified": localizationKey("Unspecified"),
    "disallowed": localizationKey("Disallowed"),
    "allowed": localizationKey("Allowed"),
  },
  wifiState: {
    "unspecified": localizationKey("Unspecified"),
    "userChoice": localizationKey("User choice"),
    "enabled": localizationKey("Enabled"),
    "disabled": localizationKey("Disabled"),
  },
  airplaneModeState: {
    "unspecified": localizationKey("Unspecified"),
    "userChoice": localizationKey("User choice"),
    "disabled": localizationKey("Disabled"),
  },
  ultraWidebandState: {
    "unspecified": localizationKey("Unspecified"),
    "userChoice": localizationKey("User choice"),
    "disabled": localizationKey("Disabled"),
  },
  cellularTwoGState: {
    "unspecified": localizationKey("Unspecified"),
    "userChoice": localizationKey("User choice"),
    "disabled": localizationKey("Disabled"),
  },
  minimumWifiSecurityLevel: {
    "unspecified": localizationKey("Unspecified"),
    "openNetworkSecurity": localizationKey("Open network security"),
    "personalNetworkSecurity": localizationKey("Personal network security"),
    "enterpriseNetworkSecurity": localizationKey("Enterprise network security"),
    "enterpriseBit192NetworkSecurity": localizationKey("Enterprise Bit-192 network security"),
  },
  workProfileWidgetsDefault: {
    "unspecified": localizationKey("Unspecified"),
    "allowed": localizationKey("Allowed"),
    "disallowed": localizationKey("Disallowed"),
  },
  credentialProviderPolicyDefault: {
    "unspecified": localizationKey("Unspecified"),
    "disallowed": localizationKey("Disallowed"),
    "disallowedExceptSystem": localizationKey("Disallowed except for system"),
  },
}

const getTokenKey = compose(join("."), slice(3, Infinity), split("."))
export const addLabelTokenToEnumOptions = (restrictionKey, options) => {
  const result = []
  for (const option of options) {
    const tokenKey = getTokenKey(option.labelToken)
    const token = enumOptionsTokenMapper[restrictionKey]?.[tokenKey]
    if (token) {
      result.push(assoc("labelToken", token, option))
    } else {
      result.push({
        ...option,
        labelToken: option.labelToken,
        labelText: tokenKey.toUpperCase(),
      })
    }
  }
  return result
}
