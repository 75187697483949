import { Fragment } from "react"
import styled from "@emotion/styled"
import { resetButtonStyle, Tooltip } from "@ninjaone/components"

const StyledButton = styled.button`
  ${resetButtonStyle}
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colorThemeText};

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }
`

const NavActionIcons = ({ actionIcons = [] }) => {
  if (!actionIcons.length) return null

  return actionIcons.map(({ element, tooltip, onClick, ref, sideOffset, id }, index) => (
    <Fragment key={tooltip || index}>
      {tooltip ? (
        <Tooltip align="center" label={tooltip} position="right" sideOffset={sideOffset}>
          <StyledButton
            {...{
              onClick,
              "aria-label": tooltip,
              id,
            }}
          >
            {element}
          </StyledButton>
        </Tooltip>
      ) : (
        <StyledButton
          {...{
            onClick,
            "aria-label": tooltip,
            id,
          }}
        >
          {element}
        </StyledButton>
      )}
    </Fragment>
  ))
}

export default NavActionIcons
