import { Select } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { Box } from "js/includes/components/Styled"
import withInheritance from "js/includes/components/withInheritance"
import {
  enrollmentOptions,
  osUpdatesOptionValues,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/ios/OSUpdates/formCommons"

const EnrollmentSelectComponent = ({ inheritableItem, onChange, labelText, valueKey, labelId }) => {
  const { [valueKey]: selectedValue = osUpdatesOptionValues.ALLOWED } = inheritableItem

  return (
    <Box marginBottom={tokens.spacing[2]}>
      <Select
        labelId={labelId}
        labelText={labelText}
        value={selectedValue}
        onChange={enrollment => onChange({ ...inheritableItem, [valueKey]: enrollment })}
        options={enrollmentOptions}
      />
    </Box>
  )
}

export default withInheritance(EnrollmentSelectComponent)
