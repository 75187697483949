import React, { Component } from "react"
import { connect } from "react-redux"
import { compose, prop, sortBy, toLower } from "ramda"
import { EditorHeader, EditorContent } from "js/includes/components/Editors"
import {
  faServer,
  faCog,
  faFileSpreadsheet,
  faFileInvoice,
  faSync,
  faMapMarkerAlt,
  faExclamationCircle,
} from "@fortawesome/pro-solid-svg-icons"
import ConnectWiseProducts from "./ConnectWise/settings/ConnectWiseProducts"
import ConnectWiseConfigurationTypes from "./ConnectWise/settings/ConnectWiseConfigurationTypes"
import ConnectWiseSettingsCompaniesTab from "js/includes/configuration/psa/ConnectWise/settings/ConnectWiseAccountSettings/ConnectWiseSettingsCompaniesTab"
import AutotaskAccountSettings from "js/includes/configuration/psa/Autotask/settings/AutotaskAccountSettings"
import AutotaskDeviceRoles from "js/includes/configuration/psa/Autotask/settings/AutotaskDeviceRoles"
import AutotaskSyncAttributes from "js/includes/configuration/psa/Autotask/settings/AutotaskSyncAttributes"
import AutotaskLocationMapping from "js/includes/configuration/psa/Autotask/settings/AutotaskLocationMapping"
import AutotaskHelpdesk from "js/includes/configuration/psa/Autotask/settings/AutotaskHelpdesk"
import Loading from "js/includes/components/Loading"
import { defaultToEmpty } from "js/includes/common/utils"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

export function sortListByName(values) {
  return compose(sortBy(compose(toLower, defaultToEmpty, prop("name"))))(values)
}

class PsaSettingsEditor extends Component {
  state = {
    warning: null,
  }

  setInlineWarning = warning => this.setState({ warning })

  getRoutes = () => {
    const { setInlineWarning } = this
    const { warning } = this.state
    const { loading } = this.props
    const isConnectWise = window.psaConnect.isConnectWise()
    const isAutotask = window.psaConnect.isAutotask()

    if (isConnectWise) {
      return [
        {
          icon: faCog,
          label: localizationKey("Companies"),
          route: "general",
          Component: () => <ConnectWiseSettingsCompaniesTab {...{ loading }} />,
        },
        {
          icon: faFileSpreadsheet,
          label: localizationKey("Products"),
          route: "products",
          Component: () => <ConnectWiseProducts {...{ loading }} />,
        },
        {
          icon: faServer,
          label: localizationKey("Configuration Types"),
          route: "configurationTypes",
          Component: () => <ConnectWiseConfigurationTypes {...{ loading }} />,
        },
      ]
    }

    if (isAutotask) {
      return [
        {
          icon: faCog,
          label: localizationKey("Accounts"),
          route: "general",
          action: () => this.setInlineWarning(null),
          Component: () => <AutotaskAccountSettings {...{ loading, warning, setInlineWarning }} />,
        },
        {
          icon: faFileInvoice,
          label: localizationKey("Device Roles"),
          route: "deviceRoles",
          Component: () => <AutotaskDeviceRoles {...{ loading }} />,
        },
        {
          icon: faSync,
          label: localizationKey("Sync Attributes"),
          route: "syncAttributes",
          Component: () => <AutotaskSyncAttributes {...{ loading }} />,
        },
        {
          icon: faMapMarkerAlt,
          label: localizationKey("Location Mapping"),
          route: "locationMapping",
          Component: () => <AutotaskLocationMapping {...{ loading }} />,
        },
        {
          icon: faExclamationCircle,
          label: localizationKey("Help Desk"),
          route: "helpdesk",
          Component: () => <AutotaskHelpdesk {...{ loading }} />,
        },
      ]
    }
  }

  handleSave = () => {
    this.props.save()
    this.setInlineWarning(null)
  }

  render() {
    const { close, loading, saving } = this.props
    let imageLogo, psaText

    if (window.psaConnect.isAutotask()) {
      psaText = "Autotask"
      imageLogo = `${process.env.PUBLIC_URL}/img/vendors/autotask_logo.png`
    } else if (window.psaConnect.isConnectWise()) {
      psaText = "ConnectWise"
      imageLogo = `${process.env.PUBLIC_URL}/img/vendors/connectwise.png`
    }

    return (
      <div className="flex-aside-editor" id="psa-settings-editor">
        <EditorHeader
          {...{
            close,
            save: this.handleSave,
            isSaving: loading || saving,
          }}
        />
        <div className="ninja-editor">
          <div className="editor-container">
            <div className="psa-settings-editor-header">
              <img src={imageLogo} alt={psaText} />
            </div>
            <div className="editor-content">
              {loading ? <Loading /> : <EditorContent {...{ tabs: this.getRoutes() }} />}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(({ psa }) => ({
  loading: psa.editorLoading,
  saving: psa.editorSaving,
}))(PsaSettingsEditor)
