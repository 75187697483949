import { reduce, isNil } from "ramda"
import {
  validations,
  localized,
  isNilOrEmpty,
  applyMultipleValidations,
  isNinjaPSAEnabledFromSettings,
} from "js/includes/common/utils"
import { MAX_INTEGER_VALUE, MIN_INTEGER_VALUE } from "js/includes/common/_constants"
import { isResolvedStatus } from "js/includes/configuration/integrations/ticketing/ticketCustomStatus/common"

const DECIMAL_REGEX = /^(-{0,1})(\d*(\.|,))?\d+$/
const INTEGER_REGEX = /^(-{0,1})(\d)+$/

export const isTicketFieldRequired = (technicianOption, status) => {
  const isResolved = isResolvedStatus(status)
  const fieldIsRequiredToResolve = technicianOption === "REQUIRED_TO_RESOLVE"
  const fieldIsRequiredToCreate = technicianOption === "REQUIRED_TO_CREATE"

  return (isResolved && fieldIsRequiredToResolve) || fieldIsRequiredToCreate
}

export function logEntryBodyValidation(value, values) {
  const hasAttachments = values?.description?.uploads?.length
  const hasInlineAttachments = values?.description?.uploads.some(upload => upload?.metadata?.inline)
  const success = !!value.trim() || !!hasAttachments || hasInlineAttachments

  return { success, message: success ? "" : localized("Please enter a message") }
}

export function validateResponse({ body, uploads }) {
  const hasAttachments = !!uploads?.length
  const hasInlineAttachments = uploads?.some(upload => upload?.metadata?.inline)
  const success = !!body?.trim() || hasAttachments || hasInlineAttachments

  return { success, message: success ? "" : localized("ticketing.pleaseEnterMessage") }
}

//Value is required and validate type
function validateInteger({ value }) {
  const success = !isNil(value) && INTEGER_REGEX.test(value)
  if (success) {
    return validations.isValidIntegerWithinRange(MIN_INTEGER_VALUE, MAX_INTEGER_VALUE)(value)
  }
  return {
    success,
    message: success ? "" : localized("Required"),
  }
}

//Value is not required but still must validate that is valid type
function validateIntegerNotRequired({ value }) {
  const success = isNilOrEmpty(value) ? true : INTEGER_REGEX.test(value)
  if (success) {
    return validations.isValidIntegerWithinRange(MIN_INTEGER_VALUE, MAX_INTEGER_VALUE)(value)
  }
  return {
    success,
    message: success ? "" : localized("Invalid"),
  }
}

function validateFloat({ value }) {
  const success = !isNil(value) && DECIMAL_REGEX.test(value)
  return {
    success,
    message: success ? "" : localized("Required"),
  }
}

function validateFloatNotRequired({ value }) {
  const success = isNilOrEmpty(value) ? true : DECIMAL_REGEX.test(value)
  return {
    success,
    message: success ? "" : localized("Invalid"),
  }
}

export function getValidatorForNumberField(attributeType, isRequired) {
  const numberValidationMap = {
    DECIMAL: {
      required: validateFloat,
      notRequired: validateFloatNotRequired,
    },
    NUMERIC: {
      required: validateInteger,
      notRequired: validateIntegerNotRequired,
    },
  }
  return numberValidationMap[attributeType][isRequired ? "required" : "notRequired"]
}

export function validateTicketField(attribute) {
  return validations.required(attribute?.value)
}

function validateRequiredCheckbox(attribute) {
  const success = attribute.value === true
  const message = success ? "" : localized("Required")

  return {
    success,
    message,
  }
}

function validationByRequiredType(type) {
  const isNumeric = ["NUMERIC", "DECIMAL"].includes(type)
  const isCheckbox = type === "CHECKBOX"

  if (isNumeric) return getValidatorForNumberField(type, true)
  if (isCheckbox) return validateRequiredCheckbox

  return validateTicketField
}

export const generateTicketAttributesValidation = ({ attributes, status }) => {
  const attributeValues = reduce((acc, ticketField) => {
    const { id, technicianOption, attributeType } = ticketField

    const isRequired = isTicketFieldRequired(technicianOption, status)

    if (isRequired) {
      const validation = validationByRequiredType(attributeType)
      return { ...acc, [id]: validation }
    }

    return acc
  }, {})(attributes)

  return {
    attributeValues,
  }
}

export const getSummaryValidations = () => [
  validations.required,
  value => {
    let success = true
    let message = ""

    if (/\[.+\].+\(#\d+\)/.test(value)) {
      success = false
      message = localized("Subject must not contain the pattern [abc] (#123)")
    }

    return { success, message }
  },
]

const agreementValidation = ({ currentAgreement }) => agreement => {
  const requiredValidation = validations.required(agreement)
  if (!requiredValidation.success) return requiredValidation

  const { isOneTimeAndBilled } = agreement
  const isCurrentAgreement = currentAgreement?.id === agreement?.id
  const success = !isOneTimeAndBilled || isCurrentAgreement

  return {
    success,
    message: success ? "" : localized("Agreement cannot be of interval one time and billed"),
  }
}

export const isRequesterRequired = source => !["CONDITION", "SCHEDULED_SCRIPT", "ACTIVITY"].includes(source)

export const generateTicketValidation = (options = {}) => {
  const { source, hasUnbilledProducts, agreement } = options

  const shouldRequireAgreement = hasUnbilledProducts && isNinjaPSAEnabledFromSettings()

  return {
    summary: applyMultipleValidations(getSummaryValidations()),
    clientId: validations.required,
    ticketFormId: validations.required,
    ...(isRequesterRequired(source) && { requester: validations.required }),
    ...(shouldRequireAgreement && { agreement: agreementValidation({ currentAgreement: agreement }) }),
  }
}
