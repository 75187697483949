import moment from "moment"
import styled from "@emotion/styled"
import { append, equals, pick } from "ramda"
import { sizer } from "@ninjaone/utils"
import tokens from "@ninjaone/tokens"
import { Button, DataTable, Text } from "@ninjaone/components"
import showModal from "js/includes/common/services/showModal"
import { localizationKey, localized } from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"
import {
  canAddFreezePeriod,
  convertDateRangeToFreezePeriod,
  getDiffDatesinDays,
  isFreezeDatePeriodValid,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"
import FreezePeriodModal from "./FreezePeriodModal"

const StyledFlex = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  height: 62px;
  padding: 0 ${tokens.spacing[2]};
`

function FreezePeriod({ freezePeriods, handleUpdate }) {
  const removeFreezePeriod = selectedPeriods => {
    const newPeriods = freezePeriods.filter(
      fp => !selectedPeriods.find(selectedFp => equalFreezePeriods(fp, selectedFp)),
    )
    handleUpdate(newPeriods)
  }

  const pickFreezePeriodsProps = pick(["startDate", "endDate"])
  const equalFreezePeriods = (a, b) => equals(pickFreezePeriodsProps(a), pickFreezePeriodsProps(b))

  const validateFreezePeriod = (from, to) => {
    if (!from || !to) {
      return { valid: false, message: localized("Please select a freeze period using the date input") }
    }
    const newFreezePeriod = convertDateRangeToFreezePeriod({ from, to })
    const duplicated = freezePeriods?.find(fp => equalFreezePeriods(fp, newFreezePeriod))
    if (duplicated) {
      return { valid: false, message: localized("The selected freeze period already exists") }
    }

    if (!isFreezeDatePeriodValid(newFreezePeriod)) {
      return { valid: false, message: localized("Freeze period max length is 90 days.") }
    }

    if (!canAddFreezePeriod(newFreezePeriod, freezePeriods)) {
      return { valid: false, message: localized("Need 60 days between freeze periods.") }
    }
    return { valid: true, message: null }
  }

  const onClickAdd = ({ from, to, unmount }) => {
    if (validateFreezePeriod(from, to)?.valid) {
      const newFreezePeriod = convertDateRangeToFreezePeriod({ from, to })
      const updatedFreezePeriods = append(newFreezePeriod, freezePeriods)
      handleUpdate(updatedFreezePeriods)
      unmount()
    }
  }

  return (
    <>
      <StyledFlex>
        <Flex width="100%" alignItems="center" justifyContent="space-between">
          <Text
            type="body"
            token={localized("Freeze periods can be a maximum of 90 days and need 60 days between freeze periods.")}
            color="colorTextWeakest"
            wordWrap="break-word"
          />
          <Button
            variant="primary"
            labelToken={localizationKey("Add freeze periods")}
            onClick={() => showModal(<FreezePeriodModal {...{ onClickAdd, validateFreezePeriod, freezePeriods }} />)}
          />
        </Flex>
      </StyledFlex>
      <Box height="350px" padding={sizer(0, 3)} display="block">
        <DataTable
          tableId="android-freeze-period-policy-table"
          rows={freezePeriods ?? []}
          noRowsToken={localizationKey("No freeze periods set")}
          columns={[
            {
              id: "startDate",
              Header: localized("Start Date"),
              accessor: ({ startDate }) =>
                moment({
                  month: startDate.month - 1,
                  date: startDate.day,
                }).format("MMMM DD"),
            },
            {
              id: "endDate",
              Header: localized("End Date"),
              accessor: ({ endDate }) =>
                moment({
                  month: endDate.month - 1,
                  date: endDate.day,
                }).format("MMMM DD"),
            },
            {
              id: "totalDays",
              Header: localized("Total days"),
              accessor: row => {
                const diff = getDiffDatesinDays(row.startDate, row.endDate)
                return localized("{{diffInDays}} {{days}}", {
                  diffInDays: diff,
                  days: diff > 1 ? localized("Days").toLowerCase() : localized("Day").toLowerCase(),
                })
              },
            },
          ]}
          actions={{
            primary: [
              {
                labelToken: localizationKey("Remove"),
                action: ({ selected }) => removeFreezePeriod(selected),
                variant: "danger",
                isRed: true,
              },
            ],
          }}
          showSearchBar={false}
        />
      </Box>
    </>
  )
}

export default FreezePeriod
