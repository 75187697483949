import { Children, memo, useCallback } from "react"
import {
  append,
  applySpec,
  compose,
  identity,
  isNil,
  join,
  last,
  map,
  memoizeWith,
  path,
  prop,
  propEq,
  split,
  splitAt,
  without,
} from "ramda"
import styled from "@emotion/styled"

import { spacing, typography } from "@ninjaone/tokens"
import { PlusIcon } from "@ninjaone/icons"
import { Body, Button, Heading, Input, Select, Stepper, Text, TextArea } from "@ninjaone/components"
import { Label } from "@ninjaone/components/src/Form/Label"
import { Box, Flex } from "js/includes/components/Styled"
import { capitalizeFirstLetter, isNotNil, localized, trueFalseStringToBool } from "js/includes/common/utils"
import { ManagedConfigType } from "./utils"
import { BundleArrayItem } from "./BundleArrayItem"

const mapEntriesToOptions = map(applySpec({ labelText: prop("name"), value: prop("value") }))
const TEXTAREA_MAXLENGTH = 4992
export const joinKeys = join("|")
export const splitKeys = split("|")
export const getLastLevelKeys = compose(last, splitAt)

export const ManagedConfigurations = ({
  propertiesList = [],
  managedConfigurations,
  updateManagedConfigField,
  connectionId,
}) => {
  const { managedConfiguration = {} } = managedConfigurations.find(propEq("connectionId", connectionId)) ?? {}
  return (
    <Flex flexDirection="column" gap={spacing[4]}>
      {propertiesList.map(({ key, ...fields }, index) => {
        const value = managedConfiguration[key]
        return (
          <ConfigItem
            {...{ key, value, updateManagedConfigField, ...fields }}
            configKeys={key}
            hideDivider={propertiesList.length === index + 1}
          />
        )
      })}
    </Flex>
  )
}

const StyledBox = styled(Box)`
  margin-top: ${({ isFirstLevel }) => (isFirstLevel ? 0 : spacing[3])};
  border-bottom: ${({ isFirstLevel, hideDivider, theme }) =>
    isFirstLevel && !hideDivider ? `1px solid ${theme.colorBorderWeak}` : "none"};
  padding-bottom: ${({ isFirstLevel }) => (isFirstLevel ? spacing[4] : 0)};
`

export const getAddActionTooltip = ({ settingName }) => localized("Add a new item of {{settingName}}", { settingName })

export const BundleSection = ({
  level,
  labelText,
  descriptionText,
  children,
  addAction,
  childrenGap,
  hideDivider,
  isArrayBundle,
}) => {
  const isFirstLevel = level === 1
  const hasChildren = Children.count(children) > 0
  return (
    <StyledBox {...{ isFirstLevel, hideDivider }}>
      <Flex alignItems="center" justifyContent="space-between" {...(hasChildren && { marginBottom: spacing[4] })}>
        <Box alignItems="center">
          <Heading level={level <= 2 ? level + 1 : 3} color="colorTextStrong" textWrap>
            {labelText}
          </Heading>
          {descriptionText && (
            <Text type="body" textWrap>
              {descriptionText}
            </Text>
          )}
        </Box>
        {addAction && (
          <Box marginLeft={spacing[6]}>
            <Button
              id={labelText}
              labelText={localized("Add")}
              tooltip={getAddActionTooltip({ settingName: labelText })}
              onClick={addAction}
              Icon={PlusIcon}
              compact
            />
          </Box>
        )}
      </Flex>
      {hasChildren && (
        <Flex flexDirection="column" gap={childrenGap ?? spacing[4]} marginLeft={isArrayBundle ? 0 : spacing[7]}>
          {children}
        </Flex>
      )}
    </StyledBox>
  )
}

const getMultiSelectPlaceholder = selectedCount => {
  if (selectedCount === 0) {
    return localized("Select options")
  }
  return selectedCount === 1
    ? localized("1 option selected")
    : localized("{{numberOfOptions}} options selected", { numberOfOptions: selectedCount })
}

const FieldContainerWithLabel = ({ id, children, labelText, descriptionText }) => (
  <Box>
    <Label labelFor={id} labelText={labelText} fontWeight={typography.fontWeight.medium} />
    <Body marginBottom={spacing[2]} textWrap>
      {descriptionText}
    </Body>
    {children}
  </Box>
)

export const unspecifiedOption = "unspecified"
export const getBoolOptions = memoizeWith(identity, showUnspecified => [
  ...(showUnspecified ? [{ labelText: localized("Unspecified"), value: unspecifiedOption }] : []),
  { labelText: localized("Enabled"), value: "true" },
  { labelText: localized("Disabled"), value: "false" },
])

const renderItemFormElement = ({
  configKeys,
  type,
  labelText,
  descriptionText,
  value,
  defaultValue,
  entries,
  onChange,
  nestedProperties,
  updateManagedConfigField,
  level = 1,
  hideDivider,
}) => {
  switch (type) {
    case ManagedConfigType.BOOL:
      return (
        <Select
          onChange={onChange}
          value={(value ?? defaultValue ?? unspecifiedOption).toString()}
          labelId={configKeys}
          triggerAriaLabel={labelText}
          titleRenderer={() => <FieldContainerWithLabel {...{ id: configKeys, labelText, descriptionText }} />}
          options={getBoolOptions(isNil(defaultValue))}
          placeholderLabel={localized("Choose an option")}
        />
      )

    case ManagedConfigType.STRING:
      return (
        <FieldContainerWithLabel {...{ id: configKeys, labelText, descriptionText }}>
          <Input
            id={configKeys}
            ariaLabel={labelText}
            onChange={onChange}
            value={value ?? defaultValue ?? ""}
            width="100%"
          />
        </FieldContainerWithLabel>
      )

    case ManagedConfigType.CHOICE:
      return (
        <Select
          onChange={onChange}
          value={value ?? defaultValue}
          labelId={configKeys}
          triggerAriaLabel={labelText}
          titleRenderer={() => <FieldContainerWithLabel {...{ id: configKeys, labelText, descriptionText }} />}
          options={mapEntriesToOptions(entries)}
          placeholderLabel={localized("Choose an option")}
        />
      )

    case ManagedConfigType.INTEGER:
      return (
        <FieldContainerWithLabel {...{ id: configKeys, labelText, descriptionText }}>
          <Stepper onChange={onChange} value={value ?? defaultValue} min={Number.MIN_SAFE_INTEGER} compact />
        </FieldContainerWithLabel>
      )

    case ManagedConfigType.MULTISELECT:
      if (isNotNil(entries)) {
        const _value = value ?? defaultValue ?? []
        return (
          <Select
            triggerAriaLabel={labelText}
            value={_value}
            labelId={configKeys}
            titleRenderer={() => <FieldContainerWithLabel {...{ id: configKeys, labelText, descriptionText }} />}
            placeholderLabel={getMultiSelectPlaceholder(_value.length)}
            options={mapEntriesToOptions(entries)}
            onChange={selected => {
              onChange(value?.includes(selected) ? without(selected, value) : append(selected, value))
            }}
          />
        )
      }
      return (
        <FieldContainerWithLabel {...{ id: configKeys, labelText, descriptionText }}>
          <TextArea
            id={configKeys}
            onChange={onChange}
            value={value ?? defaultValue ?? ""}
            placeholder={localized("Type the comma separated values")}
            maxLength={TEXTAREA_MAXLENGTH}
            width="100%"
          />
        </FieldContainerWithLabel>
      )

    case ManagedConfigType.BUNDLE:
      return (
        <BundleSection {...{ level, labelText, descriptionText, hideDivider }}>
          {nestedProperties.map(({ key, ...configItemFields }) => {
            const newKeys = [...splitKeys(configKeys), key]
            const _value = path(getLastLevelKeys(level, newKeys), value)
            return (
              <ConfigItem
                {...{ key, updateManagedConfigField, ...configItemFields }}
                value={_value}
                configKeys={joinKeys(newKeys)}
                level={level + 1}
              />
            )
          })}
        </BundleSection>
      )

    case ManagedConfigType.BUNDLE_ARRAY:
      return (
        <BundleArrayItem
          {...{ configKeys, labelText, descriptionText, onChange, nestedProperties, level }}
          valueList={value}
        />
      )

    case ManagedConfigType.HIDDEN:
      return null

    default:
      return null
  }
}

export const ConfigItem = memo(({ configKeys, updateManagedConfigField, title, description, ...props }) => {
  const labelText = capitalizeFirstLetter(title)
  const descriptionText = description ? capitalizeFirstLetter(description) : null
  const onChange = useCallback(
    e => {
      let value = e.target?.value ?? e
      if (props.type === ManagedConfigType.BOOL) {
        value = value === unspecifiedOption ? undefined : trueFalseStringToBool(value)
      }
      updateManagedConfigField(configKeys, value)
    },
    [configKeys, updateManagedConfigField, props.type],
  )

  return renderItemFormElement({
    configKeys,
    onChange,
    labelText,
    descriptionText,
    updateManagedConfigField,
    ...props,
  })
})
