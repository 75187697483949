import PropTypes from "prop-types"
import tokens from "@ninjaone/tokens"
import { Box } from "js/includes/components/Styled"
import SectionTitle from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/appleCommon/SectionTitle"
import { isMacPolicy, localizationKey, localized } from "js/includes/common/utils"
import { osTabGridColumns } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/appleCommon/OSUpdates/util"
import EnrollmentSelectComponent from "./EnrollmentSelectComponent"

const basePath = "content.patchManagement.deviceBehavior"

const DeviceBehaviorSection = ({ deviceBehavior, onPolicyChange, nodeRole }) => {
  const { automaticDownloadsSettings, automaticInstallationSettings, automaticSecurityUpdatesSettings } = deviceBehavior

  const onSettingsChange = ({ setting, settingPath }) => {
    onPolicyChange(`deviceBehavior.${settingPath}`, setting)
  }

  const isMac = isMacPolicy(nodeRole)

  return (
    <Box padding={tokens.spacing[6]}>
      <EnrollmentSelectComponent
        {...{
          gridColumns: osTabGridColumns,
          inheritableItem: automaticDownloadsSettings,
          onChange: setting => {
            onSettingsChange({ setting, settingPath: "automaticDownloadsSettings" })
          },
          itemPath: `${basePath}.automaticDownloadsSettings`,
          labelId: "install-beta-updates",
          labelText: isMac
            ? localized("Automatically download new macOS updates when available (macOS 15+)")
            : localized("Automatically download new iOS updates when available (iOS 18+)"),
          valueKey: "automaticDownloads",
          LabelComponent: () => (
            <SectionTitle
              titleToken={localizationKey("Device behavior")}
              tooltipToken={localizationKey(
                "Controls device behavior defined under Settings > Software Update > Automatic Updates. Settings that are enabled or disabled cannot be overridden by the user.",
              )}
            />
          ),
        }}
      />
      <EnrollmentSelectComponent
        {...{
          gridColumns: osTabGridColumns,
          inheritableItem: automaticInstallationSettings,
          onChange: setting => onSettingsChange({ setting, settingPath: "automaticInstallationSettings" }),
          itemPath: `${basePath}.automaticInstallationSettings`,
          labelText: isMac
            ? localized("Automatically install macOS updates (macOS 15+)")
            : localized("Automatically install iOS updates (iOS 18+)"),
          labelId: "install-automatic-updates",
          valueKey: "automaticInstallation",
        }}
      />
      {isMac && (
        <EnrollmentSelectComponent
          {...{
            gridColumns: osTabGridColumns,
            inheritableItem: automaticSecurityUpdatesSettings,
            onChange: setting => onSettingsChange({ setting, settingPath: "automaticSecurityUpdatesSettings" }),
            itemPath: `${basePath}.automaticSecurityUpdatesSettings`,
            labelText: localized("Automatically install Security Responses and system files (macOS 15+)"),
            labelId: "install-security-updates",
            valueKey: "automaticSecurityUpdates",
          }}
        />
      )}
    </Box>
  )
}

export default DeviceBehaviorSection

DeviceBehaviorSection.propTypes = {
  deviceBehavior: PropTypes.object.isRequired,
  onPolicyChange: PropTypes.func.isRequired,
  nodeRole: PropTypes.string.isRequired,
}
