import { find, propEq } from "ramda"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { isFeatureEnabled } from "js/includes/common/utils"

export const CLOUD = "CLOUD"
export const LOCAL = "LOCAL"
export const HYBRID = "HYBRID"

export const destinationTokens = {
  CLOUD: localizationKey("Cloud"),
  LOCAL: localizationKey("Local"),
  HYBRID: localizationKey("Hybrid"),
}

export const destinationOptions = () => [
  { value: CLOUD, label: localized(destinationTokens.CLOUD) },
  { value: LOCAL, label: localized(destinationTokens.LOCAL), disabled: !isFeatureEnabled("backup_arrow_image_local") },
  {
    value: HYBRID,
    label: localized(destinationTokens.HYBRID),
    disabled: !isFeatureEnabled("backup_arrow_image_hybrid"),
  },
]

export const isLocalOrHybrid = destination => destination === LOCAL || destination === HYBRID

export const getDestination = destination => find(propEq("value", destination), destinationOptions())
