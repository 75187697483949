import { useEffect } from "react"
import { shallowEqual, useSelector } from "react-redux"
import { fetchAvailablePatchVersions } from "js/includes/common/client"
import { useMountedState } from "js/includes/common/hooks"
import { getPlatform } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/ios/OSUpdates/enforcedUpdatesComponents/ManuallyScheduledUpdatesModal/util"
import { localizationKey, reportErrorAndShowMessage } from "js/includes/common/utils"

const useAvailableVersions = takenVersions => {
  const [versions, setVersions] = useMountedState([])
  const [isLoading, setIsLoading] = useMountedState(false)

  const nodeClass = useSelector(({ policyEditor: { policy } }) => policy.nodeClass, shallowEqual)
  const formatVersions = versions =>
    versions.map(productVersion => ({
      ...productVersion,
      labelText: productVersion.version,
      value: productVersion.version,
    }))

  useEffect(() => {
    const getAvailableVersions = async () => {
      setIsLoading(true)
      try {
        const { response } = await fetchAvailablePatchVersions(getPlatform(nodeClass))
        const filteredVersions = response.filter(({ version }) => !takenVersions.includes(version))
        const versionFormatted = formatVersions(filteredVersions)
        setVersions(versionFormatted)
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Failed to fetch available versions"))
        setVersions([])
      } finally {
        setIsLoading(false)
      }
    }
    getAvailableVersions()
  }, [setVersions, setIsLoading, takenVersions, nodeClass])
  return { versions, isLoading, nodeClassText: getPlatform(nodeClass) }
}
export default useAvailableVersions
