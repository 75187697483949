import { clone } from "ramda"
import FullModel from "./FullModel"
import {
  showErrorMessage,
  localized,
  ninjaReportError,
  MFA_ERROR_CODE_LIST,
  MFA_ERROR_CODES,
} from "js/includes/common/utils"
import { convertToServerFormat, convertToClientFormat, removeWebProperties } from "./common"
import { defaultConditionUids } from "js/includes/editors/Policy/Models/Conditions/DefaultConditions.js"
import { handlEditorSaveMfaException } from "js/includes/common/services/mfa"

export const PolicyModel = FullModel.extend(
  {
    urlRoot: "/policy",
    parseParam: "policy",
    enforceMfa: true,

    isUnhandledError(resultCode) {
      return !["POLICY_NAME_ALREADY_IN_USE", ...MFA_ERROR_CODE_LIST].includes(resultCode)
    },

    onSaveError({ responseJSON }) {
      if (!responseJSON || this.isUnhandledError(responseJSON.resultCode)) {
        showErrorMessage(localized("Error saving policy"))
      }
    },

    parse(response) {
      if (response.resultCode === "SUCCESS") {
        const policyJson = response[this.parseParam]

        policyJson.content = convertToClientFormat(policyJson.content)
        this.unsetFieldsThatAreNoLongerPresent(policyJson)

        return policyJson
      } else {
        this.unsetFieldsThatAreNoLongerPresent(response)

        return JSON.stringify(response)
      }
    },

    async save({ showSavingMessage, useMfaUrl, onMfaSubmit } = {}) {
      // Deep copy the JSON in order to prevent this model from transforming
      const policyJson = clone(this.toJSON())

      try {
        PolicyModel.checkDataIntegrity(policyJson)
        policyJson.content = convertToServerFormat(policyJson.content)
        removeWebProperties(policyJson)
        return await this.saveAsJson({
          json: JSON.stringify(policyJson),
          showSavingMessage,
          useMfaUrl,
        })
      } catch (err) {
        if (err.responseJSON?.resultCode === MFA_ERROR_CODES.MFA_REQUIRED) {
          return await handlEditorSaveMfaException({
            caller: this,
            err,
            onMfaSubmit: onMfaSubmit ?? (() => this.save({ showSavingMessage, useMfaUrl: true })),
          })
        } else if (err.message) {
          showErrorMessage(err.message)
          ninjaReportError(err)
        } else if (
          [MFA_ERROR_CODES.INCORRECT_MFA_CODE, MFA_ERROR_CODES.bad_u2f_input_format].includes(
            err.responseJSON?.resultCode,
          )
        ) {
          throw err
        }
      }
    },

    isAntivirusEnabled() {
      return (
        this.get("content") &&
        this.get("content").antivirus &&
        this.get("content").antivirus.general &&
        (!!this.get("content").antivirus.general.productCode ||
          (this.get("content").antivirus.general.productCode === undefined &&
            this.get("content").antivirus.general.enabled))
      )
    },

    isPatchManagementEnabled() {
      return (
        this.get("content") &&
        this.get("content").patchManagement &&
        this.get("content").patchManagement.general.enabled
      )
    },

    isPatchManagementControlEnabled() {
      return (
        this.get("content") &&
        this.get("content").patchManagement &&
        this.get("content").patchManagement.general.enabled &&
        this.get("content").patchManagement.controlPatchManagement.general.enabled
      )
    },

    isPatchManagementConfigureEnabled() {
      if (!this.get("content") || !this.get("content").patchManagement) {
        return false
      }
      return (
        this.get("content").patchManagement.general.enabled &&
        this.get("content").patchManagement.configureWindowsUpdate.general.enabled
      )
    },

    hasScheduledActions() {
      return (
        this.get("content") &&
        this.get("content").actionsetSchedules &&
        this.get("content").actionsetSchedules.length > 0
      )
    },
  },
  {
    getModel(id) {
      return PolicyModel.findOrCreate({ id: id })
    },

    checkDeviceDownConditionScripts({ conditions }) {
      return conditions.some(
        condition => condition.conditionUid === defaultConditionUids.DeviceDown && condition.scripts?.length,
      )
    },

    checkDataIntegrity({ content }) {
      if (!content) return
      if (this.checkDeviceDownConditionScripts(content)) {
        throw new Error(localized("Automations are not allowed for 'Device Down' condition"))
      }

      content.conditions.forEach(function(condition) {
        if (!condition.identifier || !condition.conditionUid) {
          throw new Error(localized("Invalid condition configuration found"))
        }
      })

      content.actionsetSchedules.forEach(function(actionsetSchedule) {
        if (!actionsetSchedule.identifier) {
          throw new Error(localized("Invalid scheduled action configuration found"))
        }
      })
    },
  },
)
