import { useCallback, useEffect, useRef } from "react"
import { useMounted } from "./mounted"

export function useDataPolling({ pollingIntervalDuration, fetchData }) {
  const pollingIntervalRef = useRef()
  const mounted = useMounted()

  const pollForData = useCallback(() => {
    pollingIntervalRef.current = setTimeout(async () => {
      if (!mounted.current) return
      if (!window.application.get("pauseTimers")) await fetchData(true)

      if (pollingIntervalRef.current) pollForData()
    }, pollingIntervalDuration)
  }, [pollingIntervalDuration, fetchData, mounted])

  const startPollingData = useCallback(async () => {
    if (pollingIntervalRef.current) {
      clearTimeout(pollingIntervalRef.current)
      pollingIntervalRef.current = null
    }
    await fetchData()

    pollForData()
  }, [pollForData, fetchData])

  useEffect(() => {
    startPollingData()

    return () => {
      clearTimeout(pollingIntervalRef.current)
    }
  }, [startPollingData])

  return { restart: startPollingData }
}
