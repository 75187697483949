import { handlEditorSaveMfaException } from "js/includes/common/services/mfa"
import FullModel from "./FullModel"
import { showErrorMessage, localized, ninjaReportError, reportErrorAndShowMessage } from "js/includes/common/utils"

export const CustomerModel = FullModel.extend(
  {
    urlRoot: "/client",
    parseParam: "client",
    enforceMfa: true,

    isUnhandledError(resultCode) {
      return !["MFA_REQUIRED", "INCORRECT_MFA_CODE", "CLIENT_NAME_ALREADY_IN_USE"].includes(resultCode)
    },

    onSaveError({ responseJSON }) {
      if (!responseJSON || this.isUnhandledError(responseJSON.resultCode)) {
        showErrorMessage(localized("Error saving customer"))
      }
    },

    async save(showSavingMessage, useMfaUrl) {
      try {
        const response = await this.saveAsJson({
          json: JSON.stringify(this.toJSON()),
          showSavingMessage,
          useMfaUrl,
        })
        return response
      } catch (err) {
        if (err.responseJSON?.resultCode === "MFA_REQUIRED") {
          return await handlEditorSaveMfaException({
            caller: this,
            err,
            onMfaSubmit: () => this.save(showSavingMessage, true),
          })
        } else if (err.message) {
          showErrorMessage(err.message)
          ninjaReportError(err)
        } else {
          reportErrorAndShowMessage(err)
        }
      }
    },
  },
  {
    getModel(id) {
      return CustomerModel.findOrCreate({ id: id })
    },
  },
)
