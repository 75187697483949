import { useCallback } from "react"
import { connect, useSelector } from "react-redux"
import { assoc, compose, isEmpty, isNil, map, path, when } from "ramda"

import { spacing } from "@ninjaone/tokens"
import { AlertMessage, Select, Text, Tooltip, VerticalTabsModal } from "@ninjaone/components"
import { RegularInfoCircleIcon } from "@ninjaone/icons"
import { validateXML } from "@ninjaone/components/src/WYSIWYG"
import { useMountedState } from "@ninjaone/utils"

import { useForm } from "js/includes/common/hooks"
import {
  AppleCustomAppType,
  decodeBase64,
  encodeBase64,
  getAppleAppName,
  isMacPolicy,
  localizationKey,
  localized,
  showErrorMessage,
  validations,
} from "js/includes/common/utils"
import { updatePolicyItem as _updatePolicyItem } from "js/state/actions/policyEditor/editor"
import { Box, Flex } from "js/includes/components/Styled"
import {
  ApplePolicyAppLicenseType,
  ApplePolicyAppsInstallTypes,
  defaultInheritance,
  formatApplicationId,
  ninjaOneAssistAppBundleId,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"
import { cleanHtml } from "js/includes/ticketing/editor/shared/components"
import { ForceInstallOptions, installTypeOptions, BlockedAssignmentInfoAlertBanner } from "./InstallationMethodModal"
import { WysiwygXMLEditor } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/common/WysiwygXMLEditor"

const getAppPolicy = (selectedApp, appsFromPolicy) => {
  const appPolicy = appsFromPolicy[formatApplicationId(selectedApp.bundleId)]
  // When iPadOS policy app was set as blocked appPolicy is undefined
  return isNil(appPolicy)
    ? {
        ...selectedApp,
        ...defaultInheritance,
      }
    : appPolicy
}

const disableBlockedOption = option =>
  compose(
    assoc("disabled", true),
    assoc("LabelComponent", () => (
      <Tooltip
        label={localized(
          "NinjaOne Assist App can not be set to blocked install type when location tracking is enabled.",
        )}
      >
        <Flex justifyContent="flex-start" alignItems="baseline" minWidth="470px" width="100%" gap={spacing[2]}>
          <Text type="body" token={localizationKey("Blocked")}></Text>
          <Box>
            <RegularInfoCircleIcon size="sm" />
          </Box>
        </Flex>
      </Tooltip>
    )),
  )(option)

const getInstallTypeOptions = shouldDisableBlockedOptionForN1A =>
  shouldDisableBlockedOptionForN1A
    ? map(
        when(
          option => option.value === ApplePolicyAppsInstallTypes.BLOCKED,
          option => disableBlockedOption(option),
        ),
        installTypeOptions,
      )
    : installTypeOptions

const getDefaultManagedConfigurationXML = message =>
  `<dict>\n\t${message ?? localized("Write your configurations here.")}\n</dict>`

const getFormattingErrorMessage = error => {
  const errorLine = path(["loc", "start", "line"], error)
  return errorLine
    ? localized("There's an issue in the configurations XML at line {{errorLine}}", {
        errorLine,
      })
    : localized("There's an issue in the configurations XML.")
}

const showGlobalErrorMessage = () =>
  showErrorMessage(localized("An error has occurred on the XML configurations editor."))

function EditAppModal({ selectedApp, appsFromPolicy, findDevice, unmount, parentPolicy, updatePolicyItem }) {
  const appPolicy = getAppPolicy(selectedApp, appsFromPolicy)
  const isCustomApp = appPolicy.customApp === AppleCustomAppType.CUSTOM
  const isVPP = appPolicy.licenseType === ApplePolicyAppLicenseType.VPP
  const isMac = useSelector(state => isMacPolicy(state.policyEditor.policy.nodeClass))

  const [configurationXML, setConfigurationXML] = useMountedState(() =>
    decodeBase64(appPolicy.managedConfiguration ?? "", {
      defaultValue: getDefaultManagedConfigurationXML(localized("Error decoding the configuration XML.")),
    }),
  )
  const [initialXml, setInitialXML] = useMountedState(configurationXML)

  const { values, onChange, validateForm } = useForm({
    fields: {
      installType: isMac ? ApplePolicyAppsInstallTypes.INSTALLED : appPolicy.installType ?? installTypeOptions[0].value,
      allowUserUninstallation: appPolicy.allowUserUninstallation ?? false,
      forceManagement: appPolicy.forceManagement ?? false,
      removeAppOnPolicyRemoval: appPolicy.removeAppOnPolicyRemoval ?? false,
      removeAppOnUnenrollment: appPolicy.removeAppOnUnenrollment ?? false,
    },
    validate: {
      installType: validations.required,
    },
  })

  const onInitialXMLParseError = useCallback(error => {
    showErrorMessage(getFormattingErrorMessage(error))
  }, [])

  const shouldDisableBlockedOptionForN1A =
    findDevice?.generalSettings.enabled && selectedApp.bundleId === ninjaOneAssistAppBundleId

  const handleSaveChanges = async () => {
    if (!validateForm()) return

    try {
      validateXML(configurationXML)
    } catch (error) {
      showErrorMessage(getFormattingErrorMessage(error))
      return
    }

    const uniqueId = formatApplicationId(selectedApp.bundleId)
    const newApp = {
      ...(appsFromPolicy[uniqueId] ?? appPolicy),
      ...values,
      managedConfiguration: isEmpty(configurationXML) ? null : encodeBase64(cleanHtml(configurationXML)),
      allowUserUninstallation:
        values.assignmentType === ApplePolicyAppsInstallTypes.BLOCKED ? false : values.allowUserUninstallation,
    }

    updatePolicyItem(`application.applications.${uniqueId}`, parentPolicy, newApp)
    unmount()
  }

  return (
    <VerticalTabsModal
      titleGroup={{
        titleToken: localized("Edit application policy - {{appName}}", {
          appName: getAppleAppName(selectedApp),
        }),
      }}
      tabsAriaLabel={localized("Edit application tabs")}
      getActiveTabIndex={index => {
        index === 0 && setInitialXML(configurationXML)
      }}
      tabs={[
        {
          label: localized("General"),
          renderer: () => (
            <Flex flexDirection="column" gap={spacing[3]} margin="0px 4px">
              {isCustomApp && (
                <AlertMessage
                  variant="danger"
                  labelToken={localizationKey(
                    "This app can only be removed when a device is fully unenrolled from NinjaOne.",
                  )}
                />
              )}
              <Select
                labelId="edit-assignment-type"
                labelToken={localizationKey("Assignment type")}
                value={values.installType}
                onChange={e => onChange("installType", e)}
                options={getInstallTypeOptions(shouldDisableBlockedOptionForN1A)}
                disabled={isMac || isCustomApp}
              />
              {values.installType === ApplePolicyAppsInstallTypes.INSTALLED && (
                <ForceInstallOptions
                  {...{ values, onChange, isVPP: isVPP || isCustomApp, disabled: isCustomApp }}
                  isPolicy
                />
              )}
              {values.installType === ApplePolicyAppsInstallTypes.BLOCKED && <BlockedAssignmentInfoAlertBanner />}
            </Flex>
          ),
        },
        {
          label: localized("Configurations"),
          renderer: () => (
            <Flex flexDirection="column" gap={spacing[3]}>
              <AlertMessage
                labelToken={localizationKey(
                  "Managed application configurations allow configuring settings of third party applications deployed in a policy.",
                )}
              />
              <WysiwygXMLEditor
                initialStringContent={isEmpty(initialXml) ? getDefaultManagedConfigurationXML() : initialXml}
                onChange={setConfigurationXML}
                placeholderToken={localizationKey("Enter the configurations in XML format.")}
                onInitialParseError={onInitialXMLParseError}
                onError={showGlobalErrorMessage}
              />
            </Flex>
          ),
        },
      ]}
      primaryButtonLabel={localized("Update")}
      onPrimaryButtonClick={handleSaveChanges}
      unmount={unmount}
      minHeight={500}
    />
  )
}

export default connect(
  ({ policyEditor }) => ({
    appsFromPolicy: policyEditor.policy.content.application?.applications ?? {},
    findDevice: policyEditor.policy.content.findDevice,
    parentPolicy: policyEditor.parentPolicy,
  }),
  {
    updatePolicyItem: _updatePolicyItem,
  },
)(EditAppModal)
