import { ConfirmationModal } from "@ninjaone/components"
import { localizationKey, localized, localizedWith } from "js/includes/common/utils"
import { BoldText } from "js/includes/configuration/integrations/mdm/apple/common"

export const DeletePayloadModal = ({ unmount, payloads = [], onConfirm, policyName }) => {
  const isMany = payloads.length > 1

  return (
    <ConfirmationModal
      {...{
        unmount,
        onConfirm: () => {
          unmount()
          onConfirm()
        },
        titleText: isMany ? localized("Delete custom payloads?") : localized("Delete custom payload?"),
        actionToken: localizationKey("Delete"),
        type: "danger",
      }}
    >
      {isMany
        ? localizedWith(
            "You are about to delete <%numberOfPayloads> <%> from the custom payloads in <%policyName> <%>. Are you sure you want to delete these payloads?",
            {
              numberOfPayloads: () => (
                <BoldText>{localized("{{count}} payloads", { count: payloads.length })}</BoldText>
              ),
              policyName: () => <BoldText>{policyName}</BoldText>,
            },
          )
        : localizedWith(
            "You are about to delete <%payloadName> <%> custom payload in <%policyName> <%>. Are you sure you want to delete this payload?",
            {
              payloadName: () => <BoldText>{payloads[0].name}</BoldText>,
              policyName: () => <BoldText>{policyName}</BoldText>,
            },
          )}
    </ConfirmationModal>
  )
}
