import { fetch } from "js/includes/common/utils"

export const setTestConnectionResult = result => ({
  type: "SET_TEST_CONNECTION_RESULT",
  result,
})

export const closeConnectionModal = () => dispatch => {
  dispatch({ type: "RESET_TEST_CONNECTION_RESULT" })
}

export const testConnection = (psaType, values) => async (dispatch, getState) => {
  try {
    if (values) {
      const body = JSON.stringify(values)
      const response = await fetch(`/psa${psaType === "CONNECTWISE" ? "cw" : "at"}/v2/testconnection`, {
        options: {
          method: "POST",
          body,
        },
      })

      if (response.status === 200) {
        dispatch(setTestConnectionResult("TEST_SUCCEEDED"))
      } else {
        throw response
      }
    }
  } catch (error) {
    dispatch(setTestConnectionResult("TEST_FAILED"))
    throw error
  }
}
