import { useState } from "react"

const useStableRows = ({ rows, rowsHashCode, rowsContentLength }) => {
  const [stableData, setStableData] = useState({
    rows,
    rowsHashCode,
    rowsContentLength,
  })
  const dataChanged = rowsHashCode !== stableData.rowsHashCode || rowsContentLength !== stableData.rowsContentLength

  if (dataChanged) {
    setStableData({
      rows,
      rowsHashCode,
      rowsContentLength,
    })
  }

  return stableData.rows
}

export default useStableRows
