import {
  any,
  assoc,
  complement,
  compose,
  either,
  find,
  forEach,
  filter,
  join,
  map,
  mergeLeft,
  pathEq,
  propEq,
  test,
  toPairs,
  split,
  toLower,
  toUpper,
  tail,
} from "ramda"
import { v4 as uuidv4 } from "uuid"
import moment from "moment"
import { cellTypes } from "@ninjaone/components/src/DataTable"
import { localized, localizationKey, availableLanguages } from "js/includes/common/utils"
import { PasswordPolicyScope } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/android/enums"

export const MAX_FREEZE_PERIOD = 90
export const DAYS_THRESHOLD = 60
export const MAXIMUM_PASSCODE_AGE_IN_DAYS = 730

export const resolveSecurityOptionLabel = (securityValue, securityOptions) => {
  const option = find(propEq("value", securityValue), securityOptions)
  if (option?.labelToken) {
    return localized(option.labelToken)
  }
  return option?.labelText || localized("None")
}

export const createWiFiNetworkPolicy = (network, updatePolicyItem, parentPolicy) => {
  const networkObject = mergeLeft(network, {
    guid: uuidv4(),
    active: true,
    inheritance: {
      inherited: false,
      overridden: false,
      sourcePolicyId: null,
    },
  })
  updatePolicyItem(`network.wifi.${networkObject.guid}`, parentPolicy, networkObject)
}

export const objectWithUUIDToArray = (obj, idKeyName = "guid") =>
  compose(
    map(([uuid, value]) => assoc(idKeyName, uuid, value)),
    toPairs,
  )(obj)

export const canAddFreezePeriod = ({ startDate, endDate }, periods = []) => {
  const newStart = moment({ month: startDate.month - 1, date: startDate.day })
  const newEnd = moment({ month: endDate.month - 1, date: endDate.day })
  for (const period of periods) {
    const start = moment({ month: period.startDate.month - 1, date: period.startDate.day })
    const end = moment({ month: period.endDate.month - 1, date: period.endDate.day })
    if (
      Math.abs(newEnd.diff(start, "days")) < DAYS_THRESHOLD ||
      Math.abs(end.diff(newStart, "days")) < DAYS_THRESHOLD
    ) {
      return false
    }
  }
  return true
}

export const getDiffDatesinDays = (startDate, endDate) => {
  const start = moment({ month: startDate.month - 1, date: startDate.day })
  const end = moment({ month: endDate.month - 1, date: endDate.day })
  const res = end.diff(start, "days")
  if (res < 0) {
    const newEnd = end.add(1, "years")
    return newEnd.diff(start, "days") + 1
  }
  return res + 1
}

export const getDateFromPeriod = ({ month, day, year }, dayShift = 0) => {
  const _year = year ?? new Date().getFullYear()
  const date = new Date(_year, month - 1, day)
  return dayShift >= 0
    ? moment(date)
        .add(dayShift, "days")
        .toDate()
    : moment(date)
        .subtract(Math.abs(dayShift), "days")
        .toDate()
}

export const KioskPowerButtonActions = {
  POWER_BUTTON_ACTIONS_UNSPECIFIED: "POWER_BUTTON_ACTIONS_UNSPECIFIED",
  POWER_BUTTON_AVAILABLE: "POWER_BUTTON_AVAILABLE",
  POWER_BUTTON_BLOCKED: "POWER_BUTTON_BLOCKED",
}

export const KioskSystemErrorWarnings = {
  SYSTEM_ERROR_WARNINGS_UNSPECIFIED: "SYSTEM_ERROR_WARNINGS_UNSPECIFIED",
  ERROR_AND_WARNINGS_ENABLED: "ERROR_AND_WARNINGS_ENABLED",
  ERROR_AND_WARNINGS_MUTED: "ERROR_AND_WARNINGS_MUTED",
}

export const KioskSystemNavigation = {
  SYSTEM_NAVIGATION_UNSPECIFIED: "SYSTEM_NAVIGATION_UNSPECIFIED",
  NAVIGATION_ENABLED: "NAVIGATION_ENABLED",
  NAVIGATION_DISABLED: "NAVIGATION_DISABLED",
  HOME_BUTTON_ONLY: "HOME_BUTTON_ONLY",
}

export const KioskStatusBar = {
  STATUS_BAR_UNSPECIFIED: "STATUS_BAR_UNSPECIFIED",
  NOTIFICATIONS_AND_SYSTEM_INFO_ENABLED: "NOTIFICATIONS_AND_SYSTEM_INFO_ENABLED",
  NOTIFICATIONS_AND_SYSTEM_INFO_DISABLED: "NOTIFICATIONS_AND_SYSTEM_INFO_DISABLED",
  SYSTEM_INFO_ONLY: "SYSTEM_INFO_ONLY",
}

export const KioskDeviceSettings = {
  DEVICE_SETTINGS_UNSPECIFIED: "DEVICE_SETTINGS_UNSPECIFIED",
  SETTINGS_ACCESS_ALLOWED: "SETTINGS_ACCESS_ALLOWED",
  SETTINGS_ACCESS_BLOCKED: "SETTINGS_ACCESS_BLOCKED",
}

export const AndroidPolicyAppsInstallTypes = {
  INSTALL_TYPE_UNSPECIFIED: "INSTALL_TYPE_UNSPECIFIED",
  PREINSTALLED: "PREINSTALLED",
  FORCE_INSTALLED: "FORCE_INSTALLED",
  BLOCKED: "BLOCKED",
  AVAILABLE: "AVAILABLE",
  REQUIRED_FOR_SETUP: "REQUIRED_FOR_SETUP",
  KIOSK: "KIOSK",
}

export const AndroidPolicyAppsInstallTypeOptions = [
  { value: AndroidPolicyAppsInstallTypes.INSTALL_TYPE_UNSPECIFIED, labelToken: localizationKey("Unspecified") },
  { value: AndroidPolicyAppsInstallTypes.PREINSTALLED, labelToken: localizationKey("Preinstalled") },
  { value: AndroidPolicyAppsInstallTypes.FORCE_INSTALLED, labelToken: localizationKey("Force Installed") },
  { value: AndroidPolicyAppsInstallTypes.BLOCKED, labelToken: localizationKey("Blocked") },
  { value: AndroidPolicyAppsInstallTypes.AVAILABLE, labelToken: localizationKey("Available") },
  { value: AndroidPolicyAppsInstallTypes.REQUIRED_FOR_SETUP, labelToken: localizationKey("Required for Setup") },
  { value: AndroidPolicyAppsInstallTypes.KIOSK, labelToken: localizationKey("Single app Kiosk") },
]

export const AndroidPolicyAppsApplicationSources = {
  PLAY_STORE: "PLAY_STORE",
  SYSTEM_APP: "SYSTEM",
}

export const isFreezeDatePeriodValid = ({ startDate, endDate }) => {
  const diff = getDiffDatesinDays(startDate, endDate)
  return diff <= MAX_FREEZE_PERIOD
}

const getFreezePeriodFromDate = date => ({ day: date.getDate(), month: date.getMonth() + 1 })
export const convertDateRangeToFreezePeriod = ({ from, to }) => ({
  startDate: getFreezePeriodFromDate(from),
  endDate: getFreezePeriodFromDate(to),
})

export const checkKioskAppExist = compose(any(propEq("installType", "KIOSK")), objectWithUUIDToArray)

export const isInheritedRow = pathEq(["inheritance", "inherited"], true)
export const isNotOverriddenRow = pathEq(["inheritance", "overridden"], false)

export const getOverrideStateColumn = () => ({
  id: "overrides",
  Header: localized("Overrides"),
  accessor: () => {},
  isRemovable: false,
  getCellTypeProps: ({ inheritance: { overridden, inherited } = {}, guid = "inheritance-tag" } = {}) =>
    (overridden || inherited) && {
      type: cellTypes.TAGS,
      props: {
        labels: [
          {
            id: guid,
            label: overridden ? localized("Overridden") : localized("Inherited"),
            ...(overridden && { variant: "alternate" }),
          },
        ],
      },
    },
})

export const defaultInheritance = {
  inheritance: {
    inherited: false,
    overridden: false,
    sourcePolicyId: null,
  },
}

export const getActiveStatusColumn = () => ({
  id: "active",
  Header: localized("Status"),
  tooltipToken: localizationKey("If Inactive, the item is ignored from the policy and not applied to the device."),
  accessor: ({ active }) => (active !== false ? localized("Active") : localized("Inactive")),
  getIconName: ({ active }) => (active !== false ? "CircleCheckIcon" : "CircleMinusIcon"),
  getIconProps: ({ active }) => ({ color: active !== false ? "colorAlertSuccess" : "colorTextDisabled" }),
  isRemovable: false,
})

const isActive = ({ active = true }) => active

export const getStatusActions = (onChangeStatus, isChildPolicy, customHideDeactivate) => {
  const action = ({ selected }) => {
    const filterApps = !!customHideDeactivate ? selected.filter(app => !customHideDeactivate?.(app)) : selected
    return compose(forEach(onChangeStatus))(filterApps)
  }
  const isParent = () => !isChildPolicy
  const hideActivateOption = either(isParent, isActive)
  const hideDeactivateOption = either(isParent, complement(isActive))
  return [
    {
      action,
      labelToken: localizationKey("Activate"),
      hideMultiAction: any(hideActivateOption),
      hideRowAction: hideActivateOption,
    },
    {
      action,
      labelToken: localizationKey("Deactivate"),
      hideMultiAction: any(hideDeactivateOption),
      hideRowAction: app => hideDeactivateOption(app) || customHideDeactivate?.(app),
    },
  ]
}

export const getInlineTags = (items, id) => {
  const remainingItems = tail(items)
  return remainingItems.length > 0
    ? [
        {
          id,
          label: localized("+{{remainingItems}} more", { remainingItems: remainingItems.length }),
          tooltipLabel: remainingItems.join("\n"),
        },
      ]
    : null
}

// Find if there's at least one `overridden: true` within an object
export const hasOverridden = obj => {
  for (const key in obj) {
    if (typeof obj[key] === "object") {
      if (hasOverridden(obj[key])) return true
    } else if (key === "overridden" && obj[key] === true) {
      return true
    }
  }
  return false
}

export const isPasswordUnspecifiedScopeInUse = passcodeRules => {
  // Currently and due to inheritance, defaults will be added to the unspecfied scope even if nothing is defined there
  // Only if the value for enabled is explicitly true / false we can confirm there was something set in the scope
  return typeof passcodeRules?.[PasswordPolicyScope.SCOPE_UNSPECIFIED]?.generalSettings?.enabled === "boolean"
}

// Replace the dot `.` in packageName or bundleId to double underscore
export const formatApplicationId = appId => appId.replaceAll(".", "__")

// Revert back applicationId to valid packageName
export const formatPackageName = packageName => packageName.replaceAll("__", ".")

// It must have at least two segments (one or more dots).
// Each segment must start with a letter.
// All characters must be alphanumeric or an underscore [a-zA-Z0-9_].
export const isValidPackageName = test(/^([A-Za-z]{1}\w*\.)+[A-Za-z]\w*$/)

// Find Device form - Apple

export const ninjaOneAssistAppBundleId = "com.ninjaone.assist"

export const isNinjaOneAssistAppActive = applications => {
  const ninjaOneAssistApp = formatApplicationId(ninjaOneAssistAppBundleId)

  return (
    applications[ninjaOneAssistApp]?.installType === ApplePolicyAppsInstallTypes.INSTALLED &&
    applications[ninjaOneAssistApp].active
  )
}

// Apple Applications form

export const ApplePolicyAppsInstallTypes = {
  INSTALLED: "INSTALLED",
  BLOCKED: "BLOCKED",
}

export const ApplePolicyAppLicenseType = {
  VPP: "VPP",
  STORE: "STORE",
}

export const ApplePolicyAppCustomType = {
  CUSTOM: "CUSTOM",
  PUBLIC: "PUBLIC",
}

export const getAvailableLanguages = () => {
  const localeFormat = lang => {
    const segments = split("-", lang)
    return join("-", [toLower(segments[0]), toUpper(segments[1])])
  }
  return compose(
    filter(item => item.value !== "browser-LANG"),
    map(language => ({ value: localeFormat(language.name), labelToken: language.token })),
  )(availableLanguages)
}

export const getLabelTokenFromValue = (value, options) => find(propEq("value", value))(options)?.labelToken
