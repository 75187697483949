import { find, pipe, prop, propEq } from "ramda"

export const scheduledUpdatedTabs = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  ENFORCED: "ENFORCED",
}

export const updateTypes = {
  APPROVAL: "APPROVAL",
  REJECTION: "REJECTION",
}

export const getIdentifierByVersion = (version, versions) =>
  pipe(find(propEq("version", version)), prop("identifier"))(versions)

export const getPlatform = nodeClass => {
  switch (nodeClass) {
    case "APPLE_IOS":
      return "iOS"
    case "APPLE_IPADOS":
      return "iPadOS"
    case "MAC":
    case "MAC_SERVER":
      return "macOS"
    default:
      throw new Error(`Platform not found for nodeClass: ${nodeClass}`)
  }
}
