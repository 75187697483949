//@flow
import $ from "jquery"
import React from "react"
import ReactDOM from "react-dom"
import { isEmpty } from "ramda"
import Backbone from "backbone"
import { v4 as uuidv4 } from "uuid"
import {
  faInfoCircle,
  faExclamationTriangle,
  faCircleNotch,
  faSpinner,
  faWrench,
  faBolt,
  faShieldHalved,
  faCircleUp,
  faCloudArrowDown,
  faCloudArrowUp,
  faRotate,
  faFileExport,
} from "@fortawesome/pro-solid-svg-icons"
import { faWindowMaximize } from "@fortawesome/pro-regular-svg-icons"
import { faNinjaControl, faTeamviewer } from "media/icons"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import { refreshDeviceHealthWarnings, refreshCustomerOverview } from "js/includes/common/services/deviceHealth"
import {
  addIndicator,
  removeIndicator,
  fetchJson,
  showErrorMessage,
  localized,
  localizationKey,
  isMacDevice,
  ninjaReportError,
  isAppInQaMode,
  isCloudMonitor,
  isUp,
  isFeatureEnabled,
  reportErrorAndShowMessage,
  runDeviceSearchToolbarAction,
} from "./utils"
import { NINJA_NETWORK_DISCOVERY } from "js/includes/common/jobs"

export const JobModel = Backbone.Model.extend(
  {
    urlRoot: "/webapp/submit/job",

    async saveJob(deviceId, isWakeUp, jobNodes, payload = {}) {
      if (isWakeUp) {
        try {
          await fetchJson(`/node/${deviceId}/wakeup`)
          await ShowMessageDialog({
            icon: { icon: faInfoCircle, type: "info" },
            title: localizationKey("Wake-on-LAN attempt"),
            message: localizationKey("Attempting to wake up the device..."),
            note: localizationKey(
              "Please make sure that Wake-on-LAN is enabled within the host BIOS settings as well as the Network Adapter settings within Device Manager.",
            ),
            buttons: [{ id: "OK", label: localizationKey("OK") }],
          })
          return
        } catch (error) {
          if (error.response.status === 500 && error.resultCode === "NO_USABLE_MAC_ADRESSES") {
            ShowMessageDialog({
              icon: { icon: faInfoCircle, type: "info" },
              title: localizationKey("Wake-on-LAN unsuccessful"),
              message: localizationKey("No devices are online that can wake up the device"),
              note: localizationKey(
                "Please make sure that Wake-on-LAN is enabled within the host BIOS settings as well as the Network Adapter settings within Device Manager.",
              ),
              buttons: [{ id: "OK", label: localizationKey("OK") }],
            })
          } else {
            showErrorMessage(error.errorMessage || localized("Error sending wake-on-lan"))
            throw error
          }
        }
      }

      this.set("appUserId", window.application.get("user").get("id"))

      try {
        const response = !isEmpty(payload)
          ? await runDeviceSearchToolbarAction({
              action: payload.action,
              data: payload.data,
            })
          : await fetchJson("/webapp/submit/job", {
              options: {
                method: "POST",
                body: JSON.stringify({ nodes: jobNodes || [{ nodeId: deviceId, jobs: [this] }] }),
              },
            })

        if (response?.resultCode === "SUCCESS" || response?.status === "SUCCESS") {
          return Promise.resolve()
        } else {
          return Promise.reject(response)
        }
      } catch (error) {
        if (!error.isDevicesCacheExpiredError) {
          showErrorMessage(localized("Error creating/updating jobs"))
        }
        throw error
      }
    },

    saveConfigurationAndWaitForCompletition(deviceId, context, indicatorMessage) {
      const job = this
      job.saveJob(deviceId)

      JobModel.addIndicator(context, indicatorMessage)

      const deferred = new $.Deferred()
      let jobQueryCount = 0
      const timerId = setInterval(() => {
        $.ajax({
          url: "/webapp/job/" + job.get("jobUid"),
          type: "GET",
          async success(jqXHR, response, model) {
            if (jqXHR.job && jqXHR.job.jobStatus === "COMPLETED") {
              clearInterval(timerId)
              if (jqXHR.job.jobResult === "SUCCESS") {
                JobModel.removeIndicator(context)
                deferred.resolve()
              } else {
                const buttonPressed = await ShowMessageDialog({
                  icon: { icon: faExclamationTriangle, type: "critical" },
                  title: localizationKey("Unable to perform this action"),
                  message: () => jqXHR.job.resultContent.errorMessage,
                  buttons: [{ id: "OK", label: localizationKey("OK") }],
                })
                if (buttonPressed === "OK") {
                  JobModel.removeIndicator(context)
                  deferred.reject()
                }
              }
            } else if (jobQueryCount++ === 30) {
              clearInterval(timerId)
              const buttonPressed = await ShowMessageDialog({
                icon: { icon: faExclamationTriangle, type: "critical" },
                title: localizationKey("Did not receive a response from the device"),
                message: localizationKey("Did not receive a response from the device"),
                buttons: [{ id: "OK", label: localizationKey("OK") }],
              })
              if (buttonPressed === "OK") {
                JobModel.removeIndicator(context)
                deferred.reject()
              }
            }
          },
          error(jqXHR) {
            clearInterval(timerId)
            showErrorMessage(localized("There was a problem completing the request you made"))
            JobModel.removeIndicator(context)
            deferred.reject()
          },
        })
      }, 1000)
      return deferred.promise()
    },

    setContent(e, explicitOptions) {
      // We need to grab data attr's differently in this method because
      // JQuery would cache old values even though the DOM attr's updated
      const sourceUid = explicitOptions ? explicitOptions.sourceUid : $(e.target).attr("data-source-uid")

      // Kill a process
      if (sourceUid === "ed534ab1-bfdd-442c-bf0f-977bb9f85c6c") {
        const pid = explicitOptions ? explicitOptions.pid : $(e.target).attr("data-pid")
        const image = explicitOptions ? explicitOptions.image : $(e.target).attr("data-image")

        this.get("content").actions[0].actionParams.pid = parseInt(pid, 10)
        this.setNamedParameters({
          process_name: image,
        })
      }

      // Reboot machine
      else if (sourceUid === "7f72d612-463e-4fac-b6ed-435b9c568ce9") {
        const forced = explicitOptions ? explicitOptions.forced : $(e.target).data("forced")

        this.get("content").actions[0].actionParams.forced = forced
        this.setNamedParameters({
          type: forced === true ? "forced" : "normal",
        })
      }

      // Set Service to Manual/Automatic/Disabled/Auto(Delayed)
      else if (sourceUid === "36B277F1-B697-4789-A022-DC6ACD767EF3") {
        const serviceName = explicitOptions ? explicitOptions.serviceName : $(e.target).attr("data-servicename")
        const startType = explicitOptions ? explicitOptions.startType : $(e.target).attr("data-type")

        this.get("content").actions[0].actionParams.serviceName = serviceName
        this.get("content").actions[0].actionParams.startType = startType
        this.setNamedParameters({
          service_name: serviceName,
          start_type: startType,
        })
      }

      // Start/Stop/Restart Service
      else if (
        sourceUid === "c0327be5-ef12-42ac-b446-823fc6161b21" ||
        sourceUid === "586b2745-0b98-4821-9b1b-feeb752077df" ||
        sourceUid === "5a2d8e6c-b8b7-45b1-b4bc-7a8c42719c88"
      ) {
        const serviceName = explicitOptions ? explicitOptions.serviceName : $(e.target).attr("data-servicename")

        this.get("content").actions[0].actionParams.serviceName = serviceName
        this.setNamedParameters({
          service_name: serviceName,
        })
      }

      // Uninstall Program
      else if (sourceUid === "3141a0d7-a1b3-47ff-bfba-d38b1eca724e") {
        const productCode = explicitOptions ? explicitOptions.productCode : $(e.target).attr("data-productcode")
        const softwareName = explicitOptions ? explicitOptions.softwareName : $(e.target).attr("data-name")

        this.get("content").actions[0].actionParams.productCode = productCode
        this.setNamedParameters({
          software_name: softwareName,
        })
      }
    },

    setNamedParameters(args) {
      let message = this.get("messageTemplate")
      const data = this.get("data")

      const namedParameterPattern = /\{(\w+)\}/g
      let parameterMatch = namedParameterPattern.exec(message)
      message.match(namedParameterPattern)
      while (parameterMatch) {
        let [, parameterName] = parameterMatch
        const parameterValue = args[parameterName]
        message = message.replace("{" + parameterName + "}", parameterValue)
        data.message.params[parameterName] = parameterValue
        parameterMatch = namedParameterPattern.exec(message)
      }

      this.set({
        data,
        message,
      })
    },
  },
  {
    addIndicator(context, indicatorMessage) {
      if (context === "ShowMessageDialog") {
        ShowMessageDialog({
          icon: { icon: faCircleNotch, type: "warning", spin: true },
          title: () => indicatorMessage + "...",
          message: localizationKey("This could take a few seconds. Please wait."),
          buttons: [],
        })
      } else {
        addIndicator(context, indicatorMessage)
      }
    },
    removeIndicator(context) {
      if (context === "ShowMessageDialog") {
        ReactDOM.unmountComponentAtNode(document.getElementById("message-dialog"))
      } else {
        removeIndicator(context)
      }
    },
  },
)

const JobCollection = Backbone.Collection.extend({
  url: "/webapp/job/list",
  model: JobModel,
})

export const renderJobs = (context, policyContent, nodeRole) => {
  window.globalActionSets = new JobCollection()
  // Add actionsets from the policy
  if (policyContent) {
    if (policyContent.actionsetSchedules) {
      const actionsetCollection = new JobCollection()
      actionsetCollection.comparator = "jobName"
      policyContent.actionsetSchedules.forEach(({ actions, enabled, actionsetScheduleName, identifier }) => {
        if (enabled) {
          const jobModel = new JobModel({
            sourceName: actionsetScheduleName,
            jobName: actionsetScheduleName,
            jobType: "ACTIONSET",
            message: localized("Scheduled automation '{{automationName}}' running on demand", {
              automationName: actionsetScheduleName,
            }),
            data: {
              message: {
                code: "job_scheduled_action_running",
                params: {
                  actionsetScheduleName,
                },
              },
            },
            mac: isMacDevice(nodeRole),
            sourceConfigUid: identifier,
            jobStatus: "START_REQUESTED",
            content: { actions },
          })

          actionsetCollection.add(jobModel)
          window.globalActionSets.add(jobModel)
        }
      })
    }
  }
}

export function getNewJobModel(name, message, content) {
  const identifier = uuidv4()

  return new JobModel({
    sourceName: name,
    jobName: name,
    jobType: "ACTIONSET",
    message,
    identifier: identifier,
    sourceConfigUid: identifier,
    jobStatus: "START_REQUESTED",
    content,
  })
}

export const jobLinkClickWithExplicitOptions = (deviceInfo, explicitOptions) => {
  return jobLinkClick(null, deviceInfo, explicitOptions)
}

export const jobLinkClick = (e, deviceInfo, explicitOptions) => {
  const $target = explicitOptions ? null : $(e.target)
  const sourceUid = explicitOptions ? explicitOptions.sourceUid : $target.data("source-uid")
  const sourceConfigUid = explicitOptions ? explicitOptions.sourceConfigUid : $target.data("source-config-uid")
  const allowOffline = explicitOptions ? explicitOptions.allowOffline : $target.hasClass("allow-offline")

  // Prevent the page from navigating away
  e?.preventDefault()
  //if this is a wake-on-LAN job and the device is up, we can't run this job, show a warning
  if (isWakeUpJob(sourceUid) && deviceInfo.isUp(false)) {
    e?.stopPropagation()

    ShowMessageDialog({
      icon: { icon: faInfoCircle, type: "warning" },
      title: () => `${deviceInfo.get("name")} ${localized("is online")}`,
      message: localizationKey(
        "You can't send a Wake-on-LAN command to a device that is online. This action requires the device to be offline.",
      ),
      note: localizationKey(
        "Please make sure that Wake-on-LAN is enabled within the host BIOS settings as well as the Network Adapter settings within Device Manager.",
      ),
    })
  } //if this is a wake-on-LAN job and the device is down then we should run this job
  else if (isWakeUpJob(sourceUid) && !deviceInfo.isUp(false)) {
    const job =
      window.jobTypeCollection.findWhere({ identifier: sourceUid }) || window.globalActionSets.findWhere({ sourceUid })

    if (!job) return
    return job.saveJob(deviceInfo.get("id"), true)
  } // If we can't run this job on an offline device and the device is offline, show a warning
  else if (!allowOffline && !deviceInfo.isCloudMonitor() && !deviceInfo.isUp(true)) {
    e?.stopPropagation()
  } else {
    let job
    if (sourceUid) {
      if (isRebootJob(sourceUid)) {
        ShowMessageDialog({
          icon: { icon: faInfoCircle, type: "info" },
          title: localizationKey("Request Received"),
          message: localizationKey("Your request has been received. Reboot can take up to 1 minute to process."),
          buttons: [{ id: "OK", label: localizationKey("OK") }],
        })
      }
      job =
        window.jobTypeCollection.findWhere({ identifier: sourceUid }) ||
        window.globalActionSets.findWhere({ sourceUid })
    } else if (sourceConfigUid) {
      job =
        window.jobTypeCollection.findWhere({ sourceConfigUid }) ||
        window.globalActionSets.findWhere({ sourceConfigUid })
    }
    if (job) {
      job.setContent(e, explicitOptions)

      // If this is a upload agent logs job
      if (!$target?.hasClass("maintainance-job")) {
        return job.saveJob(deviceInfo.get("id"))
      }
    }
  }
}

export const setupJobLinks = (context, deviceInfo) => {
  context.off("click", ".job-link").on("click", ".job-link", e => {
    jobLinkClick(e, deviceInfo)
  })
}

export const destroyJobs = context => {
  context
    .find(".job-menu")
    .parent()
    .removeClass("open")
  context
    .find(".job-link")
    .not(".maintainance-job")
    .off("click")
    .remove()
}

export const isWakeUpJob = identifier =>
  identifier &&
  (identifier.includes("ad4c0ffd-a71c-41ce-85d8-705483e3df60") ||
    identifier.includes("d1c02f8e-3b4c-427a-94b3-01c8be8a4e91"))

export const isRebootJob = identifier =>
  identifier &&
  (identifier === "e2b5f0f4-0774-43fa-86ec-3ccf4bdf5915" || identifier === "7f72d612-463e-4fac-b6ed-435b9c568ce9")

export const initJobTypeCollection = () => {
  const userName = window.application.get("user").getName()
  window.jobTypeCollection = new JobCollection([
    new JobModel({
      jobName: localized("Deep scan (Webroot) recommended"),
      runnerActionName: "RUN_ANTIVIRUS_WEBROOT_DEEP_SCAN",
      jobType: "ANTIVIRUS",
      message: userName + " triggered an antivirus deep scan (Webroot)",
      data: {
        message: {
          code: "job_deep_webroot_scan",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "a194d2fe-c121-4e0b-9ac5-28b3cf382364",
      sourceUid: "a194d2fe-c121-4e0b-9ac5-28b3cf382364",
      jobStatus: "START_REQUESTED",
      category: "ANTIVIRUS",
      subCategory: "WEBROOT",
    }),
    new JobModel({
      jobName: localized("Full scan (Webroot)"),
      runnerActionName: "RUN_ANTIVIRUS_WEBROOT_FULL_SCAN",
      jobType: "ANTIVIRUS",
      message: userName + " triggered an antivirus full scan (Webroot)",
      data: {
        message: {
          code: "job_full_webroot_scan",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "f66e4a76-3c38-4b4b-b8a6-3b3498e47793",
      sourceUid: "f66e4a76-3c38-4b4b-b8a6-3b3498e47793",
      jobStatus: "START_REQUESTED",
      category: "ANTIVIRUS",
      subCategory: "WEBROOT",
    }),
    new JobModel({
      jobName: localized("Cleanup (Webroot)"),
      runnerActionName: "RUN_ANTIVIRUS_WEBROOT_CLEANUP",
      jobType: "ANTIVIRUS",
      message: userName + " triggered an antivirus cleanup (Webroot)",
      data: {
        message: {
          code: "job_cleanup_webroot_scan",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "091a3f42-3e9d-40af-9f4d-4cd55613addb",
      sourceUid: "091a3f42-3e9d-40af-9f4d-4cd55613addb",
      jobStatus: "START_REQUESTED",
      category: "ANTIVIRUS",
      subCategory: "WEBROOT",
    }),
    new JobModel({
      jobName: localized("Custom scan (Bitdefender)"),
      runnerActionName: "RUN_ANTIVIRUS_BITDEFENDER_CUSTOM_SCAN",
      jobType: "ANTIVIRUS",
      message: userName + " triggered an antivirus (Bitdefender) Custom Scan",
      data: {
        message: {
          code: "job_custom_bitdefender_scan",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "657CE921-7334-4AA2-B103-025194E3140E",
      sourceUid: "657CE921-7334-4AA2-B103-025194E3140E",
      jobStatus: "START_REQUESTED",
      category: "ANTIVIRUS",
      subCategory: "BITDEFENDER",
    }),
    new JobModel({
      jobName: localized("Quick scan (Bitdefender)"),
      runnerActionName: "RUN_ANTIVIRUS_BITDEFENDER_QUICK_SCAN",
      jobType: "ANTIVIRUS",
      message: userName + " triggered an antivirus (Bitdefender) Quick scan",
      data: {
        message: {
          code: "job_quick_bitdefender_scan",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "783F1036-FCDC-4BDC-BB6C-5F449594060C",
      sourceUid: "783F1036-FCDC-4BDC-BB6C-5F449594060C",
      jobStatus: "START_REQUESTED",
      category: "ANTIVIRUS",
      subCategory: "BITDEFENDER",
    }),
    new JobModel({
      jobName: localized("Full scan (Bitdefender)"),
      runnerActionName: "RUN_ANTIVIRUS_BITDEFENDER_FULL_SCAN",
      jobType: "ANTIVIRUS",
      message: userName + " triggered an antivirus (Bitdefender) Full scan",
      data: {
        message: {
          code: "job_deep_bitdefender_scan",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "22809A7D-E413-4958-BAC9-7BC344BD7695",
      sourceUid: "22809A7D-E413-4958-BAC9-7BC344BD7695",
      jobStatus: "START_REQUESTED",
      category: "ANTIVIRUS",
      subCategory: "BITDEFENDER",
    }),
    ...(isFeatureEnabled("gravityzone")
      ? [
          new JobModel({
            activityStatus: "GRAVITYZONE_SCAN_STARTED",
            jobName: localized("Quick scan (Bitdefender GravityZone)"),
            runnerActionName: "RUN_ANTIVIRUS_GRAVITYZONE_QUICK_SCAN",
            jobType: "ANTIVIRUS",
            message: userName + " triggered an antivirus (GravityZone) Quick scan",
            data: {
              message: {
                code: "job_quick_gravityzone_scan",
                params: {
                  appUserName: userName,
                },
              },
            },
            identifier: "5d5e51f6-8b8c-4740-9a34-4391111209a5",
            sourceUid: "5d5e51f6-8b8c-4740-9a34-4391111209a5",
            jobStatus: "START_REQUESTED",
            category: "ANTIVIRUS",
            subCategory: "GRAVITYZONE",
          }),
          new JobModel({
            activityStatus: "GRAVITYZONE_SCAN_STARTED",
            jobName: localized("Full scan (Bitdefender GravityZone)"),
            runnerActionName: "RUN_ANTIVIRUS_GRAVITYZONE_FULL_SCAN",
            jobType: "ANTIVIRUS",
            message: userName + " triggered an antivirus (GravityZone) Full scan",
            data: {
              message: {
                code: "job_deep_gravityzone_scan",
                params: {
                  appUserName: userName,
                },
              },
            },
            identifier: "328d1f7b-56e5-436e-b25e-c77577719a04",
            sourceUid: "328d1f7b-56e5-436e-b25e-c77577719a04",
            jobStatus: "START_REQUESTED",
            category: "ANTIVIRUS",
            subCategory: "GRAVITYZONE",
          }),
          new JobModel({
            activityStatus: "GRAVITYZONE_SCAN_STARTED",
            jobName: localized("Memory scan (Bitdefender GravityZone)"),
            runnerActionName: "RUN_ANTIVIRUS_GRAVITYZONE_MEMORY_SCAN",
            jobType: "ANTIVIRUS",
            message: userName + " triggered an antivirus (GravityZone) Memory scan",
            data: {
              message: {
                code: "job_memory_gravityzone_scan",
                params: {
                  appUserName: userName,
                },
              },
            },
            identifier: "352f4f29-50a3-4e46-b708-fe519974ec9a",
            sourceUid: "352f4f29-50a3-4e46-b708-fe519974ec9a",
            jobStatus: "START_REQUESTED",
            category: "ANTIVIRUS",
            subCategory: "GRAVITYZONE",
          }),
        ]
      : []),
    new JobModel({
      jobName: localized("Quarantine a threat"),
      jobType: "ANTIVIRUS",
      message: userName + " quarantined a threat",
      data: {
        message: {
          code: "job_quarantine_threat",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "fdb0d7b5-8d54-4e2c-abb1-d963105a43a3",
      sourceUid: "fdb0d7b5-8d54-4e2c-abb1-d963105a43a3",
      jobStatus: "START_REQUESTED",
      newThreatStatus: "Pending Quarantine",
      needsContent: true,
    }),
    new JobModel({
      jobName: localized("Restore a threat"),
      jobType: "ANTIVIRUS",
      message: userName + " restored a threat",
      data: {
        message: {
          code: "job_restore_threat",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "fa6d3b23-2b4f-471a-8d94-ab79780b6a4a",
      sourceUid: "fa6d3b23-2b4f-471a-8d94-ab79780b6a4a",
      jobStatus: "START_REQUESTED",
      newThreatStatus: "Pending Restore",
      needsContent: true,
    }),
    new JobModel({
      jobName: localized("Delete a threat"),
      jobType: "ANTIVIRUS",
      message: userName + " deleted a threat",
      data: {
        message: {
          code: "job_delete_threat",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "e9b327c2-1976-49c9-b817-754deda04ff1",
      sourceUid: "e9b327c2-1976-49c9-b817-754deda04ff1",
      jobStatus: "START_REQUESTED",
      newThreatStatus: "Pending Delete",
      needsContent: true,
    }),
    new JobModel({
      jobName: localized("Remove a threat (Bitdefender)"),
      jobType: "ANTIVIRUS",
      message: userName + " antivirus (Bitdefender) removed a threat",
      data: {
        message: {
          code: "job_bitdefender_remove_threat",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "496ee209-9cf3-448f-8bc6-59eb9dab6b0a",
      sourceUid: "496ee209-9cf3-448f-8bc6-59eb9dab6b0a",
      jobStatus: "START_REQUESTED",
      newThreatStatus: "Excluded",
      needsContent: true,
    }),
    new JobModel({
      jobName: localized("Restore a threat (Bitdefender)"),
      jobType: "ANTIVIRUS",
      message: userName + " antivirus (Bitdefender) restored a threat",
      data: {
        message: {
          code: "job_bitdefender_restore_threat",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "4b03769b-b879-4d1c-bc72-ecdd6d51532f",
      sourceUid: "4b03769b-b879-4d1c-bc72-ecdd6d51532f",
      jobStatus: "START_REQUESTED",
      newThreatStatus: "Pending Restore",
      needsContent: true,
    }),
    new JobModel({
      jobName: localized("Delete a threat (Bitdefender)"),
      jobType: "ANTIVIRUS",
      message: userName + " antivirus (Bitdefender) deleted a threat",
      data: {
        message: {
          code: "job_bitdefender_delete_threat",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "fbc34ea2-7d94-40bc-97d1-76b3a580cf79",
      sourceUid: "fbc34ea2-7d94-40bc-97d1-76b3a580cf79",
      jobStatus: "START_REQUESTED",
      newThreatStatus: "Pending Delete",
      needsContent: true,
    }),
    new JobModel({
      jobName: localized("Scan"),
      jobType: "PATCH_MANAGEMENT",
      runnerActionName: "RUN_OS_UPDATE_SCAN",
      message: userName + " triggered a scan for Windows updates",
      data: {
        message: {
          code: "job_scan_windows_updates",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "16C45430-0D74-4405-BE7A-8DB1F27FE927",
      sourceUid: "16C45430-0D74-4405-BE7A-8DB1F27FE927",
      jobStatus: "START_REQUESTED",
      category: "WINDOWS_UPDATES",
    }),
    new JobModel({
      jobName: localized("Apply"),
      jobType: "PATCH_MANAGEMENT",
      runnerActionName: "RUN_OS_UPDATE_APPLY",
      message: userName + " triggered an application of Windows updates",
      data: {
        message: {
          code: "job_apply_windows_updates",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "9b55c184-cb84-4133-9df1-53150deee3fd",
      sourceUid: "9b55c184-cb84-4133-9df1-53150deee3fd",
      jobStatus: "START_REQUESTED",
      category: "WINDOWS_UPDATES",
    }),
    new JobModel({
      jobName: localized("Scan"),
      jobType: "PATCH_MANAGEMENT",
      runnerActionName: "RUN_OS_UPDATE_SCAN",
      message: userName + " triggered a scan for macOS updates",
      data: {
        message: {
          code: "job_scan_macos_updates",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "16C45430-0D74-4405-BE7A-8DB1F27FE927",
      sourceUid: "16C45430-0D74-4405-BE7A-8DB1F27FE927",
      jobStatus: "START_REQUESTED",
      category: "MACOS_UPDATES",
    }),
    new JobModel({
      jobName: localized("Apply"),
      jobType: "PATCH_MANAGEMENT",
      runnerActionName: "RUN_OS_UPDATE_APPLY",
      message: userName + " triggered an application of macOS updates",
      data: {
        message: {
          code: "job_apply_macos_updates",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "9b55c184-cb84-4133-9df1-53150deee3fd",
      sourceUid: "9b55c184-cb84-4133-9df1-53150deee3fd",
      jobStatus: "START_REQUESTED",
      category: "MACOS_UPDATES",
    }),
    new JobModel({
      jobName: localized("Scan"),
      jobType: "PATCH_MANAGEMENT",
      runnerActionName: "RUN_OS_UPDATE_SCAN",
      message: userName + " triggered a scan for linux updates",
      data: {
        message: {
          code: "job_scan_linux_updates",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "16C45430-0D74-4405-BE7A-8DB1F27FE927",
      sourceUid: "16C45430-0D74-4405-BE7A-8DB1F27FE927",
      jobStatus: "START_REQUESTED",
      category: "LINUX_UPDATES",
    }),
    new JobModel({
      jobName: localized("Apply"),
      jobType: "PATCH_MANAGEMENT",
      runnerActionName: "RUN_OS_UPDATE_APPLY",
      message: userName + " triggered an application of linux updates",
      data: {
        message: {
          code: "job_apply_linux_updates",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "9b55c184-cb84-4133-9df1-53150deee3fd",
      sourceUid: "9b55c184-cb84-4133-9df1-53150deee3fd",
      jobStatus: "START_REQUESTED",
      category: "LINUX_UPDATES",
    }),
    new JobModel({
      jobName: localized("Scan"),
      jobType: "SOFTWARE_PATCH_MANAGEMENT",
      runnerActionName: "RUN_SOFTWARE_UPDATE_SCAN",
      message: userName + " triggered a scan for Software updates",
      data: {
        message: {
          code: "job_scan_software_updates",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "8e4b3fa3-76f5-44a2-80b5-a211f0a32935",
      sourceUid: "8e4b3fa3-76f5-44a2-80b5-a211f0a32935",
      jobStatus: "START_REQUESTED",
      category: "SOFTWARE_UPDATES",
    }),
    new JobModel({
      jobName: localized("Apply"),
      jobType: "SOFTWARE_PATCH_MANAGEMENT",
      runnerActionName: "RUN_SOFTWARE_UPDATE_APPLY",
      message: userName + " triggered an application of Software updates",
      data: {
        message: {
          code: "job_apply_software_updates",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "99cb2951-3158-414f-817a-590ae806bc3d",
      sourceUid: "99cb2951-3158-414f-817a-590ae806bc3d",
      jobStatus: "START_REQUESTED",
      category: "SOFTWARE_UPDATES",
    }),

    // Some of these need to be created based on user input.
    // Like install application or start/stop service
    new JobModel({
      jobName: "Reboot",
      sourceName: localized("Reboot"),
      jobType: "ACTIONSET",
      message: `${userName} triggered a reboot`,
      data: {
        message: {
          code: "job_reboot",
          params: {
            appUserName: userName,
          },
        },
      },
      messageTemplate: `${userName} triggered a {type} reboot`,
      identifier: "7f72d612-463e-4fac-b6ed-435b9c568ce9",
      jobStatus: "START_REQUESTED",
      category: "SYSTEM_REBOOT",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Reboot"),
            actionUid: "7f72d612-463e-4fac-b6ed-435b9c568ce9",
            actionParams: {},
          },
        ],
      },
    }),
    new JobModel({
      jobName: "Forced Reboot",
      sourceName: localized("Forced Reboot"),
      jobType: "ACTIONSET",
      message: `${userName} triggered a forced reboot`,
      data: {
        message: {
          code: "job_forced_reboot",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "e2b5f0f4-0774-43fa-86ec-3ccf4bdf5915",
      jobStatus: "START_REQUESTED",
      category: "SYSTEM_REBOOT",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Forced Reboot"),
            actionUid: "e2b5f0f4-0774-43fa-86ec-3ccf4bdf5915",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Fix rebuild performance counters",
      sourceName: localized("Fix rebuild performance counters"),
      jobType: "ACTIONSET",
      message: userName + " Fixed rebuild performace counters",
      data: {
        message: {
          code: "job_fix_rebuild_counters",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "1F438DE8-328F-4CE8-B81E-A91C4A439CBB",
      jobStatus: "START_REQUESTED",
      category: "MAINTENANCE",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Fix rebuild performance counters"),
            actionUid: "1F438DE8-328F-4CE8-B81E-A91C4A439CBB",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Group policy force update",
      sourceName: localized("Group policy force update"),
      jobType: "ACTIONSET",
      message: userName + " Group policy force updated",
      data: {
        message: {
          code: "job_group_policy_update",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "679FDE5E-5DFC-4B19-9CE9-5057BF7E0285",
      jobStatus: "START_REQUESTED",
      category: "MAINTENANCE",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Group policy force update"),
            actionUid: "679FDE5E-5DFC-4B19-9CE9-5057BF7E0285",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Clear DNS cache",
      sourceName: localized("Clear DNS cache"),
      jobType: "ACTIONSET",
      message: userName + " cleared DNS cache",
      data: {
        message: {
          code: "job_clear_dns_cache",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "3800e62f-9e9d-4193-8b59-4cc4d1465020",
      jobStatus: "START_REQUESTED",
      category: "MAINTENANCE",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Clear DNS cache"),
            actionUid: "3800e62f-9e9d-4193-8b59-4cc4d1465020",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Windows OS patch scan",
      sourceName: localized("Windows OS patch scan"),
      jobType: "ACTIONSET",
      message: userName + " requested start of automation Windows OS patch scan",
      data: {
        message: {
          code: "job_scan_windows_updates",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "5ab2e4a8-22a5-4962-a405-7baaeff3c4a8",
      jobStatus: "START_REQUESTED",
      category: "PATCHING",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Windows OS patch scan"),
            actionUid: "5ab2e4a8-22a5-4962-a405-7baaeff3c4a8",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Mac OS patch scan",
      sourceName: localized("Mac OS patch scan"),
      jobType: "ACTIONSET",
      message: userName + " requested start of automation Mac OS patch scan",
      data: {
        message: {
          code: "job_scan_macos_updates",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "9463ecd3-5ce4-4620-94da-2802efe9fcb0",
      jobStatus: "START_REQUESTED",
      category: "PATCHING",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Mac OS patch scan"),
            actionUid: "9463ecd3-5ce4-4620-94da-2802efe9fcb0",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Linux OS patch scan",
      sourceName: localized("Linux OS patch scan"),
      jobType: "ACTIONSET",
      message: userName + " requested start of automation Linux OS patch scan",
      data: {
        message: {
          code: "job_scan_linux_updates",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "eeccf70f-46d0-4d25-8d2f-e1401415851f",
      jobStatus: "START_REQUESTED",
      category: "PATCHING",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Linux OS patch scan"),
            actionUid: "eeccf70f-46d0-4d25-8d2f-e1401415851f",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Windows OS patch apply",
      sourceName: localized("Windows OS patch apply"),
      jobType: "ACTIONSET",
      message: userName + " requested start of automation Windows OS patch apply",
      data: {
        message: {
          code: "job_apply_windows_updates",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "2aa579bb-e731-4973-998f-d86bd3cfea4f",
      jobStatus: "START_REQUESTED",
      category: "PATCHING",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Windows OS patch apply"),
            actionUid: "2aa579bb-e731-4973-998f-d86bd3cfea4f",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Mac OS patch apply",
      sourceName: localized("Mac OS patch apply"),
      jobType: "ACTIONSET",
      message: userName + " requested start of automation Mac OS patch apply",
      data: {
        message: {
          code: "job_apply_macos_updates",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "1ee490cc-14e3-47a6-b775-b5ee5dcf0548",
      jobStatus: "START_REQUESTED",
      category: "PATCHING",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Mac OS patch apply"),
            actionUid: "1ee490cc-14e3-47a6-b775-b5ee5dcf0548",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Linux OS patch apply",
      sourceName: localized("Linux OS patch apply"),
      jobType: "ACTIONSET",
      message: userName + " requested start of automation Linux OS patch apply",
      data: {
        message: {
          code: "job_apply_linux_updates",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "d4817ce8-3f66-482f-8855-88dae630e79d",
      jobStatus: "START_REQUESTED",
      category: "PATCHING",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Linux OS patch apply"),
            actionUid: "d4817ce8-3f66-482f-8855-88dae630e79d",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Windows Software patch scan",
      sourceName: localized("Windows Software patch scan"),
      jobType: "ACTIONSET",
      message: userName + " requested start of automation Windows Software patch scan",
      data: {
        message: {
          code: "job_scan_software_updates",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "5f54b65f-bb9d-4ee3-a1cf-2311262a3cf4",
      jobStatus: "START_REQUESTED",
      category: "PATCHING",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Windows Software patch scan"),
            actionUid: "5f54b65f-bb9d-4ee3-a1cf-2311262a3cf4",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Mac Software patch scan",
      sourceName: localized("Mac Software patch scan"),
      jobType: "ACTIONSET",
      message: userName + " requested start of automation Mac Software patch scan",
      data: {
        message: {
          code: "job_scan_software_updates",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "d3c73cfe-5722-4ac7-b4a2-64b58be1bd83",
      jobStatus: "START_REQUESTED",
      category: "PATCHING",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Mac Software patch scan"),
            actionUid: "d3c73cfe-5722-4ac7-b4a2-64b58be1bd83",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Windows Software patch apply",
      sourceName: localized("Windows Software patch apply"),
      jobType: "ACTIONSET",
      message: userName + " requested start of automation Windows Software patch apply",
      data: {
        message: {
          code: "job_apply_software_updates",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "3bc4eb60-9868-4893-803a-cda72706fd67",
      jobStatus: "START_REQUESTED",
      category: "PATCHING",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Windows Software patch apply"),
            actionUid: "3bc4eb60-9868-4893-803a-cda72706fd67",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Mac Software patch apply",
      sourceName: localized("Mac Software patch apply"),
      jobType: "ACTIONSET",
      message: userName + " requested start of automation Mac Software patch apply",
      data: {
        message: {
          code: "job_apply_software_updates",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "ebc86ea8-8195-408c-8618-fead7e51077d",
      jobStatus: "START_REQUESTED",
      category: "PATCHING",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Mac Software patch apply"),
            actionUid: "ebc86ea8-8195-408c-8618-fead7e51077d",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Clear event logs",
      sourceName: localized("Clear Event Logs"),
      jobType: "ACTIONSET",
      message: userName + " cleared event logs",
      data: {
        message: {
          code: "job_clear_event_logs",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "fabfc3e2-d408-4e5d-a733-cfc43dca3761",
      jobStatus: "START_REQUESTED",
      category: "MAINTENANCE",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Clear Event Logs"),
            actionUid: "fabfc3e2-d408-4e5d-a733-cfc43dca3761",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Delete temporary files",
      sourceName: localized("Delete Temporary Files"),
      jobType: "ACTIONSET",
      message: userName + " deleted temporary files",
      data: {
        message: {
          code: "job_delete_temp_files",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "680caf8e-d7f5-4a93-ad47-cd801229eed0",
      jobStatus: "START_REQUESTED",
      category: "MAINTENANCE",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Delete Temporary Files"),
            actionUid: "680caf8e-d7f5-4a93-ad47-cd801229eed0",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Empty recycle bin",
      sourceName: localized("Empty Recycle Bin"),
      jobType: "ACTIONSET",
      message: userName + " emptied recycle bin",
      data: {
        message: {
          code: "job_empty_recycle_bin",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "82e25a31-0245-487e-8a77-362be992716d",
      jobStatus: "START_REQUESTED",
      category: "MAINTENANCE",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Empty Recycle Bin"),
            actionUid: "82e25a31-0245-487e-8a77-362be992716d",
            actionParams: {},
          },
        ],
      },
    }),
    new JobModel({
      jobName: "Wake-on-LAN",
      sourceName: "Wake-on-LAN",
      jobType: "ACTIONSET",
      message: userName + " attempt to wake",
      data: {
        message: {
          code: "job_wake_on_lan",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "ad4c0ffd-a71c-41ce-85d8-705483e3df60",
      jobStatus: "START_REQUESTED",
      category: "MAINTENANCE",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: "Wake-on-LAN",
            actionUid: "ad4c0ffd-a71c-41ce-85d8-705483e3df60",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Fix file system errors",
      sourceName: localized("Fix File System Errors"),
      jobType: "ACTIONSET",
      message: userName + " fixed file system errors",
      data: {
        message: {
          code: "job_fix_file_errors",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "2e59fcd5-d36a-4e69-8da4-68ec8abdf041",
      jobStatus: "START_REQUESTED",
      category: "MAINTENANCE",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Fix File System Errors"),
            actionUid: "2e59fcd5-d36a-4e69-8da4-68ec8abdf041",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Release & renew IP address",
      sourceName: localized("Release & Renew IP Address"),
      jobType: "ACTIONSET",
      message: userName + " triggered a release and renewal of IP address",
      data: {
        message: {
          code: "job_release_renew_ip",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "540150c3-3f1a-461a-a599-052bfb8d47d2",
      jobStatus: "START_REQUESTED",
      category: "MAINTENANCE",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Release & Renew IP Address"),
            actionUid: "540150c3-3f1a-461a-a599-052bfb8d47d2",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Windows cleanup",
      sourceName: localized("Windows Cleanup"),
      jobType: "ACTIONSET",
      message: userName + " triggered a Windows cleanup",
      data: {
        message: {
          code: "job_windows_cleanup",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "826ece58-2e36-432c-8188-5e6fa31c231c",
      jobStatus: "START_REQUESTED",
      category: "MAINTENANCE",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Windows Cleanup"),
            actionUid: "826ece58-2e36-432c-8188-5e6fa31c231c",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Enable CD-ROM drives",
      sourceName: localized("Enable CD-ROM drives"),
      jobType: "ACTIONSET",
      message: userName + " enabled CD-ROM drives",
      data: {
        message: {
          code: "job_enable_cd_drives",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "7202FA90-73EE-4854-981A-C29954138E8E",
      jobStatus: "START_REQUESTED",
      category: "HARDWARE_SETTINGS",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Enable CD-ROM drives"),
            actionUid: "7202FA90-73EE-4854-981A-C29954138E8E",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Disable CD-ROM drives",
      sourceName: localized("Disable CD-ROM drives"),
      jobType: "ACTIONSET",
      message: userName + " disabled CD-ROM drives",
      data: {
        message: {
          code: "job_disable_cd_drives",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "89BF9801-BF0F-4CEB-B9AC-DC5192D031A1",
      jobStatus: "START_REQUESTED",
      category: "HARDWARE_SETTINGS",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Disable CD-ROM drives"),
            actionUid: "89BF9801-BF0F-4CEB-B9AC-DC5192D031A1",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Enable USB drives",
      sourceName: localized("Enable USB drives"),
      jobType: "ACTIONSET",
      message: userName + " enabled USB drives",
      data: {
        message: {
          code: "job_enable_usb",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "C41A7050-821E-4E99-8910-DCD9A73628CA",
      jobStatus: "START_REQUESTED",
      category: "HARDWARE_SETTINGS",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Enable USB drives"),
            actionUid: "C41A7050-821E-4E99-8910-DCD9A73628CA",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Disable USB drives",
      sourceName: localized("Disable USB drives"),
      jobType: "ACTIONSET",
      message: userName + " disabled USB drives",
      data: {
        message: {
          code: "job_disable_usb",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "5CBC65F9-F039-4165-AF74-5128952B4E72",
      jobStatus: "START_REQUESTED",
      category: "HARDWARE_SETTINGS",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Disable USB drives"),
            actionUid: "5CBC65F9-F039-4165-AF74-5128952B4E72",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Enable Floppy drives",
      sourceName: localized("Enable Floppy drives"),
      jobType: "ACTIONSET",
      message: userName + " enabled Floppy drives",
      data: {
        message: {
          code: "job_enable_floppy",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "4E1EC53D-84C1-4DCD-A4B9-ADAA9B1072C6",
      jobStatus: "START_REQUESTED",
      category: "HARDWARE_SETTINGS",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Enable Floppy drives"),
            actionUid: "4E1EC53D-84C1-4DCD-A4B9-ADAA9B1072C6",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Disable Floppy drives",
      sourceName: localized("Disable Floppy drives"),
      jobType: "ACTIONSET",
      message: userName + " disabled Floppy drives",
      data: {
        message: {
          code: "job_disable_floppy",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "38A80C81-46A0-4BCD-9CFD-035279F5D6A7",
      jobStatus: "START_REQUESTED",
      category: "HARDWARE_SETTINGS",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Disable Floppy drives"),
            actionUid: "38A80C81-46A0-4BCD-9CFD-035279F5D6A7",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Retry Install",
      sourceName: localized("requested to retry the installation"),
      jobType: "ANTIVIRUS",
      message: `${localized("User")}: ${userName} ${localized("requested to retry the installation")}`,
      data: {
        message: {
          code: "Job_retry_installation",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "cfdf5183-5639-402a-8993-cf9fbc2d3fa6",
      sourceUid: "cfdf5183-5639-402a-8993-cf9fbc2d3fa6",
      jobStatus: "START_REQUESTED",
      category: "ANTIVIRUS",
      subCategory: "INSTALLATION",
      content: {
        actionUid: uuidv4(),
        actions: [],
      },
    }),

    new JobModel({
      jobName: "Enable UAC",
      sourceName: localized("Enable UAC"),
      jobType: "ACTIONSET",
      message: userName + " enabled User Account Control",
      data: {
        message: {
          code: "job_enable_uac",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "B6746C96-D1BE-482E-8FC8-58C4387954BC",
      jobStatus: "START_REQUESTED",
      category: "USER_ACCOUNT_CONTROL",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Enable UAC"),
            actionUid: "B6746C96-D1BE-482E-8FC8-58C4387954BC",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Disable UAC",
      sourceName: localized("Disable UAC"),
      jobType: "ACTIONSET",
      message: userName + " disabled User Account Control",
      data: {
        message: {
          code: "job_disable_uac",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "4C7B01C1-0747-4618-8049-D22446C394B8",
      jobStatus: "START_REQUESTED",
      category: "USER_ACCOUNT_CONTROL",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Disable UAC"),
            actionUid: "4C7B01C1-0747-4618-8049-D22446C394B8",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Log off current user",
      sourceName: localized("Log Off Current User"),
      jobType: "ACTIONSET",
      message: userName + " triggered a log out for the current user",
      data: {
        message: {
          code: "job_log_off_user",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "2139e58a-5765-4ea5-bf60-e6e6963eabd4",
      jobStatus: "START_REQUESTED",
      category: "USER_ACCOUNT_CONTROL",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Log Off Current User"),
            actionUid: "2139e58a-5765-4ea5-bf60-e6e6963eabd4",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Remove Google toolbar",
      sourceName: localized("Remove Google toolbar"),
      jobType: "ACTIONSET",
      message: userName + " removed Google Toolbar",
      data: {
        message: {
          code: "job_remove_google_toolbar",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "14FD52EC-D8DC-413D-A7A7-C5796D67AFCD",
      jobStatus: "START_REQUESTED",
      category: "REMOVAL_SCRIPTS",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Remove Google toolbar"),
            actionUid: "14FD52EC-D8DC-413D-A7A7-C5796D67AFCD",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Remove Yahoo toolbar",
      sourceName: localized("Remove Yahoo toolbar"),
      jobType: "ACTIONSET",
      message: userName + " removed Yahoo toolbar",
      data: {
        message: {
          code: "job_remove_yahoo_toolbar",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "D2528180-87B8-46A3-86F0-AB9AED0A7204",
      jobStatus: "START_REQUESTED",
      category: "REMOVAL_SCRIPTS",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Remove Yahoo toolbar"),
            actionUid: "D2528180-87B8-46A3-86F0-AB9AED0A7204",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Upload agent logs",
      sourceName: localized("Upload agent logs"),
      jobType: "ACTIONSET",
      message: userName + " requested agent logs",
      data: {
        message: {
          code: "job_request_agent_logs",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "d960fc54-5bdb-45f8-8384-7bb53f93467d",
      jobStatus: "START_REQUESTED",
      internal: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Upload agent logs"),
            actionUid: "d960fc54-5bdb-45f8-8384-7bb53f93467d",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Kill a process",
      sourceName: localized("Kill a process"),
      jobType: "ACTIONSET",
      messageTemplate: userName + " killed {process_name}",
      data: {
        message: {
          code: "job_kill_process",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "ed534ab1-bfdd-442c-bf0f-977bb9f85c6c",
      jobStatus: "START_REQUESTED",
      needsContent: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Kill a process"),
            actionUid: "ed534ab1-bfdd-442c-bf0f-977bb9f85c6c",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Start Service",
      sourceName: localized("Start Service"),
      jobType: "ACTIONSET",
      messageTemplate: userName + " started {service_name}",
      data: {
        message: {
          code: "job_start_service",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "c0327be5-ef12-42ac-b446-823fc6161b21",
      jobStatus: "START_REQUESTED",
      needsContent: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Start a Service"),
            actionUid: "c0327be5-ef12-42ac-b446-823fc6161b21",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Stop Service",
      sourceName: localized("Stop Service"),
      jobType: "ACTIONSET",
      messageTemplate: userName + " stopped {service_name}",
      data: {
        message: {
          code: "job_stop_service",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "586b2745-0b98-4821-9b1b-feeb752077df",
      jobStatus: "START_REQUESTED",
      needsContent: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Stop a Service"),
            actionUid: "586b2745-0b98-4821-9b1b-feeb752077df",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Restart Service",
      sourceName: localized("Restart Service"),
      jobType: "ACTIONSET",
      messageTemplate: userName + " restarted {service_name}",
      data: {
        message: {
          code: "job_restart_service",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "5a2d8e6c-b8b7-45b1-b4bc-7a8c42719c88",
      jobStatus: "START_REQUESTED",
      needsContent: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Restart a Service"),
            actionUid: "5a2d8e6c-b8b7-45b1-b4bc-7a8c42719c88",
            actionParams: {},
          },
        ],
      },
    }),
    new JobModel({
      jobName: "Set Service Type",
      sourceName: localized("Set Service Type"),
      jobType: "ACTIONSET",
      messageTemplate: userName + " set {service_name} to {start_type}",
      data: {
        message: {
          code: "job_set_service_type",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "36B277F1-B697-4789-A022-DC6ACD767EF3",
      jobStatus: "START_REQUESTED",
      needsContent: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Set Service Type"),
            actionUid: "36B277F1-B697-4789-A022-DC6ACD767EF3",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "Uninstall Program",
      sourceName: localized("Uninstall Program"),
      jobType: "ACTIONSET",
      messageTemplate: userName + " uninstalled {software_name}",
      data: {
        message: {
          code: "job_uninstall_program",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "3141a0d7-a1b3-47ff-bfba-d38b1eca724e",
      jobStatus: "START_REQUESTED",
      needsContent: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Uninstall Program"),
            actionUid: "3141a0d7-a1b3-47ff-bfba-d38b1eca724e",
            actionParams: {},
          },
        ],
      },
    }),

    new JobModel({
      jobName: "SFC Scannow",
      sourceName: localized("SFC Scannow"),
      jobType: "ACTIONSET",
      message: userName + " ran SFC Scannow",
      data: {
        message: {
          code: "job_sfc_scannow",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "C4CB6876-F9D2-41FC-B53F-A8EC58A994FA",
      jobStatus: "START_REQUESTED",
      category: "MAINTENANCE",
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("SFC Scannow"),
            actionUid: "C4CB6876-F9D2-41FC-B53F-A8EC58A994FA",
            actionParams: {},
          },
        ],
      },
    }),

    /* MAC */
    new JobModel({
      jobName: "Enable firewall",
      sourceName: localized("Enable Firewall"),
      jobType: "ACTIONSET",
      message: userName + " enabled firewall",
      data: {
        message: {
          code: "job_enable_firewall",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "9D5007BF-D40A-4918-9B11-E80C5DD08F1D",
      jobStatus: "START_REQUESTED",
      category: "MAINTENANCE",
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Enable Firewall"),
            actionUid: "9D5007BF-D40A-4918-9B11-E80C5DD08F1D",
            actionParams: {},
          },
        ],
      },
    }),
    new JobModel({
      jobName: "Reboot",
      sourceName: localized("Reboot"),
      jobType: "ACTIONSET",
      message: userName + " rebooted",
      data: {
        message: {
          code: "job_reboot",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "40BE7B53-7567-4DFC-A434-E1E33DE752BA",
      jobStatus: "START_REQUESTED",
      category: "MAINTENANCE",
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Reboot"),
            actionUid: "40BE7B53-7567-4DFC-A434-E1E33DE752BA",
            actionParams: {},
          },
        ],
      },
    }),
    new JobModel({
      jobName: "Disable firewall",
      sourceName: localized("Disable Firewall"),
      jobType: "ACTIONSET",
      message: userName + " disabled firewall",
      data: {
        message: {
          code: "job_disable_firewall",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "4bd4330a-260f-43e9-9709-ed28e8be767f",
      jobStatus: "START_REQUESTED",
      category: "MAINTENANCE",
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Disable Firewall"),
            actionUid: "4bd4330a-260f-43e9-9709-ed28e8be767f",
            actionParams: {},
          },
        ],
      },
    }),
    new JobModel({
      jobName: "Enable ARD",
      sourceName: localized("Enable ARD"),
      jobType: "ACTIONSET",
      message: userName + " enabled ARD",
      data: {
        message: {
          code: "job_enable_ard",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "106c9c25-fead-4849-828c-87b0eb8174ad",
      jobStatus: "START_REQUESTED",
      category: "REMOTE_MANAGEMENT",
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Enable ARD"),
            actionUid: "106c9c25-fead-4849-828c-87b0eb8174ad",
            actionParams: {},
          },
        ],
      },
    }),
    new JobModel({
      jobName: "Disable ARD",
      sourceName: localized("Disable ARD"),
      jobType: "ACTIONSET",
      message: userName + " disabled ARD",
      data: {
        message: {
          code: "job_disable_ard",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "24282760-b103-4a83-9b65-c433d7ec0f3d",
      jobStatus: "START_REQUESTED",
      category: "REMOTE_MANAGEMENT",
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Disable ARD"),
            actionUid: "24282760-b103-4a83-9b65-c433d7ec0f3d",
            actionParams: {},
          },
        ],
      },
    }),
    /*
    // TODO: Disabling option
    // story NJ-5410
    // will be re-enable when agent is ready
    new JobModel({
      jobName: 'Wake-on-LAN',
      sourceName: 'Wake-on-LAN',
      jobType: 'ACTIONSET',
      message: userName + ' attempt to wake',
      identifier: 'd1c02f8e-3b4c-427a-94b3-01c8be8a4e91',
      jobStatus: 'START_REQUESTED',
      subJobMenu: '.maintenance-job-menu',
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [{
          actionName: 'Wake-on-LAN',
          actionUid: 'd1c02f8e-3b4c-427a-94b3-01c8be8a4e91',
          actionParams: {}
        }]
      }
    }),
    // Disabling option
    // story NJ-4648
    // will be re-enable when agent is ready
    new JobModel({
      jobName: 'Update to Sierra',
      sourceName: 'Update to Sierra',
      jobType: 'ACTIONSET',
      message: userName + ' updated to sierra',
      identifier: '59d41286-e965-453e-acdd-154a03fed667',
      jobStatus: 'START_REQUESTED',
      subJobMenu: '.maintenance-job-menu',
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [{
          actionName: 'Update to Sierra',
          actionUid: '59d41286-e965-453e-acdd-154a03fed667',
          actionParams: {}
        }]
      }
    }),*/
    new JobModel({
      jobName: "Enable SSH",
      sourceName: localized("Enable SSH"),
      jobType: "ACTIONSET",
      message: userName + " enabled SSH",
      data: {
        message: {
          code: "job_enable_ssh",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "233d7933-d594-4199-8bd2-d3f65a176ca4",
      jobStatus: "START_REQUESTED",
      category: "REMOTE_MANAGEMENT",
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Enable SSH"),
            actionUid: "233d7933-d594-4199-8bd2-d3f65a176ca4",
            actionParams: {},
          },
        ],
      },
    }),
    new JobModel({
      jobName: "Disable SSH",
      sourceName: localized("Disable SSH"),
      jobType: "ACTIONSET",
      message: userName + " disabled SSH",
      data: {
        message: {
          code: "job_disable_ssh",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "8c8271b1-0f62-4924-93ca-0413857359b6",
      jobStatus: "START_REQUESTED",
      category: "REMOTE_MANAGEMENT",
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Disable SSH"),
            actionUid: "8c8271b1-0f62-4924-93ca-0413857359b6",
            actionParams: {},
          },
        ],
      },
    }),
    new JobModel({
      jobName: "Enable wake on LAN",
      sourceName: localized("Enable Wake On LAN"),
      jobType: "ACTIONSET",
      message: userName + " enabled wake on LAN",
      data: {
        message: {
          code: "job_enable_wake_on_lan",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "dfe6db44-3cd5-4eae-b720-4e8d21133363",
      jobStatus: "START_REQUESTED",
      category: "POWER_SAVING",
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Enable Wake On LAN"),
            actionUid: "dfe6db44-3cd5-4eae-b720-4e8d21133363",
            actionParams: {},
          },
        ],
      },
    }),
    new JobModel({
      jobName: "Reset printing",
      sourceName: localized("Reset Printing"),
      jobType: "ACTIONSET",
      message: userName + " reset printing",
      data: {
        message: {
          code: "job_reset_printing",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "9eb4cf79-f55b-4094-9c05-4abd7d47646e",
      jobStatus: "START_REQUESTED",
      category: "MAINTENANCE",
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Reset Printing"),
            actionUid: "9eb4cf79-f55b-4094-9c05-4abd7d47646e",
            actionParams: {},
          },
        ],
      },
    }),
    new JobModel({
      jobName: "Disable Mac sleep",
      sourceName: localized("Disable Mac Sleep"),
      jobType: "ACTIONSET",
      message: userName + " disabled mac sleep",
      data: {
        message: {
          code: "job_disable_mac_sleep",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "b9db58e4-4eef-4ab8-ba64-96bd00df2a35",
      jobStatus: "START_REQUESTED",
      category: "POWER_SAVING",
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Disable Mac Sleep"),
            actionUid: "b9db58e4-4eef-4ab8-ba64-96bd00df2a35",
            actionParams: {},
          },
        ],
      },
    }),
    new JobModel({
      jobName: "Disable sleep",
      sourceName: localized("Disable Sleep"),
      jobType: "ACTIONSET",
      message: userName + " disabled sleep",
      data: {
        message: {
          code: "job_disable_sleep",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "2f8b6cc5-d81c-4754-9d15-088050064215",
      jobStatus: "START_REQUESTED",
      category: "POWER_SAVING",
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Disable Sleep"),
            actionUid: "2f8b6cc5-d81c-4754-9d15-088050064215",
            actionParams: {},
          },
        ],
      },
    }),
    new JobModel({
      jobName: "Empty trash",
      sourceName: localized("Empty Trash"),
      jobType: "ACTIONSET",
      message: userName + " emptied trash",
      data: {
        message: {
          code: "job_empty_trash",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "13e5be81-147c-4236-a7cc-bb8b512f4ab5",
      jobStatus: "START_REQUESTED",
      category: "MAINTENANCE",
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Empty Trash"),
            actionUid: "13e5be81-147c-4236-a7cc-bb8b512f4ab5",
            actionParams: {},
          },
        ],
      },
    }),
    new JobModel({
      jobName: "Flush DNS",
      sourceName: localized("Flush DNS"),
      jobType: "ACTIONSET",
      message: userName + " flushed DNS",
      data: {
        message: {
          code: "job_flush_dns",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "85c0859c-a7ba-4624-8909-8cecca1059fb",
      jobStatus: "START_REQUESTED",
      category: "MAINTENANCE",
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Flush DNS"),
            actionUid: "85c0859c-a7ba-4624-8909-8cecca1059fb",
            actionParams: {},
          },
        ],
      },
    }),
    new JobModel({
      jobName: "Kill core audio",
      sourceName: localized("Kill Core Audio"),
      jobType: "ACTIONSET",
      message: userName + " killed core audio",
      data: {
        message: {
          code: "job_kill_audio",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "cea2dd60-8b2b-41fd-82f0-65c4371d496c",
      jobStatus: "START_REQUESTED",
      category: "MAINTENANCE",
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Kill Core Audio"),
            actionUid: "cea2dd60-8b2b-41fd-82f0-65c4371d496c",
            actionParams: {},
          },
        ],
      },
    }),
    new JobModel({
      jobName: "Auto updates off",
      sourceName: localized("Auto updates off"),
      jobType: "ACTIONSET",
      message: userName + " turned auto updates off",
      data: {
        message: {
          code: "job_auto_updates_off",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "0524f2d4-dcf6-4f90-b031-5318916fd1fe",
      jobStatus: "START_REQUESTED",
      category: "MAINTENANCE",
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Auto updates off"),
            actionUid: "0524f2d4-dcf6-4f90-b031-5318916fd1fe",
            actionParams: {},
          },
        ],
      },
    }),
    new JobModel({
      jobName: "Purge disk cache",
      sourceName: localized("Purge Disk Cache"),
      jobType: "ACTIONSET",
      message: userName + " purged disk cache",
      data: {
        message: {
          code: "job_purge_disk_cache",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "68e8e0f7-0be1-4b14-8b4f-981161feb785",
      jobStatus: "START_REQUESTED",
      category: "MAINTENANCE",
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Purge Disk Cache"),
            actionUid: "68e8e0f7-0be1-4b14-8b4f-981161feb785",
            actionParams: {},
          },
        ],
      },
    }),
    new JobModel({
      jobName: "Reapply user permissions",
      sourceName: localized("Reapply user permissions"),
      jobType: "ACTIONSET",
      message: userName + " reapplied user permissions",
      data: {
        message: {
          code: "job_reapply_permissions",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "b2a071ff-cfbf-4573-9e6b-f642b15445c4",
      jobStatus: "START_REQUESTED",
      category: "MAINTENANCE",
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Reapply user permissions"),
            actionUid: "b2a071ff-cfbf-4573-9e6b-f642b15445c4",
            actionParams: {},
          },
        ],
      },
    }),
    new JobModel({
      jobName: "Reset launcher service",
      sourceName: localized("Reset Launcher Services"),
      jobType: "ACTIONSET",
      message: userName + " reset launcher service",
      data: {
        message: {
          code: "job_reset_launcher",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "11e7aa77-8de3-4e2a-99d9-b571b564c628",
      jobStatus: "START_REQUESTED",
      category: "MAINTENANCE",
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Reset Launcher Services"),
            actionUid: "11e7aa77-8de3-4e2a-99d9-b571b564c628",
            actionParams: {},
          },
        ],
      },
    }),
    new JobModel({
      jobName: "Wake display",
      sourceName: localized("Wake Display Up"),
      jobType: "ACTIONSET",
      message: userName + " woke display",
      data: {
        message: {
          code: "job_wake_display",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "019e9e69-82b7-4346-a792-b48aea0fc384",
      jobStatus: "START_REQUESTED",
      category: "POWER_SAVING",
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Wake Display Up"),
            actionUid: "019e9e69-82b7-4346-a792-b48aea0fc384",
            actionParams: {},
          },
        ],
      },
    }),
    /* re-enable when agent is ready
    new JobModel({
      jobName: 'Deploy Webroot',
      sourceName: 'Deploy Webroot',
      jobType: 'ACTIONSET',
      message: userName + ' deployed Webroot',
      identifier: 'e5f44319-77cf-419b-8f1a-e828886dba95',
      jobStatus: 'START_REQUESTED',
      subJobMenu: '.webroot-job-menu',
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [{
          actionName: 'Deploy Webroot',
          actionUid: 'e5f44319-77cf-419b-8f1a-e828886dba95',
          actionParams: {}
        }]
      }
    }),
    new JobModel({
      jobName: 'Remove Webroot',
      sourceName: 'Remove Webroot',
      jobType: 'ACTIONSET',
      message: userName + ' removed Webroot',
      identifier: '8780d436-05d2-4cd4-bb76-e471f32b5431',
      jobStatus: 'START_REQUESTED',
      subJobMenu: '.webroot-job-menu',
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [{
          actionName: 'Remove Webroot',
          actionUid: '8780d436-05d2-4cd4-bb76-e471f32b5431',
          actionParams: {}
        }]
      }
    }),
    */
    new JobModel({
      jobName: "Repair disk permissions",
      sourceName: localized("Repair disk permissions"),
      jobType: "ACTIONSET",
      message: userName + " repaired disk permissions",
      data: {
        message: {
          code: "job_repair_disk_permissions",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "e5a06cf8-1d8f-4652-86e0-941f96b6aefc",
      jobStatus: "START_REQUESTED",
      category: "REPAIR_SCRIPTS",
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Repair disk permissions"),
            actionUid: "e5a06cf8-1d8f-4652-86e0-941f96b6aefc",
            actionParams: {},
          },
        ],
      },
    }),
    new JobModel({
      jobName: "Enable VNC",
      sourceName: localized("Enable VNC"),
      jobType: "ACTIONSET",
      message: userName + " enabled VNC",
      data: {
        message: {
          code: "job_enable_vnc",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "0b53f366-9bfd-47e0-ae80-23b1d13e9d1e",
      jobStatus: "START_REQUESTED",
      category: "REMOTE_MANAGEMENT",
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Enable VNC"),
            actionUid: "0b53f366-9bfd-47e0-ae80-23b1d13e9d1e",
            actionParams: {},
          },
        ],
      },
    }),
    new JobModel({
      jobName: "Disable VNC",
      sourceName: localized("Disable VNC"),
      jobType: "ACTIONSET",
      message: userName + " disabled VNC",
      data: {
        message: {
          code: "job_disable_vnc",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "050e5c9b-0640-4af1-b9b9-3804e3027d7c",
      jobStatus: "START_REQUESTED",
      category: "REMOTE_MANAGEMENT",
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Disable VNC"),
            actionUid: "050e5c9b-0640-4af1-b9b9-3804e3027d7c",
            actionParams: {},
          },
        ],
      },
    }),
    new JobModel({
      jobName: "Repair SSH",
      sourceName: localized("Repair SSH"),
      jobType: "ACTIONSET",
      message: userName + " repaired SSH",
      data: {
        message: {
          code: "job_repair_ssh",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "7c3d5022-730c-4ab6-872a-a3803a62800b",
      jobStatus: "START_REQUESTED",
      category: "REPAIR_SCRIPTS",
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Repair SSH"),
            actionUid: "7c3d5022-730c-4ab6-872a-a3803a62800b",
            actionParams: {},
          },
        ],
      },
    }),
    new JobModel({
      jobName: "Repair TeamViewer",
      sourceName: localized("Repair TeamViewer"),
      jobType: "ACTIONSET",
      message: userName + " repaired TeamViewer",
      data: {
        message: {
          code: "job_repair_teamviewer",
          params: {
            appUserName: userName,
          },
        },
      },
      identifier: "e204e419-1e19-43ea-8239-d8f354f213a4",
      jobStatus: "START_REQUESTED",
      category: "REPAIR_SCRIPTS",
      mac: true,
      content: {
        actionsetScheduleUid: uuidv4(),
        actions: [
          {
            actionName: localized("Repair TeamViewer"),
            actionUid: "e204e419-1e19-43ea-8239-d8f354f213a4",
            actionParams: {},
          },
        ],
      },
    }),
    ...(isFeatureEnabled("sentinel_one")
      ? [
          new JobModel({
            jobName: localized("SentinelOne Full Disk Scan"),
            runnerActionName: "RUN_ANTIVIRUS_SENTINELONE_FULL_DISK_SCAN",
            jobType: "ANTIVIRUS",
            message: userName + " triggered an antivirus scan (SentinelOne)",
            data: {
              message: {
                code: "sentinel_one_scan",
                params: {
                  appUserName: userName,
                },
              },
            },
            identifier: "d0cf287a-6b38-4e38-a533-1964cf5d8da7",
            sourceUid: "d0cf287a-6b38-4e38-a533-1964cf5d8da7",
            jobStatus: "START_REQUESTED",
            category: "ANTIVIRUS",
            subCategory: "SENTINEL_ONE",
          }),
        ]
      : []),
  ])
}

export const getJobType = jobtype => {
  let icon, jobType, identifier

  switch (jobtype) {
    case 1:
    case "ACTIONSET":
      icon = faWrench
      jobType = localized("Action Set")
      identifier = "ACTIONSET"
      break
    case 2:
    case "ACTION":
      icon = faWrench
      jobType = localized("Action")
      identifier = "ACTION"
      break
    case 3:
    case "CONDITION":
      icon = faBolt
      jobType = localized("Condition")
      identifier = "CONDITION"
      break
    case 4:
    case "CONDITION_ACTIONSET":
      icon = faBolt
      jobType = localized("Condition Action Set")
      identifier = "CONDITION_ACTIONSET"
      break
    case 5:
    case "CONDITION_ACTION":
      icon = faBolt
      jobType = localized("Condition action")
      identifier = "CONDITION_ACTION"
      break
    case 6:
    case "ANTIVIRUS":
      icon = faShieldHalved
      jobType = localized("Antivirus")
      identifier = "ANTIVIRUS"
      break
    case 7:
    case "PATCH_MANAGEMENT":
      icon = faCircleUp
      jobType = localized("Patch management")
      identifier = "PATCH_MANAGEMENT"
      break
    case 8:
    case "TEAMVIEWER":
      icon = faTeamviewer
      jobType = "TeamViewer"
      identifier = "TEAMVIEWER"
      break
    // case 9:
    // case 'MONITOR':
    // case 10:
    // case 'SYSTEM':
    // case 11:
    // case 'COMMENT':
    // case 12:
    // case 'INTERNAL':
    case 15:
    case "SOFTWARE_PATCH_MANAGEMENT":
      icon = faWindowMaximize
      jobType = localized("Software patch management")
      identifier = "SOFTWARE_PATCH_MANAGEMENT"
      break
    case 16:
    case "CLOUDBERRY":
      icon = faCloudArrowDown
      jobType = localized("Restore")
      identifier = "CLOUDBERRY"
      break
    case 17:
    case "CLOUDBERRY_BACKUP":
      icon = faCloudArrowUp
      jobType = localized("Backup")
      identifier = "CLOUDBERRY_BACKUP"
      break
    case 18:
    case "REBOOT_ACTION":
      icon = faRotate
      jobType = localized("Action Set")
      identifier = "ACTIONSET"
      break
    case 19:
    case "VIRTUALIZATION":
      icon = faRotate
      jobType = localized("Virtualization")
      identifier = "VIRTUALIZATION"
      break
    case 27:
    case 34:
    case 100:
    case "LOCKHART":
      icon = faFileExport
      jobType = localized("Backup")
      identifier = "LOCKHART"
      break
    case 30:
    case "NINJA_CONNECT":
      icon = faNinjaControl
      jobType = localized("NinjaOne Remote")
      identifier = "NINJA_CONNECT"
      break
    case 37:
    case NINJA_NETWORK_DISCOVERY:
      icon = faWrench
      jobType = localized("Network Discovery")
      identifier = NINJA_NETWORK_DISCOVERY
      break
    default:
      throw new Error(`Invalid Job Type: ${jobtype}`)
  }
  return {
    icon,
    jobType,
    identifier,
  }
}

export const getJobDetailsParts = job => {
  let jobStatus
  let spinnerComponent = faSpinner
  const parseJobType = getJobType(job.jobType)
  const { jobType, icon, identifier } = parseJobType

  switch (job.jobStatus) {
    case 1001:
    case "START_REQUESTED":
      jobStatus = localized("Start requested")
      break
    case 1002:
    case "STARTED":
      jobStatus = localized("Started")
      break
    case 1003:
    case "IN_PROCESS":
      jobStatus = localized("In Process")
      break
    case 1004:
    case "PENDING":
      jobStatus = localized("Pending Reboot ")
      spinnerComponent = null
      break
    default:
      throw new Error(`Invalid Job Status: ${job.jobStatus}`)
  }

  return {
    icon,
    jobType,
    jobStatus,
    identifier,
    spinnerComponent,
  }
}

const getCancelStatus = (jobTypeId, job) => {
  const restoreType = job.restoreType
  const requestCancel =
    jobTypeId === "LOCKHART" ||
    jobTypeId === "CLOUDBERRY_BACKUP" ||
    jobTypeId === "NINJA_CONNECT" ||
    (jobTypeId === "CLOUDBERRY" &&
      restoreType &&
      (restoreType === "VIRTUAL_DISK" || restoreType === "PHYSICAL_DISK" || restoreType === "FILE_FOLDER"))
  return requestCancel ? "CANCEL_REQUESTED" : "CANCELLED"
}

const lockhartCancelMessages = {
  LOCKHART_BACKUP: "jobs.lockhart_backup_cancelation_request_msg",
  LOCKHART_RESTORE: "jobs.lockhart_restore_cancelation_request_msg",
  LOCKHART_INTEGRITY_CHECK: "jobs.lockhart_integrity_check_cancelation_request_msg",
}

const getCancelOptions = (jobTypeId, job) => {
  const userModel = window.application.get("user")
  const appUserName = userModel.getName()
  const data = {
    message: {
      code: "job_cancel_requested",
      params: {
        appUserName,
      },
    },
  }

  if (isAppInQaMode()) {
    return {
      data,
      message: `User ${appUserName} requested cancelation for job uid '${job.jobUid}' in QA mode`,
    }
  }

  switch (jobTypeId) {
    case "LOCKHART":
      return {
        data,
        message: localized(lockhartCancelMessages[job?.content?.actionType], {
          appUserName,
          planName: job?.content?.planName || job?.content?.sourcePlanName || localized("n/a"),
        }),
      }
    case "NINJA_CONNECT":
      return {
        message: localized("{{appUserName}} canceled this action", { appUserName }),
      }
    default:
      return {
        data,
        message: `${appUserName} ${localized("canceled this action")}`,
      }
  }
}

export const cancelJob = (job, nodeId) => {
  const jobTypeId = getJobType(job.jobType).identifier

  new JobModel({
    jobUid: job.jobUid || job.uid,
    jobType: jobTypeId,
    jobStatus: getCancelStatus(jobTypeId, job),
    jobName: localized("Cancellation Request"),
    ...getCancelOptions(jobTypeId, job),
  }).saveJob(nodeId)
}

export const clearBackupJob = async (nodeId, planUid, jobUid) => {
  try {
    await fetchJson(`/backup/lockhart/nodes/${nodeId}/plans-status/${planUid}/jobs/${jobUid}/ignore`, {
      options: { method: "PUT" },
    })
  } catch (error) {
    showErrorMessage(localized("Error clearing backup job"))
    ninjaReportError(error)
  }
}

export const clearRestoreJob = async (nodeId, jobUid) => {
  try {
    await fetchJson(`/backup/lockhart/nodes/${nodeId}/restore-destination-status/${jobUid}/ignore`, {
      options: { method: "PUT" },
    })
  } catch (error) {
    showErrorMessage(localized("Error clearing restore job"))
    ninjaReportError(error)
  }
}

export const clearIntegrityCheckJob = async jobUid => {
  try {
    await fetchJson(`/backup/lockhart/plans-status/integrity-check-jobs/${jobUid}/ignore`, {
      options: { method: "PUT" },
    })
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error clearing integrity check job"))
  }
}

export const resetCondition = async ({ jobUid, message }, device, allowOffline) => {
  if (allowOffline || isCloudMonitor(device.nodeClass) || isUp(device)) {
    const job = new JobModel({
      jobUid,
      message,
      jobType: "CONDITION",
      jobStatus: "RESET",
      resetReason: "MANUAL",
    })

    try {
      await job.saveJob(device.id)
      refreshDeviceHealthWarnings(device.id)
      refreshCustomerOverview()
    } catch (error) {
      throw error
    }
  }
}

export const renderJobItems = (category, subCategory, action, ButtonComponent) => {
  return window.jobTypeCollection.where({ category, ...(subCategory && { subCategory }) }).map(j => {
    const jobName = j.get("jobName")
    const onClick = () => action(j)

    return (
      <li key={jobName}>
        {ButtonComponent ? (
          <ButtonComponent {...{ onClick, jobName }} />
        ) : (
          <button className="btn-link" onClick={onClick}>
            {jobName}
          </button>
        )}
      </li>
    )
  })
}
