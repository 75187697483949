import styled from "@emotion/styled"
import { memo, useState } from "react"
import { NavLink, Link } from "react-router-dom"
import { css } from "@emotion/react"
import { sizer } from "@ninjaone/utils"
import { Tooltip } from "@ninjaone/components"
import { visuallyHiddenStyles } from "js/includes/components/Styled"
import SubNavItemContent from "./SubNavItemContent"
import NavActionIcons from "./NavActionIcons"

export const StyledLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 2px;
  overflow: hidden;

  &:hover {
    background-color: ${({ theme }) => theme.colorThemeBackgroundHover};
  }

  &:not(:hover):not(:focus-within) .action-icons {
    ${visuallyHiddenStyles}
  }
`

export const linkStyle = theme => css`
  width: 100%;
  padding-left: ${sizer(2)};
  padding-right: ${sizer(2)};
  overflow: hidden;

  && {
    color: ${theme.colorThemeText};
  }

  &.active {
    color: ${theme.colorThemeTextStrong};
  }

  &:focus-visible {
    background-color: ${theme.colorThemeBackgroundHover};
    outline: 2px solid ${theme.colorForegroundFocus};
    outline-offset: -2px;
  }
`

export const actionIconWrapperStyle = css`
  display: flex;
  gap: ${sizer(2)};
  padding-left: ${sizer(1)};
  padding-right: ${sizer(2)};
  margin-left: auto;
  font-size: initial; // reset back so font-awesome sizes work as expected
`

export const actionIconStyle = theme => css`
  display: flex;
  align-items: center;
  height: 16px;
  color: ${theme.colorThemeText};

  &:focus-visible {
    outline: 2px solid ${theme.colorForegroundFocus};
  }
`

const contentStyle = css`
  padding-right: ${sizer(2)};
`

const SubNavLink = memo(({ href = "", icon, name, actionIcons, usesNavLink = true, linkRef }) => {
  const to = href.startsWith("#") ? href.substr(1) : href
  const LinkItem = usesNavLink ? NavLink : Link
  const [textOverflows, setTextOverflows] = useState(false)

  const linkContent = (
    <StyledLinkWrapper>
      <LinkItem {...{ to, css: linkStyle, "aria-label": name, ...(linkRef && { ref: linkRef }) }}>
        <SubNavItemContent {...{ icon, name, contentStyle, actionIcons, onTextOverflow: setTextOverflows }} />
      </LinkItem>
      <div css={actionIconWrapperStyle} className="action-icons">
        <NavActionIcons {...{ actionIcons }} />
      </div>
    </StyledLinkWrapper>
  )

  return textOverflows ? (
    <Tooltip
      {...{
        label: name,
        sideOffset: 12,
        align: "center",
        position: "left",
      }}
    >
      {linkContent}
    </Tooltip>
  ) : (
    linkContent
  )
})

export default SubNavLink
