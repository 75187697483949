import { fetchJson, reportErrorAndShowMessage, localizationKey } from "js/includes/common/utils"

const setHealthStatus = payload => ({
  type: "SET_AT_HEALTH_STATUS",
  payload,
})

export const getHealthStatus = () => async dispatch => {
  try {
    const healthStatus = await fetchJson("/divisionconfig/PSA/status")
    dispatch(setHealthStatus(healthStatus))
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error while fetching health status"))
  }
}
