import moment from "moment"
import { useMemo } from "react"
import { has, prop, propEq, reduce, reject, values } from "ramda"
import { VerticalTabsModal } from "@ninjaone/components"
import { useMountedState } from "js/includes/common/hooks"
import { isDateInPast, localizationKey, localized } from "js/includes/common/utils"
import showModal from "js/includes/common/services/showModal"
import AddScheduledUpdateModal from "./AddScheduledUpdateModal"
import { defaultInheritance } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"
import { scheduledUpdatedTabs } from "./util"
import UpdateModal from "./UpdateModal"
import SkipUpdateModal from "./SkipUpdateModal"

function ManuallyScheduledUpdatesModal({ unmount, manualUpdates, onChange }) {
  const [updates, setUpdates] = useMountedState(manualUpdates)
  const { approvedUpdates, enforcedUpdates, rejectedUpdates, takenVersions } = useMemo(() => {
    return reduce(
      (acc, update) => {
        if (has("approvalDate", update)) {
          acc.approvedUpdates.push(update)
        }
        if (has("rejectionDate", update)) {
          acc.rejectedUpdates.push(update)
        }
        if (has("enforcementDate", update) && isDateInPast(update.enforcementDate)) {
          acc.enforcedUpdates.push(update)
        }
        acc.takenVersions.push(prop("version", update))
        return acc
      },
      {
        approvedUpdates: [],
        enforcedUpdates: [],
        rejectedUpdates: [],
        takenVersions: [],
      },
      values(updates),
    )
  }, [updates])

  const handleUpdates = (identifier, newUpdate) => {
    setUpdates(prevState => {
      return {
        ...prevState,
        [identifier]: newUpdate,
      }
    })
  }

  const handleDelete = version => {
    setUpdates(prevState => reject(propEq("version", version), prevState))
  }

  const handleSaveAdd = values => {
    const newUpdate = {
      action: "APPROVE",
      version: values.version,
      enforcementDate: moment(values.startDate)
        .utcOffset(0, true)
        .valueOf(),
      approvalDate: moment()
        .utc()
        .valueOf(),
      ...defaultInheritance,
    }
    handleUpdates(values.identifier, newUpdate)
  }

  const handleAddUpdate = () => {
    showModal(<AddScheduledUpdateModal onSave={handleSaveAdd} takenVersions={takenVersions} />, { withProvider: true })
  }

  const handleSaveSkip = values => {
    const newUpdate = {
      action: "REJECT",
      version: values.version,
      rejectionDate: moment()
        .utc()
        .valueOf(),
    }
    handleUpdates(values.identifier, newUpdate)
  }

  const handleSkipUpdates = () => {
    showModal(<SkipUpdateModal onSave={handleSaveSkip} takenVersions={takenVersions} />, { withProvider: true })
  }

  const configTabs = [
    {
      label: localized("Approved"),
      renderer: () => (
        <UpdateModal
          alertMessageToken={localizationKey(
            "Devices will be periodically notified to update until the deadline is reached.",
          )}
          onAdd={handleAddUpdate}
          onDelete={handleDelete}
          type={scheduledUpdatedTabs.APPROVED}
          updates={approvedUpdates}
        />
      ),
    },
    {
      label: localized("Rejected"),
      renderer: () => (
        <UpdateModal
          alertMessageToken={localizationKey("NinjaOne will not enforce the following OS versions.")}
          onAdd={handleSkipUpdates}
          onDelete={handleDelete}
          type={scheduledUpdatedTabs.REJECTED}
          updates={rejectedUpdates}
        />
      ),
    },
    {
      label: localized("Enforced"),
      renderer: () => (
        <UpdateModal
          alertMessageToken={localizationKey(
            "Devices will be forced to update to the highest specified version once the deadline has passed.",
          )}
          type={scheduledUpdatedTabs.ENFORCED}
          updates={enforcedUpdates}
        />
      ),
    },
  ]

  const onSave = () => {
    onChange(updates)
    unmount()
  }

  return (
    <VerticalTabsModal
      cancelable
      minHeight={600}
      closeAction={unmount}
      onPrimaryButtonClick={onSave}
      primaryButtonLabel={localizationKey("Apply")}
      tabs={configTabs}
      unmount={unmount}
      tabsAriaLabel={localized("Manually scheduled updates tabs")}
      titleGroup={{
        titleToken: localizationKey("Manually scheduled updates"),
      }}
    />
  )
}

export default ManuallyScheduledUpdatesModal
