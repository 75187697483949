import styled from "@emotion/styled"
import { useTheme } from "@emotion/react"
import { getColorFromProps } from "@ninjaone/utils"
import tokens from "@ninjaone/tokens"
import { getDataVizColorNameFromIndex } from "../utils"

const StyledLegend = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  gap: 14px;
  margin: 0;
  padding: 0;
  font-size: ${tokens.typography.fontSize.bodyXs};
  color: ${({ theme }) => theme.colorTextWeak};
  margin-top: ${tokens.spacing[3]};

  li {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .legend-square {
    width: 12px;
    height: 12px;
    border-radius: 2px;
  }
`

const ChartLegend = ({ payload, enableCustomColors }) => {
  const theme = useTheme()

  return (
    <StyledLegend>
      {payload.map(({ value, color }, index) => (
        <li key={`item-${index}`}>
          <div
            className="legend-square"
            style={{
              background: enableCustomColors
                ? getColorFromProps({}, { color, theme })
                : theme[getDataVizColorNameFromIndex(index)],
            }}
          />
          {value}
        </li>
      ))}
    </StyledLegend>
  )
}

export default ChartLegend
