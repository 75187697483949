import { useCallback, useEffect } from "react"
import { allPass } from "ramda"
import { useSelector } from "react-redux"
import { entityType } from "js/includes/editors/User/Permissions/NinjaPSA/entities"
import { localizationKey, reportErrorAndShowMessage } from "js/includes/common/utils"
import { isGranted } from "js/includes/common/services/userPermissions"
import { getProducts } from "js/includes/common/client"
import { useMountedState } from "js/includes/common/hooks"
import { isFeatureEnabled } from "./app"
import { isNumber, localized } from "./ssrAndWebUtils"

export const isNinjaPSAEnabledFromSettings = () =>
  isFeatureEnabled("ninja_psa") &&
  window.store.getState()?.ninjaPSA?.configurations?.enabled &&
  window.store.getState()?.ninjaPSA?.quickBooks?.enabled

export function useNinjaPSAConfigurations() {
  return useSelector(state => state.ninjaPSA.configurations)
}

export const useNinjaPSAEnabledFromSettings = () => {
  return useSelector(state => state.ninjaPSA.configurations?.enabled && state.ninjaPSA.quickBooks?.enabled)
}

export const isUserAllowedToUseNinjaPSAAdministrativeActions = () => {
  return isFeatureEnabled("ninja_psa") && isGranted({ entityType, permissionName: "Configuration.NinjaPSA.Access" })
}

export const isUserAllowedToViewNinjaPSAProductPrice = () => {
  if (!isFeatureEnabled("ninja_psa")) return

  return (
    isGranted({ entityType, permissionName: "Configuration.NinjaPSA.TicketProducts.Price.Access" }) ||
    isUserAllowedToUseNinjaPSAAdministrativeActions()
  )
}

export const doesUserHaveTicketProductPermission = ({ view, update, create, delete: _delete }) => {
  const permissions = [
    ...(view ? ["Configuration.NinjaPSA.TicketProducts.View"] : []),
    ...(update ? ["Configuration.NinjaPSA.TicketProducts.Update"] : []),
    ...(create ? ["Configuration.NinjaPSA.TicketProducts.Create"] : []),
    ...(_delete ? ["Configuration.NinjaPSA.TicketProducts.Delete"] : []),
  ]

  return (
    isFeatureEnabled("ninja_psa") &&
    (isUserAllowedToUseNinjaPSAAdministrativeActions() ||
      allPass(permissions.map(permissionName => () => isGranted({ entityType, permissionName })))())
  )
}

export const formatNumber = ({ value, withCurrencyStyle = false, currency = "USD" }) => {
  return isNumber(value)
    ? localized(value, {
        minRound: 2,
        maxRound: 2,
        ...(withCurrencyStyle && { styleType: "currency", currency }),
      })
    : "-"
}

export const ninjaPSAWampOperations = {
  invoices: {
    INVOICE_EXPORTED: "INVOICE_EXPORTED",
    INVOICE_EXPORTED_FAILED: "INVOICE_EXPORTED_FAILED",
  },
}

export const useProductCatalog = (avoidFetch = false) => {
  const [productCatalog, setProductCatalog] = useMountedState([])
  const [loading, setLoading] = useMountedState(false)

  const fetchProducts = useCallback(async () => {
    setLoading(true)
    try {
      const productsResponse = await getProducts()
      setProductCatalog(productsResponse)
    } catch (error) {
      reportErrorAndShowMessage(error, localizationKey("Error fetching products"))
    } finally {
      setLoading(false)
    }
  }, [setLoading, setProductCatalog])

  useEffect(() => {
    if (avoidFetch) return
    fetchProducts()
  }, [fetchProducts, avoidFetch])

  return {
    loading,
    productCatalog,
    fetchProducts,
  }
}
