import { assocPath } from "ramda"
import { useDispatch, useSelector } from "react-redux"
import { updatePolicyItem as _updatePolicyItem } from "js/state/actions/policyEditor/editor"
import { Flex } from "js/includes/components/Styled"
import {
  defaultInheritance,
  OSUpdatesHeader,
  Separator,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/appleCommon/OSUpdates/util"
import UserBehaviorSection from "./UserBehaviorSection"
import DeviceBehaviorSection from "./DeviceBehaviorSection"
import EnforcedUpdatesSection from "./EnforcedUpdatesSection"

const OSUpdatesForm = () => {
  const patchManagement = useSelector(state => state.policyEditor.policy.content.patchManagement)
  const nodeRole = useSelector(state => state.policyEditor.policy.nodeRole)
  const parentPolicy = useSelector(state => state.policyEditor.parentPolicy)

  const dispatch = useDispatch()
  const updatePolicyItem = (path, parentPolicy, item) => {
    dispatch(_updatePolicyItem(path, parentPolicy, item))
  }

  const onPolicyChange = (path, value) => {
    const updatedOSForm = assocPath(path.split("."), value, { ...patchManagement, ...defaultInheritance })
    updatePolicyItem(`patchManagement`, parentPolicy, updatedOSForm)
  }
  const { userBehavior, patchOverrides, deviceBehavior } = patchManagement

  return (
    <Flex flex={1} flexDirection="column" overflowY="auto" overflowX="hidden">
      <OSUpdatesHeader />
      <Separator />
      <UserBehaviorSection {...{ userBehavior, onPolicyChange, nodeRole }} />
      <Separator />
      <DeviceBehaviorSection {...{ deviceBehavior, onPolicyChange, nodeRole }} />
      <Separator />
      <EnforcedUpdatesSection {...{ patchOverrides, onPolicyChange }} />
    </Flex>
  )
}

export default OSUpdatesForm
