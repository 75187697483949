import React, { PureComponent } from "react"
import styled from "@emotion/styled"
import { flatten, identity, memoizeWith } from "ramda"
import { faPlay } from "@fortawesome/pro-solid-svg-icons"
import FavoriteButton from "../FavoriteButton"
import {
  localized,
  isVmGuest,
  isMacDevice,
  isWindowsDevice,
  user,
  isFeatureEnabled,
  isRmmDevice,
  isDownWindowsDevice,
  askForReboot,
  canBeRebooted,
  rebootLater,
  localizationKey,
  isMobileDevice,
} from "js/includes/common/utils"
import showModal from "js/includes/common/services/showModal"
import SplashtopConnectModal from "js/includes/components/SplashtopConnectModal"
import {
  getSplashTopAndNinjaConnectProperties,
  getTeamViewerConnectProperties,
  runAdhocJob,
  getGuestSystemForVmGuest,
  getNode,
  getConnectWiseControlProperties,
  launchConnectWiseControl,
  getNodeProductList,
} from "./utils"
import TeamViewerConnectingModal from "js/includes/configuration/integrations/teamViewer/TeamViewerConnectingModal"
import DeviceToolsContextSubMenu from "./DeviceToolsContextSubMenu"
import AsyncComponent from "js/includes/components/AsyncComponent"
import moment from "moment"
import RDPConnectModal from "js/includes/deviceDashboard/RDP/RDPConnectModal"
import { getOrganizationCredentials } from "js/state/actions/credentials"
import { getUsernamePasswordCredentials } from "js/state/selectors/credentials"
import VMJobActionsSubMenu from "./VMJobActionsSubMenu"
import DeviceMenuWakeOnLanButton from "./DeviceMenuWakeOnLanButton"
import MaintenanceMenuItem from "js/includes/deviceSearch/components/DeviceList/DeviceListToolbar/maintanance/MaintenanceMenuItem"
import { getAVProperties, getConnectWiseControlConfigs } from "js/includes/common/client"
import ResetAlertsButton from "js/includes/components/Device/Toolbar/ResetAlertsButton"
import RunADDiscoveryJobMenuItem from "js/includes/components/Device/Toolbar/RunADDiscoveryJobMenuItem"
import NinjaControlClientDownloadModal from "js/includes/components/NinjaControlClientDownloadModal"
import { renderJobItems } from "js/includes/common/_jobs"
import DeviceOsPatchesSubMenu from "js/includes/components/DeviceContextMenu/DeviceOsPatchesSubMenu"
import DeviceSoftwarePatchesSubMenu from "js/includes/components/DeviceContextMenu/DeviceSoftwarePatchesSubMenu"
import CreateScheduledTask from "js/includes/deviceSearch/components/DeviceList/DeviceListToolbar/create/CreateScheduledTask"
import { HoverDropdown, HoverDropdownItem, IconButton } from "@ninjaone/components"
import DeviceRunScriptHoverDropdown from "js/includes/components/DeviceContextMenu/DeviceRunScriptHoverDropdown"
import MobileDeviceSubMenu from "./MobileDeviceSubMenu"
import { MAINTENANCE_SCRIPTS_ID } from "js/includes/vmDashboard/constants"

const StyledWrapper = styled.div`
  ${({ showOnHover, isHovered }) =>
    showOnHover &&
    !isHovered &&
    `
      opacity: 0;

      &:focus-visible,
      &:focus-within,
      &:has([data-ninja-hover-dropdown-button][aria-expanded="true"]) {
        opacity: 1;
      }
  `}
`

const CommandLineModal = AsyncComponent({
  fallback: () => null,
  loader: () => import("js/includes/deviceDashboard/CommandLineModal"),
})

const getConnectAndAVProperties = memoizeWith(identity, async (timestamp, device) => {
  const { id, nodeClass } = device
  const userType = window.application.get("session").get("userType")
  const isWindowsOrMac = isWindowsDevice(nodeClass) || isMacDevice(nodeClass)
  const nodeProducts = await getNodeProductList(id)
  const { enabled, licenseKey } = getConnectWiseControlProperties(nodeProducts)

  const node = isRmmDevice(nodeClass) || isVmGuest(nodeClass) ? await getNode(id) : {}
  const [
    { showSplashTopConnect, splashtopSuuid, showNinjaConnect },
    showTeamViewerConnectV2,
    guestSystem,
    connectWiseControlConfigs,
  ] = await Promise.all([
    ...(isWindowsOrMac
      ? [
          getSplashTopAndNinjaConnectProperties({ ...device, content: node?.content }, nodeProducts),
          getTeamViewerConnectProperties(device),
        ]
      : [{}, false]),
    ...(isVmGuest(nodeClass) ? [getGuestSystemForVmGuest(id)] : [{}]),
    ...(enabled && licenseKey ? [getConnectWiseControlConfigs()] : [{}]),
  ])

  const avProperties = await getAVProperties(node)

  const instanceId = connectWiseControlConfigs?.content?.instanceId
  const url = connectWiseControlConfigs?.content?.url

  return {
    showSplashTopConnect,
    splashtopSuuid,
    showNinjaConnect,
    showTeamViewerConnectV2,
    node,
    userType,
    guestSystem,
    avProperties,
    connectWiseControl: {
      showConnectWiseControl: instanceId && url && user("canConnectToConnectWiseControl"),
      url,
      licenseKey,
    },
  }
})

export default class DeviceQuickActionsContextMenu extends PureComponent {
  state = {
    shouldShowPopover: false,
    showSplashTopConnect: false,
    showTeamViewerConnectV2: false,
    avProperties: {},
    connectWiseControl: {},
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  onShowPopover = shouldShowPopover => {
    if (shouldShowPopover) {
      const { device } = this.props
      const { cacheStart } = this.state
      this.onExecuteRequests && clearTimeout(this.onExecuteRequests)
      this.onExecuteRequests = setTimeout(
        async () => {
          const now = moment()
          const newCacheStart = !cacheStart || now.diff(cacheStart, "seconds") > 30 ? now : cacheStart

          const {
            showSplashTopConnect,
            splashtopSuuid,
            showNinjaConnect,
            node,
            showTeamViewerConnectV2,
            userType,
            guestSystem,
            avProperties,
            connectWiseControl,
          } = await getConnectAndAVProperties(newCacheStart, device)

          this._isMounted &&
            this.setState({
              showSplashTopConnect,
              splashtopSuuid,
              showNinjaConnect,
              showTeamViewerConnectV2,
              userType,
              cacheStart: newCacheStart,
              shouldShowPopover: true,
              guestSystem,
              node,
              avProperties,
              connectWiseControl,
            })
        },
        cacheStart ? 0 : 250,
      )
    } else {
      this._isMounted && this.setState({ shouldShowPopover })
    }
  }

  onShowCommandLine = ({ terminalType, shellPath }) =>
    showModal(
      <CommandLineModal
        {...{
          node: this.state.node,
          terminalType,
          shellPath,
        }}
      />,
    )

  showRdpConnect = () => {
    const { device } = this.props
    return device.isUp && isWindowsDevice(device.nodeClass) && user("canUseRDP") && isFeatureEnabled("rdp")
  }

  openRDPConnectModal = async () => {
    const {
      device: { clientId, id },
    } = this.props
    const { userType } = this.state
    const credentials = await getOrganizationCredentials(clientId)

    showModal(
      <RDPConnectModal
        nodeId={id}
        clientId={clientId}
        hasDefaultCredential={this.state.node?.credential_role_map?.SR_RDP_ACCESS}
        credentials={getUsernamePasswordCredentials(credentials)}
        userType={userType}
      />,
    )
  }

  showCreateScheduledTask = () => user("canCreateScheduledTasks") && this.props.device.nodeType === "AGENT"

  render() {
    const { device, closePopover, hoveredItemId, showOnHover } = this.props
    const {
      userType,
      showSplashTopConnect,
      splashtopSuuid,
      showNinjaConnect,
      shouldShowPopover,
      showTeamViewerConnectV2,
      guestSystem,
      node,
      avProperties,
      connectWiseControl,
    } = this.state
    const { onShowCommandLine, showRdpConnect, openRDPConnectModal, showCreateScheduledTask } = this
    const { id, name, nodeClass, maintenanceMode, isUp, isDomainController, nodeRoleId } = device
    const { avEnabled, productCode } = avProperties
    const { showConnectWiseControl, url, licenseKey } = connectWiseControl

    return (
      <StyledWrapper {...{ showOnHover, isHovered: hoveredItemId === id }}>
        <HoverDropdown
          onMouseEnter={() => this.onShowPopover(true)}
          onFocus={() => this.onShowPopover(true)}
          onCloseRootDropdown={() => {
            closePopover?.()
          }}
          buttonRenderer={({ isOpen, closedViaMouseLeave }) => (
            <IconButton
              icon={faPlay}
              variant="secondary"
              active={isOpen}
              tooltip={localized("Run")}
              hideTooltip={isOpen || closedViaMouseLeave}
              isHoverDropdownTrigger
            />
          )}
          dropdownRenderer={() =>
            shouldShowPopover && (
              <>
                {isRmmDevice(nodeClass) && user("canRunAtLeastOneAdhocScript") && (
                  <DeviceRunScriptHoverDropdown
                    {...{
                      commonPolicy: node?.policyContent,
                      devices: [device],
                      renderPolicyScheduled: true,
                      renderFromLibrary: true,
                    }}
                  />
                )}
                {showCreateScheduledTask() && (
                  <CreateScheduledTask devices={[device]} labelToken={localizationKey("Create Scheduled Task")} />
                )}
                <DeviceOsPatchesSubMenu {...{ node }} />
                <DeviceSoftwarePatchesSubMenu {...{ node }} />
                {isMobileDevice(nodeClass) && user("canUpdateDevices", { nodeClass, nodeRoleId }) && (
                  <MobileDeviceSubMenu {...{ id, nodeClass, nodeRoleId }} />
                )}
                {canBeRebooted({ nodeClass, nodeRoleId, isUp }) && (
                  <HoverDropdown
                    buttonRenderer={({ isOpen }) => (
                      <HoverDropdownItem token={localizationKey("Reboot")} isOpen={isOpen} showArrow isDropdownButton />
                    )}
                    dropdownRenderer={() => (
                      <>
                        <HoverDropdownItem token={localizationKey("Now")} onClick={() => askForReboot([device])} />
                        {user("canCreateScheduledTasks") && (
                          <HoverDropdownItem token={localizationKey("Later")} onClick={() => rebootLater([device])} />
                        )}
                      </>
                    )}
                    placement="right"
                    fullWidth
                  />
                )}
                {showSplashTopConnect && isUp && (
                  <HoverDropdownItem
                    onClick={() =>
                      showModal(<SplashtopConnectModal node={{ nodeId: id, splashtopSuuid }} userType={userType} />)
                    }
                    plainText="Splashtop"
                  />
                )}
                {showTeamViewerConnectV2 && isUp && (
                  <HoverDropdownItem
                    onClick={() => showModal(<TeamViewerConnectingModal {...{ nodeId: id, userType }} />)}
                    plainText="TeamViewer"
                  />
                )}
                {showNinjaConnect && (
                  <HoverDropdownItem
                    onClick={() => showModal(<NinjaControlClientDownloadModal {...{ nodeId: id, userType }} />)}
                    plainText="NinjaOne Remote"
                  />
                )}
                {showRdpConnect() && isUp && (
                  <HoverDropdownItem onClick={() => openRDPConnectModal()} token={localizationKey("Remote Desktop")} />
                )}
                {showConnectWiseControl && isUp && (
                  <HoverDropdownItem
                    onClick={() => launchConnectWiseControl(url, licenseKey, device)}
                    token={localizationKey("ConnectWise Control")}
                  />
                )}
                {avEnabled && productCode && productCode !== "CROWDSTRIKE" && (
                  <HoverDropdown
                    buttonRenderer={({ isOpen }) => (
                      <HoverDropdownItem
                        token={localizationKey("Antivirus")}
                        isOpen={isOpen}
                        showArrow
                        isDropdownButton
                      />
                    )}
                    dropdownRenderer={() => (
                      <ul>
                        {flatten([
                          renderJobItems(
                            "ANTIVIRUS",
                            productCode,
                            job => runAdhocJob(job, device),
                            ({ jobName, onClick }) => <HoverDropdownItem plainText={jobName} onClick={onClick} />,
                          ),
                        ])}
                      </ul>
                    )}
                    placement="right"
                    fullWidth
                  />
                )}
                {isRmmDevice(nodeClass) && isUp && (
                  <DeviceToolsContextSubMenu {...{ device, showADButton: isDomainController, onShowCommandLine }} />
                )}
                {isDownWindowsDevice(device) && user("canUpdateDevices", { nodeClass, nodeRoleId }) && (
                  <DeviceMenuWakeOnLanButton {...{ id, filteredSelectedDevices: [{ id, name }] }} />
                )}
                {user("canUpdateDevicesAndMaintenanceMode", device) && !isMobileDevice(nodeClass) && (
                  <HoverDropdown
                    buttonRenderer={({ isOpen }) => (
                      <HoverDropdownItem
                        token={localizationKey("Maintenance")}
                        isOpen={isOpen}
                        showArrow
                        isDropdownButton
                      />
                    )}
                    dropdownRenderer={() => (
                      <ul>
                        <MaintenanceMenuItem
                          selectedFilteredDevicesIds={[id]}
                          filteredSelectedDevices={[{ id, name, nodeClass, maintenanceMode }]}
                          usesButtonComponent
                        />
                      </ul>
                    )}
                    placement="right"
                    fullWidth
                  />
                )}
                {isVmGuest(nodeClass) && user("canRunScripts", [MAINTENANCE_SCRIPTS_ID]) && (
                  <VMJobActionsSubMenu {...{ guestSystem, node }} />
                )}
                {user("canUpdateDevices", { nodeClass, nodeRoleId }) &&
                  device.nodeStatus?.triggeredConditionsCount > 0 && (
                    <ResetAlertsButton selectedFilteredDevicesIds={[id]} />
                  )}
                <FavoriteButton
                  {...{
                    id,
                    name,
                    nodeClass,
                    type: "DEVICE",
                    ButtonComponent: ({ isFavorite, onClick }) => (
                      <HoverDropdownItem
                        token={
                          isFavorite ? localizationKey("Remove from favorites") : localizationKey("Add to favorites")
                        }
                        onClick={onClick}
                      />
                    ),
                  }}
                />
                {isDomainController && isUp && user("canAccessActiveDirectoryDiscovery") && (
                  <RunADDiscoveryJobMenuItem
                    {...{
                      device,
                      MenuComponent: ({ dropdownItems }) => (
                        <HoverDropdown
                          buttonRenderer={({ isOpen }) => (
                            <HoverDropdownItem
                              token={localizationKey("Run an AD Discovery Job")}
                              isOpen={isOpen}
                              showArrow
                              isDropdownButton
                            />
                          )}
                          dropdownRenderer={() =>
                            dropdownItems.map(({ onClick, token }) => <HoverDropdownItem {...{ onClick, token }} />)
                          }
                          placement="right"
                          fullWidth
                        />
                      ),
                    }}
                  />
                )}
              </>
            )
          }
          loading={!shouldShowPopover}
          dropdownMargin
        />
      </StyledWrapper>
    )
  }
}
