import qs from "qs"
import { pick, defaultTo, find, propEq } from "ramda"
import {
  billingTypes,
  agreementOriginTypes,
} from "js/includes/configuration/integrations/psa/psaProducts/productForm/formCommons"
import { unassignedTicketsBoardUrl } from "js/includes/configuration/integrations/ticketing/utils"
import { localizationKey } from "js/includes/common/utils"

export function createTicketTimeEntry() {
  return {
    remote: true,
    agreementOriginType: agreementOriginTypes.TICKET,
    startDate: Date.now(),
    agreementId: null,
    billingType: billingTypes.BILLABLE,
  }
}

export const getEditTicketPagePath = ({ ticketId, withHashTag = false }) => {
  return `${withHashTag ? "#" : ""}/ticketing/ticket/${ticketId}`
}

export const getNewTicketPagePath = ({ queryParams, withHashTag = false } = {}) => {
  const goBackPath = `#${unassignedTicketsBoardUrl}`
  window.history.pushState({ path: goBackPath }, "", goBackPath)

  const definedQueryParams = pick(["deviceId", "jobUid", "clientId", "formId"], queryParams || {})

  const queryString = qs.stringify(definedQueryParams, { addQueryPrefix: true })
  return `${withHashTag ? "#" : ""}/ticketing/ticket/new${queryString}`
}

export const findDefaultAgreement = (agreements = []) => defaultTo(null, find(propEq("timeDefault", true), agreements))

const errorTokens = {
  "ticket_parent_can_not_be_equal_ticket_child": localizationKey("Cannot assign ticket to the same problem"),
  "cannot_set_inactive_agreement_on_ticket": localizationKey("Cannot set inactive agreement on ticket"),
  "cannot_remove_agreement_from_ticket_with_pending_products": localizationKey(
    "Cannot remove agreement from ticket with pending products",
  ),
  "cannot_set_one_time_billed_agreement_with_pending_products": localizationKey(
    "Cannot set one-time billed agreement while ticket has pending products",
  ),
}
export const getUpdateTicketErrorMessage = resultCode => {
  return errorTokens[resultCode] || localizationKey("Error updating ticket")
}
