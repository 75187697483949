import { fetchJson, showErrorMessage, localized, ninjaReportError, isFeatureEnabled } from "js/includes/common/utils"
import { pathOr } from "ramda"
import { getConnection } from "./connection"

export function setProductType(productCategories) {
  return dispatch => {
    dispatch({ type: "SET_AT_PRODUCT_TYPE", productCategories })
  }
}

const setProductList = payload => ({
  type: "SET_AT_PRODUCT_LIST",
  payload,
})

const setAccountList = payload => ({
  type: "SET_AT_ACCOUNT_LIST",
  payload,
})

const setAccountCriteria = payload => ({
  type: "SET_AT_ACCOUNT_CRITERIA",
  payload,
})

const setProductTypes = payload => ({
  type: "SET_AT_PRODUCT_TYPES",
  payload,
})

const setNodeRoleMappings = payload => ({
  type: "SET_AT_NODE_ROLE_MAPPINGS",
  payload,
})

const setLocationMappings = payload => ({
  type: "SET_AT_LOCATION_MAPPINGS",
  payload,
})

export const setAccountOrganizationMappings = payload => ({
  type: "SET_AT_ACCOUNT_ORGANIZATION_MAPPINGS",
  payload,
})

export const setDeviceDataSyncAttributes = attributes => dispatch => {
  dispatch({
    type: "SET_AT_DEVICE_DATA_SYNC_ATTRIBUTES",
    attributes,
  })
}

export const saveAccountOrganizationMappings = mapping => dispatch => {
  dispatch(setAccountOrganizationMappings(mapping))
}

export const saveNodeRoleMappings = mapping => dispatch => {
  dispatch(setNodeRoleMappings(mapping))
}

export const saveLocationMappings = mapping => dispatch => {
  dispatch(setLocationMappings(mapping))
}

const getListFromListFields = response => {
  const [values] = pathOr([], ["fieldConfig", "pickListFields"], response)
  return (values && values.values) || []
}

const isAutotaskEndpointUnhealthy = error => error?.resultCode === "autotask_is_unhealthy"
export const getProductTypes = () => async dispatch => {
  try {
    const response = await fetchJson("/psaat/v2/setupvalues/product")
    dispatch(setProductTypes(getListFromListFields(response)))
  } catch (error) {
    showErrorMessage(localized("Error fetching product types"))

    if (isAutotaskEndpointUnhealthy(error)) {
      throw error
    }

    if (error?.response?.status !== 403) {
      ninjaReportError(error)
    }
  }
}

export const getAccountCriteria = () => async dispatch => {
  try {
    const response = await fetchJson("/psaat/v2/setupvalues/account")
    dispatch(setAccountCriteria(getListFromListFields(response)))
  } catch (error) {
    showErrorMessage(localized("Error fetching account criteria"))

    if (isAutotaskEndpointUnhealthy(error)) {
      throw error
    }

    if (error?.response?.status !== 403) {
      ninjaReportError(error)
    }
  }
}

// TODO: This action/reducer/state are probably not needed anymore because of last minute changes that require the call to be made directly in the AutotaskAccountMappingModal
export const getAccountList = () => async dispatch => {
  try {
    if (isFeatureEnabled("autotask_big_data")) return

    const { accountList } = await fetchJson("/psaat/v2/accountlist")
    dispatch(setAccountList(accountList))
  } catch (error) {
    showErrorMessage(localized("Error fetching account companies"))

    if (isAutotaskEndpointUnhealthy(error)) {
      throw error
    }

    if (error?.response?.status !== 403) {
      ninjaReportError(error)
    }
  }
}

export const getProductList = () => async dispatch => {
  try {
    if (isFeatureEnabled("autotask_big_data")) return

    const { productList } = await fetchJson("/psaat/v2/productlist")
    dispatch(setProductList(productList))
  } catch (error) {
    showErrorMessage(localized("Error fetching product list"))

    if (isAutotaskEndpointUnhealthy(error)) {
      throw error
    }

    if (error?.response?.status !== 403) {
      ninjaReportError(error)
    }
  }
}

export const getAccountOrganizationsMapping = () => async dispatch => {
  try {
    const accountClientMappings = await fetchJson("/psaat/v3/accountClientMappings")
    dispatch(setAccountOrganizationMappings(accountClientMappings))
  } catch (error) {
    showErrorMessage(localized("Error fetching organizations"))
    throw error
  }
}

export const getNodeRoleMappings = () => async dispatch => {
  try {
    const installedProductCategoryMappings = await fetchJson("/psaat/v3/installedProductCategoryMappings")
    dispatch(setNodeRoleMappings(installedProductCategoryMappings))
  } catch (error) {
    showErrorMessage(localized("Error fetching default node role mappings"))
    throw error
  }
}

export const getLocationMappings = () => async dispatch => {
  try {
    const locationMappings = await fetchJson("/psaat/v3/locationMappings")
    dispatch(setLocationMappings(locationMappings))
  } catch (error) {
    showErrorMessage(localized("Error fetching default node role mappings"))
    throw error
  }
}

export function saveSettings() {
  return async (dispatch, getState) => {
    const {
      Autotask: {
        settings: {
          syncNodes,
          productCategories,
          companyFilterMap,
          deviceDataSyncAttributes,

          accountOrganizationMappings,
          nodeRoleMappings,
          locationMappings,
        },
        helpdeskSettings: { createContact, embedHelpRequestImagesInTickets },
        connection: { username, password },
      },
      defaultTicketTemplateId,
    } = getState().psa

    try {
      const body = JSON.stringify({
        content: {
          enabled: true,
          psaType: "AUTOTASK",

          defaultTicketTemplateId,

          username,
          password,

          syncNodes,
          companyFilterMap,
          productCategories,
          deviceDataSyncAttributes,

          embedHelpRequestImagesInTickets,
          createContact,
        },

        mappings: {
          autotaskAccountClientMappings: accountOrganizationMappings,
          installedProductCategoryMappings: nodeRoleMappings,
          locationMappings,
        },
      })

      await fetchJson("/psaat/v3/settings", { options: { method: "PUT", body } })

      await Promise.all([
        dispatch(getConnection()),
        dispatch(getAccountOrganizationsMapping()),
        dispatch(getProductTypes()),
        dispatch(getProductList()),
        dispatch(getAccountList()),
        dispatch(getAccountCriteria()),
        dispatch(getNodeRoleMappings()),
        dispatch(getLocationMappings()),
      ])
    } catch (error) {
      throw error
    }
  }
}
