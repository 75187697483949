import { Fragment } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import {
  AreaChart as RechartsAreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  Legend,
  Customized,
} from "recharts"
import { useTheme } from "@emotion/react"
import chainPropTypes, { getColorFromProps, isRequiredIf } from "@ninjaone/utils"
import tokens from "@ninjaone/tokens"
import { getDataVizColorNameFromIndex, formatWidthOrHeightForRecharts } from "../utils"
import ChartLegend from "../Components/ChartLegend"
import ChartTooltip from "../Components/ChartTooltip"
import AreaChartTick from "./Components/AreaChartTick"
import EmptyAreaChartLabel from "./Components/EmptyAreaChartLabel"
import { filterLegendPayload, filterTooltipPayload } from "./utils"
import { useAreaChartSizing } from "./hooks"

const defaultXAxisHeight = 30
const defaultYAxisWidth = 50

const StyledAreaChart = styled(RechartsAreaChart)`
  svg[role="application"] {
    &:focus,
    &:focus-visible {
      outline-color: ${({ theme }) => theme.colorForegroundFocus};
    }
  }
`

const AreaChart = ({
  data,
  areas,
  ariaLabel,
  width = "100%",
  height = "230px",
  xAxis = {},
  yAxis = {},
  getTooltipTypeProps,
  showLegend,
  usesCustomColors,
}) => {
  const theme = useTheme()
  const isEmpty = !data.length
  const enableCustomColors = usesCustomColors && areas.every(({ color }) => color)
  const { size, responsiveContainerHandlesSize, AreaChartSizingContainer } = useAreaChartSizing({ width, height })

  if (!xAxis.dataKey && !yAxis.dataKey) {
    console.error("Either `xAxis.dataKey` or `yAxis.dataKey` must be provided to AreaChart")
  }

  if (usesCustomColors && !enableCustomColors) {
    console.error("`usesCustomColors` is enabled but not all areas have a defined color")
  }

  if (isEmpty) {
    return (
      <AreaChartSizingContainer {...(responsiveContainerHandlesSize && { ...size })}>
        <StyledAreaChart
          {...{
            data,
            "aria-label": ariaLabel,
            accessibilityLayer: true,
            ...(!responsiveContainerHandlesSize && { ...size }),
          }}
        >
          <Customized
            component={({ offset }) => <EmptyAreaChartLabel x={offset.left + offset.width / 2} y={offset.height / 2} />}
          />
          <XAxis
            {...{
              height: defaultXAxisHeight,
              domain: [0, 100],
              tick: false,
              tickLine: false,
              axisLine: { stroke: theme.colorBorderWeak },
            }}
          />
          <YAxis
            {...{
              width: defaultYAxisWidth,
              domain: [0, 100],
              tick: false,
              tickLine: false,
              axisLine: { stroke: theme.colorBorderWeak },
              ...(yAxis.label && {
                label: (
                  <Label
                    value={yAxis.label}
                    position="insideLeft"
                    angle={-90}
                    style={{ textAnchor: "middle" }}
                    fill={theme.colorTextWeakest}
                    fontSize={tokens.typography.fontSize.bodyXs}
                  />
                ),
              }),
            }}
          />
        </StyledAreaChart>
      </AreaChartSizingContainer>
    )
  }

  return (
    <AreaChartSizingContainer {...(responsiveContainerHandlesSize && { ...size })}>
      <StyledAreaChart
        {...{
          data,
          accessibilityLayer: true,
          "aria-label": ariaLabel,
          ...(!responsiveContainerHandlesSize && { ...size }),
        }}
      >
        <CartesianGrid strokeDasharray="3 3" stroke={theme.colorBorderWeakest} />
        <XAxis
          {...{
            type: xAxis.type || "category",
            scale: xAxis.scale || "auto",
            height: xAxis.height ? formatWidthOrHeightForRecharts(xAxis.height) : defaultXAxisHeight,
            dataKey: xAxis.dataKey,
            domain: xAxis.domain,
            tick: ({ payload, x, y, textAnchor }) => {
              const value = xAxis.getTickLabel ? xAxis.getTickLabel(payload) : payload.value

              if (!value && value !== 0) return null

              return <AreaChartTick {...{ value, x, y, textAnchor, isXAxis: true }} />
            },
            interval: xAxis.interval || "equidistantPreserveStart",
            tickLine: false,
            minTickGap: 10,
            axisLine: { stroke: theme.colorBorderWeak },
          }}
        />
        <YAxis
          {...{
            type: yAxis.type || "number",
            scale: yAxis.scale || "auto",
            width: yAxis.width ? formatWidthOrHeightForRecharts(yAxis.width) : defaultYAxisWidth,
            dataKey: yAxis.dataKey,
            domain: yAxis.domain,
            tick: ({ payload, x, y, textAnchor }) => {
              const value = yAxis.getTickLabel ? yAxis.getTickLabel(payload) : payload.value

              if (!value && value !== 0) return null

              return <AreaChartTick {...{ value, x, y, dy: 5, textAnchor }} />
            },
            interval: yAxis.interval || "preserveStartEnd",
            tickLine: false,
            minTickGap: 10,
            axisLine: { stroke: theme.colorBorderWeak },
            allowDataOverflow: false,
            ...(yAxis.label && {
              label: (
                <Label
                  value={yAxis.label}
                  position="insideLeft"
                  angle={-90}
                  style={{ textAnchor: "middle" }}
                  fill={theme.colorTextWeakest}
                  fontSize={tokens.typography.fontSize.bodyXs}
                />
              ),
            }),
          }}
        />
        {showLegend && (
          <Legend
            content={({ payload }) => (
              <ChartLegend {...{ payload: filterLegendPayload(payload), enableCustomColors }} />
            )}
          />
        )}
        {!!getTooltipTypeProps && (
          <Tooltip
            offset={12}
            content={({ active, payload }) => (
              <ChartTooltip {...{ active, payload: filterTooltipPayload(payload), getTooltipTypeProps }} />
            )}
          />
        )}
        {areas.map(({ type, dataKey, name, color }, index) => {
          const dataVizColor = enableCustomColors
            ? getColorFromProps({}, { color, theme })
            : theme[getDataVizColorNameFromIndex(index)]

          return (
            <Fragment key={dataKey}>
              {/* fake border needed for a11y color contrast */}
              <Area
                {...{
                  style: { transform: "translateY(-1px)" },
                  stroke: theme.colorBackgroundWidget,
                  fill: "transparent",
                  dataKey,
                  isAnimationActive: false,
                  legendType: "none",
                  tooltipType: "none",
                  dot: false,
                  activeDot: false,
                }}
              />

              <Area
                {...{
                  type,
                  dataKey,
                  name,
                  stroke: dataVizColor,
                  fill: dataVizColor,
                  fillOpacity: 0.3,
                  isAnimationActive: false,
                }}
              />
            </Fragment>
          )
        })}
      </StyledAreaChart>
    </AreaChartSizingContainer>
  )
}

AreaChart.propTypes = {
  /**
   * An array of data objects that the chart will be rendered from.
   */
  data: PropTypes.array.isRequired,
  /**
   * An array of objects defining the areas to be rendered on the chart.
   */
  areas: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * The type of area to render.
       * See [Area type](https://recharts.org/en-US/api/Area#type)
       * for a reference of the accepted value types.
       */
      type: PropTypes.string,
      /**
       * The key used to map values to this area from the data array.
       */
      dataKey: PropTypes.string.isRequired,
      /**
       * The name of the area to be displayed in the legend or for certain tooltips.
       */
      name: PropTypes.string,
      /**
       * The custom color for the area. Should be used with the `usesCustomColors` prop.
       */
      color: PropTypes.string,
    }),
  ).isRequired,
  /**
   * A description of the chart to be used for accessibility purposes.
   */
  ariaLabel: PropTypes.string,
  /**
   * The percentage value of the chart's width (ex: "100%") or a fixed number of pixels (ex: "200px").
   */
  width: PropTypes.string,
  /**
   * The percentage value of the chart's height (ex: "100%") or a fixed number of pixels (ex: "200px").
   */
  height: PropTypes.string,
  /**
   * The configuration options for the X-axis of the chart.
   */
  xAxis: PropTypes.shape({
    /**
     * The type of X-axis to render.
     * Must be 'number' when scale is set to 'time'.
     * See [XAxis type](https://recharts.org/en-US/api/XAxis#type)
     * for a reference of the accepted value types.
     */
    type: chainPropTypes(PropTypes.string, props => {
      if (props.type !== "number" && props.scale === "time") {
        return new Error("xyAxis.type` must be `number` when `xAxis.scale` is `time`")
      }

      return null
    }),
    /**
     * The key of data displayed in the X-axis. Required if not passing a 'dataKey' for the Y-axis.
     */
    dataKey: PropTypes.string,
    /**
     * Determines the range of values that will be displayed on the X-axis.
     * Required when X-axis type is 'number' and scale is 'time'.
     * See [XAxis domain](https://recharts.org/en-US/api/XAxis#domain)
     * for a reference of the accepted value types.
     */
    domain: isRequiredIf(
      PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      ]),
      props => props.type === "number" && props.scale === "time",
      "`xAxis.domain` is required when `xAxis.type` is `number` and `xAxis.scale` is `time`",
    ),
    /**
     * The scale to use for the X-axis.
     * When set to 'time' type must be 'number' and domain is required.
     * See [XAxis scale](https://recharts.org/en-US/api/XAxis#scale)
     * for a reference of the accepted value types.
     */
    scale: PropTypes.string,
    /**
     * Determines the interval between ticks on the X-axis
     * See [YAxis interval](https://recharts.org/en-US/api/XAxis#interval)
     * for a reference of the accepted value types.
     */
    interval: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * A function to customize the tick labels for the X-axis.
     */
    getTickLabel: PropTypes.func,
    /**
     * The height in pixels for the X-axis.
     */
    height: PropTypes.string,
  }).isRequired,
  /**
   * The configuration options for the Y-axis of the chart.
   */
  yAxis: PropTypes.shape({
    /**
     * The type of Y-axis to render.
     * Must be 'number' when scale is set to 'time'.
     * See [YAxis type](https://recharts.org/en-US/api/YAxis#type)
     * for a reference of the accepted value types.
     */
    type: chainPropTypes(PropTypes.string, props => {
      if (props.type !== "number" && props.scale === "time") {
        return new Error("`yAxis.type` must be `number` when `yAxis.scale` is `time`")
      }

      return null
    }),
    /**
     * The key of data displayed in the Y-axis. Required if not passing a 'dataKey' for the X-axis.
     */
    dataKey: PropTypes.string,
    /**
     * Determines the range of values that will be displayed on the Y-axis.
     * Required when Y-axis type is 'number' and scale is 'time'.
     * See [YAxis domain](https://recharts.org/en-US/api/YAxis#domain)
     * for a reference of the accepted value types.
     */
    domain: isRequiredIf(
      PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      ]),
      props => props.type === "number" && props.scale === "time",
      "`yAxis.domain` is required when `yAxis.type` is `number` and `yAxis.scale` is `time`",
    ),
    /**
     * The scale to use for the Y-axis.
     * When set to 'time' type must be 'number' and domain is required.
     * See [YAxis scale](https://recharts.org/en-US/api/YAxis#scale)
     * for a reference of the accepted value types.
     */
    scale: PropTypes.string,
    /**
     * Determines the interval between ticks on the Y-axis
     * See [YAxis interval](https://recharts.org/en-US/api/YAxis#interval)
     * for a reference of the accepted value types.
     */
    interval: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * A function to customize the tick labels for the Y-axis.
     */
    getTickLabel: PropTypes.func,
    /**
     * The label for the Y-axis.
     */
    label: PropTypes.string,
    /**
     * The width in pixels for the Y-axis.
     */
    width: PropTypes.string,
  }),
  /**
   * A function that determines the type of tooltip to be displayed for each data point.
   */
  getTooltipTypeProps: PropTypes.func,
  /**
   * A boolean to determine whether to display the legend.
   */
  showLegend: PropTypes.bool,
  /**
   * A boolean to determine whether custom colors are used.
   */
  usesCustomColors: PropTypes.bool,
}

export default AreaChart
