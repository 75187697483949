import {
  always,
  cond,
  T,
  last,
  propEq,
  map,
  find,
  flatten,
  pluck,
  tail,
  reduce,
  assocPath,
  keys,
  hasPath,
  lensPath,
  set,
  applySpec,
  prop,
} from "ramda"
import moment from "moment"

import {
  isAndroidDevice,
  isAppleMobileDevice,
  isFeatureEnabled,
  isIosDevice,
  isIpadDevice,
  isMacDevice,
  isMobileDevice,
  validations,
} from "js/includes/common/utils"
import { getReadableBytes } from "js/includes/common/_conversions"
import { getNodeRoles } from "js/includes/common/client"
import { NodeType } from "js/includes/common/_enums"
import { dateTimeTz, getDivisionTimeZone, localizationKey, localized } from "js/includes/common/utils/ssrAndWebUtils"
import { canViewMDMConnection } from "js/includes/configuration/integrations/mdm/permissions"
import { MDMPermissionType } from "js/includes/configuration/integrations/mdm/constants"
import {
  defaultAPNsFilterQueryParamName,
  mdmAndroidURL,
  mdmAppleURL,
  MDMConfigTabKey,
  nameFilterQueryParam,
  tabQueryParamName,
} from "js/includes/configuration/integrations/mdm/utils"

export const mdmScopeOptionsEnum = {
  DIVISION: "DIVISION",
  ORGANIZATION: "ORGANIZATION",
  NOT_CONFIGURED: "NOT_CONFIGURED",
}

export const geolocationStatusEnum = {
  DEFAULT: "default",
  RECEIVED: "received",
  UNAVAILABLE: "unavailable",
  LOADING: "loading",
}

export const FeatureFlag = {
  mdm_apple: "mdm_apple",
  mdm_android: "mdm_android",
  mdm_ninja_remote: "mdm_ninja_remote",
  mdm_macos_support: "mdm_macos_support",
  agent_tokenization: "agent_tokenization",
}

export const MDMNodeClass = {
  ANDROID: "ANDROID",
  APPLE_IOS: "APPLE_IOS",
  APPLE_IPADOS: "APPLE_IPADOS",
  MAC: "MAC",
  MAC_SERVER: "MAC_SERVER",
}

export const MDMStatus = {
  ERASED: "ERASED",
}

export const MDMOwnership = {
  PROFILE_OWNER: localizationKey("Profile Owned"),
  PERSONALLY_OWNED: localizationKey("Personally Owned"),
  COMPANY_OWNED: localizationKey("Company Owned"),
  DEVICE_OWNER: localizationKey("Device Owner"),
}

export const isAppleMDMFeatureEnabled = () => isFeatureEnabled(FeatureFlag.mdm_apple)

export const isAndroidMDMFeatureEnabled = () => isFeatureEnabled(FeatureFlag.mdm_android)

export const isMDMNinjaRemoteEnabled = () => isFeatureEnabled(FeatureFlag.mdm_ninja_remote)

export const isMacMDMFeatureEnabled = () => isFeatureEnabled(FeatureFlag.mdm_macos_support)

export const isAgentTokenizationFeatureEnabled = () => isFeatureEnabled(FeatureFlag.agent_tokenization)

/** Has at least one MDM feature (Apple / Android) */
export const isMDMFeatureEnabled = () => isAppleMDMFeatureEnabled() || isAndroidMDMFeatureEnabled()

export const isAppleMDMAppEnabled = () => {
  const appleApp = window.store.getState()?.session?.divisionConfig?.find(({ name }) => name === "MDM_APPLE")

  return !!appleApp?.enabled && isAppleMDMFeatureEnabled()
}

export const isAndroidMDMAppEnabled = () => {
  const androidApp = window.store.getState()?.session?.divisionConfig?.find(({ name }) => name === "MDM_ANDROID")

  return !!androidApp?.enabled && isAndroidMDMFeatureEnabled()
}

/** Has at least one MDM app enabled (Apple / Android) */
export const isMDMAppEnabled = () => isAppleMDMAppEnabled() || isAndroidMDMAppEnabled()

export const isMDMGeolocationEnabled = () => isMDMAppEnabled() && isFeatureEnabled("mdm_geolocation")

export const isMDMDevice = ({ mdmEnrolledAt } = {}) => !!mdmEnrolledAt

/** Device with mobile target */
export const isMDMOnlyDevice = ({ nodeType }) => nodeType === NodeType.MOBILE_TARGET

/** macOS device with MDM set */
export const isMacDeviceWithMDM = ({ mdmEnrolledAt, nodeClass }) => !!mdmEnrolledAt && isMacDevice(nodeClass)

/** Mac device with both agent and MDM set */
export const isMacAgentDeviceWithMDM = device => {
  return isMacDeviceWithMDM(device) && device.nodeType === NodeType.AGENT
}

export const getMobileData = async (content, mobileNode) => {
  const { node } = content
  const { name: friendlyName, datasets, nodeClass, nodeId, description, locationId } = node || {}
  const {
    osVersion,
    osBuildNumber,
    brand,
    model,
    enrollmentType,
    ownership,
    policyCompliant,
    enrollmentTime,
    phoneNumber,
    imei,
    isEncrypted,
    serialNumber,
    deviceKernelVersion,
    deviceType,
    hardware,
    manufacturer,
    name,
  } = datasets?.[0]?.datapoints?.[0]?.data || {}
  const { node: mobileNodeData } = mobileNode
  const {
    ipAddress,
    mdmStatus,
    netbiosName,
    systemName,
    policyContent,
    policyId,
    policyIdSource,
    policyName,
    approvalStatus,
    approvalStatusByAppUserId,
    approvalStatusByAppUserName,
    approvalStatusTime,
    clientId,
    clientName,
    nodeRoleId,
    ownerName,
    ownerUid,
    creationTime,
    contactTime,
    submitTime,
    mdmStatusUpdatedTime,
    locationName,
  } = mobileNodeData
  const nodeRoleData = await getNodeRoles()
  const currentNodeRole = find(propEq("id", nodeRoleId))(nodeRoleData)
  const nodeRole = currentNodeRole?.name ?? ""

  return {
    node: {
      name,
      systemName,
      netbiosName,
      ipAddress,
      friendlyName,
      osVersion,
      osBuildNumber,
      brand,
      model,
      enrollmentType,
      ownership,
      ownerName,
      ownerUid,
      policyCompliant,
      enrollmentTime,
      phoneNumber,
      imei,
      isEncrypted,
      serialNumber,
      deviceKernelVersion,
      deviceType,
      hardware,
      manufacturer,
      nodeClass,
      nodeId,
      description,
      locationId,
      locationName,
      id: nodeId,
      nodeRoleId,
      nodeRole,
      mdmStatus,
      policyContent,
      policyId,
      policyIdSource,
      policyName,
      approvalStatus,
      approvalStatusByAppUserId,
      approvalStatusByAppUserName,
      approvalStatusTime,
      clientId,
      clientName,
      creationTime,
      contactTime,
      submitTime,
      mdmStatusUpdatedTime,
      healthStatus: mobileNode?.healthStatus,
    },
  }
}

export const getMobileDeviceType = cond([
  [isAndroidDevice, always("android")],
  [isIosDevice, always("apple_ios")],
  [isIpadDevice, always("apple_ipados")],
  [isMacDevice, always("MAC")],
  [T, always("mobile")],
])

export const mapMemoryData = resultData => {
  const {
    node: { datasets },
  } = resultData
  const memoryDataset = datasets.find(propEq("dataspecName", "mdm-memory"))

  if (memoryDataset?.datapoints?.length) {
    const { datapoints } = memoryDataset
    if (datapoints?.length) {
      const data = flatten(pluck("volatileData")(tail(datapoints)))
      const groupByMemoryType = data =>
        reduce(
          (groupToMap, m) => {
            groupToMap[m.memoryType].push({
              ...m,
              pct: Math.floor(m.utilizationPercentage * 100),
              total: getReadableBytes(m.capacityBytes),
              used: getReadableBytes(m.usedBytes),
            })
            return groupToMap
          },
          { RAM_MEASURED: [], INTERNAL_STORAGE_MEASURED: [], EXTERNAL_STORAGE_MEASURED: [] },
          data,
        )
      const groupedData = groupByMemoryType(data)
      const summaryData = groupByMemoryType(datapoints[0].staticData)

      const { RAM_MEASURED: memory, INTERNAL_STORAGE_MEASURED: internal, EXTERNAL_STORAGE_MEASURED: external } = map(
        last,
      )(summaryData)
      const {
        RAM_MEASURED: memoryData,
        INTERNAL_STORAGE_MEASURED: internalStorageData,
        EXTERNAL_STORAGE_MEASURED: externalStorageData,
      } = groupedData

      const lastMemory = memoryData && last(memoryData)
      const lastInternalStorageData = internalStorageData && last(internalStorageData)
      const lastExternalStorageData = externalStorageData && last(externalStorageData)
      return {
        memoryData,
        internalStorageData,
        externalStorageData,
        summary: {
          memory: {
            ...memory,
            pct: lastMemory?.pct || 0,
            used: lastMemory?.used || 0,
          },
          internal: {
            ...internal,
            pct: lastInternalStorageData?.pct || 0,
            used: lastInternalStorageData?.used || 0,
          },
          external: {
            ...external,
            pct: lastExternalStorageData?.pct || 0,
            used: lastExternalStorageData?.used || 0,
          },
        },
      }
    }
  }

  return {}
}

export const getDisplayName = node => node?.friendlyName || node?.name || ""

export const getEnabledRestrictions = restrictions => {
  let updatedRestrictions = { ...restrictions }
  const updateRestrictionValues = category =>
    map(key => {
      const restrictionLensPath = lensPath([category, key])
      updatedRestrictions = set(restrictionLensPath, true, updatedRestrictions)
    })(keys(updatedRestrictions[category]))

  map(updateRestrictionValues)(keys(updatedRestrictions))
  return updatedRestrictions
}

export const setRestrictionsValues = (updatedRestrictions, restrictions, restrictionsPolicyConfiguration) => {
  let newRestrictions = {}
  const updateRestrictionValues = category =>
    map(key => {
      const defaultConfiguration = find(propEq("key", key))(restrictionsPolicyConfiguration)
      const defaultValue =
        defaultConfiguration?.valueType === "boolean"
          ? defaultConfiguration?.value === "true"
          : defaultConfiguration?.value || ""
      const restrictionValue = hasPath([category, key], restrictions) ? restrictions[category][key] : defaultValue
      newRestrictions = assocPath([category, key], restrictionValue, newRestrictions)
    })(keys(updatedRestrictions[category]))
  map(updateRestrictionValues)(keys(updatedRestrictions))
  return newRestrictions
}

export const getApplicationsList = ({ node }) => {
  const dataSpec = find(propEq("dataspecName", "mdm-applicationreport"))(node.datasets)
  return dataSpec?.datapoints[0]?.data ?? []
}

export const MDMDataspecName = {
  systemInformation: "mdm-systemInformation",
  memory: "mdm-memory",
  appleSystemUpdate: "mdm-apple-systemUpdate",
  appleCloud: "mdm-apple-cloud",
  appleSecurity: "mdm-apple-security",
  appleNetwork: "mdm-apple-network",
  androidNetwork: "mdm-android-network",
  androidCompliance: "mdm-android-compliance",
  androidSecurity: "mdm-android-security",
}

export const getDetailsMenuItems = nodeClass =>
  [
    {
      label: localized("Compliance"),
      id: "compliance",
      visible: isAndroidDevice(nodeClass),
      defaultDataspecName: MDMDataspecName.androidCompliance,
    },
    {
      label: localized("Security"),
      id: "securityDetailsMenu",
      visible: isMobileDevice(nodeClass) || isMacDevice(nodeClass),
      dataspecs: {
        ANDROID: MDMDataspecName.androidSecurity,
      },
      defaultDataspecName: MDMDataspecName.appleSecurity,
    },
    {
      label: localized("System"),
      id: "system",
      visible: isMobileDevice(nodeClass) || isMacDevice(nodeClass),
      defaultDataspecName: MDMDataspecName.systemInformation,
    },

    {
      label: localized("Memory"),
      id: "memory",
      visible: isMobileDevice(nodeClass) || isMacDevice(nodeClass),
      defaultDataspecName: MDMDataspecName.memory,
    },
    {
      label: localized("Network"),
      id: "network",
      visible: isMobileDevice(nodeClass) || isMacDevice(nodeClass),
      dataspecs: {
        ANDROID: MDMDataspecName.androidNetwork,
      },
      defaultDataspecName: MDMDataspecName.appleNetwork,
    },
    {
      label: localized("Cloud"),
      id: "cloud",
      // Not applicable for Mac MDM devices
      visible: isAppleMobileDevice(nodeClass),
      defaultDataspecName: MDMDataspecName.appleCloud,
    },
    {
      label: localized("System Update"),
      id: "systemupdate",
      visible: isAppleMobileDevice(nodeClass) || isMacDevice(nodeClass),
      defaultDataspecName: MDMDataspecName.appleSystemUpdate,
    },
  ].filter(menu => menu.visible)

export const getDataspecsList = nodeClass =>
  getDetailsMenuItems(nodeClass).map(
    ({ dataspecs, defaultDataspecName }) => dataspecs?.[nodeClass] ?? defaultDataspecName,
  )

const getDMS = (coordinate, direction) => {
  const truncate = number => (number > 0 ? Math.floor(number) : Math.ceil(number))
  const hemisphere = /^[WE]|(?:lon)/i.test(direction) ? (coordinate < 0 ? "W" : "E") : coordinate < 0 ? "S" : "N"

  const absDD = Math.abs(coordinate)
  const degrees = truncate(absDD)
  const minutes = truncate((absDD - degrees) * 60)
  const seconds = ((absDD - degrees - minutes / 60) * Math.pow(60, 2)).toFixed(2)
  return `${degrees}°${minutes}'${seconds}" ${hemisphere}`
}

const formatDivisionDateTime = issuedAt => {
  const divisionTimezone = getDivisionTimeZone()
  const date = moment.unix(issuedAt).tz(divisionTimezone)
  return dateTimeTz(date)
}

export const mapLocation = location => ({
  ...location,
  coordinates: `${getDMS(location.latitude, "lat")}, ${getDMS(location.longitude, "long")}`,
  time: `${formatDivisionDateTime(location.issuedAt)}`,
  // TODO: Add status location when data on BE is ready
  deviceData: localized("Location received"),
})

// SECURITY_LOGS, CERT_SELECTION, NETWORK_ACTIVITY_LOGS (reserved for companion app)
export const androidDelegatedScopesOverrides = [
  {
    value: "CERT_INSTALL",
    labelToken: localizationKey("Grants access to certificate installation and management"),
  },
  {
    value: "MANAGED_CONFIGURATIONS",
    labelToken: localizationKey("Grants access to managed configurations management"),
  },
  {
    value: "BLOCK_UNINSTALL",
    labelToken: localizationKey("Grants access to blocking uninstallation"),
  },
  {
    value: "PERMISSION_GRANT",
    labelToken: localizationKey("Grants access to permission policy and permission grant state"),
  },
  {
    value: "PACKAGE_ACCESS",
    labelToken: localizationKey("Grants access to package access state"),
  },
  {
    value: "ENABLE_SYSTEM_APP",
    labelToken: localizationKey("Grants access for enabling system apps"),
  },
]

export const mapMDMConnectionOptions = (nodeClass, connections = []) => {
  const isAndroid = isAndroidDevice(nodeClass)
  const getValue = connection => (isAndroid ? connection.id : connection.pushCertificateId)
  const getLabel = connection => (isAndroid ? connection.connectionName : connection.name)
  return connections.map(applySpec({ value: getValue, label: getLabel }))
}

export const mapAndroidConnectionOptions = (androidConnections = []) =>
  androidConnections.map(applySpec({ value: prop("id"), labelText: prop("connectionName") }))

export const mdmValidations = {
  /**
   * Email validation following RFC 5321 protocol (https://www.rfc-editor.org/rfc/rfc5321#section-4.5.3.1)
   * Meaning it has additional validation on top of the default email one (e.g.: local-part of email <= 64ch)
   * @param {string} value
   * @param {{ invalidEmailText: string }} options - Optionally pass a custom text when email is invalid
   * @returns {(value) => { success: boolean, message: string | null}}
   */
  email: (value, { invalidEmailText } = {}) => {
    const { success: isEmail, message: defaultInvalidEmailMessage } = validations.email(value)
    const [local] = value.split("@")
    const isValidLength = local.length <= 64

    const getMessage = () => {
      if (!isEmail) return invalidEmailText ?? defaultInvalidEmailMessage
      return isValidLength ? null : localized("Local-part of email exceeds maximum character count")
    }

    return { success: isEmail && isValidLength, message: getMessage() }
  },
}

export const getDisabledTextMDMNinjaRemote = nodeClass => {
  const labels = {
    APPLE_MOBILE: { appName: "NinjaOne MDM Apple", devices: localized("Apple mobile devices") },
    ANDROID: { appName: "NinjaOne MDM Android", devices: localized("Android mobile devices") },
  }

  const appName = labels[nodeClass].appName
  const devices = labels[nodeClass].devices

  return localized("{{appName}} app is disabled, it needs to be enabled to activate remote control for {{devices}}.", {
    appName,
    devices,
  })
}

export const AppleCustomAppType = {
  CUSTOM: "CUSTOM",
  PUBLIC: "PUBLIC",
}

export const getAppleAppName = ({ name, customApp, id, applicationId }) => {
  return customApp === AppleCustomAppType.CUSTOM ? localized("Custom app: {{id}}", { id: id ?? applicationId }) : name
}

export const isMDMMobileDevice = ({ nodeClass }) => isAndroidDevice(nodeClass) || isAppleMobileDevice(nodeClass)

export const getAndroidEnterpriseURL = name => {
  const params = new URLSearchParams({
    [tabQueryParamName]: MDMConfigTabKey.AE,
    [nameFilterQueryParam]: name,
  })
  return `/#${mdmAndroidURL}?${params.toString()}`
}

export const getAppleAPNsURL = name => {
  const params = new URLSearchParams({
    [tabQueryParamName]: MDMConfigTabKey.APN,
    [defaultAPNsFilterQueryParamName]: name,
  })
  return `/#${mdmAppleURL}?${params.toString()}`
}

export const getAppleVPPURL = name => {
  const params = new URLSearchParams({
    [tabQueryParamName]: MDMConfigTabKey.VPP,
    [nameFilterQueryParam]: name,
  })
  return `/#${mdmAppleURL}?${params.toString()}`
}

export const hasMDMConnectionPermission = ({ nodeClass, connectionId }) => {
  if (!isMDMMobileDevice({ nodeClass })) return false

  const permissionType = {
    ANDROID: MDMPermissionType.Android,
    APPLE_IOS: MDMPermissionType.AppleAPN,
    APPLE_IPADOS: MDMPermissionType.AppleAPN,
  }

  return canViewMDMConnection(permissionType[nodeClass], {
    entityId: connectionId,
  })
}

export const getMDMConnectionURL = ({ nodeClass, connectionName }) => {
  if (!isMDMMobileDevice({ nodeClass })) return null
  return isAndroidDevice(nodeClass) ? getAndroidEnterpriseURL(connectionName) : getAppleAPNsURL(connectionName)
}

export const hasAppleMDMAppsAndBooksPermission = ({ nodeClass, appleAppsAndBooksContentTokenId }) => {
  if (!isAppleMobileDevice(nodeClass)) return false

  return canViewMDMConnection(MDMPermissionType.VPP, {
    entityId: appleAppsAndBooksContentTokenId,
  })
}

export const getAppleMDMAppsAndBooksURL = ({ nodeClass, appleAppsAndBooksContentTokenName }) => {
  return isAppleMobileDevice(nodeClass) ? getAppleVPPURL(appleAppsAndBooksContentTokenName) : null
}
