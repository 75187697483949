import styled from "@emotion/styled"
import tokens from "@ninjaone/tokens"
import { Body, Modal } from "@ninjaone/components"
import { VARIANTS } from "@ninjaone/components/src/Button"

import { localizationKey, localized, localizedWith } from "js/includes/common/utils"

const { spacing, borderRadius } = tokens

const StyledList = styled.ul`
  margin-top: ${spacing[4]};
  padding: ${spacing[1]} ${spacing[3]};
  border-radius: ${borderRadius[1]};
  background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeakest};
  max-height: 240px;
  overflow-y: auto;
  list-style-type: none;

  > li {
    padding: ${spacing[1]} 0;
    color: ${({ theme }) => theme.colorTextStrong};
  }
`

const getModalTitle = selectedKeyRules => {
  const keyRulesLength = selectedKeyRules.length
  if (keyRulesLength === 1) {
    return localized("Remove key {{name}}?", { name: selectedKeyRules[0]?.name })
  }
  return localized("Remove all {{length}} keys?", { length: keyRulesLength })
}

const getDeleteConfirmationMessage = selectedKeyRules => {
  const keyRulesLength = selectedKeyRules.length

  const getFinalMessage = ({ text, keyName }) =>
    localizedWith(text, {
      keyName: () => <strong>{keyName}</strong>,
      bold: ({ localizedText }) => <strong>{localizedText}</strong>,
    })

  if (keyRulesLength === 1) {
    const { name } = selectedKeyRules[0]

    return getFinalMessage({
      text:
        "You are about to remove <%keyName>keyName<%>. Once removed and the policy is saved, the key rule <%bold>will be removed from all devices associated with this policy<%>. This action can not be undone.",
      keyName: name,
    })
  }

  const message = localized("the following {{length}} key rules", { length: keyRulesLength })
  return getFinalMessage({
    text:
      "You are about to remove <%keyName>keyName<%>. Once removed and the policy is saved, the key rules <%bold>will be uninstalled from all devices associated with this policy<%>. This action can not be undone.",
    keyName: message,
  })
}

const DeleteKeyRuleModal = ({ unmount, selectedKeyRules = [], removeKeyRule }) => {
  const handleConfirm = () => {
    removeKeyRule(selectedKeyRules)
    unmount()
  }

  return (
    <Modal
      titleGroup={{
        titleText: getModalTitle(selectedKeyRules),
      }}
      descriptionText={getDeleteConfirmationMessage(selectedKeyRules)}
      unmount={unmount}
      cancelable
      buttons={[
        {
          labelToken: localizationKey("Remove"),
          onClick: handleConfirm,
          variant: VARIANTS.DANGER,
        },
      ]}
    >
      <Body textWrap>{getDeleteConfirmationMessage(selectedKeyRules)}</Body>
      {selectedKeyRules.length > 1 && (
        <StyledList>
          {selectedKeyRules.map(({ alias, name }) => (
            <li key={alias}>{name}</li>
          ))}
        </StyledList>
      )}
    </Modal>
  )
}

export default DeleteKeyRuleModal
