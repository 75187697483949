import { Fragment } from "react"
import { ResponsiveContainer } from "recharts"
import { formatWidthOrHeightForRecharts } from "../utils"

export const useAreaChartSizing = ({ width, height }) => {
  const formattedWidth = formatWidthOrHeightForRecharts(width)
  const formattedHeight = formatWidthOrHeightForRecharts(height)
  const responsiveContainerHandlesSize = typeof formattedWidth !== "number" || typeof formattedHeight !== "number"
  const AreaChartSizingContainer = responsiveContainerHandlesSize ? ResponsiveContainer : Fragment

  return {
    AreaChartSizingContainer,
    responsiveContainerHandlesSize,
    size: {
      width: formattedWidth,
      height: formattedHeight,
    },
  }
}
