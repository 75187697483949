import { useSelector } from "react-redux"
import { localizationKey, localized } from "js/includes/common/utils"
import SectionTitle from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/appleCommon/SectionTitle"
import { getPlatform } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/ios/OSUpdates/enforcedUpdatesComponents/ManuallyScheduledUpdatesModal/util"

export const HeaderComponent = () => {
  const platform = useSelector(state => state.policyEditor.policy.nodeRole)

  return (
    <SectionTitle
      titleToken={localizationKey("User behavior")}
      tooltipText={localized(
        "Control when and how users can self-install {{platform}} updates on their own devices. Requires a supervised device.",
        {
          platform: getPlatform(platform),
        },
      )}
    />
  )
}
