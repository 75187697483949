import moment from "moment"
import { isNil, omit, pick, sort } from "ramda"
import momentTZ from "moment-timezone"

import type { Agreement, AgreementContent, AgreementResponse } from "js/includes/common/client/ninjaPSAAgreements"
import type { AgreementTemplateResponse } from "js/includes/common/client/ninjaPSAAgreementTemplates"
import {
  date,
  isNotNil,
  isSameDay,
  localized,
  precisionRound,
  serverScheduleDateFormat,
  validations,
} from "js/includes/common/utils"
import { productQuantityTypeMapper } from "js/includes/customerDashboard/finance/sections/Agreements/productEditorModals/formCommons"
import { getShouldDisableQuantity } from "js/includes/configuration/integrations/psa/psaProducts/productForm/utils"
import {
  ANNUAL,
  MONTHLY,
  QUARTERLY,
  WEEKLY,
} from "js/includes/configuration/integrations/psa/psaProducts/productForm/constants"

type GetInitialValueProps = {
  agreement?: AgreementResponse,
  agreementTemplate?: AgreementTemplateResponse,
}

type InitialValues = Omit<Agreement, "content"> & AgreementContent

const defaultBillingCycles = ["ON_EVERY_BILL_DATE"]

/**
 * Simplifies an agreement product (in the products array returned by the server) or a normal (catalog) product.
 *
 * @param {Object} product. A product.
 *
 * @returns A simplified product.
 */
export const simplifyAgreementProduct = (product, lastOrder = 0) => {
  if (!product.productId) {
    let adHocProduct = product
    if (isNil(adHocProduct.order)) {
      adHocProduct = { ...product, order: lastOrder + 1 }
    }
    return adHocProduct
  }

  return {
    ...(product.id && { id: product.id }),
    name: product.name,
    description: product.description,
    order: product.order || lastOrder + 1,
    type: product.type,
    productId: product.productId,
    price: product.content.price,
    cost: product.content.cost,
    billingCycles: product.content.billingCycles || defaultBillingCycles,
    quantity: product.content.quantity,
    billing: product.content.billing,
    userRoles: product.content.userRoles,
    taxable: product.content.taxable,
  }
}

const sortProducts = sort((a, b) => (a.order > b.order ? 1 : -1))
export const defaultBusinessHours = { twentyFourSeven: true, days: [] }

export function getInitialValues({ agreementTemplate, agreement }: GetInitialValueProps): InitialValues {
  const isEditing = !!agreement
  const initiator = isEditing ? agreement : agreementTemplate

  const products = initiator?.products ?? []
  const laborTicketTimeEntryProducts = initiator?.laborTicketTimeEntryProducts ?? []
  const managedDeviceProducts = initiator?.managedDeviceProducts ?? []

  return {
    name: initiator?.name ?? "",
    description: initiator?.description ?? "",
    invoiceGenerationType: initiator?.invoiceGenerationType ?? "AUTOMATIC",
    interval: initiator?.content?.interval ?? null,
    startDate: initiator?.content?.startDate ?? null,
    endDate: initiator?.content?.endDate ?? null,
    billDate: initiator?.content?.billDate ?? null,
    nextBillDate: initiator?.nextBillDate ?? null,
    businessHours: initiator?.content?.businessHours ?? defaultBusinessHours,
    businessHoursSource: initiator?.businessHoursSource ?? "CUSTOM",
    externalTax: initiator?.externalTax ?? null,
    laborTicketTimeEntryProducts: sortProducts(laborTicketTimeEntryProducts).map(simplifyAgreementProduct),
    managedDeviceProducts: sortProducts(managedDeviceProducts).map(simplifyAgreementProduct),
    products: sortProducts(products).map(simplifyAgreementProduct),
    multiMatchDevices: initiator?.multiMatchDevices ?? false,
    nextBillingPeriodStartDate: initiator?.nextBillingPeriodStartDate ?? null,
    billEmails: initiator?.billEmails ?? [],
    billCcEmails: initiator?.billCcEmails ?? [],
    billBccEmails: initiator?.billBccEmails ?? [],
    dueDays: initiator?.dueDays ?? null,
    dueDaysSource: initiator?.dueDaysSource ?? "INHERIT_FROM_CONFIGURATION",
    invoiceNote: initiator?.invoiceNote ?? "",
    allowOnlineCreditCardPayments: initiator?.allowOnlineCreditCardPayments ?? false,
    allowOnlineAchPayments: initiator?.allowOnlineAchPayments ?? false,
  }
}

const omitAdHocFields = omit(["tempId", "productId", "productQuantityType"])

const parseProductsForServer = products => {
  return products.map((prod, index) => {
    if (!prod.productId) {
      // AdHoc product
      const mappedAdHocProduct = omitAdHocFields(prod)
      mappedAdHocProduct.content.billingCycles = mappedAdHocProduct.content.billingCycles || defaultBillingCycles
      mappedAdHocProduct.order = index + 1
      return mappedAdHocProduct
    }

    return {
      ...(prod.id && { id: prod.id }),
      name: prod.name,
      description: prod.description,
      productId: prod.productId,
      order: index + 1,
      type: prod.type,
      content: {
        price: precisionRound(prod.price, 2),
        cost: precisionRound(prod.cost, 2),
        ...(!getShouldDisableQuantity(productQuantityTypeMapper[prod.type]) && { quantity: prod.quantity }),
        billingCycles: prod.billingCycles || defaultBillingCycles,
        // taxable: prod.taxable, // TODO add this field when server accepts it
      },
    }
  })
}

const nextBillDateIntervals = new Set([ANNUAL, QUARTERLY, MONTHLY, WEEKLY])
export function isNextBillDateEditable({ invoiceGenerationType, interval }) {
  return invoiceGenerationType === "AUTOMATIC" && nextBillDateIntervals.has(interval)
}

export function parseValuesForServer(values: InitialValues): Agreement & { clientId: number } {
  const { interval, invoiceGenerationType } = values
  return {
    name: values.name.trim(),
    description: values.description.trim(),
    content: {
      billDate: values.billDate,
      interval,
      startDate: values.startDate,
      endDate: values.endDate,
      businessHours: values.businessHoursSource === "CUSTOM" ? values.businessHours : null,
    },
    invoiceGenerationType,
    clientId: values.clientId,
    externalTax: values.externalTax,
    laborTicketTimeEntryProducts: parseProductsForServer(values.laborTicketTimeEntryProducts),
    managedDeviceProducts: parseProductsForServer(values.managedDeviceProducts),
    products: parseProductsForServer(values.products),
    multiMatchDevices: values.multiMatchDevices,
    businessHoursSource: values.businessHoursSource,
    nextBillingPeriodStartDate: values.nextBillingPeriodStartDate,
    billEmails: values.billEmails,
    billCcEmails: values.billCcEmails,
    billBccEmails: values.billBccEmails,
    invoiceNote: values.invoiceNote.trim() || null,
    ...(isNextBillDateEditable({ interval, invoiceGenerationType }) && { nextBillDate: values.nextBillDate }),
    dueDays: values.dueDaysSource === "INHERIT_FROM_CONFIGURATION" ? null : values.dueDays,
    dueDaysSource: values.dueDaysSource,
    allowOnlineCreditCardPayments: values.allowOnlineCreditCardPayments,
    allowOnlineAchPayments: values.allowOnlineAchPayments,
  }
}

export function customIntervalValidation(value, { invoiceGenerationType }) {
  if (invoiceGenerationType === "AUTOMATIC") {
    return validations.required(value)
  }

  return { success: true, message: "" }
}

export function customBillDateValidation(value, { interval, invoiceGenerationType, startDate }) {
  if (interval === "ONE_TIME" && !isNil(startDate) && moment(startDate).isAfter(value)) {
    return { success: false, message: localized("Billing date must be equal to or after the start date") }
  }
  if (invoiceGenerationType === "AUTOMATIC" || (invoiceGenerationType === "MANUAL" && !isNil(interval))) {
    return validations.required(value)
  }
  return { success: true, message: "" }
}

export function cloneBillDateValidation(value, { startDate }) {
  if (isNotNil(startDate) && moment(startDate).isAfter(value)) {
    return { success: false, message: localized("Billing date must be equal to or after the start date") }
  }
  return validations.required(value)
}

export function overrideFieldsInCatalogProduct(catalogProduct, agreementProduct) {
  return {
    ...catalogProduct,
    name: agreementProduct.name,
    description: agreementProduct.description,
    content: {
      ...catalogProduct.content,
      cost: agreementProduct.cost,
      price: agreementProduct.price,
      quantity: agreementProduct.quantity,
      taxable: agreementProduct.taxable,
    },
  }
}

export function overrideFieldsInAgreementProduct(agreementProduct, catalogProduct) {
  return {
    ...agreementProduct,
    name: catalogProduct.name,
    description: catalogProduct.description,
    price: catalogProduct.content.price,
    cost: catalogProduct.content.cost,
    quantity: catalogProduct.content.quantity,
    taxable: catalogProduct.content.taxable,
  }
}

const weekdayIndexes = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 0,
}

export const scheduleDateToDate = scheduleDate => {
  const [year, month, day] = scheduleDate.split("-")
  return new Date(+year, month - 1, +day)
}

const scheduleDateToMoment = scheduleDate => {
  return moment(scheduleDate, serverScheduleDateFormat)
}

export const dateToScheduleDate = date => {
  return moment(date).format(serverScheduleDateFormat)
}

/**
 * Return true if date1 is after date2.
 *
 * @param {String} date1 a date in format YYYY-MM-DD
 * @param {String} date2 a date in format YYYY-MM-DD
 * @returns
 */
export const isScheduleDateAfter = (date1, date2) => {
  const momentDate1 = scheduleDateToMoment(date1)
  const momentDate2 = scheduleDateToMoment(date2)
  return momentDate1.isAfter(momentDate2)
}

/**
 * Return true if date1 is before date2.
 *
 * @param {String} date1 a date in format YYYY-MM-DD
 * @param {String} date2 a date in format YYYY-MM-DD
 * @returns
 */
export const isScheduleDateBefore = (date1, date2) => {
  const momentDate1 = scheduleDateToMoment(date1)
  const momentDate2 = scheduleDateToMoment(date2)
  return momentDate1.isBefore(momentDate2)
}

const isLastDayOfMonthSelectable = ({ billDate: _billDate, date }) => {
  const billDate = typeof _billDate === "number" ? _billDate : _billDate.getDate()

  const currentDate = date.getDate()
  const dateOfEndOfMonth = moment(date)
    .endOf("month")
    .get("date")

  return currentDate === dateOfEndOfMonth && currentDate < billDate
}

const isBillDateTheSameAsEndDate = ({ date, endDate }) => {
  return isSameDay(date, endDate)
}

export const isNextBillDateValid = ({ interval, date, billDate, endDate }) => {
  const response = {
    isValid: true,
    errorMessage: "",
  }

  if (billDate) {
    //This is needed to verify that the next bill date is valid when is the end date
    const parsedEndDate = endDate ? momentTZ(endDate).toDate() : null
    const isSameAsEndDate = parsedEndDate ? isBillDateTheSameAsEndDate({ endDate: parsedEndDate, date }) : false

    if ([QUARTERLY, MONTHLY].includes(interval)) {
      response.isValid =
        date.getDate() === +billDate || isLastDayOfMonthSelectable({ billDate: +billDate, date }) || isSameAsEndDate

      response.errorMessage = response.isValid ? "" : localized("Must match day of month")
    } else if (interval === ANNUAL) {
      const parsedBillDate = momentTZ(billDate).toDate()

      const isSameDay = date.getDate() === parsedBillDate.getDate()
      const isSameMonth = date.getMonth() === parsedBillDate.getMonth()

      response.isValid =
        (isSameDay && isSameMonth) || isLastDayOfMonthSelectable({ billDate: parsedBillDate, date }) || isSameAsEndDate
      response.errorMessage = response.isValid ? "" : localized("Must match both day and month")
    } else if (interval === WEEKLY) {
      response.isValid = date.getDay() === weekdayIndexes[billDate] || isSameAsEndDate
      response.errorMessage = response.isValid ? "" : localized("Must match day of week")
    }
  }
  return response
}

const isScheduleNextBillDateValid = ({ interval, scheduleDate, billDate, endDate }) => {
  return isNextBillDateValid({ interval, date: scheduleDateToDate(scheduleDate), billDate, endDate })
}

const pickNextBillDateRelatedFields = pick([
  "interval",
  "invoiceGenerationType",
  "billDate",
  "startDate",
  "endDate",
  "nextBillDate",
])

const successValidation = { success: true, message: "" }

const buildNextBillDateValidation = ({ serverTodayScheduleDate }) => (scheduleNextBillDate, values, validationData) => {
  const finalValues = {
    ...pickNextBillDateRelatedFields(values),
    nextBillDate: scheduleNextBillDate,
    ...validationData,
  }
  const { interval, invoiceGenerationType, billDate, startDate, endDate, nextBillDate } = finalValues

  if (!nextBillDate || !isNextBillDateEditable({ interval, invoiceGenerationType })) {
    return successValidation
  }
  if (!isScheduleDateAfter(nextBillDate, serverTodayScheduleDate)) {
    return {
      success: false,
      message: localized("Must be after {{min}}", {
        min: date(moment(serverTodayScheduleDate, serverScheduleDateFormat)),
      }),
    }
  }
  if (startDate && isScheduleDateBefore(nextBillDate, startDate)) {
    return { success: false, message: localized("Must not be before start date") }
  }
  if (endDate && isScheduleDateAfter(nextBillDate, endDate)) {
    return { success: false, message: localized("Must not be after end date") }
  }

  const validationResponse = isScheduleNextBillDateValid({ interval, scheduleDate: nextBillDate, billDate, endDate })
  return {
    success: validationResponse.isValid,
    message: validationResponse.errorMessage,
  }
}

const validateName = (value, _, validationData) => {
  const requiredValidation = validations.required(value)
  if (!requiredValidation.success) return requiredValidation

  const success = !validationData?.isTaken
  return { success, message: success ? "" : localized("Agreement name already taken within Organization") }
}

export const getServerBasedTodayScheduleDate = timezone => {
  // This is today but using the specific (server) timezone
  const today = momentTZ()
  if (timezone) {
    today.tz(timezone)
  }
  return today.endOf("day").format(serverScheduleDateFormat)
}

const startDateValidation = (scheduleStartDate, values, validationData) => {
  const finalValues = {
    ...values,
    startDate: scheduleStartDate,
    ...validationData,
  }

  const requiredResponse = validations.required(finalValues.startDate)
  if (!requiredResponse.success) {
    return requiredResponse
  }

  const isValid = !finalValues.endDate || isScheduleDateBefore(scheduleStartDate, finalValues.endDate)
  return {
    success: isValid,
    message: isValid ? "" : localized("Must be before end date"),
  }
}

export const buildFormValidations = ({ initialValues, serverTodayScheduleDate }) => {
  return {
    interval: customIntervalValidation,
    billDate: customBillDateValidation,
    name: validateName,
    startDate: startDateValidation,
    invoiceGenerationType: validations.required,
    businessHoursSource: validations.required,
    nextBillDate: buildNextBillDateValidation({
      serverTodayScheduleDate,
    }),
  }
}

export const buildOnChangeAndRunValidationsHandler = ({ sourceField, onChange, runValidations }) => {
  return newValues => {
    onChange(newValues)
    runValidations?.({ sourceField, newValues })
  }
}
