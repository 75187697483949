import { useEffect } from "react"
import { connect } from "react-redux"
import { compose, forEach, keys, mergeRight, prop } from "ramda"
import { ConfirmationModal, DataTable, TitleGroup } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { cellTypes } from "@ninjaone/components/src/DataTable"
import { getPolicyEnforcementOptions } from "js/includes/common/client"
import showModal from "js/includes/common/services/showModal"
import {
  isNotNil,
  localizationKey,
  localized,
  reportErrorAndShowMessage,
  showWarningMessage,
} from "js/includes/common/utils"
import { useMountedState } from "js/includes/common/hooks"
import { Box } from "js/includes/components/Styled"
import {
  deletePolicyItem as _deletePolicyItem,
  updatePolicyItem as _updatePolicyItem,
  revertPolicySection as _revertPolicySection,
} from "js/state/actions/policyEditor/editor"
import PolicyEnforcementModal, { blockScopesOptions, rulesToArray } from "./PolicyEnforcementModal"
import {
  defaultInheritance,
  getActiveStatusColumn,
  getOverrideStateColumn,
  getStatusActions,
  isInheritedRow,
  isNotOverriddenRow,
} from "./util"

const blockScopeAccesor = ({ blockAction: { blockScope } }) => {
  const { labelToken } = blockScopesOptions.find(({ value }) => value === blockScope)
  return localized(labelToken)
}

const allSettingsSet = (rules, settingNameOptions) => keys(rules).length === settingNameOptions.length

function PolicyEnforcement({ rules, updatePolicyItem, deletePolicyItem, parentPolicy, revertPolicySection }) {
  const [loadingOptions, setLoadingOptions] = useMountedState(false)
  const [settingNameOptions, setSettingNameOptions] = useMountedState([])

  useEffect(() => {
    ;(async () => {
      try {
        setLoadingOptions(true)
        const settingNames = await getPolicyEnforcementOptions()
        setSettingNameOptions(settingNames)
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Error fetching enforcement rules options"))
      } finally {
        setLoadingOptions(false)
      }
    })()
  }, [setSettingNameOptions, setLoadingOptions])

  const saveRule = (rule, key) => {
    const enforcementKey = key ?? rule.settingName
    const newRule = mergeRight({ active: true, ...defaultInheritance })(rule)
    updatePolicyItem(`enforcementRules.${enforcementKey}`, parentPolicy, newRule)
  }

  const addEnforcement = () => {
    if (allSettingsSet(rules, settingNameOptions)) {
      showWarningMessage(
        localizationKey("Enforcement rules already set for all settings. Edit o delete one from the list"),
      )
      return
    }
    showModal(<PolicyEnforcementModal {...{ rules, settingNameOptions }} onSave={saveRule} />)
  }

  const editEnforcement = rule => {
    showModal(
      <PolicyEnforcementModal
        {...{ rule, rules, settingNameOptions }}
        onSave={updatedRule => saveRule(updatedRule, rule.settingName)}
      />,
    )
  }

  const deleteEnforcement = ({ settingName }) => {
    showModal(
      <ConfirmationModal
        type="danger"
        titleToken={localizationKey("Are you sure you want to delete your selection?")}
        actionToken={localizationKey("Delete")}
        onConfirm={({ unmount }) => {
          deletePolicyItem(`enforcementRules.${settingName}`)
          unmount?.()
        }}
      />,
    )
  }

  const isChildPolicy = isNotNil(parentPolicy)

  const columns = [
    {
      id: "settingName",
      Header: localized("Setting Name"),
      accessor: ({ labelToken }) => localized(labelToken),
      getCellTypeProps: rule => {
        return {
          type: cellTypes.BUTTON_LINK,
          props: {
            onClick: () => editEnforcement(rule),
          },
        }
      },
    },
    {
      id: "blockScope",
      Header: localized("Block Scope"),
      accessor: blockScopeAccesor,
    },
    ...(isChildPolicy ? [getActiveStatusColumn(), getOverrideStateColumn()] : []),
  ]

  const changeRuleStatus = rule => {
    updatePolicyItem(`enforcementRules.${rule.settingName}`, parentPolicy, {
      ...rule,
      active: rule.active === false,
    })
  }

  return (
    <>
      <Box marginLeft={tokens.spacing[6]} marginBottom={tokens.spacing[3]}>
        <TitleGroup
          titleToken={localizationKey("Policy enforcements")}
          descriptionToken={localizationKey("Manage policy enforcement rules.")}
        />
      </Box>
      <Box marginLeft={tokens.spacing[6]} height="80vh">
        <DataTable
          {...{ columns }}
          rows={rulesToArray(rules)}
          tableId="policy-enforcements-table"
          noRowsToken={localizationKey("No enforcement policies added")}
          loading={loadingOptions}
          actions={{
            primary: [
              {
                labelToken: localizationKey("Revert overrides"),
                action: compose(
                  forEach(rule => revertPolicySection(`enforcementRules.${rule.settingName}`, rule)),
                  prop("selected"),
                ),
                hideRowAction: rule => !isInheritedRow(rule) || isNotOverriddenRow(rule),
              },
              ...getStatusActions(changeRuleStatus, isChildPolicy),
              {
                action: ({ selected: [value] }) => editEnforcement(value),
                labelToken: localizationKey("Edit"),
                splitAfter: true,
              },

              {
                action: ({ selected: [value] }) => deleteEnforcement(value),
                labelToken: localizationKey("Delete"),
                variant: "danger",
                hideRowAction: isInheritedRow,
                isRed: true,
              },
            ],
            row: {
              action: rowItem => editEnforcement(rowItem),
            },
          }}
          globalActionsButton={{
            disabled: loadingOptions,
            buttonProps: { action: addEnforcement },
          }}
          showSearchBar={false}
          hideSettingsButton
          hideCheckboxes
        />
      </Box>
    </>
  )
}

export default connect(
  ({ policyEditor }) => ({
    rules: policyEditor.policy.content.enforcementRules,
    parentPolicy: policyEditor.parentPolicy,
  }),
  {
    updatePolicyItem: _updatePolicyItem,
    deletePolicyItem: _deletePolicyItem,
    revertPolicySection: _revertPolicySection,
  },
)(PolicyEnforcement)
