import PropTypes from "prop-types"
import { DateTimePicker, Modal, Select } from "@ninjaone/components"
import { spacing } from "@ninjaone/tokens"
import { validateDateConstraints } from "@ninjaone/utils"
import { useForm } from "js/includes/common/hooks"
import { validations } from "js/includes/components/CustomFields/common/validation"
import { localizationKey, localized, setUTCOffset } from "js/includes/common/utils"
import Loading from "js/includes/components/Loading"
import { Box, Flex } from "js/includes/components/Styled"
import { disabledDays } from "js/includes/components/CustomFields/common"
import useAvailableVersions from "./hooks/useAvailableVersions"
import { getIdentifierByVersion } from "./util"

function AddScheduledUpdateModal({ unmount, onSave, selectedDate, takenVersions }) {
  const { versions, isLoading, nodeClassText } = useAvailableVersions(takenVersions)
  const { values, validation, validateForm, onChange } = useForm({
    fields: {
      startDate: "",
      version: "",
    },
    validate: {
      startDate: value => {
        if (!value) return { success: false, message: localized("Date and time are required") }
        const isValidDate = validateDateConstraints({
          startDate: value,
          disabledDays: {
            before: setUTCOffset(new Date(), 0),
          },
        })
        return {
          success: isValidDate,
          message: isValidDate ? "" : localized("Invalid date. The selected date and time cannot be in the past"),
        }
      },
      version: validations.required,
    },
    validateOnChange: true,
  })

  return (
    <Modal
      titleGroup={{
        titleText: localized("Schedule update for a specific {{nodeClassText}} version", {
          nodeClassText,
        }),
      }}
      size="md"
      unmount={unmount}
      buttons={[
        {
          labelToken: localizationKey("Add"),
          onClick: () => {
            if (validateForm()) {
              const newUpdate = {
                ...values,
                identifier: getIdentifierByVersion(values.version, versions),
              }
              onSave?.(newUpdate)
              unmount()
            }
          },
          variant: "primary",
        },
      ]}
      cancelable
    >
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Loading />
        </Box>
      ) : (
        <Flex flexDirection="column" gap={spacing[2]}>
          <Select
            labelId="edit-version"
            placeholderToken={localizationKey("Select version")}
            labelText={localized("Version")}
            value={values.version}
            onChange={value => onChange("version", value)}
            options={versions}
            disabled={isLoading}
            errorMessage={validation.message.version}
          />
          <Box marginTop={spacing[2]}>
            <DateTimePicker
              labelText={localized("Enforcement deadline")}
              id="timer-start-date"
              selectedDate={selectedDate}
              onDateTimeChange={startDate => onChange("startDate", startDate?.valueOf())}
              errorMessage={validation.message.startDate}
              disabledDays={disabledDays({ dateFilters: { type: "TODAY_AND_FUTURE_DAYS_ONLY" } })}
            />
          </Box>
        </Flex>
      )}
    </Modal>
  )
}
export default AddScheduledUpdateModal

AddScheduledUpdateModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  selectedDate: PropTypes.object,
  takenVersions: PropTypes.array.isRequired,
}
