import { repeat } from "ramda"

export const PAGE_SIZE_LIMIT = 150
export const MANAGED_DEVICES = "MANAGED"
export const UNMANAGED_DEVICES = "UNMANAGED"
export const SYSTEM_LEVEL = "system"
export const CUSTOMER_LEVEL = "customer"
export const DEVICE_LEVEL = "device"

export const initialSeverityFilters = ["CRITICAL", "HIGH", "MEDIUM", "LOW", "NONE"]

export const SKELETON_ROWS = count => repeat("100%", count)

export const MAX_PRODUCTS_DISPLAYED = 75
