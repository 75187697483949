import { useState, useEffect, useMemo, useRef } from "react"
import styled from "@emotion/styled"
import { v4 as uuidv4 } from "uuid"
import PropTypes from "prop-types"

import { localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils/localization/autocomplete"
import { Box, Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"
import tokens from "@ninjaone/tokens"

import DatePicker from "../DatePicker/DatePicker"
import TimePicker from "../TimePicker/TimePicker"
import { Label } from "../../Form/Label"

import InputActions from "../../Form/InputActions"
import {
  TOKEN_ERROR_MSG_DATETIME_REQUIRED,
  TOKEN_ERROR_MSG_DATE_REQUIRED,
  TOKEN_ERROR_MSG_TIME_REQUIRED,
} from "../errorMessages"

const StyledBox = styled(Box)`
  &[disabled] {
    cursor: not-allowed;
  }

  &:not([disabled]):focus,
  &:not([disabled]):focus-within {
    border-radius: ${tokens.borderRadius[1]};
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }

  #clearBtn {
    display: none;
  }

  ${({ hasValue }) =>
    hasValue &&
    `
    &:hover,
    &:focus,
    &:focus-within {
      #clearBtn {
        display: block;
      }
    }
  `}
`

const StyledFlex = styled(Flex)`
  position: relative;
  gap: ${tokens.spacing[6]};
  padding-right: 56px;
  border-radius: ${tokens.borderRadius[1]};
  border: 1px solid
    ${({ theme, disabled, error }) => (error && !disabled ? theme.colorBorderError : theme.colorBorderWeak)};
  background-color: ${({ disabled, theme }) => (disabled ? theme.colorBackgroundInputDisabled : theme.colorBackground)};

  &:after {
    content: "";
    position: absolute;
    top: 9px;
    left: calc(50% - 27px);
    width: 1px;
    height: 21px;
    background-color: ${({ theme }) => theme.colorBorderWeakest};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colorBackgroundInputDisabled};
    cursor: not-allowed;
  }

  & > div {
    width: 50%;

    input[type="text"] {
      height: 36px;
      padding-bottom: calc(${tokens.spacing[2]} - 1px);
    }
  }
`

const getErrorMessage = ({ date, time }) => {
  if (!time && !date) {
    return TOKEN_ERROR_MSG_DATETIME_REQUIRED
  }
  if (!time) {
    return TOKEN_ERROR_MSG_TIME_REQUIRED
  }
  if (!date) {
    return TOKEN_ERROR_MSG_DATE_REQUIRED
  }

  return null
}

const getDateTime = ({ date, time }) => {
  const hours = time.getHours()
  const minutes = time.getMinutes()
  date.setHours(hours)
  date.setMinutes(minutes)
  return date
}

const DateTimePicker = props => {
  const {
    disabled,
    errorMessage: error,
    labelText,
    onClearClick,
    onDateTimeChange,
    onDayChange,
    onError,
    onTimeChange,
    selectedDate,
    selectedTime,
    tooltipText,
    width,
  } = props
  const [dateTimeError, setDateTimeError] = useState(null)
  const [time, setTime] = useState(selectedTime)
  const [date, setDate] = useState(selectedDate)
  const containerRef = useRef(null)

  const uuid = useMemo(() => {
    return uuidv4()
  }, [])

  const handleBlur = e => {
    // only run when blur occurs outside component when enabled
    if (disabled || e.currentTarget.contains(e.relatedTarget)) return
    const error = getErrorMessage({ date, time })
    setDateTimeError(error)
    onError?.(error)
  }

  const handleDateChange = ({ date }) => {
    setDate(date)
    onDayChange?.({ date })

    if (!time && dateTimeError === TOKEN_ERROR_MSG_DATETIME_REQUIRED) {
      setDateTimeError(TOKEN_ERROR_MSG_TIME_REQUIRED)
      onError?.(TOKEN_ERROR_MSG_TIME_REQUIRED)
    }

    if (time) {
      const dateTime = getDateTime({ date, time })
      onDateTimeChange?.(dateTime)
      onError?.(null)
      setDateTimeError(null)
    }
  }

  const handleTimeChange = time => {
    setTime(time)
    onTimeChange?.(time)

    if (!date && dateTimeError === TOKEN_ERROR_MSG_DATETIME_REQUIRED) {
      setDateTimeError(TOKEN_ERROR_MSG_DATE_REQUIRED)
      onError?.(TOKEN_ERROR_MSG_DATE_REQUIRED)
    }

    if (date) {
      const dateTime = getDateTime({ date, time })
      onDateTimeChange?.(dateTime)
      onError?.(null)
      setDateTimeError(null)
    }
  }

  const handleClearClick = () => {
    onClearClick?.()
    onDateTimeChange?.(null)
    onDayChange?.({ date: null })
    onTimeChange?.(null)
    onError?.(null)
    setTime(null)
    setDate(null)
    setDateTimeError(null)
    containerRef.current.focus()
  }

  useEffect(() => {
    setDate(selectedDate)
    setTime(selectedTime)
  }, [selectedDate, selectedTime])

  return (
    <>
      {labelText && (
        <Label
          labelFor={uuid}
          required
          {...{
            disabled,
            labelText,
            tooltipText,
          }}
        />
      )}

      <StyledBox
        minWidth="346px"
        onBlur={handleBlur}
        position="relative"
        ref={containerRef}
        tabIndex={0}
        width={width || "100%"}
        id={uuid}
        {...{ disabled, hasValue: date ?? time }}
      >
        <StyledFlex error={error || dateTimeError} {...{ disabled }}>
          <DatePicker
            {...props}
            ariaLabel={localized("Date")}
            ariaErrorId={`${uuid}-error`}
            errorMessage={dateTimeError === TOKEN_ERROR_MSG_TIME_REQUIRED ? null : dateTimeError}
            fullWidth
            isDateTime
            labelText={null}
            onDayChange={handleDateChange}
            required={!disabled}
            selectedDays={date}
            useDropdownNavigation
            readOnly
          />
          <TimePicker
            {...props}
            ariaLabel={localized("Time")}
            ariaErrorId={`${uuid}-error`}
            errorMessage={dateTimeError === TOKEN_ERROR_MSG_DATE_REQUIRED ? null : dateTimeError}
            isDateTime
            labelText={null}
            onTimeChange={handleTimeChange}
            required={!disabled}
            portal={false}
            value={time}
          />
        </StyledFlex>

        <InputActions
          onClearClick={handleClearClick}
          ariaErrorId={uuid}
          error={error || dateTimeError}
          tooltipAlignment="end"
          {...{ disabled }}
        />
      </StyledBox>
    </>
  )
}

DateTimePicker.propTypes = {
  /**
   * The disabled state for the component.
   */
  disabled: PropTypes.bool,
  /**
   * The error message passed from the parent component.
   */
  errorMessage: PropTypes.string,
  /**
   * The label for the component.
   */
  labelText: PropTypes.string,
  /**
   * The language for the component. Use when organization or division language
   * should override the language from the user's settings.
   */
  locale: PropTypes.string,
  /**
   * The event handler run when the clear button is clicked.
   */
  onClearClick: PropTypes.func,
  /**
   * The event handler run when both the date and time have been selected.
   */
  onDateTimeChange: PropTypes.func,
  /**
   * The event handler run when the date changes.
   */
  onDayChange: PropTypes.func,
  /**
   * The event handler run when an error occurs and when an error is cleared.
   * Will return an error message when an error occurs, otherwise returns null.
   */
  onError: PropTypes.func,
  /**
   * The event handler run when the time changes.
   */
  onTimeChange: PropTypes.func,
  /**
   * The selected date displayed in the date input.
   */
  selectedDate: PropTypes.instanceOf(Date),
  /**
   * The selected time displayed in the time input.
   */
  selectedTime: PropTypes.instanceOf(Date),
  /**
   * The text for the more information tooltip in the component's label.
   */
  tooltipText: PropTypes.string,
  /**
   * The fixed width of the component. When undefined the component will expand
   * to the full width of it's container.
   */
  width: PropTypes.string,
}

export default DateTimePicker
