import {
  append,
  assoc,
  assocPath,
  evolve,
  flatten,
  isNil,
  map,
  path,
  prop,
  propEq,
  propOr,
  reject,
  values,
} from "ramda"

import { Link } from "@ninjaone/components"

import { isNotNil, localizationKey, localized, localizedWith } from "js/includes/common/utils"
import { AndroidPolicyAppsApplicationSources } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"
import { SelectOptionTooltip } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/android/util"

export const ManagedConfigType = {
  BOOL: "BOOL",
  STRING: "STRING",
  INTEGER: "INTEGER",
  CHOICE: "CHOICE",
  MULTISELECT: "MULTISELECT",
  HIDDEN: "HIDDEN",
  BUNDLE: "BUNDLE",
  BUNDLE_ARRAY: "BUNDLE_ARRAY",
}

export const getManagedConfigByConnection = (managedConfigurations = [], connectionId) =>
  managedConfigurations.find(propEq("connectionId", connectionId))

export const applyDefaultValue = (values, { keyPath, defaultValue }) => {
  const isConfigSet = isNotNil(path(keyPath, values))
  return isConfigSet ? values : assocPath(keyPath, defaultValue, values)
}

export const applyDefaultManagedConfigValues = (managedConfigurations = [], connectionId, propertiesList = []) => {
  const _managedConfigurations = getManagedConfigByConnection(managedConfigurations, connectionId)
    ? managedConfigurations
    : [{ connectionId, managedConfiguration: {} }, ...managedConfigurations]

  const propsWithDefault = flattenManagedProperties(propertiesList, {
    excludeItem: ({ type, defaultValue }) =>
      type === ManagedConfigType.BUNDLE_ARRAY || (isNil(defaultValue) && type !== ManagedConfigType.BUNDLE),
  })

  return _managedConfigurations.map(config => {
    const newValues = propsWithDefault.reduce(applyDefaultValue, config.managedConfiguration)
    return assoc("managedConfiguration", newValues, config)
  })
}

export const flattenManagedProperties = (properties = [], { excludeItem = () => {} } = {}) => {
  const result = []

  function flatten({ nestedProperties: _nestedProperties, keyPath: _keyPath, ...rest }) {
    if (excludeItem?.(rest)) {
      return
    }

    const keyPath = _keyPath ? [..._keyPath, rest.key] : [rest.key]
    _nestedProperties
      ? _nestedProperties.forEach(item => flatten({ ...item, keyPath }))
      : result.push({ ...rest, keyPath })
  }

  properties.forEach(flatten)

  return result
}

export const PermissionPolicy = {
  PERMISSION_POLICY_UNSPECIFIED: "PERMISSION_POLICY_UNSPECIFIED",
  PROMPT: "PROMPT",
  GRANT: "GRANT",
  DENY: "DENY",
}

export const DistributionChannels = {
  DISTRIBUTION_CHANNEL_UNSPECIFIED: "DISTRIBUTION_CHANNEL_UNSPECIFIED",
  PUBLIC_GOOGLE_HOSTED: "PUBLIC_GOOGLE_HOSTED",
  PRIVATE_GOOGLE_HOSTED: "PRIVATE_GOOGLE_HOSTED",
  PRIVATE_SELF_HOSTED: "PRIVATE_SELF_HOSTED",
}

export const permissionPolicyOptions = [
  {
    value: PermissionPolicy.PERMISSION_POLICY_UNSPECIFIED,
    labelToken: localizationKey("Unspecified"),
  },
  { value: PermissionPolicy.PROMPT, labelToken: localizationKey("Prompt") },
  { value: PermissionPolicy.GRANT, labelToken: localizationKey("Grant") },
  { value: PermissionPolicy.DENY, labelToken: localizationKey("Deny") },
]

export const untrustedAppsPolicyOptions = [
  {
    value: "UNTRUSTED_APPS_POLICY_UNSPECIFIED",
    labelToken: localizationKey("Unspecified untrusted apps policy"),
  },
  { value: "DISALLOW_INSTALL", labelToken: localizationKey("Disallow installing") },
  {
    value: "ALLOW_INSTALL_IN_PERSONAL_PROFILE_ONLY",
    labelToken: localizationKey("Allow installing in profile only"),
  },
  { value: "ALLOW_INSTALL_DEVICE_WIDE", labelToken: localizationKey("Allow installing device wide") },
]

export const PlayStoreAppTypes = {
  PUBLIC_APP: "PUBLIC_APP",
  PRIVATE_APP: "PRIVATE_APP",
  WEB_APP: "WEB_APP",
}

export const getPlayStoreAppType = app => {
  const { packageName, distributionChannel } = app
  // Web app type
  if (packageName.includes(".webapp.")) {
    return PlayStoreAppTypes.WEB_APP
  }
  // Public / Private type
  switch (distributionChannel) {
    case DistributionChannels.PUBLIC_GOOGLE_HOSTED:
      return PlayStoreAppTypes.PUBLIC_APP
    case DistributionChannels.PRIVATE_GOOGLE_HOSTED:
    case DistributionChannels.PRIVATE_SELF_HOSTED:
      return PlayStoreAppTypes.PRIVATE_APP
    default:
      return null
  }
}

// Find non-system apps that are pending metadata update
export const getPendingPackagesNames = (appList, defaultConnectionId) => {
  // Generate an object in the form of `{ [connectionId]: [{ packangeNames, connectionId }]}`
  const packageAndConnection = appList.reduce((acc, { packageName, connectionId, updated }) => {
    if (!updated) {
      const currentConnectionId = connectionId ?? defaultConnectionId
      const currentList = propOr([], currentConnectionId, acc)
      const lastEntry = currentList[currentList.length - 1]
      // Limit the number of packageNames to 20 per connectionId
      if (!lastEntry || lastEntry.packageNames.length >= 20) {
        acc[currentConnectionId] = append(
          { packageNames: [packageName], connectionId: currentConnectionId },
          currentList,
        )
      } else {
        lastEntry.packageNames = append(packageName, lastEntry.packageNames)
      }
    }
    return acc
  }, {})

  return flatten(values(packageAndConnection))
}

export const mapApplicationsId = map(evolve({ applicationId: prop("packageName") }))

export const cleanSettingsData = reject(isNil)

export const playStoreModeOptions = [
  {
    value: "PLAY_STORE_MODE_UNSPECIFIED",
    LabelComponent: () => (
      <SelectOptionTooltip
        tooltipToken={localizationKey("Unspecified. Defaults to allowlist.")}
        labelToken={localizationKey("Play store mode unspecified")}
      />
    ),
  },
  {
    value: "WHITELIST",
    LabelComponent: () => (
      <SelectOptionTooltip
        tooltipToken={localizationKey(
          "Only apps that are in the policy are available and any app not in the policy will be automatically uninstalled from the device.",
        )}
        labelToken={localizationKey("Allowlist")}
      />
    ),
  },
  {
    value: "BLACKLIST",
    LabelComponent: () => (
      <SelectOptionTooltip
        tooltipToken={localizationKey(
          "All apps are available and any app that should not be on the device should be explicitly marked as 'BLOCKED' in the applications policy.",
        )}
        labelToken={localizationKey("Blocklist")}
      />
    ),
  },
]

export const contentProtectionPolicyOptions = [
  {
    value: "CONTENT_PROTECTION_POLICY_UNSPECIFIED",
    labelToken: localizationKey("Unspecified"),
  },
  {
    value: "CONTENT_PROTECTION_DISABLED",
    LabelComponent: () => (
      <SelectOptionTooltip
        tooltipToken={localizationKey("Content protection is disabled and the user cannot change this.")}
        labelToken={localizationKey("Disabled")}
      />
    ),
  },
  {
    value: "CONTENT_PROTECTION_ENFORCED",
    LabelComponent: () => (
      <SelectOptionTooltip
        tooltipToken={localizationKey(
          "Content protection is enabled and the user cannot change this. Supported on Android 15 and above. A non-compliance detail with API level is reported if the Android version is less than 15.",
        )}
        labelToken={localizationKey("Enabled")}
      />
    ),
  },
  {
    value: "CONTENT_PROTECTION_USER_CHOICE",
    LabelComponent: () => (
      <SelectOptionTooltip
        tooltipToken={localizationKey(
          "Content protection is not controlled by the policy. The user is allowed to choose the behavior of content protection. Supported on Android 15 and above. A non-compliance detail with API level is reported if the Android version is less than 15.",
        )}
        labelToken={localizationKey("User choice")}
      />
    ),
  },
]

export const getApplicationTypeOptions = () => [
  {
    value: AndroidPolicyAppsApplicationSources.SYSTEM_APP,
    label: localized("System"),
  },
  {
    value: PlayStoreAppTypes.PUBLIC_APP,
    label: localized("Public"),
  },
  {
    value: PlayStoreAppTypes.PRIVATE_APP,
    label: localized("Private"),
  },
  {
    value: PlayStoreAppTypes.WEB_APP,
    label: localized("Web app"),
  },
]

export const alertMessageTokens = {
  title: localizationKey("Android MDM Connection"),
  message: () => localized("You need access to an Android connection to view Managed Applications."),
}

export const addAndroidConnectionsAlertMessage = {
  title: localizationKey("Android MDM Connection"),
  message: () =>
    localizedWith(
      "Android devices can be linked to one MDM connection at a time. Navigate to your <%link>Android MDM Configuration<%> to add a new connection.",
      {
        link: ({ localizedText }) => (
          <Link href="/#/administration/apps/mdmAndroid" target="_blank" rel="noopener noreferrer">
            {localizedText}
          </Link>
        ),
      },
    ),
}
