import { show, hide } from "js/state/actions/common/visibility"
import { fetchJson, localized, showErrorMessage, ninjaReportError } from "js/includes/common/utils"
import { updateOrganizationForConfigurationTypeMappingDeleted } from "js/state/actions/psa/ConnectWise/mappings/accounts"

export function getConfigurationTypeMappings() {
  return async dispatch => {
    try {
      const configurationTypeMappings = await fetchJson("/psacw/v2/configurationTypeMappings")
      dispatch({
        type: "SET_CW_CONFIGURATION_TYPE_MAPPINGS",
        payload: configurationTypeMappings,
      })
    } catch (error) {
      showErrorMessage(localized("Error loading configuration type mappings"))
      throw error
    }
  }
}

export function getConfigurationTypeList() {
  return async dispatch => {
    try {
      const { configurationTypeList } = await fetchJson("/psacw/v2/configuration/types")
      dispatch({
        type: "SET_CW_CONFIGURATION_TYPE_LIST",
        payload: configurationTypeList,
      })
    } catch (error) {
      showErrorMessage(localized("Error loading configuration types"))
      if (error?.response?.status !== 403) {
        ninjaReportError(error)
      }
    }
  }
}

export function getConfigurationStatusList() {
  return async dispatch => {
    try {
      const { configurationStatusList } = await fetchJson("/psacw/v2/configuration/statuses")
      dispatch({
        type: "SET_CW_CONFIGURATION_STATUS_LIST",
        payload: configurationStatusList,
      })
    } catch (error) {
      showErrorMessage(localized("Error fetching configuration status list"))
      if (error?.response?.status !== 403) {
        ninjaReportError(error)
      }
    }
  }
}

export function showConfigurationTypeMappingModal(mode, id) {
  return dispatch => {
    dispatch({ type: "SET_CW_CONFIGURATION_TYPE_MAPPING_MODAL_EDITOR_MODE", mode })
    dispatch({ type: "SET_CW_ONFIGURATION_TYPE_MAPPING_ID", id })
    dispatch(show("CWConfigurationTypeMappingsModal")())
  }
}

export function copyConfigurationTypeMapping(newConfigurationTypeMapping) {
  return dispatch => {
    dispatch({ type: "SET_CW_CONFIGURATION_TYPE_MAPPING_MODAL_EDITOR_MODE", mode: "ADD" })
    dispatch(show("CWConfigurationTypeMappingsModal")())
  }
}

export function closeConfigurationTypeMappingModal() {
  return dispatch => {
    dispatch({ type: "RESET_CW_CONFIGURATION_TYPE_MAPPING" })
    dispatch(hide("CWConfigurationTypeMappingsModal")())
  }
}

export function addConfigurationTypeMapping(data) {
  return dispatch => {
    dispatch({ type: "ADD_CW_CONFIGURATION_TYPE_MAPPING", newConfigurationTypeMapping: data })
  }
}

export function updateConfigurationTypeMapping(data) {
  return dispatch => {
    dispatch({ type: "EDIT_CW_CONFIGURATION_TYPE_MAPPING", configurationTypeMapping: data })
  }
}

export function removeConfigurationTypeMapping(id) {
  return dispatch => {
    dispatch({ type: "REMOVE_CW_CONFIGURATION_TYPE_MAPPING", id })
    dispatch(updateOrganizationForConfigurationTypeMappingDeleted(id))
  }
}

export function setConfigurationTypeMappings(configurationsTypeMappings) {
  return dispatch => {
    dispatch({ type: "SET_CW_CONFIGURATION_TYPE_MAPPINGS", payload: configurationsTypeMappings })
  }
}

export function setCurrentConfigurationTypeMapping(configurationTypeMapping) {
  return dispatch => {
    dispatch({ type: "SET_CW_CONFIGURATION_TYPE_MAPPING", configurationTypeMapping })
  }
}

export function setConfigurationTypeMappingName(name) {
  return dispatch => {
    dispatch({ type: "SET_CW_CONFIGURATION_TYPE_MAPPING_NAME", name })
  }
}
