import {
  faExclamationTriangle,
  faKey,
  faLock,
  faTv,
  faNetworkWired,
  faMobileScreenButton,
  faLocationDot,
  faCircleUp,
  faGear,
} from "@fortawesome/pro-solid-svg-icons"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import PasscodeForm from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/android/passcode/PasscodeForm"
import AndroidNetworkForm from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/android/NetworkForm"
import { IosNetworkForm, PasscodeIOSForm, OSUpdatesForm } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/ios/"
import Conditions from "./Conditions"
import { MobileIosApplications, SecurityForm, FindDeviceForm, PolicyEnforcement, RestrictionForm } from "./mdm"
import { isFeatureEnabled } from "js/includes/common/utils"
import MobileApplicationsForm from "./mdm/android/applications/MobileApplicationsForm"
import MobileApplicationsKioskSettings from "./mdm/android/applications/MobileApplicationsKioskSettings"
import MobileApplicationsAdvancedSettings from "./mdm/android/applications/MobileApplicationsAdvancedSettings"
import { AppleCustomPayload } from "./mdm/ios/customPayload/AppleCustomPayload"

export const policyEditorVMTabs = [
  {
    icon: faExclamationTriangle,
    label: localizationKey("Conditions"),
    route: "conditions",
    Component: Conditions,
  },
]

export const policyEditorAndroidTabs = () => [
  {
    icon: faKey,
    label: localizationKey("Passcode"),
    route: "passcode",
    Component: PasscodeForm,
  },
  {
    icon: faLock,
    label: localizationKey("Restrictions"),
    route: "restrictions",
    Component: RestrictionForm,
  },
  {
    icon: faTv,
    label: localizationKey("Applications"),
    route: "applications",
    subTabs: [
      {
        label: localizationKey("Management"),
        route: "applications/management",
        Component: MobileApplicationsForm,
      },
      {
        label: localizationKey("Kiosk settings"),
        route: "applications/single-app-kiosk",
        Component: MobileApplicationsKioskSettings,
      },
      {
        label: localizationKey("Advanced"),
        route: "applications/advanced",
        Component: MobileApplicationsAdvancedSettings,
      },
    ],
  },
  {
    icon: faNetworkWired,
    label: localizationKey("Network"),
    route: "network",
    Component: AndroidNetworkForm,
  },
  {
    icon: faLock,
    label: localizationKey("Security"),
    route: "security",
    Component: SecurityForm,
  },
  {
    icon: faMobileScreenButton,
    label: localizationKey("Policy enforcement"),
    route: "enforcement",
    Component: PolicyEnforcement,
  },
  ...(isFeatureEnabled("mdm_geolocation")
    ? [
        {
          icon: faLocationDot,
          label: localizationKey("Location tracking"),
          route: "geolocation",
          Component: FindDeviceForm,
        },
      ]
    : []),
]

const generalAppleTabs = [
  {
    icon: faKey,
    label: localizationKey("Passcode"),
    route: "passcode",
    Component: PasscodeIOSForm,
  },
  {
    icon: faLock,
    label: localizationKey("Restrictions"),
    route: "restrictions",
    Component: RestrictionForm,
  },
  {
    icon: faTv,
    label: localizationKey("Applications"),
    route: "applications",
    Component: MobileIosApplications,
  },
  {
    icon: faNetworkWired,
    label: localizationKey("Network"),
    route: "network",
    Component: IosNetworkForm,
  },
  {
    icon: faCircleUp,
    label: localizationKey("OS updates"),
    route: "osupdates",
    Component: OSUpdatesForm,
  },
  {
    // TODO: Icon should be remove in new policy designs
    icon: faGear,
    label: localizationKey("Custom payload"),
    route: "customPayload",
    Component: AppleCustomPayload,
  },
]

export const getMacMDMTabs = () => [...generalAppleTabs]

export const getAppleMobileMDMTabs = () => [
  ...generalAppleTabs,
  ...(isFeatureEnabled("mdm_geolocation")
    ? [
        {
          icon: faLocationDot,
          label: localizationKey("Location tracking"),
          route: "geolocation",
          Component: FindDeviceForm,
        },
      ]
    : []),
]
