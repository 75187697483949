import { memo } from "react"
import PropTypes from "prop-types"
import tokens from "@ninjaone/tokens"
import { Input, Checkbox } from "@ninjaone/components"
import { Box, Flex } from "js/includes/components/Styled"
import withInheritance from "js/includes/components/withInheritance"
import { useMountedState } from "js/includes/common/hooks"

const delayDaysRange = {
  min: 1,
  max: 90,
}

const DelayUpdateComponent = memo(
  ({ inheritableItem, onChange, checkboxLabel, inputLabelToken, checkboxKey, inputKey, tooltipText }) => {
    const isChecked = inheritableItem[checkboxKey]
    const delayValue = inheritableItem[inputKey] ?? 1

    const [internalValue, setInternalValue] = useMountedState(delayValue)
    return (
      <Flex width="100%" flexDirection="column" gap={tokens.spacing[2]} marginTop={tokens.spacing[2]}>
        <Checkbox
          label={checkboxLabel}
          checked={isChecked}
          onChange={({ isChecked }) =>
            onChange({
              ...inheritableItem,
              [checkboxKey]: isChecked,
              [inputKey]: 1,
            })
          }
        />
        {isChecked && (
          <Box margin={`0 0 ${tokens.spacing[2]} ${tokens.spacing[7]}`}>
            <Input
              tooltipText={tooltipText}
              value={internalValue}
              min={delayDaysRange.min}
              max={delayDaysRange.max}
              labelToken={inputLabelToken}
              onChange={e => {
                setInternalValue(e.target.value)
              }}
              onBlur={e => {
                const constrainedValue = Math.max(
                  delayDaysRange.min,
                  Math.min(delayDaysRange.max, Math.trunc(Number(internalValue))),
                )
                onChange({
                  ...inheritableItem,
                  [inputKey]: constrainedValue,
                })
              }}
              type="number"
            />
          </Box>
        )}
      </Flex>
    )
  },
)

DelayUpdateComponent.propTypes = {
  inheritableItem: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  checkboxLabel: PropTypes.string.isRequired,
  inputLabelToken: PropTypes.string.isRequired,
  checkboxKey: PropTypes.string.isRequired,
  inputKey: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
}

export default withInheritance(DelayUpdateComponent)
