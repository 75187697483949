import { map, prop, reduce, reject } from "ramda"
import { memo, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Modal, Select } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"

import { Box } from "js/includes/components/Styled"
import { localizationKey, localized } from "js/includes/common/utils"
import { getProductTypeOptions } from "js/includes/configuration/integrations/psa/psaProducts/productForm/formCommons"
import { useProductCatalog } from "js/includes/common/utils/ninjaPSA"
import Loading from "js/includes/components/Loading"

export const ExistingProductSelectorModal = memo(
  ({
    unmount,
    onSelect,
    products: _products,
    currentProducts = [],
    hiddenProductTypes,
    allowedProductTypes,
    initialProductType = null,
  }) => {
    const [product, setProduct] = useState()

    const {
      loading: loadingProductCatalog,
      productCatalog,
      //TODO: refactor other areas of PSA that use the ProductForm component and pass down the product list to this component
    } = useProductCatalog(!!_products)

    const products = useMemo(() => _products || productCatalog, [_products, productCatalog])

    const { typeOptions, acceptedTypes } = useMemo(() => {
      let typeOptions = getProductTypeOptions()
      if (hiddenProductTypes?.length) {
        typeOptions = reject(option => hiddenProductTypes.includes(option.value), typeOptions)
      } else if (allowedProductTypes?.length) {
        typeOptions = reject(option => !allowedProductTypes.includes(option.value), typeOptions)
      }
      return {
        typeOptions,
        acceptedTypes: new Set(map(option => option.value, typeOptions)),
      }
    }, [hiddenProductTypes, allowedProductTypes])

    const [productTypeFilter, setProductTypeFilter] = useState(initialProductType)

    const { productOptions, productMap } = useMemo(() => {
      const currentProductsIds = new Set(map(prop("productId"), currentProducts))

      return reduce(
        (acc, product) => {
          if (
            product.isActive &&
            !currentProductsIds.has(product.id) &&
            (productTypeFilter ? productTypeFilter === product.type : acceptedTypes.has(product.type))
          ) {
            acc.productOptions.push({
              labelText: product.name,
              value: product.id,
              type: product.type,
            })
            acc.productMap[product.id] = product
          }
          return acc
        },
        {
          productOptions: [],
          productMap: {},
        },
        products,
      )
    }, [products, currentProducts, acceptedTypes, productTypeFilter])

    const onTypeChange = optionValue => {
      setProductTypeFilter(optionValue)
      if (product && optionValue !== product.type) {
        setProduct()
      }
    }

    const onSave = () => {
      onSelect(productMap[product])
      unmount()
    }

    return (
      <Modal
        unmount={unmount}
        titleGroup={{
          titleText: localized("Add existing product"),
        }}
        buttons={[
          {
            type: "save",
            labelToken: localizationKey("Add"),
            onClick: onSave,
            disabled: !product,
          },
        ]}
      >
        {loadingProductCatalog ? (
          <Loading />
        ) : (
          <>
            {typeOptions.length > 1 && !initialProductType && (
              <Box marginBottom={tokens.spacing[3]}>
                <Select
                  value={productTypeFilter}
                  options={typeOptions}
                  labelId="product-type"
                  labelText={localized("Product type")}
                  onChange={onTypeChange}
                  triggerAriaLabel={localized("Product type")}
                />
              </Box>
            )}
            <Select
              key={productTypeFilter}
              value={product}
              options={productOptions}
              labelId="product"
              labelText={localized("Product")}
              onChange={setProduct}
              triggerAriaLabel={localized("Product")}
            />
          </>
        )}
      </Modal>
    )
  },
)

ExistingProductSelectorModal.propTypes = {
  currentProducts: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.number.isRequired,
    }),
  ),
  hiddenProductTypes: PropTypes.arrayOf(PropTypes.string),
  allowedProductTypes: PropTypes.arrayOf(PropTypes.string),
  initialProductType: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  products: PropTypes.array,
  unmount: PropTypes.func,
}
