import { clone, path, omit } from "ramda"
import fastDeepEqual from "fast-deep-equal"
import {
  fetchJson,
  ninjaReportError,
  showSuccessMessage,
  showErrorMessage,
  isMDMPolicy,
} from "js/includes/common/utils"
import { NinjaResponseError } from "js/includes/common/types"
import { localized } from "js/includes/common/utils"
import { getCompletePathToItem } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/helpers"
import { startLoading, stopLoading } from "js/state/actions/common/loading"
import { getParentPolicyContent, mobilePolicyErrorMessages, updateMDMPolicy } from "js/includes/common/client"
import { needsOnSaveAlertModal } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/OnSaveAlertModal"

export const setPolicy = (dispatch, policy) => {
  dispatch({ type: "SET_POLICY_ORIGINAL", policy: clone(policy) })
  dispatch({ type: "SET_POLICY", policy })
}

export const setParentPolicy = (dispatch, policy) => {
  dispatch({
    type: "SET_PARENT_POLICY",
    parentPolicy: policy,
  })
}

export const fetchPolicy = async (id, hideErrorOnUnknownPolicy) => {
  try {
    const response = await fetchJson(`/policy/${id}`)

    if (response.resultCode === "SUCCESS") {
      return response.policy
    } else {
      throw new NinjaResponseError(response)
    }
  } catch (error) {
    if (!hideErrorOnUnknownPolicy) {
      showErrorMessage(localized("Error loading policy"))
    }
    ninjaReportError(error)
    return error
  }
}

export const cleanupEditorPoliciesStore = dispatch => {
  dispatch({ type: "CLEAR_PARENT_POLICY" })
  dispatch({ type: "CLEAR_POLICY" })
  dispatch({ type: "CLEAR_NODE" })
}

export const setEditorPolicies = (id, deviceId) => async dispatch => {
  cleanupEditorPoliciesStore(dispatch)

  const policy = await fetchPolicy(id, true)

  if (policy?.data?.resultCode === "UNKNOWN_POLICY_ID") {
    dispatch({ type: "SHOW_NOT_FOUND_ERROR" })
    return
  }

  try {
    if (deviceId) {
      //This is a policy override
      const response = await fetchJson(`/node/${deviceId}`, { options: { method: "GET" } })

      if (response.resultCode === "SUCCESS") {
        const { node } = response
        const policyIdFromDeviceList = window.deviceList.get(node.id).get("policyId")

        if (policyIdFromDeviceList !== parseInt(id, 10)) {
          const error = new Error(`The policy does not correspond to this device`)
          showErrorMessage(localized("This policy does not correspond to the device"))
          ninjaReportError(error)
        } else {
          const devicePolicy = {
            name: node.friendlyName || node.dnsName || node.netbiosName,
            description: policy.description,
            enabled: policy.enabled,
            nodeRole: policy.nodeRole,
            nodeClass: policy.nodeClass,
            parentPolicyId: policy.id,
            content: node.policyContent,
          }

          setPolicy(dispatch, devicePolicy)
          setParentPolicy(dispatch, policy)
          dispatch({ type: "SET_NODE_FOR_OVERRIDE", node })
        }
      } else {
        throw new NinjaResponseError(response)
      }
    } else {
      setPolicy(dispatch, policy)

      if (policy.parentPolicyId) {
        const parentPolicyContent = await getParentPolicyContent(id)
        setParentPolicy(dispatch, { id: policy.parentPolicyId, content: parentPolicyContent })
      }
    }
  } catch (error) {
    ninjaReportError(error)
  }
}

const ignoreFieldsForComparison = omit(["inheritance"])

export const updatePolicyItem = (pathToItem, parentPolicy, newItem) => dispatch => {
  const completePathToItem = getCompletePathToItem(pathToItem)

  if (newItem.inheritance.inherited) {
    const sourceItem = path(completePathToItem, parentPolicy)

    if (!fastDeepEqual(ignoreFieldsForComparison(sourceItem), ignoreFieldsForComparison(newItem))) {
      newItem.inheritance.overridden = true
    }
  }

  dispatch({
    type: "UPDATE_POLICY_ITEM",
    pathToItem,
    newItem,
    parentPolicy,
  })
}

export const deletePolicyItem = pathToItem => dispatch => {
  dispatch({
    type: "DELETE_POLICY_ITEM",
    pathToItem,
  })
}

export const revertPolicySection = (pathToItem, inheritableItem) => (dispatch, getState) => {
  const { inheritance } = inheritableItem
  const { parentPolicy } = getState().policyEditor

  if (inheritance.sourcePolicyId !== parentPolicy.id) {
    const error = new Error(`The sourcePolicyId of this inheritable section is not the parent of the policy`)
    showErrorMessage(localized("Failed to update policy"))
    ninjaReportError(error)
    return
  }

  const originalItem = path(pathToItem.split("."), parentPolicy.content)
  inheritance.overridden = false

  originalItem.inheritance = inheritance

  dispatch(updatePolicyItem(pathToItem, parentPolicy, originalItem))
}

const updateDevicesPolicy = async (policy, dispatch) => {
  try {
    dispatch(startLoading("SavingPolicy")())
    let response = await fetchJson(`/policy/${policy.id}`, {
      options: { method: "PUT", body: JSON.stringify(policy) },
      useMfaUrl: true,
    })
    showSuccessMessage(localized("Saving policy"))
    if (response.resultCode === "SUCCESS") {
      dispatch({
        type: "SET_POLICY_ORIGINAL",
        policy: clone(policy),
      })
    } else {
      throw new NinjaResponseError(response)
    }
  } catch (error) {
    const { isHandledMfaError } = error
    if (!isHandledMfaError) {
      showErrorMessage(localized("Failed to update policy"))
      ninjaReportError(error)
    }
  } finally {
    dispatch(stopLoading("SavingPolicy")())
  }
}

export const triggerUpdateMobilePolicy = async (policy, dispatch, onSuccess) => {
  try {
    dispatch(startLoading("SavingPolicy")())
    const updatedPolicy = await updateMDMPolicy(policy)
    showSuccessMessage(localized("Saving policy"))
    dispatch({
      type: "SET_POLICY_ORIGINAL",
      policy: clone(updatedPolicy),
    })
    dispatch({ type: "SET_POLICY", policy: updatedPolicy })
    onSuccess?.()
  } catch (error) {
    const { isHandledMfaError, resultCode } = error
    const policyErrorMessage = mobilePolicyErrorMessages[resultCode]
    if (policyErrorMessage) {
      showErrorMessage(policyErrorMessage())
    } else if (!isHandledMfaError) {
      showErrorMessage(localized("Failed to update policy"))
      ninjaReportError(error)
    }
  } finally {
    dispatch(stopLoading("SavingPolicy")())
  }
}

export const updateMobilePolicy = (policy, dispatch) => {
  if (needsOnSaveAlertModal(policy, dispatch)) return { hasOnSaveAlert: true }

  return triggerUpdateMobilePolicy(policy, dispatch)
}

export const updatePolicy = policy => async dispatch => {
  if (isMDMPolicy(policy.nodeRole)) {
    return await updateMobilePolicy(policy, dispatch)
  } else {
    await updateDevicesPolicy(policy, dispatch)
  }
}

export const updateDevicePolicyOverride = (policy, deviceId) => async dispatch => {
  const { nodeOverride } = window.store.getState().policyEditor
  try {
    dispatch(startLoading("SavingPolicy")())
    showSuccessMessage(localized("Saving policy"))
    const response = await fetchJson(`/node/${deviceId}`, {
      options: {
        method: "PUT",
        body: JSON.stringify({
          ...nodeOverride,
          policyContent: policy.content,
        }),
      },
      useMfaUrl: true,
    })
    if (response.resultCode !== "SUCCESS") {
      throw new NinjaResponseError(response)
    } else {
      dispatch({
        type: "SET_POLICY_ORIGINAL",
        policy: clone(policy),
      })
    }
  } catch (error) {
    showErrorMessage(localized("Failed to save policy override"))
    ninjaReportError(error)
  } finally {
    dispatch(stopLoading("SavingPolicy")())
  }
}

export const updatePolicyName = (name, description, enabled) => async dispatch => {
  dispatch({
    type: "UPDATE_POLICY_NAME",
    name,
    description,
    enabled,
  })
}
