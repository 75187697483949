import React, { PureComponent } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { map, find, isNil, pipe, complement, propEq } from "ramda"

import styled from "@emotion/styled"
import { spacing, typography } from "@ninjaone/tokens"
import { AngleDownIcon, AngleUpIcon } from "@ninjaone/icons"
import { useMountedState } from "@ninjaone/utils"

import { localized } from "js/includes/common/utils"
import { colors } from "js/includes/common/theme"
import { Box } from "js/includes/components/Styled"

const StyledNavLink = styled.li`
  padding: ${spacing[2]};

  &:not(:last-child) {
    margin-bottom: ${spacing[1]} !important;
  }
  &.active {
    color: ${colors.ninjaBlue};
    font-weight: 700;
    svg path {
      fill: ${colors.ninjaBlue};
    }
  }
  &.child-active {
    background: ${({ theme }) => theme.colorForegroundSelected} !important;
    color: ${({ theme }) => theme.colorBackgroundAccentCtaStrongest} !important;
    font-weight: ${typography.fontWeight.medium};
  }
  &:hover {
    cursor: pointer;
  }
  position: relative;

  .subtab-icon {
    position: absolute;
    right: ${spacing[1]};
    top: 50%;
    transform: translateY(-50%);
  }
`

const SubtabContainer = styled(Box)`
  border-left: solid 1px ${({ theme }) => theme.colorBorderWeak};
  padding: ${spacing[2]} 0 ${spacing[1]} ${spacing[2]};
  margin-bottom: ${spacing[2]};
  box-sizing: border-box;

  &.subtab-hidden {
    display: none;
  }
`

const Tab = ({ tab, onChangeRoute, route, isChild }) => {
  const [expanded, setExpanded] = useMountedState(true)
  const childActiveClass = isChild ? " child-active " : ""
  const active = route === tab.route ? `active ${childActiveClass}` : ""
  const hasSubtabs = tab?.subTabs?.length > 0
  return (
    <>
      <StyledNavLink
        className={hasSubtabs ? "" : active}
        onClick={() => {
          if (hasSubtabs) {
            setExpanded(!expanded)
            return
          }
          onChangeRoute(tab.route)
          tab.action?.()
        }}
        aria-haspopup="menu"
        aria-expanded={hasSubtabs ? expanded : undefined}
      >
        {tab.labelRenderer?.(active)}

        {tab.icon && !tab.labelRenderer && <FontAwesomeIcon icon={tab.icon} fixedWidth />}
        {!tab.labelRenderer && <span className="m-l">{localized(tab.label)}</span>}
        {hasSubtabs && <Box className="subtab-icon">{expanded ? <AngleDownIcon /> : <AngleUpIcon />}</Box>}
      </StyledNavLink>
      {hasSubtabs && (
        <SubtabContainer className={expanded ? "" : "subtab-hidden"}>
          {map(subtab => (
            <Tab key={subtab.route} tab={subtab} onChangeRoute={onChangeRoute} route={route} isChild={true} />
          ))(tab.subTabs)}
        </SubtabContainer>
      )}
    </>
  )
}

export default class EditorContent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      route: "",
    }
    this.initRoute = this.initRoute.bind(this)
    this.onChangeRoute = this.onChangeRoute.bind(this)
    this.renderActiveTab = this.renderActiveTab.bind(this)
  }

  componentDidMount() {
    this.initRoute()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedTab && this.props.selectedTab !== prevProps.selectedTab) {
      this.onChangeRoute(this.props.selectedTab)
    }
  }

  initRoute() {
    const { tabs } = this.props
    this.setState({ route: tabs[0].route })
  }

  onChangeRoute(route) {
    this.setState({ route })
  }

  findActiveTab(route, tabs) {
    const found = find(propEq("route", route), tabs)
    if (found) return found
    return pipe(
      map(tab => this.findActiveTab(route, tab?.subTabs || [])),
      find(complement(isNil)),
    )(tabs)
  }

  renderActiveTab() {
    const { tabs } = this.props
    const { route } = this.state

    if (!route) {
      return null
    }

    const activeTab = this.findActiveTab(route, tabs)
    const { Component } = activeTab

    return (
      <div className="display-flex flex-full flex-column not-scrollable">
        <Component />
      </div>
    )
  }

  render() {
    const { tabs } = this.props
    const { route } = this.state
    return (
      <div className="editor-content">
        <aside>
          <nav id="sidebar" className="editor-sidebar">
            <ul className="nav">
              {map(tab => {
                return (
                  <Tab
                    {...{
                      onChangeRoute: this.onChangeRoute,
                      key: tab.route,
                      tab,
                      route,
                    }}
                  />
                )
              }, tabs)}
            </ul>
          </nav>
        </aside>
        <div className="content-container">{this.renderActiveTab()}</div>
      </div>
    )
  }
}
