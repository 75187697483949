import {
  keys,
  map,
  compose,
  reduce,
  filter,
  gt,
  __,
  countBy,
  prop,
  sortWith,
  descend,
  indexOf,
  ascend,
  propEq,
} from "ramda"
import { fetchActiveBucketsList, fetchBucketsList } from "./services"
import { isFeatureEnabled, localized } from "../utils"

export const cloudberryRegionsMap = {
  "us-east-1": "US East (N. Virginia)",
  "us-east-2": "US East (Ohio)",
  "us-west-1": "US West (N. California)",
  "us-west-2": "US West (Oregon)",
  "ca-central-1": "Canada (Central)",
  "eu-central-1": "EU (Frankfurt)",
  "eu-west-1": "EU (Ireland)",
  "eu-west-2": "EU (London)",
  "ap-southeast-2": "Asia Pacific (Sydney)",
  "ap-northeast-1": "Asia Pacific (Tokyo)",
  "sa-east-1": "South America (São Paulo)",
}

//{
//   "ninja-backup-useast1": "US East (N. Virginia) us-east-1",
//   "ninja-backup-useast2": "US East (Ohio) us-east-2",
//   "ninja-backup-uswest1": "US West (N. California) us-west-1",
//   "ninja-backup-uswest2": "US West (Oregon) us-west-2",
//   "ninja-backup-cacentral1": "Canada (Central) ca-central-1",
//   "ninja-backup-eucentral1": "EU (Frankfurt) eu-central-1",
//   "ninja-backup-eunorth1": "EU (Stockholm)  eu-north-1",
//   "ninja-backup-euwest1": "EU (Ireland) eu-west-1",
//   "ninja-backup-euwest2": "EU (London) eu-west-2",
//   "ninja-backup-euwest3": "EU (Paris) eu-west-3",
//   "ninja-backup-apsoutheast2": "Asia Pacific (Sydney) ap-southeast-2",
// }

const getContinentList = () => ({
  NA: localized("North America"),
  EU: localized("Europe"),
  OC: localized("Oceania"),
  AF: localized("Africa"),
  AN: localized("Antarctica"),
  AS: localized("Asia"),
  SA: localized("South America"),
})

export const getActiveBucketsList = async () => {
  const bucketList = await fetchActiveBucketsList()
  const duplicatedNames = compose(keys, filter(gt(__, 1)), countBy(prop("friendlyName")))(bucketList)
  const defaultContinent = bucketList.find(propEq("default", true))?.continentCode

  return compose(
    sortWith([
      descend(propEq("continentCode", defaultContinent)),
      // sort by regions in the same order as getContinentList
      ascend(({ continentCode }) => indexOf(continentCode, keys(getContinentList()))),
      ascend(prop("labelText")),
    ]),
    map(({ id, bucket, friendlyName, default: isDefault, continentCode }) => ({
      value: bucket,
      labelText: `${friendlyName}${duplicatedNames.includes(friendlyName) ? ` (${id})` : ""}${
        isDefault ? " (default)" : ""
      }`,
      continentCode,
      continentName: getContinentList()[continentCode] ?? localized("Other"),
    })),
  )(bucketList)
}

export const cloudStorageMap = async backupEngineId => {
  if (backupEngineId === "lockhart") {
    const bucketList = await fetchBucketsList()
    return reduce((acc, item) => ({ ...acc, [item.bucket]: `${item.name}` }), {}, bucketList)
  } else {
    return cloudberryRegionsMap
  }
}

export const createCloudStorageOption = options => value => ({
  value,
  label: options[value],
  labelText: options[value],
})

export const cloudStorageOptions = async backupEngineId => {
  if (backupEngineId === "lockhart" && isFeatureEnabled("backup_enable_global_buckets")) {
    return await getActiveBucketsList()
  }

  const options = await cloudStorageMap(backupEngineId)
  return compose(map(createCloudStorageOption(options)), keys)(options)
}
