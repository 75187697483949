import { fetch, reportErrorAndShowMessage, localizationKey } from "js/includes/common/utils"
import { NinjaResponseError } from "js/includes/common/types"
import { testConnection } from "../connection"
import { setDefaultTicketTemplateId } from "../ticketing"
import { setDeviceDataSyncAttributes } from "./settings"
import { PSA_TYPES } from "js/includes/configuration/apps/appInfo"
import { updatePsaType } from "js/state/actions/session/psaType"
import { getHealthStatus } from "js/state/actions/psa/Autotask/healthStatus"

const setConnection = payload => ({
  type: "SET_AT_CONNECTION",
  payload,
})

const setSettings = payload => ({
  type: "SET_AT_SETTINGS",
  payload,
})

export const setHelpdeskOptions = helpdeskSettings => ({
  type: "SET_HELPDESK_OPTIONS",
  helpdeskSettings,
})

export const getConnection = () => async (dispatch, getState) => {
  try {
    const response = await window.psaConnect.fetch()

    if (response.content) {
      if (response.resultCode === "SUCCESS") {
        const {
          enabled,
          username,
          password,
          productCategories = [],
          companyFilterMap = {},
          syncNodes,
          defaultTicketTemplateId,
          deviceDataSyncAttributes,
          createContact,
          embedHelpRequestImagesInTickets,
        } = response.content

        dispatch(
          setConnection({
            enabled,
            username,
            password,
          }),
        )

        dispatch(
          setSettings({
            syncNodes,
            productCategories,
            companyFilterMap,
          }),
        )
        if (defaultTicketTemplateId) {
          dispatch(setDefaultTicketTemplateId(defaultTicketTemplateId))
        }
        const helpdeskSettings = { createContact, embedHelpRequestImagesInTickets }
        dispatch(setHelpdeskOptions(helpdeskSettings))
        dispatch(setDeviceDataSyncAttributes(deviceDataSyncAttributes ?? []))
      } else {
        throw new NinjaResponseError(response)
      }
    }
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error fetching Autotask connection"))
    throw error
  }
}

export function saveConnection(formValues) {
  return async (dispatch, getState) => {
    const autoTaskState = getState().psa.Autotask

    const {
      settings: {
        syncNodes,
        productCategories,
        companyFilterMap,
        deviceDataSyncAttributes,

        accountOrganizationMappings,
        nodeRoleMappings,
        locationMappings,
      },
      helpdeskSettings,
    } = autoTaskState

    let username, password

    const { defaultTicketTemplateId } = getState().psa

    if (formValues) {
      await dispatch(testConnection(PSA_TYPES.AUTOTASK, formValues))
      ;({ username, password } = formValues)
    } else {
      ;({ username, password } = getState().psa.Autotask.connection)
    }

    const { createContact, embedHelpRequestImagesInTickets } = helpdeskSettings

    try {
      const body = JSON.stringify({
        content: {
          enabled: true,
          psaType: "AUTOTASK",

          defaultTicketTemplateId,

          username,
          password,

          syncNodes,
          companyFilterMap,
          productCategories,
          deviceDataSyncAttributes,

          embedHelpRequestImagesInTickets,
          createContact,
        },

        mappings: {
          autotaskAccountClientMappings: accountOrganizationMappings,
          installedProductCategoryMappings: nodeRoleMappings,
          locationMappings,
        },
      })
      dispatch(updatePsaType(PSA_TYPES.AUTOTASK))
      await fetch("/divisionconfig/psa", { options: { method: "PUT", body } })
      await dispatch(getConnection())
      await dispatch(getHealthStatus())
    } catch (error) {
      reportErrorAndShowMessage(error, localizationKey("Error saving Autotask connection"))
      throw error
    }
  }
}
