import {
  fetchJson,
  showSuccessMessage,
  localized,
  localizationKey,
  reportErrorAndShowMessage,
  isFeatureEnabled,
} from "js/includes/common/utils"
import { NinjaResponseError } from "js/includes/common/types"

export async function getConnectWiseControlConfigs() {
  try {
    const response = await fetchJson("/divisionconfig/connectwise_control")
    return response
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Failed"))
  }
}

export async function updateConnectWiseControlConfigs(content) {
  try {
    const response = await fetchJson("/divisionconfig/connectwise_control", {
      options: {
        method: "PUT",
        body: JSON.stringify({
          content,
        }),
      },
    })
    showSuccessMessage(localized("Success"))
    return response
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Failed to save"))
  }
}

export const getUninstallPreventionStatus = async () => {
  const response = await fetchJson("/divisionconfig/agent_uninstaller")
  if (response.resultCode === "SUCCESS") {
    return response?.content?.enabled ?? false
  } else {
    throw new NinjaResponseError(response)
  }
}

export const saveUninstallPreventionStatus = async enabled => {
  const response = await fetchJson("/divisionconfig/agent_uninstaller", {
    options: {
      method: "PUT",
      body: JSON.stringify({ content: { enabled } }),
    },
  })
  if (response.resultCode === "FAILURE") {
    throw new NinjaResponseError(response)
  }
}

export const saveInstallerManagementStatus = async enabled => {
  const response = await fetchJson("/divisionconfig/installer_management", {
    options: {
      method: "PUT",
      body: JSON.stringify({ content: { enabled } }),
    },
  })
  if (response.resultCode === "FAILURE") {
    throw new NinjaResponseError(response)
  }
}

export const getInstallerManagementStatus = async () => {
  if (!isFeatureEnabled("agent_tokenization")) return false

  const response = await fetchJson("/divisionconfig/installer_management")

  if (response.resultCode === "SUCCESS") {
    return response?.content?.enabled ?? false
  } else {
    throw new NinjaResponseError(response)
  }
}

export const getDeviceReEnrollmentStatus = async () => {
  const response = await fetchJson("/divisionconfig/re_enrollment")
  if (response.resultCode === "SUCCESS") {
    return response?.content?.enabled ?? false
  } else {
    throw new NinjaResponseError(response)
  }
}

export const getAdvancedInstallerOptionsStatus = async () => {
  const response = await fetchJson("/divisionconfig/advanced_installer_option")
  if (response.resultCode === "SUCCESS") {
    return response?.content?.enabled ?? false
  } else {
    throw new NinjaResponseError(response)
  }
}

export const getAutomaticTokenCreationStatus = async () => {
  if (!isFeatureEnabled("agent_tokenization")) return false

  const response = await fetchJson("/divisionconfig/automatic_token_creation")

  if (response.resultCode === "SUCCESS") {
    return response?.content?.enabled ?? false
  } else {
    throw new NinjaResponseError(response)
  }
}

export const saveAutomaticTokenCreationStatus = async enabled => {
  const response = await fetchJson("/divisionconfig/automatic_token_creation", {
    options: {
      method: "PUT",
      body: JSON.stringify({ content: { enabled } }),
    },
  })

  if (response.resultCode === "FAILURE") {
    throw new NinjaResponseError(response)
  }
}

export const saveDeviceReEnrollmentStatus = async enabled => {
  const response = await fetchJson("/divisionconfig/re_enrollment", {
    options: {
      method: "PUT",
      body: JSON.stringify({ content: { enabled } }),
    },
  })

  if (response.resultCode === "FAILURE") {
    throw new NinjaResponseError(response)
  }
}

export const saveAdvancedInstallerOptionsStatus = async enabled => {
  if (isFeatureEnabled("agent_tokenization")) return false

  const response = await fetchJson("/divisionconfig/advanced_installer_option", {
    options: {
      method: "PUT",
      body: JSON.stringify({ content: { enabled } }),
    },
  })
  if (response.resultCode === "FAILURE") {
    throw new NinjaResponseError(response)
  }
}

export const saveDivisionSecurityConfig = async body => {
  return await fetchJson("/division/security", {
    options: {
      method: "PATCH",
      body: JSON.stringify(body),
    },
  })
}

export const getDivisionSecurityConfig = async () => {
  return await fetchJson("/division/security")
}

export const getCompanyDisplayName = () => fetchJson("/division/companydisplayname")

export const getGravityZoneConfig = () => fetchJson("/divisionconfig/gravityzone")

export const saveGravityZoneConfig = content =>
  fetchJson("/divisionconfig/gravityzone", {
    options: {
      method: "PUT",
      body: JSON.stringify({ content }),
    },
  })

export const getSentinelOneConfig = () => fetchJson("/divisionconfig/sentinel_one")

export const getCrowdStrikeConfig = () => fetchJson("/divisionconfig/crowdstrike")

export const getPsaConfiguration = () => fetchJson("/divisionconfig/psa")

export const getNinjaRemoteConfig = () => fetchJson("/divisionconfig/ninjaremote")

export const updateNinjaRemoteConfigs = content =>
  fetchJson("/divisionconfig/ninjaremote", {
    options: {
      method: "PUT",
      body: JSON.stringify({ content }),
    },
  })
