import { complement, equals, filter, isEmpty, mergeLeft, path, propEq } from "ramda"
import { localized, showErrorMessage } from "js/includes/common/utils"

export const payloadPolicyAction = {
  INSERT: "INSERT",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
}

export const showGlobalErrorMessage = () =>
  showErrorMessage(localized("An error has occurred on the XML configurations editor."))

export const getFormattingErrorMessage = error => {
  const errorLine = path(["loc", "start", "line"], error)
  return errorLine
    ? localized("There's an formatting issue in the XML at line {{errorLine}}", {
        errorLine,
      })
    : localized("There's an issue in the XML. Please review.")
}

export const defaultPayloadType = "Configuration"

const filterDefaultConfigurationType = filter(complement(equals(defaultPayloadType)))

export const mapPayloadsWithMetadata = (payloadsFromPolicy = [], payloadsMetadata = []) => {
  if (isEmpty(payloadsMetadata)) return payloadsFromPolicy
  return payloadsFromPolicy.map(payload => {
    const payloadMetadata = payloadsMetadata.find(propEq("id", payload.id))
    if (!payloadMetadata) return payload
    const {
      name,
      content,
      metadata: { contentType, type },
    } = payloadMetadata

    const _contentType = [...contentType, ...[type ?? defaultPayloadType]]
    return mergeLeft(payload, {
      name,
      content,
      contentType: _contentType.length > 1 ? filterDefaultConfigurationType(_contentType) : _contentType,
    })
  })
}

/**
 * Returns updated payload options from new data and existent options, each being unique.
 * @param {{ metadata: { contentType: string[] }}} newData
 * @returns {(existentOptions: { label: string, value: string }[]) => { label: string, value: string }[]}
 */
export const getPayloadTypeOptions = newData => existentOptions =>
  newData.reduce(
    (acc, { metadata: { contentType } }) => {
      contentType.forEach(type => {
        // Add type if not included already
        if (!acc.some(option => option.value === type)) {
          acc.push({ label: type, value: type })
        }
      })
      return acc
    },
    [...existentOptions],
  )
