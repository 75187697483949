import { Fragment, useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import { groupBy, prop, assocPath, assoc, propEq, path, isEmpty, keys, evolve } from "ramda"

import styled from "@emotion/styled"
import tokens from "@ninjaone/tokens"
import { sizer } from "@ninjaone/utils"
import { Select, Stepper, Text, TitleGroup, SearchInput, Checkbox } from "@ninjaone/components"
import { updatePolicyItem as _updatePolicyItem } from "js/state/actions/policyEditor/editor"
import { Box, Flex } from "js/includes/components/Styled"
import { localized, localizationKey, showErrorMessage } from "js/includes/common/utils"
import {
  addLabelTokenToEnumOptions,
  defaultAllOption,
  getCategories,
  getEnrollmentTypes,
  getRestrictionsTokens,
} from "js/includes/editors/Policy/PolicyEditor/formComponents/restrictionsPolicyConfiguration"
import Indicator from "js/includes/components/Indicator"
import { useMountedState } from "js/includes/common/hooks"
import { getMDMRestrictions } from "js/includes/common/client"
import { StyledRow } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/common/styled"
import InheritableRowPolicyItem from "./common/InheritableRowPolicyItem"

const {
  typography: { fontWeight },
} = tokens

const restrictionIsChild = prop("keyParent")
const restrictionIsParent = (restrictionKey, restrictionsOptions) =>
  restrictionsOptions.find(propEq("keyParent", restrictionKey))

const getRestrictionsOptions = async nodeClass => {
  let restrictions = await getMDMRestrictions(nodeClass)
  const categories = getCategories(restrictions)
  const tokens = getRestrictionsTokens(nodeClass)
  // TODO: remove `restrictions.map()` when server restriction categorization inconsistency get fixed
  // This doesn't affect the policy, it's just for presentation purposes only
  restrictions = restrictions.map(restriction =>
    restrictionIsChild(restriction)
      ? evolve(
          {
            category: category => restrictions.find(propEq("key", restriction.keyParent))?.category ?? category,
          },
          restriction,
        )
      : restriction,
  )
  return {
    categories,
    restrictionOptions: restrictions.map(restriction => ({ ...restriction, ...tokens[restriction.key] })),
  }
}

const filterRestrictions = (restrictionOptions, nodeClass, categories, filters = {}) => {
  const { searchText, enrollmentType, category } = filters

  const filtered = restrictionOptions.filter(
    restriction =>
      ["All", restriction.category].includes(category) &&
      ["All", ...restriction.enrollmentType].includes(enrollmentType) &&
      (isEmpty(searchText) ||
        localized(restriction.labelToken)
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        localized(restriction.descriptionToken)
          .toLowerCase()
          .includes(searchText.toLowerCase())),
  )

  const parents = []
  const children = []
  for (const restriction of filtered) {
    if (restrictionIsChild(restriction)) {
      if (parents.find(propEq("key", restriction.keyParent))) {
        children.push(restriction)
      } else {
        const parent = restrictionOptions.find(propEq("key", restriction.keyParent))
        if (parent) {
          parents.push(parent)
          children.push(restriction)
        } else {
          parents.push(restriction)
        }
      }
    } else {
      const exists = parents.find(propEq("key", restriction.key))
      !exists && parents.push(restriction)
    }
  }
  const nestedRestrictions = parents.map(restriction =>
    assoc("children", children.filter(propEq("keyParent", restriction.key)), restriction),
  )
  const groupedRestrictions = groupBy(prop("category"), nestedRestrictions)
  return {
    filteredRestrictions: groupedRestrictions,
    filteredCategories: categories.filter(({ value }) => keys(groupedRestrictions).includes(value)),
  }
}

const StyledSearchInput = styled(SearchInput)`
  ::placeholder {
    color: ${({ theme }) => theme.color.darkGrayAlt};
  }
`

const StyledSearchContainer = styled(Box)`
  border: ${({ theme }) => `1px solid ${theme.color.secondary["025"]}`};
  border-radius: ${sizer(1)};
`

const RestrictionForm = ({ restrictions, nodeClass, updatePolicyItem, isLocationTrackingEnabled, parentPolicy }) => {
  const [isLoading, setIsLoading] = useMountedState(true)
  const [filters, setFilters] = useState({ searchText: "", enrollmentType: "All", category: "All" })
  const [{ categories, restrictionOptions }, setRestrictionOptions] = useMountedState({
    categories: [],
    restrictionOptions: [],
  })

  useEffect(() => {
    async function fetchRestrictionConfiguration() {
      try {
        const _restrictionOptions = await getRestrictionsOptions(nodeClass)
        setRestrictionOptions(_restrictionOptions)
      } catch (error) {
        showErrorMessage(localized("Error while loading the Restrictions"))
      }
      setIsLoading(false)
    }

    fetchRestrictionConfiguration()
  }, [setRestrictionOptions, nodeClass, setIsLoading])

  const { filteredCategories, filteredRestrictions } = useMemo(
    () => filterRestrictions(restrictionOptions, nodeClass, categories, filters),
    [restrictionOptions, categories, nodeClass, filters],
  )

  const setRestrictionValue = (category, key, value, keyParent) => {
    if (value === path([category, key], restrictions)) {
      return
    }
    let _restrictions = restrictions
    if (keyParent && !path([category, keyParent], restrictions)) {
      _restrictions = assocPath([category, keyParent], true, restrictions)
    } else if (restrictionIsParent(key, restrictionOptions) && !value) {
      const children = restrictionOptions.filter(propEq("keyParent", key))
      for (const childRestriction of children) {
        _restrictions = assocPath([category, childRestriction.key], false, _restrictions)
      }
    }
    const newRestrictions = assocPath([category, key], value, _restrictions)
    updatePolicyItem(`restrictions.${category}`, parentPolicy, newRestrictions[category])
  }

  return (
    <Flex flexDirection="column" marginLeft={sizer(6)} paddingRight={sizer(3)} height="100%">
      <TitleGroup
        titleToken={localizationKey("Restrictions")}
        descriptionToken={localizationKey("Manage restrictions settings.")}
      />
      <Flex alignItems="center" margin={sizer(3, 0)}>
        <StyledSearchContainer padding={sizer(0, 3)}>
          <StyledSearchInput
            onChange={e => setFilters({ ...filters, searchText: e.target.value })}
            placeholderText={localized("Search")}
            flexDirection="row-reverse"
            iconColor="darkGrayAlt"
            height="36px"
          />
        </StyledSearchContainer>
        <Box margin={sizer(0, 3)}>
          <Select
            size="sm"
            triggerAriaLabel={localized("Category")}
            placeholderLabel={localized("Category")}
            options={[defaultAllOption, ...categories]}
            onChange={category => setFilters({ ...filters, category })}
          />
        </Box>
        <Select
          size="sm"
          triggerAriaLabel={localized("Enrollment type")}
          placeholderLabel={localized("Enrollment type")}
          options={[defaultAllOption, ...getEnrollmentTypes(nodeClass)]}
          onChange={enrollmentType => setFilters({ ...filters, enrollmentType })}
        />
      </Flex>
      <Flex flexDirection="column" flex={1} minHeight="1px" overflowY="auto">
        {isEmpty(filteredCategories) ? (
          isLoading ? (
            <Flex height="100%" alignItems="center" justifyContent="center" width="100%">
              <Indicator />
            </Flex>
          ) : (
            <Box paddingLeft={sizer(1)} marginTop={sizer(3)}>
              <Text type="body" token={localizationKey("No restrictions found")} />
            </Box>
          )
        ) : (
          filteredCategories.map(category => (
            <Box flexDirection="column" key={category.value} marginBottom={sizer(5)} id={category.value}>
              <InheritableRowPolicyItem
                inheritableItem={restrictions[category.value] ?? { inheritance: {} }}
                pathToItem={`restrictions.${category.value}`}
                wrapperProps={{ padding: `${tokens.spacing[3]} 2px` }}
                testId={`${category.value}-category`}
                borderColor="colorBorderStrong"
              >
                <Text color="colorTextStrong" token={category.labelToken} fontWeight={fontWeight.medium} />
              </InheritableRowPolicyItem>
              {filteredRestrictions[category.value]?.map((restrictionItem, index) => (
                <Fragment key={restrictionItem.key}>
                  <RestrictionItem
                    {...{ restrictionItem, category, restrictions, isLocationTrackingEnabled }}
                    onChange={setRestrictionValue}
                  />
                  {restrictionItem.children.map(childRestriction => (
                    <RestrictionItem
                      {...{ category, restrictions, isLocationTrackingEnabled }}
                      key={childRestriction.key}
                      restrictionItem={childRestriction}
                      onChange={setRestrictionValue}
                      keyParent={restrictionItem.key}
                      isChild
                    />
                  ))}
                </Fragment>
              ))}
            </Box>
          ))
        )}
      </Flex>
    </Flex>
  )
}

const RestrictionItem = ({
  restrictionItem,
  category,
  restrictions,
  onChange: _onChange,
  keyParent,
  isChild,
  isLocationTrackingEnabled,
}) => {
  const valueFromPolicy = restrictions[category.value]?.[restrictionItem.key]
  const { labelToken, descriptionToken, defaultValue, checkLocationTrackingPolicyEnabled = false } = restrictionItem

  const onChange = value => _onChange(category.value, restrictionItem.key, value, keyParent)

  const value = valueFromPolicy ?? defaultValue
  const disabled = !!(checkLocationTrackingPolicyEnabled && isLocationTrackingEnabled)

  return (
    <StyledRow>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        paddingLeft={isChild ? tokens.spacing[7] : 0}
        width="100%"
      >
        {restrictionItem.valueType === "boolean" && (
          <Box>
            <Text type="body" color="colorTextStrong">
              <Checkbox
                onChange={({ isChecked }) => onChange(isChecked)}
                label={localized(labelToken)}
                checked={!!value}
                fontWeight={500}
                disabled={disabled}
              />
            </Text>
            {descriptionToken && (
              <Box marginLeft="26px">
                <Text token={descriptionToken} type="body" textWrapLineLimit={5} color="colorTextWeak" textWrap />
              </Box>
            )}
          </Box>
        )}
        {restrictionItem.valueType === "number" && (
          <Flex justifyContent="space-between" width="100%">
            <Box paddingRight={tokens.spacing[4]}>
              <Text token={labelToken} type="body" color="colorTextStrong" fontWeight={fontWeight.medium} />
              {descriptionToken && (
                <Text token={descriptionToken} type="body" textWrapLineLimit={5} color="colorTextWeak" textWrap />
              )}
            </Box>
            <Flex alignItems="center">
              <Stepper
                ariaLabel={labelToken}
                compact
                min={restrictionItem.minimumValue ?? 0}
                max={restrictionItem.maximumValue ?? 100}
                value={Number(value ?? restrictionItem.minimumValue ?? 0)}
                {...{ onChange }}
                disabled={disabled}
              />
            </Flex>
          </Flex>
        )}
        {restrictionItem.valueType === "enum" && (
          <Flex justifyContent="space-between" width="100%" gap={tokens.spacing[8]}>
            <Box flex={1}>
              <Text token={labelToken} size="sm" color="text" bold />
              {descriptionToken && (
                <Box>
                  <Text token={descriptionToken} size="sm" textWrapLineLimit={6} textWrap />
                </Box>
              )}
            </Box>
            <Box flex={1}>
              <Select
                options={addLabelTokenToEnumOptions(restrictionItem.key, restrictionItem.values)}
                value={value}
                alignRight={false}
                {...{ onChange }}
                labelRenderer={() => {
                  const optionsList = addLabelTokenToEnumOptions(restrictionItem.key, restrictionItem.values)
                  const currentValue = value ?? restrictionItem.values[0].value
                  const selectedValue = optionsList.find(option => option.value === currentValue)?.labelToken ?? ""
                  return (
                    <Box textAlign="left">
                      <Text type="body" textWrap={true} textWrapLineLimit={1}>
                        {selectedValue ? localized(selectedValue) : localized("Select")}
                      </Text>
                    </Box>
                  )
                }}
                disabled={disabled}
              />
            </Box>
          </Flex>
        )}
      </Flex>
    </StyledRow>
  )
}

export default connect(
  ({
    policyEditor: {
      policy: { content, nodeClass },
      parentPolicy,
    },
  }) => ({
    nodeClass,
    parentPolicy,
    restrictions: content.restrictions,
    isLocationTrackingEnabled: content?.findDevice?.generalSettings?.enabled || false,
  }),
  {
    updatePolicyItem: _updatePolicyItem,
  },
)(RestrictionForm)
