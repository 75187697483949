import { Fragment, useMemo, useState } from "react"
import { connect } from "react-redux"
import {
  append,
  indexOf,
  join,
  remove,
  find,
  propEq,
  map,
  isEmpty,
  path,
  assoc,
  pathOr,
  compose,
  pluck,
  filter,
} from "ramda"
import styled from "@emotion/styled"

import tokens from "@ninjaone/tokens"
import { Select, Switch, TitleGroup, Stepper, Heading, SearchableSelect, AlertMessage } from "@ninjaone/components"
import { Label } from "@ninjaone/components/src/Form/Label"

import { updatePolicyItem as _updatePolicyItem } from "js/state/actions/policyEditor/editor"
import { Box, Flex, StyledTruncatedTextContainer } from "js/includes/components/Styled"

import { emailValidation } from "js/includes/common/utils"

import { canViewMDMConnection } from "js/includes/configuration/integrations/mdm/permissions"
import { MDMPermissionType } from "js/includes/configuration/integrations/mdm/constants"

import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import InheritableRowPolicyItem from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/common/InheritableRowPolicyItem"
import {
  EncryptionPolicy,
  KeyguardDisabledFeature,
  BatteryPluggedMode,
  DeveloperSettings,
  ShowWorkContactsInPersonalProfile,
  CrossProfileCopyPaste,
  CrossProfileDataSharing,
  SystemUpdateType,
  PlayStoreMode,
  MtePolicySettings,
  AssistContentPolicyType,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/android/enums"
import { defaultInheritance } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"

import { isValidPackageName } from "./android/util"
import FreezePeriod from "./android/security/FreezePeriod"
import CustomMessaging from "./android/security/CustomMessaging"
import { FlexRowWrapper, LabelWrapper, SelectWrapper } from "./common/styled"
import PrivateKeyRulesTable from "./android/security/PrivateKeyRulesTable"

const { spacing } = tokens

// TODO: Move all options to enums or util file in task NJ-94312

export const encryptionPolicyOptions = () => [
  { value: EncryptionPolicy.ENCRYPTION_POLICY_UNSPECIFIED, labelText: localized("Unspecified") },
  { value: EncryptionPolicy.ENABLED_WITHOUT_PASSWORD, labelText: localized("Enabled without Password") },
  { value: EncryptionPolicy.ENABLED_WITH_PASSWORD, labelText: localized("Enabled with Password") },
]

const keyguardDisabledFeaturesOptions = [
  // TODO: NJ-72878 KEYGUARD_DISABLED_FEATURE_UNSPECIFIED should not be given as options and instead be set when the selection is empty
  {
    value: KeyguardDisabledFeature.KEYGUARD_DISABLED_FEATURE_UNSPECIFIED,
    labelToken: localizationKey("This value is ignored"),
  },
  {
    value: KeyguardDisabledFeature.CAMERA,
    labelToken: localizationKey("Disable the camera on secure keyguard screens (e.g. PIN)"),
  },
  {
    value: KeyguardDisabledFeature.NOTIFICATIONS,
    labelToken: localizationKey("Disable showing all notifications on secure keyguard screens"),
  },
  {
    value: KeyguardDisabledFeature.UNREDACTED_NOTIFICATIONS,
    labelToken: localizationKey("Disable unredacted notifications on secure keyguard screens"),
  },
  {
    value: KeyguardDisabledFeature.TRUST_AGENTS,
    labelToken: localizationKey("Ignore trust agent state on secure keyguard screens"),
  },
  {
    value: KeyguardDisabledFeature.DISABLE_FINGERPRINT,
    labelToken: localizationKey("Disable fingerprint sensor on secure keyguard screens"),
  },
  {
    value: KeyguardDisabledFeature.DISABLE_REMOTE_INPUT,
    labelToken: localizationKey("Disable text entry into notifications on secure keyguard screens"),
  },
  {
    value: KeyguardDisabledFeature.FACE,
    labelToken: localizationKey("Disable face authentication on secure keyguard screens"),
  },
  {
    value: KeyguardDisabledFeature.IRIS,
    labelToken: localizationKey("Disable iris authentication on secure keyguard screens"),
  },
  {
    value: KeyguardDisabledFeature.BIOMETRICS,
    labelToken: localizationKey("Disable all biometric authentication on secure keyguard screens"),
  },
  {
    value: KeyguardDisabledFeature.ALL_FEATURES,
    labelToken: localizationKey("Disable all current and future keyguard customizations"),
  },
]

const stayOnPluggedModesOptions = [
  // TODO: NJ-72878 BATTERY_PLUGGED_MODE_UNSPECIFIED should not be given as options and instead be set when the selection is empty
  { value: BatteryPluggedMode.BATTERY_PLUGGED_MODE_UNSPECIFIED, labelToken: localizationKey("Unspecified") },
  { value: BatteryPluggedMode.AC, labelToken: localizationKey("AC") },
  { value: BatteryPluggedMode.USB, labelText: "USB" },
  { value: BatteryPluggedMode.WIRELESS, labelToken: localizationKey("Wireless") },
]

const developerSettingsOptions = () => [
  { value: DeveloperSettings.DEVELOPER_SETTINGS_UNSPECIFIED, labelText: localized("Unspecified") },
  { value: DeveloperSettings.DEVELOPER_SETTINGS_DISABLED, labelText: localized("Disabled") },
  { value: DeveloperSettings.DEVELOPER_SETTINGS_ALLOWED, labelText: localized("Allowed") },
]

const mtePolicySettingsOptions = () => [
  { value: MtePolicySettings.MTE_POLICY_UNSPECIFIED, labelText: localized("Unspecified") },
  { value: MtePolicySettings.MTE_USER_CHOICE, labelText: localized("User choice") },
  { value: MtePolicySettings.MTE_ENFORCED, labelText: localized("Enforced") },
  { value: MtePolicySettings.MTE_DISABLED, labelText: localized("Disabled") },
]

const showWorkContactsInPersonalProfileOptions = () => [
  {
    value: ShowWorkContactsInPersonalProfile.SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_UNSPECIFIED,
    labelText: localized("Unspecified"),
  },
  {
    value: ShowWorkContactsInPersonalProfile.SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_DISALLOWED,
    labelText: localized("Disallowed"),
  },
  {
    value: ShowWorkContactsInPersonalProfile.SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_ALLOWED,
    labelText: localized("Allowed"),
  },
  // TODO: NJ-72879 Support new SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_DISALLOWED_EXCEPT_SYSTEM option
]

const crossProfileCopyPasteOptions = () => [
  { value: CrossProfileCopyPaste.CROSS_PROFILE_COPY_PASTE_UNSPECIFIED, labelText: localized("Unspecified") },
  { value: CrossProfileCopyPaste.COPY_FROM_WORK_TO_PERSONAL_DISALLOWED, labelText: localized("Disallowed") },
  { value: CrossProfileCopyPaste.CROSS_PROFILE_COPY_PASTE_ALLOWED, labelText: localized("Allowed") },
]

const crossProfileDataSharingOptions = () => [
  { value: CrossProfileDataSharing.CROSS_PROFILE_DATA_SHARING_UNSPECIFIED, labelText: localized("Unspecified") },
  { value: CrossProfileDataSharing.CROSS_PROFILE_DATA_SHARING_DISALLOWED, labelText: localized("Disallowed") },
  {
    value: CrossProfileDataSharing.DATA_SHARING_FROM_WORK_TO_PERSONAL_DISALLOWED,
    labelText: localized("From Work to Personal Disallowed"),
  },
  { value: CrossProfileDataSharing.CROSS_PROFILE_DATA_SHARING_ALLOWED, labelText: localized("Allowed") },
]

const systemUpdateConfigTypeOptions = () => [
  { value: SystemUpdateType.SYSTEM_UPDATE_TYPE_UNSPECIFIED, labelText: localized("Unspecified") },
  { value: SystemUpdateType.AUTOMATIC, labelText: localized("Automatic") },
  { value: SystemUpdateType.WINDOWED, labelText: localized("Windowed") },
  { value: SystemUpdateType.POSTPONE, labelText: localized("Postpone") },
]

const assistContentPolicyOptions = [
  {
    value: AssistContentPolicyType.ASSIST_CONTENT_POLICY_UNSPECIFIED,
    labelText: localized("Unspecified"),
  },
  {
    value: AssistContentPolicyType.ASSIST_CONTENT_ALLOWED,
    labelText: localized("Allowed"),
  },
  {
    value: AssistContentPolicyType.ASSIST_CONTENT_DISALLOWED,
    labelText: localized("Blocked"),
  },
]

const getSecurityFormLocalizedLabels = () => ({
  MAX_TIME_TO_LOCK: localized("Maximum time to lock (seconds)"),
  ENCRYPTION_POLICY: localized("Encryption policy"),
  DISABLED_KEYGUARD_CUSTOMIZATION: localized("Disabled keyguard customizations"),
  BATTERY_PLUGGED_MODE: localized("Battery plugged mode"),
  DEVELOPER_SETTINGS: localized("Developer settings"),

  PERMITTED_INPUT_METHODS: localized("Allowed input methods"),
  PERMITTED_INPUT_METHODS_TOOLTIP: localized(
    "Define a list of input methods, or keyboard apps, that are permitted for use on managed Android devices.",
  ),
  PACKAGE_NAMES: localized("Package names"),

  ALLOWED_ACCESSIBILITY_SERVICES: localized("Allowed accessibility services"),
  ALLOWED_ACCESSIBILITY_TOOLTIP: localized(
    "Determines the accessibility services permitted for use on a device, blocking all others. Note this may detrimentally impact accessibility for device users reliant on specific services.",
  ),

  FRP_ALLOWLISTED_ACCOUNTS: localized("Factory reset protection allowlisted accounts"),
  MAX_DAYS_WITH_WORK_OFF: localized("Controls how long the work profile can stay off (days)"),
  SHOW_WORK_CONTACTS_IN_PERSONAL: localized("Shown in personal profile contact searches and incoming calls"),
  COPY_AND_PASTE_BETWEEN_PROFILES: localized("Copy and paste between profiles"),
  CROSS_PROFILE_DATA_SHARING: localized("Data from either profile can be shared with the other profile"),
  SYSTEM_UPDATE_TYPE: localized("Type"),
  START_MINUTES: localized("Start minutes"),
  END_MINUTES: localized("End minutes"),
  SHORT_SUPPORT_MESSAGE: localized("Short support message"),
  LONG_SUPPORT_MESSAGE: localized("Long support message"),
  LOCK_SCREEN_MESSAGE: localized("Lock screen message"),

  SHOW_WORK_CONTACTS_ARIA_LABEL: localized("Show work contacts in personal profile"),
  CROSS_PROFILE_ARIA_LABEL: localized("Cross profile data sharing"),
  SYSTEM_UPDATE_TYPE_ARIA_LABEL: localized("System update config type"),

  MEMORY_TAGGING_EXTENSION: localized("Memory tagging extension"),
  ASSIST_CONTENT_POLICY: localized("Send content to assist apps"),
  ENTER_PACKAGE_NAMES: localized("Enter a package name"),
  INVALID_PACKAGE_NAME: localized("Invalid package name"),
  INVALID_EMAIL: localized("Invalid email"),
})

// TODO: Centralize GroupHeading and FieldRow to own components in task NJ-94312

const StyledGroupHeading = styled(Box)`
  /* 
    Styles when there's inheritance in the heading 
    InheritableRowPolicyItem already provides padding and border
  */
  ${({ noPadding }) => !noPadding && `padding-bottom: ${spacing[2]}`};
  ${({ noBorder, theme }) => !noBorder && `border-bottom: 1px solid ${theme.colorBorderWeakest}`};
`

export const GroupHeading = ({ textToken, inheritance = null, headingLevel = 3 }) => {
  const Wrapper = !!inheritance ? InheritableRowPolicyItem : Fragment

  return (
    <Box marginTop={spacing[4]} data-test-name="GroupHeading">
      <Wrapper {...(!!inheritance && { ...inheritance, wrapperProps: { padding: `${spacing[2]}` } })}>
        <StyledGroupHeading {...(!!inheritance && { noBorder: true, noPadding: true })}>
          <Heading level={headingLevel} type="headingS" color="colorTextStrong" children={localized(textToken)} />
        </StyledGroupHeading>
      </Wrapper>
    </Box>
  )
}

const StyledFieldRow = styled(Flex)`
  &:hover {
    background-color: ${({ theme }) => theme.color.black["005"]};
  }
  border-bottom: ${({ theme }) => `1px solid ${theme.colorBorderWeakest}`};
  /*
    Extra padding so FieldRows without inheritance have the same styling as the ones that do
  */
  ${({ addPadding }) => addPadding && `padding: ${spacing[5]} ${spacing[2]};`}
`

const StyledMessagingRow = styled(Flex)`
  justify-content: space-between;
  align-items: flex-start;
  padding-top: ${spacing[3]};
  gap: ${spacing[8]};
  border-bottom: ${({ theme }) => `1px solid ${theme.colorBorderWeakest}`};
`

export const FieldRow = ({ children, inheritance = null }) => {
  const Wrapper = !!inheritance ? InheritableRowPolicyItem : Fragment

  return (
    <StyledFieldRow {...{ addPadding: !inheritance }} data-test-name="FieldRow">
      <Wrapper {...(!!inheritance && { ...inheritance, noBorder: true })}>
        <FlexRowWrapper width="100%" maxWidth={!!inheritance ? "100%" : "calc(100% - 200px)"}>
          {children}
        </FlexRowWrapper>
      </Wrapper>
    </StyledFieldRow>
  )
}

const root = "security"

// TODO : Move keys and default values to util in task NJ-94312

const keys = {
  lockSettings: "lockSettings",
  maximumTimeToLock: "maximumTimeToLock",

  encryptionSettings: "encryptionSettings",
  encryptionPolicy: "encryptionPolicy",

  keyguardSettings: "keyguardSettings",
  keyguardDisabledFeatures: "keyguardDisabledFeatures",

  batterySettings: "batterySettings",
  stayOnPluggedModes: "stayOnPluggedModes",

  developerSettings: "developerSettings",

  permittedInputMethodsSettings: "permittedInputMethodsSettings",
  permittedInputMethods: "permittedInputMethods",

  mtePolicySettings: "mtePolicySettings",
  mtePolicy: "mtePolicy",

  credentialsConfigDisabledSettings: "credentialsConfigDisabledSettings",
  credentialsConfigDisabled: "credentialsConfigDisabled",

  assistContentPolicySettings: "assistContentPolicySettings",
  assistContentPolicy: "assistContentPolicy",

  permittedAccessibilityServicesSettings: "permittedAccessibilityServicesSettings",
  permittedAccessibilityServices: "permittedAccessibilityServices",

  choosePrivateKeyRules: "choosePrivateKeyRules",

  personalUsageSettings: "personalUsageSettings",
  cameraDisabled: "cameraDisabled",
  screenCaptureDisabled: "screenCaptureDisabled",
  maxDaysWithWorkOff: "maxDaysWithWorkOff",
  personalPlayStoreMode: "personalPlayStoreMode",

  crossProfileSettings: "crossProfileSettings",
  showWorkContactsInPersonalProfile: "showWorkContactsInPersonalProfile",
  crossProfileCopyPaste: "crossProfileCopyPaste",
  crossProfileDataSharing: "crossProfileDataSharing",

  systemUpdateSettings: "systemUpdateSettings",
  updateTypeSettings: "updateTypeSettings",
  type: "type",
  startMinutes: "startMinutes",
  endMinutes: "endMinutes",
  freezePeriodsSettings: "freezePeriodsSettings",
  freezePeriods: "freezePeriods",

  minimumApiLevelSettings: "minimumApiLevelSettings",
  minimumApiLevel: "minimumApiLevel",
  frpAdminEmailSettings: "frpAdminEmailSettings",
  frpAdminEmails: "frpAdminEmails",

  deviceOwnerLockScreenInfo: "deviceOwnerLockScreenInfo",
  shortSupportMessage: "shortSupportMessage",
  longSupportMessage: "longSupportMessage",
  defaultMessage: "defaultMessage",
  message: "message",
  active: "active",
  localizedMessages: "localizedMessages",
}

export const defaultValues = {
  [keys.lockSettings]: { [keys.maximumTimeToLock]: 0, ...defaultInheritance },
  [keys.encryptionSettings]: {
    [keys.encryptionPolicy]: EncryptionPolicy.ENCRYPTION_POLICY_UNSPECIFIED,
    ...defaultInheritance,
  },
  [keys.keyguardSettings]: {
    [keys.keyguardDisabledFeatures]: [],
    ...defaultInheritance,
  },
  [keys.permittedAccessibilityServicesSettings]: {
    [keys.permittedAccessibilityServices]: {
      packageNames: [],
    },
    ...defaultInheritance,
  },
  [keys.batterySettings]: {
    [keys.stayOnPluggedModes]: [],
    ...defaultInheritance,
  },
  [keys.developerSettings]: {
    [keys.developerSettings]: DeveloperSettings.DEVELOPER_SETTINGS_UNSPECIFIED,
    ...defaultInheritance,
  },
  [keys.permittedInputMethodsSettings]: {
    [keys.permittedInputMethods]: [],
    ...defaultInheritance,
  },
  [keys.mtePolicySettings]: {
    [keys.mtePolicy]: MtePolicySettings.MTE_POLICY_UNSPECIFIED,
    ...defaultInheritance,
  },
  [keys.credentialsConfigDisabledSettings]: {
    [keys.credentialsConfigDisabled]: false,
    ...defaultInheritance,
  },
  [keys.assistContentPolicySettings]: {
    [keys.assistContentPolicy]: AssistContentPolicyType.ASSIST_CONTENT_POLICY_UNSPECIFIED,
    ...defaultInheritance,
  },
  [keys.personalUsageSettings]: {
    [keys.cameraDisabled]: false,
    [keys.screenCaptureDisabled]: false,
    [keys.maxDaysWithWorkOff]: 0,
    [keys.personalPlayStoreMode]: PlayStoreMode.PLAY_STORE_MODE_UNSPECIFIED,
    ...defaultInheritance,
  },
  [keys.crossProfileSettings]: {
    [keys.showWorkContactsInPersonalProfile]:
      ShowWorkContactsInPersonalProfile.SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_UNSPECIFIED,
    [keys.crossProfileCopyPaste]: CrossProfileCopyPaste.CROSS_PROFILE_COPY_PASTE_UNSPECIFIED,
    [keys.crossProfileDataSharing]: CrossProfileDataSharing.CROSS_PROFILE_DATA_SHARING_UNSPECIFIED,
    ...defaultInheritance,
  },
  [keys.systemUpdateSettings]: {
    [keys.updateTypeSettings]: {
      type: SystemUpdateType.SYSTEM_UPDATE_TYPE_UNSPECIFIED,
      ...defaultInheritance,
    },
    [keys.freezePeriodsSettings]: {
      freezePeriods: [],
      ...defaultInheritance,
    },
  },
  [keys.minimumApiLevelSettings]: {
    [keys.minimumApiLevel]: null,
    ...defaultInheritance,
  },
  [keys.frpAdminEmailSettings]: {
    [keys.frpAdminEmails]: [],
    ...defaultInheritance,
  },
  [keys.choosePrivateKeyRules]: {},
  [keys.deviceOwnerLockScreenInfo]: {
    [keys.defaultMessage]: {
      [keys.message]: "",
      [keys.active]: false,
      ...defaultInheritance,
    },
    [keys.localizedMessages]: {},
  },
  [keys.shortSupportMessage]: {
    [keys.defaultMessage]: {
      [keys.message]: "",
      [keys.active]: false,
      ...defaultInheritance,
    },
    [keys.localizedMessages]: {},
  },
  [keys.longSupportMessage]: {
    [keys.defaultMessage]: {
      [keys.message]: "",
      [keys.active]: false,
      ...defaultInheritance,
    },
    [keys.localizedMessages]: {},
  },
}

const canViewApps = entityId => canViewMDMConnection(MDMPermissionType.Android, { entityId })

const getAvailablePackageNameOptions = (packageNamesList = [], permittedMethods = []) =>
  mapArrayValuesToOptions([...new Set([...packageNamesList, ...permittedMethods])])

const mapArrayValuesToOptions = arrayList => arrayList.map(arrayValue => ({ value: arrayValue, labelText: arrayValue }))

const SecurityForm = ({ securityPolicy, applicationsList, updatePolicyItem, parentPolicy }) => {
  const {
    lockSettings: { maximumTimeToLock = defaultValues.lockSettings.maximumTimeToLock } = {},
    encryptionSettings: { encryptionPolicy = defaultValues.encryptionSettings.encryptionPolicy } = {},
    keyguardSettings: { keyguardDisabledFeatures = defaultValues.keyguardSettings.keyguardDisabledFeatures } = {},
    batterySettings: { stayOnPluggedModes = defaultValues.batterySettings.stayOnPluggedModes } = {},
    developerSettings: { developerSettings = defaultValues.developerSettings.developerSettings } = {},
    permittedInputMethodsSettings: {
      permittedInputMethods = defaultValues.permittedInputMethodsSettings.permittedInputMethods,
    } = {},
    mtePolicySettings: { mtePolicy = defaultValues.mtePolicySettings.mtePolicy } = {},
    credentialsConfigDisabledSettings: {
      credentialsConfigDisabled = defaultValues.credentialsConfigDisabledSettings.credentialsConfigDisabled,
    } = {},
    assistContentPolicySettings: {
      assistContentPolicy = defaultValues.assistContentPolicySettings.assistContentPolicy,
    } = {},
    permittedAccessibilityServicesSettings: {
      permittedAccessibilityServices: {
        packageNames: permittedAccessibilityPackageNames = defaultValues.permittedAccessibilityServicesSettings
          .permittedAccessibilityServices.packageNames,
      } = defaultValues.permittedAccessibilityServicesSettings.permittedAccessibilityServices,
    } = {},
    personalUsageSettings: {
      cameraDisabled = defaultValues.personalUsageSettings.cameraDisabled,
      screenCaptureDisabled = defaultValues.personalUsageSettings.screenCaptureDisabled,
      maxDaysWithWorkOff = defaultValues.personalUsageSettings.maxDaysWithWorkOff,
    } = {},
    crossProfileSettings: {
      showWorkContactsInPersonalProfile = defaultValues.crossProfileSettings.showWorkContactsInPersonalProfile,
      crossProfileCopyPaste = defaultValues.crossProfileSettings.crossProfileCopyPaste,
      crossProfileDataSharing = defaultValues.crossProfileSettings.crossProfileDataSharing,
    } = {},
    frpAdminEmailSettings: { frpAdminEmails = defaultValues.frpAdminEmailSettings.frpAdminEmails } = {},
    choosePrivateKeyRules = defaultValues.choosePrivateKeyRules,
    systemUpdateSettings: {
      updateTypeSettings: {
        type: systemUpdateType = defaultValues.systemUpdateSettings.updateTypeSettings.type,
        startMinutes: systemUpdateStartMinutes = 0,
        endMinutes: systemUpdateEndMinutes = 0,
      } = defaultValues.systemUpdateSettings.updateTypeSettings,
      freezePeriodsSettings: {
        freezePeriods = defaultValues.systemUpdateSettings.freezePeriodsSettings.freezePeriods,
      } = defaultValues.systemUpdateSettings.freezePeriodsSettings,
    } = {},
    minimumApiLevelSettings: { minimumApiLevel = defaultValues.minimumApiLevelSettings.minimumApiLevel } = {},
    shortSupportMessage = defaultValues.shortSupportMessage,
    longSupportMessage = defaultValues.longSupportMessage,
    deviceOwnerLockScreenInfo = defaultValues.deviceOwnerLockScreenInfo,
  } = securityPolicy ?? {}

  const [showErrorForPermittedInputMethods, setShowErrorForPermittedInputMethods] = useState(false)
  const [showErrorForPermittedAccessibilityServices, setShowErrorForPermittedAccessibilityServices] = useState(false)
  const [showFactoryResetEmailError, setShowFactoryResetEmailError] = useState(false)

  const availablePackagesNamesList = useMemo(
    () =>
      compose(
        pluck("packageName"),
        filter(app => canViewApps(app?.connectionId)),
      )(applicationsList),
    [applicationsList],
  )

  const availablePermittedInputMethodsOptions = getAvailablePackageNameOptions(
    availablePackagesNamesList,
    permittedInputMethods,
  )

  const availablePermittedAccessibilityServicesOptions = getAvailablePackageNameOptions(
    availablePackagesNamesList,
    permittedAccessibilityPackageNames,
  )

  const securityFormLabels = getSecurityFormLocalizedLabels()

  const frpEmailOptions = mapArrayValuesToOptions(frpAdminEmails)

  const updateState = (category, key, value) => {
    const categoryPath = [...category.split(".")]
    const updatedPath = [categoryPath, key]
    const currentValue = path(updatedPath, securityPolicy)

    if (value === currentValue) return

    const defaultValue = path(categoryPath, defaultValues)
    const currentCategory = pathOr(defaultValue, categoryPath, securityPolicy)
    const updatedSecurity = assoc(key, value, currentCategory)

    // Handle side-effects of system update type change
    if (category.includes(keys.updateTypeSettings) && key === keys.type) {
      // Remove start/end minutes if not set to windowed
      if (updatedSecurity[keys.type] !== SystemUpdateType.WINDOWED) {
        delete updatedSecurity[keys.startMinutes]
        delete updatedSecurity[keys.endMinutes]
      }
    }

    // Add default personal Play Store mode on policy if not there already
    if (securityPolicy?.personalUsageSettings?.personalPlayStoreMode !== PlayStoreMode.PLAY_STORE_MODE_UNSPECIFIED) {
      updatePolicyItem(`${root}.${keys.personalUsageSettings}`, parentPolicy, {
        ...defaultValues.personalUsageSettings,
        ...securityPolicy.personalUsageSettings,
      })
    }

    updatePolicyItem(`${root}.${category}`, parentPolicy, updatedSecurity)
  }

  const updateMultipleValue = (category, key, value, list) => {
    const valueExists = indexOf(value)(list)
    const newValuesList = valueExists >= 0 ? remove(valueExists, 1, list) : append(value, list)
    updateState(category, key, newValuesList)
  }

  const updateFreezePeriods = updatedFreezePeriods => {
    updateState(`${keys.systemUpdateSettings}.${keys.freezePeriodsSettings}`, keys.freezePeriods, updatedFreezePeriods)
  }

  const updateFrpAdminEmail = updatedEmails => {
    updateState(keys.frpAdminEmailSettings, keys.frpAdminEmails, updatedEmails)
  }

  return (
    <Box overflowY="auto" padding={[spacing[0], spacing[4], spacing[12], spacing[6]]}>
      <Box marginBottom={spacing[7]}>
        <TitleGroup
          TitleComponent={() => <Heading level={2} children={localized("Security")} type="headingL" />}
          descriptionToken={localizationKey("Manage security settings for this policy.")}
        />
      </Box>

      <GroupHeading textToken={localizationKey("General")} />

      <FieldRow
        inheritance={{
          pathToItem: `${root}.${keys.minimumApiLevelSettings}`,
          inheritableItem: securityPolicy?.minimumApiLevelSettings ?? defaultInheritance,
        }}
      >
        {/* TODO: epic/NJ-87732-mdm-amapi-solution-set-part-3 use Stepper Label once design changes are confirmed */}
        <Box width="320px">
          <Label
            labelFor={keys.minimumApiLevel}
            labelText={localized("Minimum Android version support (API level)")}
            tooltipText={localized("The minimum allowed Android API level.")}
            textWrap
            textWrapLineLimit={2}
          />
        </Box>
        <Stepper
          compact
          min={0}
          ariaLabel={localized("Minimum Android version support (API level)")}
          value={minimumApiLevel}
          onChange={value => updateState(keys.minimumApiLevelSettings, keys.minimumApiLevel, value > 0 ? value : null)}
        />
      </FieldRow>

      <FieldRow
        inheritance={{
          pathToItem: `${root}.${keys.lockSettings}`,
          inheritableItem: securityPolicy?.lockSettings ?? defaultInheritance,
        }}
      >
        {/* TODO: epic/NJ-87732-mdm-amapi-solution-set-part-3 use Stepper Label once design changes are confirmed */}
        <LabelWrapper>
          <Label
            labelFor={keys.maximumTimeToLock}
            labelText={securityFormLabels.MAX_TIME_TO_LOCK}
            textWrap
            textWrapLineLimit={2}
          />
        </LabelWrapper>
        <Stepper
          compact
          ariaLabel={securityFormLabels.MAX_TIME_TO_LOCK}
          min={0}
          max={1800}
          value={maximumTimeToLock}
          onChange={value => updateState(keys.lockSettings, keys.maximumTimeToLock, value)}
        />
      </FieldRow>

      <FieldRow
        inheritance={{
          pathToItem: `${root}.${keys.credentialsConfigDisabledSettings}`,
          inheritableItem: securityPolicy?.credentialsConfigDisabledSettings ?? defaultInheritance,
        }}
      >
        <Switch
          labelToken={localizationKey("User credentials configuration disabled")}
          checked={credentialsConfigDisabled}
          onChange={checked =>
            updateState(keys.credentialsConfigDisabledSettings, keys.credentialsConfigDisabled, checked)
          }
        />
      </FieldRow>

      <FieldRow
        inheritance={{
          pathToItem: `${root}.${keys.encryptionSettings}`,
          inheritableItem: securityPolicy?.encryptionSettings ?? defaultInheritance,
        }}
      >
        <LabelWrapper>
          <Label labelFor={keys.encryptionPolicy} labelText={securityFormLabels.ENCRYPTION_POLICY} />
        </LabelWrapper>
        <SelectWrapper>
          <Select
            triggerAriaLabel={securityFormLabels.ENCRYPTION_POLICY}
            options={encryptionPolicyOptions()}
            value={encryptionPolicy}
            onChange={selected => updateState(keys.encryptionSettings, keys.encryptionPolicy, selected)}
            matchWidth
            popoverProps={{
              portal: false,
            }}
          />
        </SelectWrapper>
      </FieldRow>

      <FieldRow
        inheritance={{
          pathToItem: `${root}.${keys.keyguardSettings}`,
          inheritableItem: securityPolicy?.keyguardSettings ?? defaultInheritance,
        }}
      >
        <LabelWrapper>
          <Label
            labelFor={keys.keyguardDisabledFeatures}
            labelText={securityFormLabels.DISABLED_KEYGUARD_CUSTOMIZATION}
          />
        </LabelWrapper>
        {/* TODO: replace with SearchableSelect when option for show groupped multiselection value labels will be available */}
        <SelectWrapper>
          <Select
            labelId={keys.keyguardDisabledFeatures}
            triggerAriaLabel={securityFormLabels.DISABLED_KEYGUARD_CUSTOMIZATION}
            options={keyguardDisabledFeaturesOptions}
            value={keyguardDisabledFeatures}
            onChange={selected => {
              updateMultipleValue(
                keys.keyguardSettings,
                keys.keyguardDisabledFeatures,
                selected,
                keyguardDisabledFeatures,
              )
            }}
            labelRenderer={() => {
              const count = keyguardDisabledFeatures.length
              return (
                <StyledTruncatedTextContainer>
                  {count === 0 ? localized("None") : localized("{{count}} option(s) selected", { count })}
                </StyledTruncatedTextContainer>
              )
            }}
          />
        </SelectWrapper>
      </FieldRow>

      <FieldRow
        inheritance={{
          pathToItem: `${root}.${keys.batterySettings}`,
          inheritableItem: securityPolicy?.batterySettings ?? defaultInheritance,
        }}
      >
        <LabelWrapper>
          <Label labelFor={keys.stayOnPluggedModes} labelText={securityFormLabels.BATTERY_PLUGGED_MODE} />
        </LabelWrapper>
        {/* TODO: replace with SearchableSelect when option for show groupped multiselection value labels will be available */}
        <SelectWrapper>
          <Select
            triggerAriaLabel={securityFormLabels.BATTERY_PLUGGED_MODE}
            options={stayOnPluggedModesOptions}
            value={stayOnPluggedModes}
            onChange={selected =>
              updateMultipleValue(keys.batterySettings, keys.stayOnPluggedModes, selected, stayOnPluggedModes)
            }
            labelRenderer={() => {
              const getBatteryModesLabels = selectedValues => {
                if (isEmpty(selectedValues)) return localized("None")

                return join(
                  ", ",
                  map(value => {
                    const { labelToken, labelText } = find(propEq("value", value), stayOnPluggedModesOptions) ?? {}
                    return labelToken ? localized(labelToken) : labelText
                  }, selectedValues),
                )
              }

              return (
                <StyledTruncatedTextContainer>{getBatteryModesLabels(stayOnPluggedModes)}</StyledTruncatedTextContainer>
              )
            }}
          />
        </SelectWrapper>
      </FieldRow>

      <FieldRow
        inheritance={{
          pathToItem: `${root}.${keys.developerSettings}`,
          inheritableItem: securityPolicy?.developerSettings ?? defaultInheritance,
        }}
      >
        <LabelWrapper>
          <Label labelFor={keys.developerSettings} labelText={securityFormLabels.DEVELOPER_SETTINGS} />
        </LabelWrapper>
        <SelectWrapper>
          <Select
            triggerAriaLabel={securityFormLabels.DEVELOPER_SETTINGS}
            options={developerSettingsOptions()}
            value={developerSettings}
            onChange={selected => updateState(keys.developerSettings, keys.developerSettings, selected)}
          />
        </SelectWrapper>
      </FieldRow>

      <FieldRow
        inheritance={{
          pathToItem: `${root}.${keys.permittedInputMethodsSettings}`,
          inheritableItem: securityPolicy?.permittedInputMethodsSettings ?? defaultInheritance,
        }}
      >
        <LabelWrapper>
          <Label
            labelFor={keys.permittedInputMethods}
            labelText={securityFormLabels.PERMITTED_INPUT_METHODS}
            tooltipText={securityFormLabels.PERMITTED_INPUT_METHODS_TOOLTIP}
          />
        </LabelWrapper>
        <SelectWrapper>
          <SearchableSelect
            labelText={securityFormLabels.PACKAGE_NAMES}
            popoverProps={{
              errorMessage: showErrorForPermittedInputMethods ? securityFormLabels.INVALID_PACKAGE_NAME : null,
              onOpenChange: () => setShowErrorForPermittedInputMethods(false),
            }}
            comboboxProps={{
              resetValueOnHide: true,
              creatable: true,
              onCreate: packageName => {
                if (!isValidPackageName(packageName)) {
                  setShowErrorForPermittedInputMethods(true)
                  return
                }
                setShowErrorForPermittedInputMethods(false)
                updateState(keys.permittedInputMethodsSettings, keys.permittedInputMethods, [
                  ...permittedInputMethods,
                  packageName,
                ])
              },
            }}
            options={availablePermittedInputMethodsOptions}
            value={permittedInputMethods}
            onChange={selected => {
              updateState(keys.permittedInputMethodsSettings, keys.permittedInputMethods, selected)
            }}
            isMulti
            placeholderText={securityFormLabels.ENTER_PACKAGE_NAMES}
          />
        </SelectWrapper>
      </FieldRow>

      <FieldRow
        inheritance={{
          pathToItem: `${root}.${keys.permittedAccessibilityServicesSettings}`,
          inheritableItem: securityPolicy?.permittedAccessibilityServicesSettings ?? defaultInheritance,
        }}
      >
        <LabelWrapper>
          <Label
            labelText={securityFormLabels.ALLOWED_ACCESSIBILITY_SERVICES}
            tooltipText={securityFormLabels.ALLOWED_ACCESSIBILITY_TOOLTIP}
          />
        </LabelWrapper>
        <SelectWrapper>
          <SearchableSelect
            popoverProps={{
              errorMessage: showErrorForPermittedAccessibilityServices ? securityFormLabels.INVALID_PACKAGE_NAME : null,
              onOpenChange: () => setShowErrorForPermittedAccessibilityServices(false),
            }}
            labelText={securityFormLabels.PACKAGE_NAMES}
            value={permittedAccessibilityPackageNames}
            comboboxProps={{
              resetValueOnHide: true,
              creatable: true,
              onCreate: packageName => {
                if (!isValidPackageName(packageName)) {
                  setShowErrorForPermittedAccessibilityServices(true)
                  return
                }
                setShowErrorForPermittedAccessibilityServices(false)
                updateState(keys.permittedAccessibilityServicesSettings, keys.permittedAccessibilityServices, {
                  packageNames: [...permittedAccessibilityPackageNames, packageName],
                })
              },
            }}
            onChange={selected => {
              updateState(keys.permittedAccessibilityServicesSettings, keys.permittedAccessibilityServices, {
                packageNames: selected,
              })
            }}
            options={availablePermittedAccessibilityServicesOptions}
            isMulti
            placeholderText={securityFormLabels.ENTER_PACKAGE_NAMES}
          />
        </SelectWrapper>
      </FieldRow>

      <FieldRow
        inheritance={{
          pathToItem: `${root}.${keys.mtePolicySettings}`,
          inheritableItem: securityPolicy?.mtePolicySettings ?? defaultInheritance,
        }}
      >
        <LabelWrapper>
          <Label labelFor={keys.mtePolicySettings} labelText={securityFormLabels.MEMORY_TAGGING_EXTENSION} />
        </LabelWrapper>
        <SelectWrapper>
          <Select
            triggerAriaLabel={securityFormLabels.MEMORY_TAGGING_EXTENSION}
            options={mtePolicySettingsOptions()}
            value={mtePolicy}
            onChange={selected => updateState(keys.mtePolicySettings, keys.mtePolicy, selected)}
          />
        </SelectWrapper>
      </FieldRow>

      <FieldRow
        inheritance={{
          pathToItem: `${root}.${keys.assistContentPolicySettings}`,
          inheritableItem: securityPolicy?.assistContentPolicySettings ?? defaultInheritance,
        }}
      >
        <LabelWrapper>
          <Label
            labelFor={keys.assistContentPolicy}
            labelText={securityFormLabels.ASSIST_CONTENT_POLICY}
            tooltipText={localized(
              "Allows for contextual content about the current app or screen state to be provided to assist apps such as Gemini, supported on Android 15+, disable to prevent content from being shared.",
            )}
          />
        </LabelWrapper>
        <SelectWrapper>
          <Select
            triggerAriaLabel={securityFormLabels.ASSIST_CONTENT_POLICY}
            value={assistContentPolicy}
            options={assistContentPolicyOptions}
            onChange={selected => updateState(keys.assistContentPolicySettings, keys.assistContentPolicy, selected)}
          />
        </SelectWrapper>
      </FieldRow>

      <FieldRow
        inheritance={{
          pathToItem: `${root}.${keys.frpAdminEmailSettings}`,
          inheritableItem: securityPolicy?.frpAdminEmailSettings ?? defaultInheritance,
        }}
      >
        <Flex maxWidth={260}>
          <Label
            id={keys.frpAdminEmails}
            forInputElement
            labelFor={keys.frpAdminEmails}
            labelText={securityFormLabels.FRP_ALLOWLISTED_ACCOUNTS}
            textWrap
            textWrapLineLimit={2}
            tooltipText={localized(
              "Define the permitted Google accounts to unlock a device if Factory Reset Protection is triggered. Account passwords should be rotated often if details are provided directly to end-users.",
            )}
          />
        </Flex>
        <SelectWrapper>
          <SearchableSelect
            ariaLabel={localized("Factory reset protection emails")}
            popoverProps={{
              errorMessage: showFactoryResetEmailError ? securityFormLabels.INVALID_EMAIL : null,
              onOpenChange: () => setShowFactoryResetEmailError(false),
            }}
            comboboxProps={{
              creatable: true,
              onCreate: newEmail => {
                if (!emailValidation(newEmail)) {
                  setShowFactoryResetEmailError(true)
                  return
                }
                setShowFactoryResetEmailError(false)
                updateState(keys.frpAdminEmailSettings, keys.frpAdminEmails, [...frpAdminEmails, newEmail])
              },
            }}
            isMulti
            value={frpAdminEmails}
            onChange={updateFrpAdminEmail}
            options={frpEmailOptions}
            placeholderText={localized("Enter an email")}
          />
        </SelectWrapper>
      </FieldRow>

      <PrivateKeyRulesTable
        withProvider
        {...{
          packageNameOptions: getAvailablePackageNameOptions(availablePackagesNamesList),
          choosePrivateKeyRules,
        }}
      />

      <GroupHeading textToken={localizationKey("Custom messaging")} />

      <Box marginTop={spacing[4]} marginBottom={spacing[6]}>
        <AlertMessage>{localized("All default messages are optional, unless a language is added.")}</AlertMessage>

        {[
          {
            label: securityFormLabels.SHORT_SUPPORT_MESSAGE,
            tooltipText: localized("Displays when an action is not permitted"),
            entryKey: keys.shortSupportMessage,
            entryValue: shortSupportMessage,
          },
          {
            label: securityFormLabels.LONG_SUPPORT_MESSAGE,
            tooltipText: localized("Displays when a user taps for more information on the action disallowed prompt"),
            entryKey: keys.longSupportMessage,
            entryValue: longSupportMessage,
          },
          {
            label: securityFormLabels.LOCK_SCREEN_MESSAGE,
            tooltipText: localized(
              "Define a message that appears on the lockscreen of the device. Short and concise messages are recommended.",
            ),
            entryKey: keys.deviceOwnerLockScreenInfo,
            entryValue: deviceOwnerLockScreenInfo,
          },
        ].map(({ label, tooltipText, entryKey, entryValue }) => (
          <StyledMessagingRow key={entryKey} data-testid={entryKey}>
            <Box minWidth="200px" marginTop="90px">
              <Label labelFor={entryKey} labelText={label} tooltipText={tooltipText} />
            </Box>
            <CustomMessaging
              key={`table-${entryKey}`}
              {...{ entryKey, entryValue, label, tooltipText }}
              withProvider={true}
            />
          </StyledMessagingRow>
        ))}
      </Box>

      <GroupHeading
        textToken={localizationKey("Personal Usage Policies")}
        inheritance={{
          pathToItem: `${root}.${keys.personalUsageSettings}`,
          inheritableItem: securityPolicy?.personalUsageSettings ?? defaultInheritance,
        }}
      />

      <FieldRow>
        <Switch
          labelToken={localizationKey("Camera disabled")}
          checked={cameraDisabled}
          onChange={checked => updateState(keys.personalUsageSettings, keys.cameraDisabled, checked)}
        />
      </FieldRow>

      <FieldRow>
        <Switch
          labelToken={localizationKey("Screen capture disabled")}
          checked={screenCaptureDisabled}
          onChange={checked => updateState(keys.personalUsageSettings, keys.screenCaptureDisabled, checked)}
        />
      </FieldRow>

      <FieldRow>
        <LabelWrapper>
          <Label
            labelFor={keys.maxDaysWithWorkOff}
            labelText={securityFormLabels.MAX_DAYS_WITH_WORK_OFF}
            textWrapLineLimit={2}
            textWrap
          />
        </LabelWrapper>
        <Stepper
          ariaLabel={securityFormLabels.MAX_DAYS_WITH_WORK_OFF}
          compact
          min={0}
          max={400}
          value={maxDaysWithWorkOff}
          onChange={value => updateState(keys.personalUsageSettings, keys.maxDaysWithWorkOff, value)}
        />
      </FieldRow>

      <GroupHeading
        textToken={localizationKey("Cross-profile policies applied on the device")}
        inheritance={{
          pathToItem: `${root}.${keys.crossProfileSettings}`,
          inheritableItem: securityPolicy?.crossProfileSettings ?? defaultInheritance,
        }}
      />

      <FieldRow>
        <LabelWrapper>
          <Label
            labelFor={keys.showWorkContactsInPersonalProfile}
            labelText={securityFormLabels.SHOW_WORK_CONTACTS_IN_PERSONAL}
            textWrap
            textWrapLineLimit={2}
          />
        </LabelWrapper>
        <SelectWrapper>
          <Select
            triggerAriaLabel={securityFormLabels.SHOW_WORK_CONTACTS_IN_PERSONAL}
            options={showWorkContactsInPersonalProfileOptions()}
            value={showWorkContactsInPersonalProfile}
            onChange={selected =>
              updateState(keys.crossProfileSettings, keys.showWorkContactsInPersonalProfile, selected)
            }
            alignRight={false}
          />
        </SelectWrapper>
      </FieldRow>

      <FieldRow>
        <LabelWrapper>
          <Label
            labelFor={keys.crossProfileCopyPaste}
            labelText={securityFormLabels.COPY_AND_PASTE_BETWEEN_PROFILES}
            textWrap
            textWrapLineLimit={2}
          />
        </LabelWrapper>
        <SelectWrapper>
          <Select
            triggerAriaLabel={securityFormLabels.COPY_AND_PASTE_BETWEEN_PROFILES}
            options={crossProfileCopyPasteOptions()}
            value={crossProfileCopyPaste}
            onChange={selected => updateState(keys.crossProfileSettings, keys.crossProfileCopyPaste, selected)}
          />
        </SelectWrapper>
      </FieldRow>

      <FieldRow>
        <LabelWrapper>
          <Label
            labelFor={keys.crossProfileDataSharing}
            labelText={securityFormLabels.CROSS_PROFILE_DATA_SHARING}
            textWrap
            textWrapLineLimit={2}
          />
        </LabelWrapper>
        <SelectWrapper>
          <Select
            triggerAriaLabel={securityFormLabels.CROSS_PROFILE_DATA_SHARING}
            options={crossProfileDataSharingOptions()}
            value={crossProfileDataSharing}
            onChange={selected => updateState(keys.crossProfileSettings, keys.crossProfileDataSharing, selected)}
          />
        </SelectWrapper>
      </FieldRow>

      <GroupHeading
        textToken={localizationKey("System Update Configuration")}
        inheritance={{
          pathToItem: `${root}.${keys.systemUpdateSettings}.${keys.updateTypeSettings}`,
          inheritableItem:
            securityPolicy?.systemUpdateSettings?.updateTypeSettings ??
            defaultValues.systemUpdateSettings.updateTypeSettings,
        }}
      />

      <FieldRow>
        <LabelWrapper>
          <Label labelFor={keys.type} labelText={securityFormLabels.SYSTEM_UPDATE_TYPE} />
        </LabelWrapper>
        <SelectWrapper>
          <Select
            triggerAriaLabel={securityFormLabels.SYSTEM_UPDATE_TYPE_ARIA_LABEL}
            options={systemUpdateConfigTypeOptions()}
            value={systemUpdateType}
            onChange={selected =>
              updateState(`${keys.systemUpdateSettings}.${keys.updateTypeSettings}`, keys.type, selected)
            }
          />
        </SelectWrapper>
      </FieldRow>

      {systemUpdateType === SystemUpdateType.WINDOWED && (
        <>
          <FieldRow>
            {/* TODO: epic/NJ-87732-mdm-amapi-solution-set-part-3 use Stepper Label once design changes are confirmed */}
            <LabelWrapper>
              <Label labelFor="startMinutes" labelText={securityFormLabels.START_MINUTES} />
            </LabelWrapper>
            <Stepper
              compact
              ariaLabel={securityFormLabels.START_MINUTES}
              min={0}
              max={1439}
              value={systemUpdateStartMinutes}
              onChange={value =>
                updateState(`${keys.systemUpdateSettings}.${keys.updateTypeSettings}`, keys.startMinutes, value)
              }
            />
          </FieldRow>

          <FieldRow>
            {/* TODO: epic/NJ-87732-mdm-amapi-solution-set-part-3 use Stepper Label once design changes are confirmed */}
            <LabelWrapper>
              <Label labelFor="endMinutes" labelText={securityFormLabels.END_MINUTES} />
            </LabelWrapper>
            <Stepper
              compact
              ariaLabel={securityFormLabels.END_MINUTES}
              min={0}
              max={1439}
              value={systemUpdateEndMinutes}
              onChange={value =>
                updateState(`${keys.systemUpdateSettings}.${keys.updateTypeSettings}`, keys.endMinutes, value)
              }
            />
          </FieldRow>
        </>
      )}

      <GroupHeading
        textToken={localizationKey("Freeze Periods")}
        inheritance={{
          pathToItem: `${root}.${keys.systemUpdateSettings}.${keys.freezePeriodsSettings}`,
          inheritableItem:
            securityPolicy?.systemUpdateSettings?.freezePeriodsSettings ??
            defaultValues.systemUpdateSettings.freezePeriodsSettings,
        }}
      />

      <FreezePeriod
        {...{
          freezePeriods,
          handleUpdate: updateFreezePeriods,
        }}
      />
    </Box>
  )
}

export default connect(
  ({ policyEditor }) => ({
    securityPolicy: policyEditor.policy.content.security,
    applicationsList: Object.values(policyEditor.policy.content.applications.settingsByApplication),
    parentPolicy: policyEditor.parentPolicy,
  }),
  {
    updatePolicyItem: _updatePolicyItem,
  },
)(SecurityForm)
