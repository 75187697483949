import { NavLink } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import tokens from "@ninjaone/tokens"
import { Tooltip } from "@ninjaone/components"
import { getMainNavStyle, mainNavTextStyle } from "./MainNavButton"
import NavActionIcons from "./NavActionIcons"
import { StyledLinkWrapper } from "./SubNavLink"

const StyledIconWrapper = styled.div`
  display: flex;
  gap: ${tokens.spacing[2]};
  padding-left: ${tokens.spacing[1]};
  padding-right: ${tokens.spacing[4]};
  margin-left: auto;
  font-size: initial; // reset back so font-awesome sizes work as expected
`

const linkStyle = theme => css`
  width: 100%;

  &.active {
    color: ${theme.colorThemeTextStrong};
    background-color: ${theme.colorThemeBackgroundSelected};
  }

  &:not(.active):hover {
    background-color: ${theme.colorThemeBackgroundHover};
  }

  &:focus-visible {
    background-color: ${theme.colorThemeBackgroundHover};
    outline: 2px solid ${theme.colorForegroundFocus};
    outline-offset: -2px;
  }
`

const MainNavLink = ({ to, isActive, icon, label, collapsed, actionIcons }) => {
  const mainNavStyle = getMainNavStyle({ collapsed })
  const showActionIcons = !!actionIcons?.length && !collapsed

  const navLink = (
    <StyledLinkWrapper>
      <NavLink
        {...{
          to,
          isActive,
          css: [mainNavStyle, linkStyle],
          "data-test-sidebar-link": "dashboard-title-link",
          "aria-label": label,
        }}
      >
        <FontAwesomeIcon icon={icon} className="main-icon" data-test-sidebar-link="dashboard-title-icon" fixedWidth />
        {!collapsed && <span css={mainNavTextStyle}>{label}</span>}
      </NavLink>
      {showActionIcons && (
        <StyledIconWrapper right={tokens.spacing[4]} className="action-icons">
          <NavActionIcons {...{ actionIcons }} />
        </StyledIconWrapper>
      )}
    </StyledLinkWrapper>
  )

  return collapsed ? (
    <Tooltip position="left" align="center" label={label} sideOffset={tokens.spacingBase}>
      {navLink}
    </Tooltip>
  ) : (
    navLink
  )
}

export default MainNavLink
