import { fetchJson } from "js/includes/common/utils"

const BASE_URL = "/psa/integration/quick-books"

export function bulkExportInvoicesToQuickBooks(invoiceIds: number[]) {
  return fetchJson(`${BASE_URL}/invoice/export`, {
    options: {
      method: "POST",
      body: JSON.stringify(invoiceIds),
    },
  })
}

export function getQuickBooksTaxCodes() {
  return fetchJson(`${BASE_URL}/tax-code`)
}

export async function getQuickBooksIntegration() {
  const response = await fetchJson("/psa/integration")
  return response.find(({ typeId }) => typeId === "QUICK_BOOKS") || {}
}
