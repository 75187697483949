import ComplexChartTooltip from "./ComplexChartTooltip"
import SimpleChartTooltip from "./SimpleChartTooltip"

export const tooltipTypes = {
  SIMPLE: "Simple",
  COMPLEX: "Complex",
}

const tooltipTypeOptions = {
  [tooltipTypes.SIMPLE]: ({ title, value, valueSecondary, valueTertiary }) => (
    <SimpleChartTooltip {...{ title, value, valueSecondary, valueTertiary }} />
  ),
  [tooltipTypes.COMPLEX]: ({ title, subTitle, rows }) => <ComplexChartTooltip {...{ title, subTitle, rows }} />,
}

const ChartTooltip = ({ getTooltipTypeProps, active, payload }) => {
  const showTooltip = active && payload?.length

  if (!showTooltip) return null

  const { type, props } = getTooltipTypeProps(payload)
  const TooltipType = tooltipTypeOptions[type]

  return <TooltipType {...props} />
}

export default ChartTooltip
