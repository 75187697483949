import {
  compose,
  either,
  evolve,
  find,
  head,
  map,
  omit,
  pathOr,
  prop,
  propEq,
  reject,
  values,
  identity,
  defaultTo,
  ifElse,
  pluck,
  is,
} from "ramda"

import tokens from "@ninjaone/tokens"
import { arrayToMapWithKey, defaultToEmpty, initials } from "js/includes/common/utils"
import { mapRequesterToAppUser } from "js/includes/ticketing/shared/utils"

export const omitTypeName = omit(["__typename"])

export const extractTextContentFromHtml = html =>
  html ? new DOMParser().parseFromString(html, "text/html").documentElement.textContent : ""

export const parseTicketDataFromServer = ticket => {
  const {
    node,
    requester,
    assignedAppUser,
    ccList,
    ccUsers = [],
    attributeValues,
    status,
    parentTicket,
    ...rest
  } = omitTypeName(ticket)

  const nodeId = node?.id ?? null
  const requesterUid = requester?.uid ?? null
  const assignedAppUserId = assignedAppUser?.id ?? null
  const _ccList = omitTypeName(ccList)
  const _ccUsers = mapCCUsersToAppUsers(ccUsers)
  const _attributeValues = map(omitTypeName, attributeValues)

  return {
    ...rest,
    nodeId,
    node,
    requester,
    requesterUid,
    assignedAppUser,
    assignedAppUserId,
    ccList: _ccList,
    ccUsers: _ccUsers,
    status: status.statusId,
    attributeValues: arrayToMapWithKey("attributeId", _attributeValues),
    parentTicket,
    parentTicketId: parentTicket?.id ?? null,
  }
}

export const mapCCUsersToAppUsers = map(mapRequesterToAppUser)

export const omitTicketStateValues = omit([
  "id",
  "assignedAppUser",
  "client",
  "location",
  "requester",
  "ccUsers",
  "agreement",
  "ccListUsers",
  "node",
  "hasUnbilledProducts",
])

export const prepareTicketValuesForServer = formValues => ({
  ...evolve({
    attributeValues: values,
  })(formValues),
  nodeId: formValues.node?.id || null,
})

export const getUserNameOrEmail = user =>
  (user.firstName?.trim() || user.lastName?.trim()
    ? `${defaultToEmpty(user.firstName)} ${defaultToEmpty(user.lastName)}`
    : user.email
  )?.trim()

export const getFullNameOrEmailInitials = either(initials, compose(head, prop("email")))

export const getTicketFormTimerSettings = (ticketForms = [], ticketFormId) =>
  compose(pathOr({}, ["content", "timerSettings"]), find(propEq("id", ticketFormId)))(ticketForms)

export const calculateTotalResponseTime = timerSets => {
  return timerSets.reduce((acc, t) => {
    const startDateSeconds = Math.floor(t.start.getTime() / 1000)
    const endDateSeconds = Math.floor((t.stop?.getTime() ?? new Date().getTime()) / 1000)

    const timeDiff = endDateSeconds - startDateSeconds
    return acc + timeDiff
  }, 0)
}

const transformHeading = (dom, headingLevel) => {
  const { fontSize, fontWeight } = tokens.typography
  const headingFontSize = {
    h1: fontSize.headingL,
    h2: fontSize.headingM,
    h3: fontSize.headingS,
    h4: fontSize.headingXs,
  }
  const headings = dom.querySelectorAll(headingLevel)
  headings.forEach(node => {
    node.style.fontSize = headingFontSize[headingLevel]
    node.style.fontWeight = fontWeight.medium
  })
}

const transformCode = (dom, theme) => {
  const codeBlocks = dom.querySelectorAll("pre.editor-code")
  codeBlocks.forEach(node => {
    node.style.display = "block"
    node.style.color = theme.colorTextStrong
    node.style.backgroundColor = theme.colorBackgroundAccentNeutralWeak
    node.style.tabSize = 2
    node.style.fontSize = tokens.typography.fontSize.bodyXs
    node.style.padding = tokens.spacing[2]
    node.style.paddingLeft = tokens.spacing[9]
    node.style.margin = 0
    node.style.marginTop = tokens.spacing[2]
    node.style.marginBottom = tokens.spacing[2]
    node.style.overflowX = "auto"
    node.style.position = "relative"
    node.style.whiteSpace = "unset"
  })
  const codeTokenStyles = {
    ".editor-token-comment": "#708090",
    ".editor-token-punctuation": "#999",
    ".editor-token-property": "#905",
    ".editor-token-selector": "#690",
    ".editor-token-operator": "#9a6e3a",
    ".editor-token-attr": "#07a",
    ".editor-token-variable": "#e90",
    ".editor-token-function": "#dd4a68",
  }
  for (const tokenStyle in codeTokenStyles) {
    dom.querySelectorAll(tokenStyle).forEach(token => {
      token.style.color = codeTokenStyles[tokenStyle]
    })
  }
}

export const transformHtmlForSaving = ({ htmlBody, theme }) => {
  if (!htmlBody) return htmlBody

  const dom = new DOMParser().parseFromString(htmlBody, "text/html")
  const images = dom.querySelectorAll("img")
  const paragraphs = dom.querySelectorAll("p")
  const blockquotes = dom.querySelectorAll("blockquote")

  paragraphs.forEach(par => {
    if (par.style.textIndent) {
      par.style.paddingLeft = par.style.textIndent
      par.style.textIndent = null
    }
  })

  transformCode(dom, theme)

  transformHeading(dom, "h1")
  transformHeading(dom, "h2")
  transformHeading(dom, "h3")
  transformHeading(dom, "h4")

  blockquotes.forEach(block => {
    block.style.margin = `0 0 ${tokens.spacing[2]} ${tokens.spacing[5]}`
    block.style.paddingLeft = tokens.spacing[4]
    block.style.fontSize = tokens.typography.fontSize.body
    block.style.color = theme.colorTextWeakest
    block.style.borderLeftColor = theme.colorBorderWeakest
    block.style.borderLeftWidth = tokens.spacing[1]
    block.style.borderLeftStyle = "solid"
  })

  images.forEach(img => {
    const cid = img.getAttribute("data-cid")
    if (cid) {
      img.setAttribute("src", `cid:${cid}`)
      img.removeAttribute("data-cid")
      img.setAttribute("height", "auto")
    }
  })

  return dom.querySelector("body").innerHTML
}

const cleanupTechniciansTagged = ({ htmlBody, techniciansTagged }) =>
  compose(
    reject(id => !htmlBody.includes(`user:${id}`)),
    ifElse(([mention]) => is(Object, mention), pluck("id"), identity),
    defaultTo([]),
  )(techniciansTagged)

export const prepareEditorData = ({
  body,
  htmlBody,
  techniciansTagged: _techniciansTagged,
  uploads,
  duplicateInIncidents,
}) => {
  const techniciansTagged = cleanupTechniciansTagged({ htmlBody, techniciansTagged: _techniciansTagged })

  return {
    body,
    htmlBody,
    ...(techniciansTagged?.length && { techniciansTagged }),
    ...(duplicateInIncidents && { duplicateInIncidents }),
    ...(uploads && {
      uploads: map(
        upload => {
          return {
            ...omit(["tempUri", "tempId", "progress"], upload),
            metadata: omit(["__src"], upload.metadata),
          }
        },
        uploads.filter(upload => !upload.metadata.inline || htmlBody.includes(upload.metadata.contentId)),
      ),
    }),
  }
}

export const prepareNewEditorData = (
  { body, htmlBody: _htmlBody, techniciansTagged, uploads, duplicateInIncidents },
  theme,
) => {
  const htmlBody = transformHtmlForSaving({ htmlBody: _htmlBody, theme })
  return prepareEditorData({ body, htmlBody, techniciansTagged, uploads, duplicateInIncidents })
}
