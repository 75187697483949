import {
  assoc,
  append,
  compose,
  equals,
  find,
  findIndex,
  map,
  mergeRight,
  propEq,
  reduce,
  update,
  cond,
  always,
  T,
  includes,
} from "ramda"
import { show, hide } from "js/state/actions/common/visibility"
import { startLoading, stopLoading } from "js/state/actions/common/loading"
import { setAvailableCompanySites } from "./accounts/locations"
import { fetchJson, showErrorMessage, localized, ninjaReportError } from "js/includes/common/utils"
import { CLIENT_DEFAULT } from "js/includes/configuration/psa/ConnectWise/settings/ConnectWiseAccountSettings/ConnectWiseAccountMapping/LocationSiteMappings/constants"

const setAccountOrganizationModalMapping = data => ({
  type: "SET_CW_ACCOUNT_ORGANIZATION_MODAL_MAPPING",
  data,
})

export const setAccountOrganizationMappings = payload => ({
  type: "SET_CW_ACCOUNT_ORGANIZATION_MAPPINGS",
  payload,
})

export function refetchCWAccountOptions() {
  return async dispatch => {
    dispatch({ type: "RESET_CW_PREVIOUSLY_FETCHED_COMPANY_FILTER_MAP" })
  }
}

const setAccountAutoMap = data => ({
  type: "SET_CW_ACCOUNT_AUTO_MAP",
  data,
})

const setCompanies = payload => ({
  type: "SET_CW_COMPANIES",
  payload,
})

const setCompanyCriteria = payload => ({
  type: "SET_CW_COMPANY_CRITERIA",
  payload,
})

function getCompanies() {
  return async (dispatch, getState) => {
    try {
      const { previouslyUsedFilterMap, companyFilterMap } = getState().psa.ConnectWise.mappings.accounts
      if (!previouslyUsedFilterMap || !equals(companyFilterMap, previouslyUsedFilterMap)) {
        dispatch(startLoading("CWAccountOrganizationMappingModal")())
        const { companyList } = await fetchJson("/psacw/v2/company/companies", {
          options: {
            method: "POST",
            body: JSON.stringify(companyFilterMap),
          },
        })
        dispatch(setCompanies(companyList))
        dispatch({ type: "SET_CW_PREVIOUSLY_FETCHED_COMPANY_FILTER_MAP", companyFilterMap })
      }
    } catch (error) {
      showErrorMessage(localized("Error fetching account companies"))
      ninjaReportError(error)
    } finally {
      dispatch(stopLoading("CWAccountOrganizationMappingModal")())
    }
  }
}

export function getCompanyCriteria() {
  return async dispatch => {
    try {
      const response = await fetchJson("/psacw/v2/company/setupvalues")
      dispatch(setCompanyCriteria(response))
    } catch (error) {
      showErrorMessage(localized("Error fetching account criteria"))
      if (error?.response?.status !== 403) {
        ninjaReportError(error)
      }
    }
  }
}

export function getAccountOrganizationsMapping() {
  return async dispatch => {
    try {
      const accountMappingsData = await fetchJson("/psacw/v2/accountMappings")
      dispatch(setAccountOrganizationMappings(accountMappingsData))
    } catch (error) {
      showErrorMessage(localized("Error fetching organizations"))
      throw error
    }
  }
}

export function openAccountOrganizationModal(mapping) {
  return dispatch => {
    dispatch(setAccountOrganizationModalMapping(mapping))
    dispatch(getCompanies())
    if (mapping.companyId) {
      dispatch(setAvailableCompanySites(mapping.companyId))
    }
    dispatch(show("CWAccountOrganizationMappingModal")())
  }
}

export function updateAccountOrganizationModalMapping(data) {
  return (dispatch, getState) => {
    const { mapping } = getState().psa.ConnectWise.mappings.accounts.accountOrganizationModal
    const companyChanged = data.companyId && mapping.companyId !== data.companyId
    if (companyChanged) {
      const clearedLocations = clearLocationSiteMapping(mapping)
      dispatch(setAccountOrganizationModalMapping(mergeRight(clearedLocations, data)))
      dispatch(setAvailableCompanySites(data.companyId))
    } else {
      dispatch(setAccountOrganizationModalMapping(mergeRight(mapping, data)))
    }
  }
}

export function updateAccountOrganizationMapping(mapping) {
  return (dispatch, getState) => {
    const accountOrganizationMappings = getState().psa.ConnectWise.mappings.accounts.accountOrganizationMappings
    const mappingIndex = findIndex(propEq("clientId", mapping.clientId), accountOrganizationMappings)
    const updatedMapping = update(mappingIndex, mapping, accountOrganizationMappings)
    dispatch(setAccountOrganizationMappings(updatedMapping))
  }
}

export function resetMapping() {
  return async dispatch => {
    dispatch(clearMappingCompanies())
  }
}

export function autoMap() {
  return async (dispatch, getState) => {
    dispatch(startLoading("CWAccountMapModal")())
    dispatch(show("CWAccountAutoMapModal")())
    await dispatch(getCompanies())
    const { companies, accountOrganizationMappings } = getState().psa.ConnectWise.mappings.accounts
    const { updatedOrganizations } = reduce((mapped, org) => {
      const { clientId, clientName, locationSiteMappings } = org
      const company = find(propEq("name", clientName), companies)
      if (!company) {
        return mapped
      } else {
        const { updatedOrganizations } = mapped

        const updatedOrganization = {
          clientId,
          clientName,
          companyId: company.id,
          companyName: company.name,
          locationSiteMappings,
          productMappingId: null,
          productMappingName: null,
          configurationTypeMappingId: null,
          configurationTypeMappingName: null,
        }
        return {
          updatedOrganizations: append(updatedOrganization, updatedOrganizations),
        }
      }
    })({ updatedOrganizations: [], usedAgreements: [] })(accountOrganizationMappings)
    dispatch(setAccountAutoMap(updatedOrganizations))
    dispatch(stopLoading("CWAccountMapModal")())
  }
}

function clearMappingCompanies() {
  return (dispatch, getState) => {
    const { accountOrganizationMappings, selectedAccountMappings } = getState().psa.ConnectWise.mappings.accounts

    const mapping = map(mapping => {
      if (includes(mapping.clientId, selectedAccountMappings)) {
        return compose(
          clearLocationSiteMapping,
          assoc("companyId", null),
          assoc("companyName", null),
          assoc("productMappingId", null),
          assoc("productMappingName", null),
          assoc("configurationTypeMappingId", null),
          assoc("configurationTypeMappingName", null),
        )(mapping)
      } else {
        return mapping
      }
    })(accountOrganizationMappings)
    dispatch(setAccountOrganizationMappings(mapping))
  }
}

function clearLocationSiteMapping(accountOrgMapping) {
  const locations = map(
    compose(
      assoc("agreementId", null),
      assoc("agreementName", null),
      assoc("psaSiteId", null),
      assoc("psaSiteName", null),
      assoc("mapped", false),
    ),
  )(accountOrgMapping.locationSiteMappings)
  return assoc("locationSiteMappings", locations, accountOrgMapping)
}

export function saveAutoMapping() {
  return (dispatch, getState) => {
    dispatch(clearMappingCompanies())
    const { accountAutoMapPreview, accountOrganizationMappings } = getState().psa.ConnectWise.mappings.accounts
    const mapping = reduce((mapping, org) =>
      update(accountOrganizationMappings.findIndex(propEq("clientId", org.clientId)), org, mapping),
    )(accountOrganizationMappings)(accountAutoMapPreview)
    dispatch(setAccountOrganizationMappings(mapping))
    dispatch(hide("CWAccountAutoMapModal")())
  }
}

export function cancelAutoMapping() {
  return dispatch => {
    dispatch({ type: "CLEAR_CW_ACCOUNT_AUTO_MAP" })
    dispatch(hide("CWAccountAutoMapModal")())
  }
}

export function updateOrganizationForProductMappingDeleted(productMappingId) {
  return (dispatch, getState) => {
    const { accountOrganizationMappings } = getState().psa.ConnectWise.mappings.accounts
    const mappings = compose(
      map(
        compose(
          acm =>
            assoc(
              "locationSiteMappings",
              map(lsm => setProductClientDefault(lsm, productMappingId), acm.locationSiteMappings),
              acm,
            ),
          acm => setNullProductProps(acm, productMappingId),
        ),
      ),
    )(accountOrganizationMappings)

    dispatch(setAccountOrganizationMappings(mappings))
  }
}

const setNullProductProps = (org, productMappingId) =>
  cond([
    [
      propEq("productMappingId", productMappingId),
      always(compose(assoc("productMappingId", null), assoc("productMappingName", null))(org)),
    ],
    [T, always(org)],
  ])(org)

const setProductClientDefault = (loc, productMappingId) =>
  cond([
    [
      propEq("productMappingId", productMappingId),
      always(
        compose(
          assoc("productMappingId", null),
          assoc("productMappingName", null),
          assoc("productMappingType", CLIENT_DEFAULT),
        )(loc),
      ),
    ],
    [T, always(loc)],
  ])(loc)

export function updateOrganizationForConfigurationTypeMappingDeleted(configurationTypeMappingId) {
  return (dispatch, getState) => {
    const { accountOrganizationMappings } = getState().psa.ConnectWise.mappings.accounts
    const mappings = compose(
      map(
        compose(
          acm =>
            assoc(
              "locationSiteMappings",
              map(lsm => setConfigurationClientDefault(lsm, configurationTypeMappingId), acm.locationSiteMappings),
              acm,
            ),
          acm => setNullConfigurationProps(acm, configurationTypeMappingId),
        ),
      ),
    )(accountOrganizationMappings)

    dispatch(setAccountOrganizationMappings(mappings))
  }
}

const setNullConfigurationProps = (org, configurationTypeMappingId) =>
  cond([
    [
      propEq("configurationTypeMappingId", configurationTypeMappingId),
      always(compose(assoc("configurationTypeMappingId", null), assoc("configurationTypeMappingName", null))(org)),
    ],
    [T, always(org)],
  ])(org)

const setConfigurationClientDefault = (loc, configurationTypeMappingId) =>
  cond([
    [
      propEq("configurationTypeMappingId", configurationTypeMappingId),
      always(
        compose(
          assoc("configurationTypeMappingId", null),
          assoc("configurationTypeMappingName", null),
          assoc("configurationTypeMappingType", CLIENT_DEFAULT),
        )(loc),
      ),
    ],
    [T, always(loc)],
  ])(loc)

export const setSelectedAccountMappings = selected => dispatch => {
  dispatch({
    type: "SET_CW_ACCOUNT_ORGANIZATION_SELECTED_MAPPINGS",
    selected,
  })
}
