import { always, cond, either, startsWith, T, compose, omit, reject, includes, keys, filter, equals } from "ramda"
import { isVMHostOrGuest } from "./devices"
import { contains } from "./ssrAndWebUtils"

export const isWindowsPolicy = nodeRole => nodeRole.startsWith("WINDOWS_")

export const isMacPolicy = nodeRole => nodeRole.startsWith("MAC")

export const isLinuxPolicy = nodeRole => nodeRole.startsWith("LINUX")

export const isWindowsOrMacPolicy = nodeRole => isWindowsPolicy(nodeRole) || isMacPolicy(nodeRole)

export const isMDMPolicy = either(startsWith("ANDROID"), contains(["APPLE_IOS", "APPLE_IPADOS"]))

export const isAndroidPolicy = startsWith("ANDROID")

export const isAppleMobilePolicy = contains(["APPLE_IOS", "APPLE_IPADOS"])

export const getEditorType = cond([
  [isVMHostOrGuest, always("vmpolicy")],
  [isAndroidPolicy, always("androidpolicy")],
  [isAppleMobilePolicy, always("iospolicy")],
  [T, always("policy")],
])

export const getPolicyRoute = ({ nodeRole, nodeClass, id }) => {
  const editorType = getEditorType(nodeRole ?? nodeClass)

  return `#/editor/${editorType}/${id}`
}

export const getParentPolicyInfo = policy => {
  const { parentPolicyId, parentPolicyName } = policy || {}
  return parentPolicyId
    ? {
        name: parentPolicyName,
        link: getPolicyRoute({ ...policy, id: parentPolicyId }),
      }
    : null
}

export const getDefaultSchedule = isScan => {
  return {
    scheduleType: "DAILY",
    weekDays: [isScan ? "FRI" : "SUN"],
    scheduleHour: 17,
    scheduleMinute: 0,
    scheduleDuration: 0,
    scheduleDurationHours: 0,
    scheduleDurationMinutes: 0,
    timeZone: "_Local",
    makeupMissed: false,
  }
}

export const productInstallerStatus = {
  NO_INSTALLER_AVAILABLE: "na",
  INSTALLER_AVAILABLE_BUT_NOT_INSTALLED: "ignore",
  INSTALLER_AVAILABLE: "install",
}

const delayedDayProperties = [
  "securityUpdateLowDaysToApprove",
  "securityUpdateModerateDaysToApprove",
  "securityUpdateImportantDaysToApprove",
  "securityUpdateCriticalDaysToApprove",
  "criticalUpdateImportantDaysToApprove",
  "criticalUpdateOptionalDaysToApprove",
  "regularUpdateImportantDaysToApprove",
  "regularUpdateOptionalDaysToApprove",
  "updateRollupImportantDaysToApprove",
  "updateRollupOptionalDaysToApprove",
  "servicePackImportantDaysToApprove",
  "servicePackOptionalDaysToApprove",
  "featurePackImportantDaysToApprove",
  "featurePackOptionalDaysToApprove",
  "definitionPackImportantDaysToApprove",
  "definitionPackOptionalDaysToApprove",
]

export const formatUpdateConfigApprovals = updateConfigApprovals =>
  compose(
    propsToRemove => omit(propsToRemove, updateConfigApprovals),
    reject(prop => !includes(prop, delayedDayProperties)),
    keys,
    filter(prop => equals(prop, 0)),
  )(updateConfigApprovals)

export const updateApprovalsDelayedDaysDefaults = {
  securityUpdateLowDaysToApprove: 0,
  securityUpdateModerateDaysToApprove: 0,
  securityUpdateImportantDaysToApprove: 0,
  securityUpdateCriticalDaysToApprove: 0,
  criticalUpdateImportantDaysToApprove: 0,
  criticalUpdateOptionalDaysToApprove: 0,
  regularUpdateImportantDaysToApprove: 0,
  regularUpdateOptionalDaysToApprove: 0,
  updateRollupImportantDaysToApprove: 0,
  updateRollupOptionalDaysToApprove: 0,
  servicePackImportantDaysToApprove: 0,
  servicePackOptionalDaysToApprove: 0,
  featurePackImportantDaysToApprove: 0,
  featurePackOptionalDaysToApprove: 0,
  definitionPackImportantDaysToApprove: 0,
  definitionPackOptionalDaysToApprove: 0,
}
