import { memo } from "react"
import { SelectionEditField } from "@ninjaone/components"
import { localized } from "js/includes/common/utils/ssrAndWebUtils"
import showModal from "js/includes/common/services/showModal"
import { getCurrentUpdatesByStatus } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/ios/OSUpdates/enforcedUpdatesUtils.js"
import { Box } from "js/includes/components/Styled"
import { osTabGridColumns } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/appleCommon/OSUpdates/util"
import ManuallyScheduledUpdatesModal from "./ManuallyScheduledUpdatesModal"

const ManuallyScheduledUpdates = memo(({ updates, onChange }) => {
  const currentUpdates = getCurrentUpdatesByStatus(updates)
  const currentUpdatesPlaceholder = currentUpdates.length
    ? currentUpdates.join(", ")
    : localized("No scheduled updates have been added")

  const handleChange = patchOverrides => {
    onChange({
      ...patchOverrides,
    })
  }
  return (
    <Box display="grid" gridTemplateColumns={osTabGridColumns}>
      <Box gridColumn="3/4">
        <SelectionEditField
          buttonText={localized("Edit")}
          labelText={localized("Manually scheduled updates")}
          selection={currentUpdates}
          maxItemsDisplayed={3}
          placeholder={currentUpdatesPlaceholder}
          onClick={() =>
            showModal(<ManuallyScheduledUpdatesModal {...{ manualUpdates: updates, onChange: handleChange }} />, {
              withProvider: true,
            })
          }
        />
      </Box>
    </Box>
  )
})

export default ManuallyScheduledUpdates
