import { fetchJson } from "js/includes/common/utils"

const BASE_URL = "/psa/ticket-product"

export const fetchTicketProducts = ticketId => {
  return fetchJson(BASE_URL + `/${ticketId}/products`)
}

export const addCatalogTicketProduct = data => {
  return fetchJson(BASE_URL + "/catalog", {
    options: {
      method: "POST",
      body: JSON.stringify(data),
    },
  })
}

export const addAdhocTicketProduct = data => {
  return fetchJson(BASE_URL + "/adhoc", {
    options: {
      method: "POST",
      body: JSON.stringify(data),
    },
  })
}

export const deleteTicketProducts = ({ ticketProductIds, ticketId }) => {
  return fetchJson(BASE_URL + "/delete", {
    options: {
      method: "POST",
      body: JSON.stringify({ ticketProductIds, ticketId }),
    },
  })
}

export const updateTicketProduct = ({ ticketProduct, ticketId }) => {
  const { id, ...rest } = ticketProduct
  return fetchJson(BASE_URL + `/${id}`, {
    options: {
      method: "PUT",
      body: JSON.stringify({ ...rest, ticketId }),
    },
  })
}

export const fetchTicketProductById = productId => fetchJson(`${BASE_URL}/${productId}`)
