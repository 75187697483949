import { fetch, showErrorMessage, localized, ninjaReportError } from "js/includes/common/utils"
import { NinjaResponseError } from "js/includes/common/types"
import { setDefaultTicketTemplateId } from "../ticketing"
import { testConnection, setTestConnectionResult } from "../connection"
import { setProductAgreementSync } from "./mappings/products/sync"
import { startLoading, stopLoading } from "js/state/actions/common/loading"
import { hide } from "js/state/actions/common/visibility"
import { setCwHelpDeskOptions } from "./helpdesk"
import { updatePsaType } from "js/state/actions/session/psaType"
import { PSA_TYPES } from "js/includes/configuration/apps/appInfo"

const setConnection = payload => ({
  type: "SET_CW_CONNECTION",
  payload,
})

const setCompanyFilterMap = payload => ({
  type: "SET_CW_COMPANY_FILTER_MAP",
  payload,
})

const setDeviceSyncAttributes = attributes => ({
  type: "SET_CW_DEVICE_DATA_SYNC_ATTRIBUTES",
  attributes,
})

export const getConnection = () => async (dispatch, getState) => {
  try {
    const response = await window.psaConnect.fetch()

    if (response.content) {
      if (response.resultCode === "SUCCESS") {
        const {
          companyFilterMap = {},
          deviceDataSyncAttributes = null,
          productAgreementSync,
          companyId,
          defaultTicketTemplateId,
          enabled = false,
          privateApiKey,
          publicApiKey,
          urlBase,
          createContact = false,
        } = response.content

        dispatch(
          setConnection({
            enabled,
            companyId,
            publicApiKey,
            privateApiKey,
            urlBase,
          }),
        )
        dispatch(setCompanyFilterMap(companyFilterMap))
        dispatch(setDeviceSyncAttributes(deviceDataSyncAttributes))
        dispatch(setProductAgreementSync(productAgreementSync))
        dispatch(setCwHelpDeskOptions({ createContact }))
        if (defaultTicketTemplateId) {
          dispatch(setDefaultTicketTemplateId(defaultTicketTemplateId))
        }
      } else {
        throw new NinjaResponseError(response)
      }
    }
  } catch (error) {
    showErrorMessage(localized("Error fetching Connectwise connection"))
    throw error
  }
}

export const getPsaConfigContent = (connection, settings, mappings, defaultTicketTemplateId, cwHelpDeskSettings) => {
  const { companyId, publicApiKey, privateApiKey, urlBase } = connection
  const { deviceDataSyncAttributes } = settings
  const {
    accounts: { companyFilterMap },
    products: { productAgreementSync },
  } = mappings
  const { createContact } = cwHelpDeskSettings ?? false
  return {
    enabled: true,
    psaType: "CONNECTWISE",
    deviceDataSyncAttributes,
    productAgreementSync,
    companyId,
    companyFilterMap,
    defaultTicketTemplateId,
    privateApiKey,
    publicApiKey,
    urlBase,
    createContact,
  }
}

export const testCWConnection = values => async dispatch => {
  try {
    dispatch(startLoading("PSAConnectionModal")())
    await dispatch(testConnection(PSA_TYPES.CONNECTWISE, values))
    dispatch(setTestConnectionResult("TEST_SUCCEEDED"))
  } catch (e) {
    dispatch(setTestConnectionResult("TEST_FAILED"))
  } finally {
    dispatch(stopLoading("PSAConnectionModal")())
  }
}

export const saveConnection = formValues => async (dispatch, getState) => {
  dispatch(startLoading("PSAConnectionModal")())

  const CWState = getState().psa
  const {
    defaultTicketTemplateId,
    ConnectWise: { settings, mappings, cwHelpDeskSettings },
  } = CWState
  try {
    await dispatch(testConnection(PSA_TYPES.CONNECTWISE, formValues))
    const body = JSON.stringify(
      getPsaConfigContent(formValues, settings, mappings, defaultTicketTemplateId, cwHelpDeskSettings),
    )
    await fetch("/psacw/v2/saveconnection", { options: { method: "PUT", body } })

    dispatch(updatePsaType(PSA_TYPES.CONNECTWISE))
    await dispatch(getConnection())
    dispatch(hide("PSAConnectionModal")())
  } catch (error) {
    showErrorMessage(localized("Error saving Connectwise connection"))
    ninjaReportError(error)
  } finally {
    dispatch(stopLoading("PSAConnectionModal")())
  }
}
