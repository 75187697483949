import PropTypes from "prop-types"
import tokens from "@ninjaone/tokens"
import { Box } from "js/includes/components/Styled"
import { isMacPolicy, localizationKey, localized } from "js/includes/common/utils"
import {
  SelfInstallSelector,
  AllowRsrCheckbox,
  RemoveRsrCheckbox,
  NotificationUpdateCheckbox,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/ios/OSUpdates/userBehaviorComponents"
import { osTabGridColumns } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/appleCommon/OSUpdates/util"
import StandardInstallSelector from "./userBehaviorComponents/StandardInstallSelector"
import DelayUpdateComponent from "./userBehaviorComponents/DelayUpdateComponent"
import EnrollmentSelectComponent from "./EnrollmentSelectComponent"
import { HeaderComponent } from "./userBehaviorComponents/HeaderComponent"

const basePath = "content.patchManagement.userBehavior"

const withCommonProps = (Component, { settingPath, additionalProps = {} }) => {
  return ({ userBehavior, onPolicyChange }) => {
    const inheritableItem = userBehavior[settingPath]
    const onChange = setting => {
      onPolicyChange(`userBehavior.${settingPath}`, setting)
    }

    return (
      <Component
        gridColumns={osTabGridColumns}
        inheritableItem={inheritableItem}
        onChange={onChange}
        itemPath={`${basePath}.${settingPath}`}
        {...additionalProps}
      />
    )
  }
}

const macOSComponents = [
  {
    Component: DelayUpdateComponent,
    settingPath: "forceDelayedMajorSoftwareUpdatesSettings",
    additionalProps: {
      LabelComponent: HeaderComponent,
      checkboxLabel: localized("Delay when new major macOS upgrades can be self-installed"),
      inputLabelToken: localizationKey(
        "How many days to delay a new major upgrade from becoming available (1 - 90 days)",
      ),
      checkboxKey: "forceDelayedMajorSoftwareUpdates",
      inputKey: "enforcedSoftwareUpdateMajorOSDeferredInstallDelay",
    },
  },
  {
    Component: DelayUpdateComponent,
    settingPath: "forceDelayedSoftwareUpdatesSettings",
    additionalProps: {
      checkboxLabel: localized("Delay when new minor macOS updates can be self-installed"),
      inputLabelToken: localizationKey(
        "How many days to delay a new minor update from becoming available (1 - 90 days)",
      ),
      checkboxKey: "forceDelayedSoftwareUpdates",
      inputKey: "enforcedSoftwareUpdateMinorOSDeferredInstallDelay",
    },
  },
  {
    Component: DelayUpdateComponent,
    settingPath: "forceDelayedAppSoftwareUpdatesSettings",
    additionalProps: {
      checkboxLabel: localized("Delay when new non-OS software updates can be self-installed"),
      inputLabelToken: localizationKey(
        "How many days to delay a new non-OS update from becoming available (1 - 90 days)",
      ),
      checkboxKey: "forceDelayedAppSoftwareUpdates",
      inputKey: "enforcedSoftwareUpdateNonOSDeferredInstallDelay",
    },
  },
  {
    Component: EnrollmentSelectComponent,
    settingPath: "allowBetaProgramEnrollmentSettings",
    additionalProps: {
      labelText: localized("Allow user to install beta OS versions (macOS 15+)"),
      labelId: "install-beta-updates-macos",
      valueKey: "allowBetaProgramEnrollment",
    },
  },
  {
    Component: AllowRsrCheckbox,
    settingPath: "allowRsrInstallationSettings",
  },
  {
    Component: RemoveRsrCheckbox,
    settingPath: "allowRsrRemovalSettings",
  },
  {
    Component: StandardInstallSelector,
    settingPath: "allowStandardUserOSUpdatesSettings",
  },
  {
    Component: NotificationUpdateCheckbox,
    settingPath: "updateNotificationsSettings",
    additionalProps: {
      minOSVersion: "macOS 15",
    },
  },
]

const iOSComponents = [
  {
    Component: SelfInstallSelector,
    settingPath: "recommendedCadenceSettings",
    additionalProps: {
      LabelComponent: HeaderComponent,
    },
  },
  {
    Component: EnrollmentSelectComponent,
    settingPath: "allowBetaProgramEnrollmentSettings",
    additionalProps: {
      labelText: localized("Allow user to install beta OS versions"),
      labelId: "install-beta-updates-ios",
      valueKey: "allowBetaProgramEnrollment",
    },
  },
  {
    Component: DelayUpdateComponent,
    settingPath: "forceDelayedSoftwareUpdatesSettings",
    additionalProps: {
      checkboxLabel: localized("Delay when new iOS updates can be self-installed"),
      inputLabelToken: localizationKey("Delay update by days (1-90)"),
      tooltipText: localized(
        "When Apple releases a new update, it will not be made available to the device for the specified amount of days (1-90)",
      ),
      checkboxKey: "forceDelayedSoftwareUpdates",
      inputKey: "enforcedSoftwareUpdateDelay",
    },
  },
  {
    Component: AllowRsrCheckbox,
    settingPath: "allowRsrInstallationSettings",
  },
  {
    Component: RemoveRsrCheckbox,
    settingPath: "allowRsrRemovalSettings",
  },
  {
    Component: NotificationUpdateCheckbox,
    settingPath: "updateNotificationsSettings",
    additionalProps: {
      minOSVersion: "iOS 18",
    },
  },
]

const UserBehaviorSection = ({ userBehavior, onPolicyChange, nodeRole }) => {
  const componentsToShow = isMacPolicy(nodeRole) ? macOSComponents : iOSComponents
  return (
    <Box padding={tokens.spacing[6]}>
      {componentsToShow.map(({ Component, settingPath, additionalProps }) => {
        const EnhancedComponent = withCommonProps(Component, {
          settingPath,
          additionalProps,
        })
        return <EnhancedComponent key={settingPath} userBehavior={userBehavior} onPolicyChange={onPolicyChange} />
      })}
    </Box>
  )
}

UserBehaviorSection.propTypes = {
  userBehavior: PropTypes.object.isRequired,
  onPolicyChange: PropTypes.func.isRequired,
  nodeRole: PropTypes.string.isRequired,
}

export default UserBehaviorSection
