import Backbone from "backbone"
import qs from "qs"
import { Base64 } from "js-base64"
import { path } from "ramda"
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons"
import TwoFactorAuthModal from "js/includes/components/TwoFactorAuthModal"
import showModal from "js/includes/common/services/showModal"
import {
  showErrorMessage,
  localized,
  fetchJson,
  decodeBase64URL,
  localizationKey,
  ninjaReportError,
  getMfaErrorMessages,
  MFA_ERROR_CODES,
} from "js/includes/common/utils"
import ShowMessageDialog from "js/includes/components/MessageDialog"

export function buildMfaBackboneModelUrl(model, useMfaUrl) {
  const url = Backbone.Model.prototype.url.call(model) + (useMfaUrl ? "/mfa" : "")
  const loginToken = model.get("loginToken")
  const mfaCode = model.get("mfaCode")
  let u2fcode = model.get("publicKeyCredentialEncoded")

  if (u2fcode) {
    u2fcode = Base64.encode(JSON.stringify(u2fcode))
  }

  const query =
    model.enforceMfa && loginToken && (mfaCode || u2fcode)
      ? `?${qs.stringify({
          token: loginToken,
          mfacode: mfaCode,
          u2fcode,
        })}`
      : ""

  return url + query
}

export function promptUserForMfa(errorResponse, { onSubmit, onClose = () => {} }) {
  return new Promise((resolve, reject) => {
    showModal(
      <TwoFactorAuthModal
        mfaResponse={errorResponse}
        onSubmit={async (queryParams = {}) => {
          const { mfaCode, publicKeyCredentialEncoded } = queryParams
          try {
            const result = await onSubmit({ mfaCode, publicKeyCredentialEncoded })
            resolve(result)
          } catch (error) {
            const resultCode = path(["resultCode"], error) || path(["responseJSON", "resultCode"], error)
            if ([MFA_ERROR_CODES.INCORRECT_MFA_CODE, MFA_ERROR_CODES.bad_u2f_input_format].includes(resultCode)) {
              showErrorMessage(localized(getMfaErrorMessages(resultCode)))
              reject({ isHandledMfaError: true, reason: resultCode })
            } else {
              reject(error)
            }
          }
        }}
        onClose={onClose}
        onCancel={() => {
          reject({ isHandledMfaError: true, reason: "USER_CANCELLED_MFA" })
        }}
      />,
    )
  })
}

export async function handlEditorSaveMfaException({ caller, err, hideMessage, event, onMfaSubmit }) {
  const { responseJSON } = err
  if (!responseJSON || responseJSON.resultCode !== MFA_ERROR_CODES.MFA_REQUIRED) {
    throw err
  }

  const onSubmit = async (queryParams = {}) => {
    const { mfaCode, publicKeyCredentialEncoded } = queryParams
    const mfaProps = {
      loginToken: responseJSON.loginToken,
      mfaCode,
      publicKeyCredentialEncoded,
    }
    caller.set(mfaProps)
    const saveFromMfa = onMfaSubmit ?? caller.save
    return saveFromMfa(hideMessage, event, mfaProps)
  }
  const onClose = () => {
    caller.set({
      isSaving: false,
      loginToken: null,
      mfaCode: null,
      publicKeyCredentialEncoded: null,
    })
  }

  try {
    return await promptUserForMfa(responseJSON, { onSubmit, onClose })
  } catch (error) {
    if (!error.isHandledMfaError) {
      throw error
    }
  }
}

const uint8arrayToBase64url = input => Base64.fromUint8Array(new Uint8Array(input), true)
const base64urlToUint8array = input => Uint8Array.from(decodeBase64URL(input), c => c.charCodeAt(0))

export const assembleHardwareKeyRegistrationData = async (keyName, { createCredentialsJson, registrationToken }) => {
  const createCredentialObject = JSON.parse(createCredentialsJson)

  const credentialCreateOptions = {
    publicKey: {
      ...createCredentialObject.publicKey,
      challenge: base64urlToUint8array(createCredentialObject.publicKey.challenge),
      user: {
        ...createCredentialObject.publicKey.user,
        id: base64urlToUint8array(createCredentialObject.publicKey.user.id),
      },
      excludeCredentials: createCredentialObject.publicKey?.excludeCredentials
        ? createCredentialObject.publicKey.excludeCredentials.map(credential => ({
            ...credential,
            id: base64urlToUint8array(credential.id),
          }))
        : [],
    },
  }

  const publicKeyCredential = await navigator.credentials.create(credentialCreateOptions)

  const encodedResult = {
    type: publicKeyCredential.type,
    id: publicKeyCredential.id,
    response: {
      attestationObject: uint8arrayToBase64url(publicKeyCredential.response.attestationObject),
      clientDataJSON: uint8arrayToBase64url(publicKeyCredential.response.clientDataJSON),
      transports: publicKeyCredential?.response?.getTransports ? publicKeyCredential.response.getTransports() : [],
    },
    clientExtensionResults: publicKeyCredential.getClientExtensionResults(),
  }

  return {
    registrationToken,
    deviceName: keyName,
    tokenResponse: encodedResult,
  }
}

export const registerU2fKey = async keyName => {
  let registrationSuccessful = false
  try {
    const response = await fetchJson(`/appuser/u2f/initregistration`)
    const data = await assembleHardwareKeyRegistrationData(keyName, response)

    const completeRegistration = await fetchJson(`/appuser/u2f/completeregistration`, {
      options: {
        method: "POST",
        body: JSON.stringify(data),
      },
    })

    if (completeRegistration) {
      window.showSuccessMessage(localized("Your security key has been registered"))
      registrationSuccessful = true
    }
  } catch (error) {
    ninjaReportError(error)
    ShowMessageDialog({
      icon: { icon: faExclamationTriangle, type: "critical" },
      title: localizationKey("Security key registration error"),
      message: () => `${error}`,
      buttons: [{ id: "OK", label: localizationKey("OK") }],
    })
  }

  return registrationSuccessful
}
