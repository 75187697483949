import { productTypes } from "js/includes/configuration/integrations/psa/psaProducts/productCommons"

export const productQuantityTypes = {
  STATIC: "STATIC",
  DYNAMIC: "DYNAMIC",
  QUANTITY_ONE: "QUANTITY_ONE",
}

export const productQuantityTypeMapper = {
  [productTypes.USER_PRODUCT]: productQuantityTypes.STATIC,
  [productTypes.SOFTWARE]: productQuantityTypes.STATIC,
  [productTypes.HARDWARE]: productQuantityTypes.STATIC,
  [productTypes.PRODUCT_GROUP]: productQuantityTypes.QUANTITY_ONE,
  [productTypes.MANAGED_DEVICES]: productQuantityTypes.DYNAMIC,
  [productTypes.LABOR_BILLED]: productQuantityTypes.STATIC,
  [productTypes.LABOR_TICKET_TIME_ENTRY]: productQuantityTypes.DYNAMIC,
  [productTypes.CUSTOM_PRODUCT]: productQuantityTypes.STATIC,
}
