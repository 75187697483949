export const WEBROOT = {
  RUN_CLEANUP: "091a3f42-3e9d-40af-9f4d-4cd55613addb",
}

export const BITDEFENDER = {
  ADD_EXCLUSION: "496ee209-9cf3-448f-8bc6-59eb9dab6b0a",
  RESTORE_QUARANTINE: "4b03769b-b879-4d1c-bc72-ecdd6d51532f",
  DELETE_QUARANTINE: "fbc34ea2-7d94-40bc-97d1-76b3a580cf79",
}

export const GRAVITYZONE = {
  RESTORE_AND_ADD_EXCEPTION: "b43ff3c1-0378-4060-b5df-61064c3da256",
  RESTORE_QUARANTINE: "0f1fb7b7-3d0e-4fb2-afd6-4eedcd9c79f5",
  DELETE_QUARANTINE: "dd8978dd-e2d6-4782-a974-8d214be2db74",
  RETRY_INSTALL_JOB_UID: "0e78d8ba-6f67-4cc5-9ea4-f8adea02ec23",
  RESET_ALERT_JOB_UID: "26199367-9636-4967-b1ac-1a164d1e7b67",
  ADD_TO_BLOCKLIST: "5008df7a-1c90-4ce0-aac3-190fce4dc810",
  FULL_SCAN: "328d1f7b-56e5-436e-b25e-c77577719a04",
}

export const SENTINELONE = {
  RETRY_INSTALL_JOB_UID: "13441800-d235-4477-ac43-ba9e3de7ea9b",
}

export const CROWDSTRIKE = {
  RETRY_INSTALL_JOB_UID: "3ae724c4-d404-4d7d-a83e-443da9a705e3",
}

export const GENERIC = {
  RESTORE: "fbc34ea2-7d94-40bc-97d1-76b3a580cf79",
  RESTORE_AND_ADD_EXCEPTION: "fa6d3b23-2b4f-471a-8d94-ab79780b6a4a",
  DELETE: "e9b327c2-1976-49c9-b817-754deda04ff1",
  RETRY_INSTALL_JOB_UID: "cfdf5183-5639-402a-8993-cf9fbc2d3fa6",
}
