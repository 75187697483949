import {
  __,
  addIndex,
  any,
  ascend,
  complement,
  compose,
  converge,
  curry,
  defaultTo,
  either,
  filter,
  find,
  findIndex,
  gt,
  head,
  includes,
  isEmpty,
  isNil,
  join,
  last,
  lt,
  map,
  nthArg,
  omit,
  path,
  pipe,
  prop,
  propEq,
  reject,
  sortBy,
  sortWith,
  split,
  symmetricDifference,
  T,
  toLower,
  update,
} from "ramda"

import { renameKeys } from "js/includes/common/utils/ssrAndWebUtils/object"

import { isNotNilOrEmpty, propContainsCaseInsensitive } from "./string"

export const joinByComma = join(",")
export const splitByComma = split(",")

export const isFirst = curry((array, i) => i === head(array))
export const isLast = curry((array, i) => i === last(array))

export const isNotFirst = complement(isFirst)
export const isNotLast = complement(isLast)

export const findById = converge(find, [pipe(nthArg(0), propEq("id")), nthArg(1)])
export const findByUid = converge(find, [pipe(nthArg(0), propEq("uid")), nthArg(1)])

export const sortByCaseInsensitive = (func = e => e) => sortBy(compose(toLower, func))

export const filterByPropEquals = curry((propName, filterValue, array) =>
  filter(filterValue ? propEq(propName, filterValue) : T, array),
)

export const filterByPropIncludes = curry((propName, filterValue, array) => {
  if (filterValue) {
    return filter(pipe(prop(propName), defaultTo([]), includes(filterValue)), array)
  }
  return array
})

export const filterByPropContainsCaseInsensitive = curry((propName, filterValue, array) =>
  filter(filterValue ? propContainsCaseInsensitive(propName, filterValue) : T)(array),
)

export const rejectInactive = reject(either(propEq("active", false), propEq("isActive", false)))

export const moveArrayItem = curry((delta, array, element) => {
  const clonedArray = array.slice()
  const index = clonedArray.indexOf(element)
  const newIndex = index + delta

  if (newIndex < 0 || newIndex === clonedArray.length) {
    //Already at the top or bottom.
    return clonedArray
  }

  const firstItem = clonedArray[index]
  clonedArray[index] = array[newIndex]
  clonedArray[newIndex] = firstItem

  return clonedArray
})

export const moveArrayItemUp = moveArrayItem(-1)
export const moveArrayItemDown = moveArrayItem(1)

export const updateByFieldName = curry((fieldName, item, arr) =>
  compose(update(__, item, arr), findIndex(propEq(fieldName, prop(fieldName, item))))(arr),
)

export const eqValues = compose(isEmpty, symmetricDifference)

export const updateByKey = (value = {}, list = [], key = "id") => {
  return map(e => (e[key] === value[key] ? value : e), list)
}

export const arrayToMapWithKey = (key, array, omitKey = false) =>
  array.reduce((acc, value) => {
    const _path = isNotNilOrEmpty(key) ? key.toString().split(".") : []
    const _value = path(_path, value)

    if (_value) {
      acc[_value] = omitKey ? omit([key], value) : value
    }

    return acc
  }, {})

export async function mapSeries(array, iterator, thisArg) {
  const length = array.length
  const cb = arguments.length > 2 ? iterator.bind(thisArg) : iterator
  const results = new Array(length)

  for (let i = 0; i < length; ++i) {
    results[i] = await cb(array[i], i, array)
  }

  return results
}

export const mapPropToLowerCase = (array, propName) => map(compose(toLower, prop(propName)), array)

export const mappedIndex = addIndex(map)

export const mapToSelectableList = map(renameKeys({ name: "label", id: "value" }))

const isAnyNil = any(isNil)

const isAnyGreater = (value, numbers) => any(gt(__, value), numbers)

const isAnyNegative = any(lt(__, 0))

/**
 * Moves the item at index `from` to the index `to` and then moves all the elements
 * after or before `to` left or right keeping its order.
 *
 * __Example__:
 * If `array` is [1, 2, 3, 4, 5] and we want to move `5` to the position of `2` so the result is [1, 5, 2, 3, 4] we
 * can do the following (note that `2`, `3` and `4` should be moved one position to the right):
 * ```
 * const myNewArray = moveElements(array.indexOf(5), array.indexOf(2), array)
 * ```
 * @param { number } from index of the item to move.
 * @param { number } to index where the item at index `from` should be moved.
 * @param { Array } array an array.
 * @returns a new array with items moved.
 */
export const moveItem = (from, to, array) => {
  const indexes = [from, to]
  if (
    !Array.isArray(array) ||
    to === from ||
    isAnyNil(indexes) ||
    isAnyNegative(indexes) ||
    isAnyGreater(array.length - 1, indexes)
  ) {
    return array
  }

  const newArray = [...array]
  newArray.splice(from, 1) // Remove "from" element from the original array
  newArray.splice(to, 0, array[from]) // insert "from" element in its new position and moves respective elements

  return newArray
}

export const sortWithProp = (list, propName = "name") =>
  list?.length ? sortWith([ascend(compose(toLower, prop(propName)))])(list) : []

export const getAsArray = entity => {
  if (isNil(entity)) {
    return []
  }
  return Array.isArray(entity) ? entity : [entity]
}
