import { pluck } from "ramda"
import { v4 as uuidv4 } from "uuid"

import { Input, Modal, SearchableSelect } from "@ninjaone/components"
import { spacing } from "@ninjaone/tokens"

import { useForm, useMountedState } from "js/includes/common/hooks"
import { Flex } from "js/includes/components/Styled"
import {
  applyMultipleValidations,
  getValidationSuccess,
  isNilOrEmpty,
  isNotNilOrEmpty,
  localizationKey,
  localized,
  showSuccessMessage,
  validations,
  withCustomError,
} from "js/includes/common/utils"

import { defaultInheritance } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"
import { isValidPackageName, isValidUrlPattern } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/android/util"

const validateNameInUse = (category, allOptions) => value => {
  const categoriesList = pluck(category, allOptions)
  const success = !categoriesList.includes(value)
  return {
    success,
    message: success
      ? ""
      : localized("The {{key}} is already in use", { key: category === "name" ? localized("key name") : category }),
  }
}

const validateUrlPattern = value => {
  if (isNilOrEmpty(value)) {
    return getValidationSuccess()
  }
  const success = isValidUrlPattern(value)
  return {
    success,
    message: success ? "" : localized("Invalid URL pattern format"),
  }
}

export default function PrivateKeyModal({
  packageNameOptions,
  editKeyRule = {},
  unmount,
  updatePrivateKeyRule,
  allKeyRules,
}) {
  const {
    values: { name, alias, urlPattern, packageNames },
    validation,
    onChange,
    validateForm,
  } = useForm({
    fields: {
      name: editKeyRule?.name ?? "",
      alias: editKeyRule?.alias ?? "",
      urlPattern: editKeyRule?.urlPattern ?? "",
      packageNames: editKeyRule?.packageNames ?? [],
    },
    validate: {
      urlPattern: validateUrlPattern,
      name: applyMultipleValidations([
        withCustomError(localizationKey("A key name is required"), validations.required),
        validateNameInUse("name", allKeyRules),
      ]),
      alias: applyMultipleValidations([
        withCustomError(localizationKey("An alias is required"), validations.required),
        validateNameInUse("alias", allKeyRules),
      ]),
    },
  })

  const isEdit = isNotNilOrEmpty(editKeyRule)
  const [packageOptions, setPackageOptions] = useMountedState(packageNameOptions)

  const handleSave = () => {
    if (!validateForm()) return

    updatePrivateKeyRule(editKeyRule?.guid ?? uuidv4(), {
      name: name.trim(),
      alias: alias.trim(),
      urlPattern: urlPattern.trim(),
      packageNames,
      ...(isEdit ? { inheritance: editKeyRule.inheritance } : defaultInheritance),
    })
    showSuccessMessage(isEdit ? localized("{{name}} was updated", { name }) : localized("{{name}} was added", { name }))

    unmount()
  }

  return (
    <Modal
      size="md"
      buttons={[
        {
          type: "primary",
          onClick: handleSave,
          labelToken: isEdit ? localizationKey("Update key") : localizationKey("Add key"),
        },
      ]}
      titleGroup={{
        titleToken: isEdit ? localizationKey("Edit key") : localizationKey("Add key"),
        descriptionText: localized(
          "Create and manage multiple private key rules by defining URL patterns, associated apps, and private key aliases for secure communication.",
        ),
      }}
      unmount={unmount}
      cancelable
    >
      <Flex flexDirection="column" gap={spacing[4]}>
        <Input
          labelToken={localizationKey("Key name")}
          name="name"
          value={name}
          onChange={onChange}
          placeholder={localized("Enter a key name")}
          errorMessage={validation.message.name}
          required
        />
        <Input
          labelToken={localizationKey("Alias")}
          name="alias"
          value={alias}
          onChange={onChange}
          placeholder={localized("Enter an alias")}
          errorMessage={validation.message.alias}
          required
        />
        <Input
          labelToken={localizationKey("URL pattern")}
          name="urlPattern"
          value={urlPattern}
          onChange={onChange}
          placeholder={localized("Enter a URL pattern, example.: https://secure.examplebank.com")}
          errorMessage={validation.message.urlPattern}
        />
        <SearchableSelect
          labelText={localized("Package names")}
          placeholderText={localized("Select package name")}
          isMulti
          value={packageNames}
          onChange={selected => onChange("packageNames", selected)}
          comboboxProps={{
            resetValueOnHide: true,
            creatable: true,
            onCreate: createdPackageName => {
              if (!isValidPackageName(createdPackageName)) {
                return
              }
              onChange("packageNames", [...packageNames, createdPackageName])
              setPackageOptions([...packageOptions, { value: createdPackageName, labelText: createdPackageName }])
            },
          }}
          options={packageOptions}
        />
      </Flex>
    </Modal>
  )
}
