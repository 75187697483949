import { localized } from "js/includes/common/utils"
import { map, compose, when, prepend, applySpec, identity } from "ramda"

export const getNoneOption = ({ valueKey = "value", labelKey = "label" } = {}) => ({
  [valueKey]: null,
  [labelKey]: localized("None"),
})

export const getTimeOptions = (showNone = false) => [
  ...(showNone ? [getNoneOption()] : []),
  { value: "MINUTES", labelText: localized("Minutes") },
  { value: "HOURS", labelText: localized("Hours") },
  { value: "DAYS", labelText: localized("Days") },
]

export const APPEND_TO_EXISTENT_TICKET_VALUE = "APPEND_TO_EXISTENT_TICKET"
export const CREATE_NEW_TICKET_VALUE = "CREATE_NEW_TICKET"

export const getRetriggerOptions = (showNone = false) => [
  ...(showNone ? [getNoneOption()] : []),
  { value: CREATE_NEW_TICKET_VALUE, labelText: localized("Create new ticket") },
  { value: APPEND_TO_EXISTENT_TICKET_VALUE, labelText: localized("Append to existing ticket (if not closed)") },
]

export const getTypeOptions = ({ showNone = false, labelKey = "label" } = {}) => [
  ...(showNone ? [getNoneOption({ labelKey })] : []),
  { value: "PROBLEM", [labelKey]: localized("Problem") },
  { value: "QUESTION", [labelKey]: localized("Question") },
  { value: "INCIDENT", [labelKey]: localized("Incident") },
  { value: "TASK", [labelKey]: localized("Task") },
]

export const getSourceOptions = ({ showNone = false, labelKey = "label" } = {}) => [
  ...(showNone ? [getNoneOption({ labelKey })] : []),
  { value: "ACTIVITY", [labelKey]: localized("Activity") },
  { value: "CONDITION", [labelKey]: localized("Condition") },
  { value: "EMAIL", [labelKey]: localized("Email") },
  { value: "END_USER", [labelKey]: localized("End user") },
  { value: "HELP_REQUEST", [labelKey]: localized("Systray help request") },
  { value: "SCHEDULED_SCRIPT", [labelKey]: localized("Scheduled automation") },
  { value: "TECHNICIAN", [labelKey]: localized("Technician") },
  // { value: "WEB_FORM", [labelKey]: localized("Web Form") },
]

export const getSeverityOptions = ({ showNone = false, labelKey = "label" } = {}) => [
  ...(showNone ? [getNoneOption({ labelKey })] : []),
  { value: "CRITICAL", [labelKey]: localized("Critical") },
  { value: "MAJOR", [labelKey]: localized("Major") },
  { value: "MODERATE", [labelKey]: localized("Moderate") },
  { value: "MINOR", [labelKey]: localized("Minor") },
]

export const getPriorityOptions = ({ showNone = false, labelKey = "label" } = {}) => [
  ...(showNone ? [getNoneOption({ labelKey })] : []),
  { value: "HIGH", [labelKey]: localized("High") },
  { value: "MEDIUM", [labelKey]: localized("Medium") },
  { value: "LOW", [labelKey]: localized("Low") },
]

export const getCommentTypeOptions = ({ labelKey = "labelText" } = {}) => [
  { value: "PRIVATE", [labelKey]: localized("Private") },
  { value: "PUBLIC", [labelKey]: localized("Public") },
]

export const getUpdatedByOptions = ({ labelKey = "labelText" } = {}) => [
  { value: "REQUESTER", [labelKey]: localized("Requester") },
  { value: "ASSIGNEE", [labelKey]: localized("Assignee") },
  { value: "CC", [labelKey]: localized("CC") },
  { value: "EXTERNAL_CONTACT", [labelKey]: localized("External contact") },
  { value: "INTERNAL_USER", [labelKey]: localized("Internal user") },
]

export const getEmailOptions = map(
  applySpec({
    label: identity,
    value: identity,
  }),
)

export const getUserOptions = (users, key = "uid", showNone = false) => {
  return compose(
    when(() => showNone, prepend(getNoneOption())),
    map(user => ({
      value: user[key],
      label: user.firstName ? `${user.firstName} ${user.lastName}` : user.email,
      email: user.email,
    })),
  )(users || [])
}

export const getOptions = (list, key = "id", showNone = false) => {
  return compose(
    when(() => showNone, prepend(getNoneOption())),
    map(listItem => ({
      ...listItem,
      value: listItem[key],
      label: listItem.name,
    })),
  )(list || [])
}
