import { TitleGroup } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { Box, Flex } from "js/includes/components/Styled"
import { localizationKey, localized } from "js/includes/common/utils/ssrAndWebUtils"
import ProxyConfig from "./proxy/ProxyConfig"
import WifiDataTable from "./WifiDataTable"
import { StyledTitleLabel } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/common/styled"

const NetworkForm = () => {
  return (
    <Flex paddingLeft={tokens.spacing[6]} flex={1} flexDirection="column" overflow="auto">
      <Box marginBottom={tokens.spacing[4]}>
        <TitleGroup
          titleToken={localizationKey("Network")}
          descriptionToken={localizationKey("Manage and define network settings.")}
        />
      </Box>
      <Box>
        <StyledTitleLabel text={localized("Proxy settings (Supervised only)")} />
        <ProxyConfig />
      </Box>
      <Box flex={1} minHeight="500px">
        <WifiDataTable />
      </Box>
    </Flex>
  )
}

export default NetworkForm
