import { useCallback, useEffect, useRef } from "react"
import { T, always, cond } from "ramda"
import { FileFolderIconSvg, ImageVolumeIconSvg } from "@ninjaone/icons"
import {
  localizationKey,
  debugLog,
  fetchJson,
  ninjaReportError,
  noop,
  reportErrorAndShowMessage,
} from "js/includes/common/utils"
import { isAnyImagePlan, planTypesMap } from "js/includes/common/backup"
import { useMountedState } from "js/includes/common/hooks"
import { clearBackupJob } from "js/includes/common/_jobs"
import { useWampProcedures } from "./useWampProcedures"

const getLatestJobDataFromServer = async jobUID => {
  const response = await fetchJson(`/webapp/job/${jobUID}`)

  if (response.resultCode === "SUCCESS") {
    return response.job
  }

  return {}
}

export const clearFailedBackupJob = async ({ nodeId, planId, jobId, unmount, refreshCallback = noop }) => {
  try {
    await clearBackupJob(nodeId, planId, jobId)
    refreshCallback()
    unmount()
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error clearing backup job"))
  }
}

const WAMP_TIMEOUT = 10000

const parseIntWithDefault = value => parseInt(value, 10) || 0

const parseWampJobData = ({
  jobUID,
  planUID,
  planName,
  jobFilesSent,
  jobFilesDownloaded,
  jobTotalFiles,
  jobSentBytes,
  fileDownloadedBytes,
  jobTotalBytes,
  status,
  percentage,
  estimatedTimeToFinish,
  throughput,
}) => ({
  jobUID,
  planUID,
  planName,
  jobFilesProcessed: parseIntWithDefault(jobFilesSent ?? jobFilesDownloaded),
  jobTotalFiles: parseIntWithDefault(jobTotalFiles),
  jobProcessedBytes: parseIntWithDefault(jobSentBytes ?? fileDownloadedBytes),
  jobTotalBytes: parseIntWithDefault(jobTotalBytes),
  status,
  percentage: parseIntWithDefault(percentage),
  estimatedTimeToFinish: parseIntWithDefault(estimatedTimeToFinish),
  throughput: parseIntWithDefault(throughput),
})

const parseServerJobData = ({
  jobUid,
  uid,
  jobStatus,
  content: {
    planId,
    sourcePlanId, // for restore job
    planName,
    sourcePlanName, // for restore job
    jobFilesSent,
    jobFilesDownloaded,
    jobTotalFiles,
    jobSentBytes,
    fileDownloadedBytes,
    jobTotalBytes,
    percentage,
  },
}) => ({
  jobUID: jobUid ?? uid,
  planUID: planId ?? sourcePlanId,
  planName: planName ?? sourcePlanName,
  jobFilesProcessed: parseIntWithDefault(jobFilesSent ?? jobFilesDownloaded),
  jobTotalFiles: parseIntWithDefault(jobTotalFiles),
  jobProcessedBytes: parseIntWithDefault(jobSentBytes ?? fileDownloadedBytes),
  jobTotalBytes: parseIntWithDefault(jobTotalBytes),
  status: jobStatus,
  percentage: parseIntWithDefault(percentage),
})

export const getPlanIcon = cond([
  [type => type === planTypesMap.FILE_FOLDER, always(FileFolderIconSvg)],
  [type => isAnyImagePlan(type), always(ImageVolumeIconSvg)],
  [T, always(ImageVolumeIconSvg)],
])

export const jobStatus = {
  FAILED: "FAILED",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
  PROCESSING: "PROCESSING",
}

const getLatestJobData = (wampJobData, serverJobData) => {
  if ([jobStatus.FAILED, jobStatus.COMPLETED, jobStatus.CANCELED].includes(wampJobData.status)) return wampJobData
  if ([jobStatus.FAILED, jobStatus.COMPLETED, jobStatus.CANCELED].includes(serverJobData.status)) return serverJobData
  return wampJobData.percentage > serverJobData.percentage ||
    wampJobData.jobFilesSent > serverJobData.jobFilesSent ||
    wampJobData.jobFilesDownloaded > serverJobData.jobFilesDownloaded
    ? wampJobData
    : serverJobData
}

export const useLiveBackupJobProcedure = ({ node, job: _job }) => {
  const [jobFromServer, setJobFromServer] = useMountedState(_job)
  const [updatingJob, setUpdatingJob] = useMountedState(true)
  const [error, setError] = useMountedState("")
  const [serverJobData, setServerJobData] = useMountedState(parseServerJobData(_job))
  const [wampJobData, setWampJobData] = useMountedState(parseServerJobData(_job))

  const latestJobData = getLatestJobData(wampJobData, serverJobData)
  const interval = useRef()

  useWampProcedures({ node, jobUID: latestJobData.jobUID, setError })

  const updateJobFromServer = useCallback(
    async showLoading => {
      try {
        showLoading && setUpdatingJob(true)
        const updatedJob = await getLatestJobDataFromServer(latestJobData.jobUID)

        debugLog("Job from server ", updatedJob)
        setJobFromServer(updatedJob)
        setServerJobData(parseServerJobData(updatedJob))
      } catch (error) {
        setError("UNKNOWN_JOB_UID")
        ninjaReportError(error)
      } finally {
        setUpdatingJob(false)
      }
    },
    [latestJobData.jobUID, setJobFromServer, setServerJobData, setError, setUpdatingJob],
  )

  useEffect(() => {
    updateJobFromServer(true)
  }, [updateJobFromServer])

  useEffect(() => {
    async function poolJobData() {
      try {
        await window.wamp.subscribeToChannel(
          "live.data.jobs",
          ([jobFromWamp]) => {
            debugLog("wamp backup job status payload ", jobFromWamp)
            debugLog(jobFromWamp.jobUID, latestJobData.jobUID)

            if (jobFromWamp.jobUID !== latestJobData.jobUID) return

            setWampJobData(parseWampJobData(jobFromWamp))
          },
          node.id,
        )
      } catch (error) {
        debugLog(error)
        ninjaReportError(error)
        setError("continueWampLiveProcedure")
      }
    }

    poolJobData()

    return () => {
      const subscription = window.wamp.globalSubscriptions.find(({ topic }) => topic.includes("live.data.jobs"))
      subscription && window.wamp.unSubscribeToChannel(subscription)
    }
  }, [node.id, latestJobData.jobUID, setError, setWampJobData])

  useEffect(() => {
    clearInterval(interval.current)
    interval.current = setInterval(
      () =>
        [jobStatus.FAILED, jobStatus.COMPLETED, jobStatus.CANCELED].includes(latestJobData.status) || error
          ? clearInterval(interval.current)
          : updateJobFromServer(),
      WAMP_TIMEOUT,
    )

    return () => {
      clearInterval(interval.current)
    }
  }, [error, latestJobData.status, updateJobFromServer])

  return {
    jobFromServer,
    wampJobData,
    updatingJob,
    error,
    jobData: latestJobData,
  }
}

export const getRunningJobMessagesMapper = (planType, jobFilesProcessed) => {
  if (planType === planTypesMap.ARROW_IMAGE) {
    return jobFilesProcessed === 1
      ? {
          preparingJobToken: localizationKey("{{sentBytes}} uploaded | 1 volume uploaded"),
          filesScannedToken: localizationKey("{{jobFilesProcessed}} of 1 volume processed"),
        }
      : {
          preparingJobToken: localizationKey("{{sentBytes}} uploaded | {{jobFilesProcessed}} volumes uploaded"),
          filesScannedToken: localizationKey("{{jobFilesProcessed}} of {{jobTotalFiles}} volumes processed"),
        }
  } else {
    return jobFilesProcessed === 1
      ? {
          preparingJobToken: localizationKey("{{sentBytes}} uploaded | 1 file uploaded"),
          filesScannedToken: localizationKey("{{jobFilesProcessed}} of 1 file scanned"),
        }
      : {
          preparingJobToken: localizationKey("{{sentBytes}} uploaded | {{jobFilesProcessed}} files uploaded"),
          filesScannedToken: localizationKey("{{jobFilesProcessed}} of {{jobTotalFiles}} files scanned"),
        }
  }
}
