import { pathOr } from "ramda"
import { fetchMDMConfiguration } from "js/includes/common/client"
import { isMDMFeatureEnabled, ninjaReportError } from "js/includes/common/utils"
import { AppName } from "js/includes/configuration/apps/appInfo"

export const setMDMConfigs = ({ type, enabled }) => async dispatch => {
  dispatch({
    type: "UPDATE_DIVISIONCONFIG",
    key: type,
    value: enabled,
  })
}

export const getMDMConfiguration = () => async dispatch => {
  if (!isMDMFeatureEnabled()) return null

  try {
    const apps = [AppName.MDM_APPLE, AppName.MDM_ANDROID]
    const responses = await Promise.all(apps.map(appName => fetchMDMConfiguration(appName)))
    responses.forEach((response, i) =>
      dispatch({
        type: "UPDATE_DIVISIONCONFIG",
        key: apps[i],
        value: pathOr(false, ["content", "enabled"], response),
      }),
    )
  } catch (error) {
    ninjaReportError(error)
  }
}

export const setAppleADEData = data => dispatch =>
  dispatch({
    type: "UPDATE_DIVISIONCONFIG",
    key: "mdm.appleADE",
    value: data,
  })

export const SET_ANDROID_CRITICAL_APPS = "SET_ANDROID_CRITICAL_APPS"
export const SET_MDM_NODE_ROLES_ADE_DATA = "SET_MDM_NODE_ROLES_ADE_DATA"

export const setAndroidCriticalApps = criticalApps => dispatch => {
  dispatch({
    type: SET_ANDROID_CRITICAL_APPS,
    payload: criticalApps,
  })
}

export const setMDMNodeRolesADEData = ({ isAdvancedInstallerEnabled, nodeRolesList }) => async dispatch => {
  dispatch({
    type: SET_MDM_NODE_ROLES_ADE_DATA,
    payload: {
      isAdvancedInstallerEnabled,
      nodeRolesList,
    },
  })
}
