import styled from "@emotion/styled"
import tokens from "@ninjaone/tokens"

const StyledTickText = styled.text`
  fill: ${({ theme }) => theme.colorTextWeak};
  font-size: ${tokens.typography.fontSize.bodyXs};
  line-height: ${tokens.typography.lineHeight};
`

const AreaChartTick = ({ x, y, dy = 16, value, textAnchor, isXAxis }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <StyledTickText
        x={0}
        y={0}
        dy={dy}
        textAnchor={textAnchor}
        data-testid={isXAxis ? "area-chart-tick-x-axis" : "area-chart-tick-y-axis"}
      >
        {value}
      </StyledTickText>
    </g>
  )
}

export default AreaChartTick
