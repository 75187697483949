import { always, anyPass, cond, evolve, find, propEq, reject, T } from "ramda"
import { isPsaFreshservice, isPsaGenericWebhook, localizationKey, localized } from "js/includes/common/utils"

export const OccurrenceDurationOptions = {
  value: 5,
  items: [
    { value: 5, text: () => `5 ${localized("Minutes").toLowerCase()}` },
    { value: 15, text: () => `15 ${localized("Minutes").toLowerCase()}` },
    { value: 30, text: () => `30 ${localized("Minutes").toLowerCase()}` },
    { value: 60, text: () => `60 ${localized("Minutes").toLowerCase()}` },
  ],
}

/**
 * @deprecated please use getResetThresholdOptions instead for the new Select component
 */
export const ResetThresholdOptions = {
  value: 4,
  items: [
    { value: 0.025, text: () => `90 ${localized("seconds")}` },
    { value: 0.05, text: () => `3 ${localized("Minutes").toLowerCase()}` },
    { value: 0.1, text: () => `6 ${localized("Minutes").toLowerCase()}` },
    { value: 0.2, text: () => `12 ${localized("Minutes").toLowerCase()}` },
    { value: 0.3, text: () => `18 ${localized("Minutes").toLowerCase()}` },
    { value: 0.4, text: () => `20 ${localized("Minutes").toLowerCase()}` },
    { value: 0.5, text: () => `30 ${localized("Minutes").toLowerCase()}` },
    { value: 1, text: () => `1 ${localized("Hour").toLowerCase()}` },
    { value: 2, text: () => `2 ${localized("Hours").toLowerCase()}` },
    { value: 4, text: () => `4 ${localized("Hours").toLowerCase()}` },
    { value: 8, text: () => `8 ${localized("Hours").toLowerCase()}` },
    { value: 12, text: () => `12 ${localized("Hours").toLowerCase()}` },
    { value: 24, text: () => `24 ${localized("Hours").toLowerCase()}` },
    { value: 596523, text: () => localized("Manual"), manual: true },
  ],
}

export const resetThresholdOptions = [
  { value: "90", labelToken: localizationKey("90 seconds") },
  { value: "180", labelToken: localizationKey("3 minutes") },
  { value: "360", labelToken: localizationKey("6 minutes") },
  { value: "720", labelToken: localizationKey("12 minutes") },
  { value: "1080", labelToken: localizationKey("18 minutes") },
  { value: "1200", labelToken: localizationKey("20 minutes") },
  { value: "1800", labelToken: localizationKey("30 minutes") },
  { value: "3600", labelToken: localizationKey("1 hour") },
  { value: "7200", labelToken: localizationKey("2 hours") },
  { value: "14400", labelToken: localizationKey("4 hours") },
  { value: "28800", labelToken: localizationKey("8 hours") },
  { value: "43200", labelToken: localizationKey("12 hours") },
  { value: "86400", labelToken: localizationKey("24 hours") },
]

export const getRunEveryOptions = reject(
  anyPass([propEq("value", "90"), propEq("value", "1200")]),
  resetThresholdOptions,
)

const getThresholdItemsWithoutManual = () => ResetThresholdOptions.items.filter(item => !item.manual)

export const ResetThresholdOptionsWithoutManual = evolve(
  { items: getThresholdItemsWithoutManual },
  ResetThresholdOptions,
)

/**
 * @deprecated please use getTicketingOptions instead for the new Select component
 */
export const TicketingOptions = {
  value: "NONE",
  items: [
    { value: "NONE", text: () => localized("Do not create a ticket") },
    { value: "CREATE", text: () => localized("Create a ticket") },
    { value: "CREATE_AND_CLOSE", text: () => localized("Create and close a ticket") },
  ],
}

export const getTicketingOptions = ({
  psaType,
  valueKey = "value",
  labelKey = "labelToken",
  localizeLabel = false,
} = {}) => {
  if (isPsaGenericWebhook(psaType)) {
    const noneToken = localizationKey("Do not send notifications")
    const createToken = localizationKey("Send notifications")
    return [
      { [valueKey]: "NONE", [labelKey]: localizeLabel ? localized(noneToken) : noneToken },
      { [valueKey]: "CREATE", [labelKey]: localizeLabel ? localized(createToken) : createToken },
    ]
  }

  const noneToken = localizationKey("Do not create a ticket")
  const createToken = localizationKey("Create a ticket")
  const createAndCloseToken = localizationKey("Create and close a ticket")
  return [
    { [valueKey]: "NONE", [labelKey]: localizeLabel ? localized(noneToken) : noneToken },
    { [valueKey]: "CREATE", [labelKey]: localizeLabel ? localized(createToken) : createToken },
    ...(isPsaFreshservice(psaType)
      ? []
      : [
        {
          [valueKey]: "CREATE_AND_CLOSE",
          [labelKey]: localizeLabel ? localized(createAndCloseToken) : createAndCloseToken,
        },
      ]),
  ]
}

export const buildGetTicketingOptionLabel = ({ psaType }) => {
  const options = getTicketingOptions({ psaType })
  return value => {
    if (value === "NONE") {
      return ""
    }
    const labelToken = find(propEq("value", value), options)?.labelToken
    return labelToken ? localized(labelToken) : ""
  }
}

const genericWebhookTicketingModelOptions = {
  value: "NONE",
  items: [
    { value: "NONE", text: () => localized("Do not send notifications") },
    { value: "CREATE", text: () => localized("Send notifications") },
  ],
}

const freshserviceTicketingModelOptions = {
  value: "NONE",
  items: [
    { value: "NONE", text: () => localized("Do not create a ticket") },
    { value: "CREATE", text: () => localized("Create a ticket") },
  ],
}

/**
 * @deprecated please use getTicketingOptions instead for the new Select component
 */
export const getPsaTicketingModelOptions = cond([
  [isPsaGenericWebhook, always(genericWebhookTicketingModelOptions)],
  [isPsaFreshservice, always(freshserviceTicketingModelOptions)],
  [T, always(TicketingOptions)],
])

/**
 * @deprecated this is for backbone components, please use getNotificationOptions instead for the new Select component
 */
export const NotificationOptions = {
  value: "NONE",
  items: [
    { value: "NONE", text: () => localized("Do not send notifications") },
    { value: "SEND", text: () => localized("Send notifications") },
  ],
}

export const getNotificationOptions = () => [
  { value: "NONE", labelToken: localizationKey("Do not send notifications") },
  { value: "SEND", labelToken: localizationKey("Send notifications") },
]

/**
 * @deprecated this is for backbone components, please use getSeverityOptions instead for the new Select component
 */
export const SeverityOptions = {
  value: null,
  items: [
    { value: "CRITICAL", text: () => localized("Critical") },
    { value: "MAJOR", text: () => localized("Major") },
    { value: "MODERATE", text: () => localized("Moderate") },
    { value: "MINOR", text: () => localized("Minor") },
    { value: null, text: () => localized("None") },
  ],
}

export const getSeverityOptions = () => [
  { value: "CRITICAL", labelToken: localizationKey("Critical") },
  { value: "MAJOR", labelToken: localizationKey("Major") },
  { value: "MODERATE", labelToken: localizationKey("Moderate") },
  { value: "MINOR", labelToken: localizationKey("Minor") },
  { value: "NONE", labelToken: localizationKey("None") },
]

/**
 * @deprecated this is for backbone components, please use getPriorityOptions instead for the new Select component
 */
export const PriorityOptions = {
  value: null,
  items: [
    { value: "HIGH", text: () => localized("High") },
    { value: "MEDIUM", text: () => localized("Medium") },
    { value: "LOW", text: () => localized("Low") },
    { value: null, text: () => localized("None") },
  ],
}

export const getPriorityOptions = () => [
  { value: "HIGH", labelToken: localizationKey("High") },
  { value: "MEDIUM", labelToken: localizationKey("Medium") },
  { value: "LOW", labelToken: localizationKey("Low") },
  { value: "NONE", labelToken: localizationKey("None") },
]
