import { useSelector } from "react-redux"
import { getSystemHealthStatusConfig } from "js/state/selectors/general/healthStatus"
import {
  getWorstOfHealthStatusesClass,
  getHealthIconClassFor,
  getHealthStatusFieldCategory,
} from "js/includes/common/utils"

export function useHealthIconClassFor(healthStatus) {
  const systemHealthStatusConfig = useSelector(getSystemHealthStatusConfig)
  return getHealthIconClassFor(healthStatus, systemHealthStatusConfig)
}

export function useWorstOfHealthStatusesClass(healthStatusMap) {
  const systemHealthStatusConfig = useSelector(getSystemHealthStatusConfig)
  return getWorstOfHealthStatusesClass(healthStatusMap, systemHealthStatusConfig)
}

export function useHealthCategoryFor(healthStatus) {
  const systemHealthStatusConfig = useSelector(getSystemHealthStatusConfig)
  return getHealthStatusFieldCategory(healthStatus, systemHealthStatusConfig)
}
