import styled from "@emotion/styled"
import { TitleGroup } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { Box } from "js/includes/components/Styled"

export const Separator = styled.hr`
  border-top: solid 1px ${({ theme }) => theme.colorBorderWeakest};
  width: 100%;
  height: 1px;
  margin: 0 0 0 ${tokens.spacing[6]};
`

export const OSUpdatesHeader = () => (
  <Box padding={`0 ${tokens.spacing[6]}`} marginBottom={tokens.spacing[6]}>
    <TitleGroup
      titleToken={localizationKey("OS updates")}
      descriptionToken={localizationKey(
        "Manage how users can install software updates and define enforced OS versions.",
      )}
    />
  </Box>
)

export const osTabGridColumns = `2fr 0fr 7fr 1fr 150px`

export const defaultInheritance = {
  inheritance: {
    inherited: false,
    overridden: false,
    sourcePolicyId: null,
  },
}
