import { always, cond, head, last, length, pipe, split, test, values } from "ramda"

import { Body, Tooltip } from "@ninjaone/components"
import { RegularInfoCircleIcon } from "@ninjaone/icons"
import { spacing } from "@ninjaone/tokens"

import { getAndroidApplicationsMetadata } from "js/includes/common/client"
import { localized, ninjaReportError } from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"
import { AndroidPolicyAppsApplicationSources } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"
import { getPendingPackagesNames } from "./applications/utils"

// TODO: Use isValidPackageName in SystemAppModal once epic/NJ-83346-mdm-enhancements-7-0-0 is merged to develop

// It must have at least two segments (one or more dots).
// Each segment must start with a letter.
// All characters must be alphanumeric or an underscore [a-zA-Z0-9_].
export const isValidPackageName = test(/^([A-Za-z]{1}\w*\.)+[A-Za-z]\w*$/)

// It must have a valid package format including the / at the last segment or /. as separator
// Each segment must start with a letter.
// All characters must be alphanumeric or an underscore [a-zA-Z0-9_].
export const isValidActivityName = test(/^([A-Za-z]{1}\w*\.)+[A-Za-z]\w*(\/|\.|\/\.)?[A-Za-z]\w*$/)

// It must have a valid domain and domain segments including blank spaces
export const isValidUrlPattern = test(/^(https?:\/\/)?([\w-]+\.)?[\w-]+\.[a-zA-Z]{2,}$|[\w-]+\s[a-zA-Z]{2,}$/)

export const isInputEmptyOrHasValidPackageName = packageName =>
  packageName?.length === 0 || isValidPackageName(packageName)

export const SelectOptionTooltip = ({ tooltipToken, labelToken }) => (
  <Flex alignItems="baseline" gap={spacing[1]}>
    <Body>{localized(labelToken)}</Body>
    <Box>
      <Tooltip label={localized(tooltipToken)}>
        <RegularInfoCircleIcon size="sm" />
      </Tooltip>
    </Box>
  </Flex>
)

export const getApplicationMetadata = async ({ apps, androidEnterpriseConnections, defaultConnectionId, locale }) => {
  const listOfConnectionIds = androidEnterpriseConnections.map(connection => connection.id)
  // Filter out playstore apps whose id don't match any of the androidEnterpriseConnections
  const allowedAppsList = values(apps).filter(app => {
    if (app.applicationSource === AndroidPolicyAppsApplicationSources.SYSTEM_APP) return false

    return app.connectionId ? listOfConnectionIds.includes(app.connectionId) : true
  })

  const pending = getPendingPackagesNames(allowedAppsList, defaultConnectionId)

  if (pending.length === 0) return []

  try {
    const metadataApps = await getAndroidApplicationsMetadata({ packageNameList: pending, locale })
    return metadataApps
  } catch (error) {
    ninjaReportError(error)
    return []
  }
}

export const getLastSegment = pipe(head, split("."), last)

export const getCellValueLabel = ({ options, showMoreLabel }) => {
  return cond([
    [options => length(options) === 0, always(null)],
    [options => length(options) === 1, getLastSegment],
    [
      options => length(options) > 1,
      options => {
        const firstOption = getLastSegment(options)
        const remainingCount = length(options) - 1
        return showMoreLabel
          ? localized("{{firstOption}} +{{remainingCount}} more", { firstOption, remainingCount })
          : `${firstOption} +${remainingCount}`
      },
    ],
    [always(true), always("")],
  ])(options)
}
