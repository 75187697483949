import { map, pick } from "ramda"
import { showSuccessMessage, showErrorMessage, fetchJson, localized, fetch } from "js/includes/common/utils"

export function getLocations(clientId) {
  return async dispatch => {
    try {
      const locations = await fetchJson(`/location/client/${clientId}`)
      dispatch({ type: "SET_LOCATIONS", payload: locations })
    } catch (err) {
      showErrorMessage(localized("Error loading locations"))
      throw err
    }
  }
}

export function createLocation(data) {
  return async dispatch => {
    try {
      const body = JSON.stringify({ ...data })
      const location = await fetchJson("/location", { options: { method: "POST", body } })
      dispatch({ type: "ADD_LOCATION", newLocation: location })
      showSuccessMessage(localized("Saving Location..."))
    } catch (err) {
      showErrorMessage(localized("Error saving location"))
      throw err
    }
  }
}

export function updateLocation(data, isDefault) {
  return async dispatch => {
    try {
      const cleanedPolicyMap = map(pick(["policyId", "nodeRoleId"]), data.policies)
      const body = JSON.stringify({ ...data, policies: cleanedPolicyMap })
      await fetch(`/location/${data.id}`, { options: { method: "PUT", body } })
      dispatch(getLocations(data.clientId))
      showSuccessMessage(localized("Saving Location..."))
    } catch (err) {
      showErrorMessage(localized("Error saving location"))
      throw err
    }
  }
}

export function removeLocation(id) {
  return async dispatch => {
    try {
      const response = await fetch(`/location/${id}`, { options: { method: "DELETE" } })
      if (response.status === 204) {
        dispatch({ type: "REMOVE_LOCATION", id })
        showSuccessMessage(localized("Deleting Location..."))
      }
    } catch (err) {
      if (err.response.status === 400) {
        showErrorMessage(localized("Cannot delete default location"))
      } else {
        showErrorMessage(localized("Error deleting location"))
      }
      throw err
    }
  }
}
