import { show, hide } from "js/state/actions/common/visibility"
import { fetchJson, localized, showErrorMessage, ninjaReportError } from "js/includes/common/utils"
import { updateOrganizationForProductMappingDeleted } from "js/state/actions/psa/ConnectWise/mappings/accounts"

export function getProductMappings() {
  return async dispatch => {
    try {
      const productMappings = await fetchJson("/psacw/v2/productMappings")
      dispatch({
        type: "SET_CW_PRODUCT_MAPPINGS",
        payload: productMappings,
      })
    } catch (error) {
      showErrorMessage(localized("Error loading product mappings"))
      throw error
    }
  }
}

export function getAgreementTypes() {
  return async dispatch => {
    try {
      const { financeAgreementTypeList } = await fetchJson("/psacw/v2/finance/agreements/types")
      dispatch({ type: "SET_CW_AGREEMENT_TYPE_LIST", payload: financeAgreementTypeList })
    } catch (error) {
      showErrorMessage(localized("Error fetching managed agreement types"))
      if (error?.response?.status !== 403) {
        ninjaReportError(error)
      }
    }
  }
}

export function getProducts() {
  return async dispatch => {
    try {
      const { procurementProductList } = await fetchJson("/psacw/v2/procurement/products")
      dispatch({ type: "SET_CW_PRODUCT_LIST", payload: procurementProductList })
    } catch (error) {
      showErrorMessage(localized("Error fetching products"))
      if (error?.response?.status !== 403) {
        ninjaReportError(error)
      }
    }
  }
}

export function showProductMappingModal(mode, id) {
  return dispatch => {
    dispatch({ type: "SET_CW_PRODUCT_MAPPING_MODAL_EDITOR_MODE", mode })
    dispatch({ type: "SET_CW_PRODUCT_MAPPING_ID", id })
    dispatch(show("CWProductMappingsModal")())
  }
}

export function copyProductMapping(newProductMapping) {
  return dispatch => {
    dispatch({ type: "SET_CW_PRODUCT_MAPPING_MODAL_EDITOR_MODE", mode: "ADD" })
    dispatch(show("CWProductMappingsModal")())
  }
}

export function closeProductMappingModal() {
  return dispatch => {
    dispatch({ type: "RESET_CW_PRODUCT_MAPPING" })
    dispatch(hide("CWProductMappingsModal")())
  }
}

export function addProductMapping(data) {
  return dispatch => {
    dispatch({ type: "ADD_CW_PRODUCT_MAPPING", newProductMapping: data })
  }
}

export function updateProductMapping(data) {
  return dispatch => {
    dispatch({ type: "EDIT_CW_PRODUCT_MAPPING", productMapping: data })
  }
}

export function removeProductMapping(id) {
  return dispatch => {
    dispatch({ type: "REMOVE_CW_PRODUCT_MAPPING", id })
    dispatch(updateOrganizationForProductMappingDeleted(id))
  }
}

export function setProductMappings(productMappings) {
  return dispatch => {
    dispatch({ type: "SET_CW_PRODUCT_MAPPINGS", payload: productMappings })
  }
}

export function setCurrentProductMapping(productMapping) {
  return dispatch => {
    dispatch({ type: "SET_CW_PRODUCT_MAPPING", productMapping })
  }
}

export function setProductMappingName(name) {
  return dispatch => {
    dispatch({ type: "SET_CW_PRODUCT_MAPPING_NAME", name })
  }
}

export function changeProductMapping(newMapping) {
  return dispatch => {
    dispatch({ type: "CHANGE_CW_CURRENT_PRODUCT_MAPPING", newMapping })
  }
}

export function moveMappingRankUp(mappingId) {
  return dispatch => {
    dispatch({ type: "MOVE_CW_MAPPING_RANK_UP", mappingId })
  }
}

export function moveMappingRankDown(mappingId) {
  return dispatch => {
    dispatch({ type: "MOVE_CW_MAPPING_RANK_DOWN", mappingId })
  }
}
