import { useCallback, useEffect, useRef, useState } from "react"
import { connect, batch } from "react-redux"
import { useHistory } from "react-router-dom"
import styled from "@emotion/styled"
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import tokens from "@ninjaone/tokens"
import { getTextSize, sizer } from "@ninjaone/utils"
import { Heading, Skeleton } from "@ninjaone/components"
import { StyledList, StyledListItem } from "@ninjaone/components/src/VerticalTabs/VerticalTabStyles"
import { localized, ninjaReportError } from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"
import {
  setGlobalSearchPageResults as _setGlobalSearchPageResults,
  setGlobalSearchPageQuery as _setGlobalSearchPageQuery,
  setGlobalSearchPersistedQuery as _setGlobalSearchPersistedQuery,
  setGlobalSearchPersistedResults as _setGlobalSearchPersistedResults,
} from "js/state/actions/globalSearch"
import { ResultsHeader, StyledAlert, getFilterOptions, minimumLoaderTime } from "./common"
import { getGlobalSearchItemKey, getGlobalSearchPageUrl, getGlobalSearchResults } from "./utils"
import GlobalSearchItem from "./GlobalSearchItem"
import GlobalSearchSkeletons from "./GlobalSearchSkeletons"
import GlobalSearchNoResultsAlert from "./GlobalSearchNoResultsAlert"

const StyledPageTitle = styled.h1`
  color: ${({ theme }) => theme.colorTextStrong};
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledListItems = styled(StyledList)`
  padding: 0;
  margin: 0;
  border-right: none;
  font-size: ${getTextSize("sm")};
  color: ${({ theme }) => theme.colorTextStrong};
`

const StyledGlobalSearchItemWrapper = styled.div`
  margin-bottom: ${sizer(2)};

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colorBorderWeakest};
    padding-bottom: ${sizer(2)};
  }
`

const StyledListItemButton = StyledListItem.withComponent("button")

const GlobalSearchPage = ({
  pageExactResults,
  pageSuggestedResults,
  pageFilter,
  pageValue,
  setGlobalSearchPageResults,
  setGlobalSearchPageQuery,
  setGlobalSearchPersistedQuery,
  searchValueFromQuery,
  searchFilterFromQuery,
  persistedValue,
  persistedFilter,
  setGlobalSearchPersistedResults,
  administrationPages,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const history = useHistory()
  const pageRef = useRef()

  const hasNotSearched = !pageExactResults && !pageSuggestedResults && !pageFilter
  const hasNoResults = pageExactResults?.length <= 0 && pageSuggestedResults?.length <= 0
  const hasBeenReset = !pageExactResults && !pageSuggestedResults && pageFilter

  const handleSearchByFilter = useCallback(
    async (value, filter) => {
      const startTime = Date.now()
      try {
        setShowError(false)
        setIsLoading(true)
        setGlobalSearchPageQuery(value, filter)

        const { exact, suggested } = await getGlobalSearchResults({
          query: value,
          filter,
          limit: 250,
          administrationPages,
        })

        if (!exact || !suggested) {
          throw new Error("Invalid data for exact and suggested results")
        }

        batch(() => {
          setGlobalSearchPageResults(exact, suggested)

          if (persistedValue === pageValue && persistedFilter === pageFilter) {
            setGlobalSearchPersistedQuery(value, filter)
            setGlobalSearchPersistedResults(exact, suggested)
          }
        })
      } catch (error) {
        setShowError(true)
        ninjaReportError(error)
      } finally {
        const timeElapsed = Date.now() - startTime

        if (timeElapsed < minimumLoaderTime) {
          setTimeout(() => {
            setIsLoading(false)
          }, minimumLoaderTime - timeElapsed)
        } else {
          setIsLoading(false)
        }
      }
    },
    [
      setGlobalSearchPageQuery,
      administrationPages,
      setGlobalSearchPageResults,
      persistedValue,
      pageValue,
      persistedFilter,
      pageFilter,
      setGlobalSearchPersistedQuery,
      setGlobalSearchPersistedResults,
    ],
  )

  useEffect(() => {
    if (hasBeenReset) {
      handleSearchByFilter(pageValue, pageFilter)
    }
  }, [hasBeenReset, handleSearchByFilter, pageValue, pageFilter])

  useEffect(() => {
    if (hasNotSearched) {
      handleSearchByFilter(searchValueFromQuery, searchFilterFromQuery)
    }
  }, [hasNotSearched, handleSearchByFilter, searchValueFromQuery, searchFilterFromQuery])

  const renderContent = () => {
    if (isLoading) return <GlobalSearchSkeletons rowCount={9} />

    if (showError)
      return (
        <Flex justifyContent="center" alignItems="center" width="100%" height="100%" padding={sizer(0, 6)}>
          <StyledAlert>
            <FontAwesomeIcon icon={faCircleExclamation} fixedWidth />
            <h3>{localized("Something went wrong on our end")}</h3>
            <p>{localized("Please refresh your page and try again")}</p>
          </StyledAlert>
        </Flex>
      )

    if (hasNoResults)
      return (
        <Flex justifyContent="center" alignItems="center" width="100%" height="100%" padding={sizer(0, 6)}>
          <GlobalSearchNoResultsAlert {...{ filter: pageFilter }} />
        </Flex>
      )

    return (
      <div data-testid="global-search-page-results" ref={pageRef}>
        {!!pageExactResults?.length && (
          <>
            <ResultsHeader padding={sizer(2)}>
              <Heading type="headingXs" fontWeight={tokens.typography.fontWeight.regular} level={2}>
                {localized("Exact results")}
              </Heading>
            </ResultsHeader>
            <Box marginTop={sizer(3)} marginBottom={sizer(3)}>
              {pageExactResults.map(resultsData => (
                <StyledGlobalSearchItemWrapper key={getGlobalSearchItemKey(resultsData)}>
                  <GlobalSearchItem
                    {...{
                      data: resultsData,
                      containerRef: pageRef,
                      searchValue: pageValue,
                    }}
                  />
                </StyledGlobalSearchItemWrapper>
              ))}
            </Box>
          </>
        )}

        {!!pageSuggestedResults?.length && (
          <>
            <ResultsHeader padding={sizer(2)}>
              <Heading type="headingXs" fontWeight={tokens.typography.fontWeight.regular} level={2}>
                {localized("Suggested results")}
              </Heading>
            </ResultsHeader>
            <Box marginTop={sizer(3)}>
              {pageSuggestedResults.map(resultsData => (
                <StyledGlobalSearchItemWrapper key={getGlobalSearchItemKey(resultsData)}>
                  <GlobalSearchItem
                    {...{
                      data: resultsData,
                      containerRef: pageRef,
                      searchValue: pageValue,
                    }}
                  />
                </StyledGlobalSearchItemWrapper>
              ))}
            </Box>
          </>
        )}
      </div>
    )
  }

  return (
    <Flex width="100%" flexDirection="column" padding={sizer(0, 6)}>
      <Box marginBottom={sizer(6)} marginTop={sizer(2)}>
        {pageValue ? (
          <StyledPageTitle>{localized(`Search results for "{{pageValue}}"`, { pageValue })}</StyledPageTitle>
        ) : (
          <Skeleton width="300px" height="22px" display="block" />
        )}
      </Box>
      <Flex gap={sizer(8)} overflow="hidden">
        <div>
          <StyledListItems>
            {getFilterOptions().map(({ label, value: filterValue }) => (
              <StyledListItemButton
                key={filterValue}
                {...(filterValue === pageFilter
                  ? {
                      "data-state": "active",
                    }
                  : {
                      onClick: () => {
                        history.push(
                          getGlobalSearchPageUrl({
                            searchValue: pageValue,
                            searchFilter: filterValue,
                            includeOrigin: false,
                          }),
                        )
                        handleSearchByFilter(pageValue, filterValue)
                      },
                    })}
              >
                <span>{label}</span>
              </StyledListItemButton>
            ))}
          </StyledListItems>
        </div>
        <Box flex="1" maxWidth="800px" overflowY="auto">
          {renderContent()}
        </Box>
      </Flex>
    </Flex>
  )
}

export default connect(
  ({ globalSearch }) => ({
    pageExactResults: globalSearch.pageExactResults,
    pageSuggestedResults: globalSearch.pageSuggestedResults,
    pageFilter: globalSearch.pageFilter,
    pageValue: globalSearch.pageValue,
    persistedFilter: globalSearch.persistedFilter,
    persistedValue: globalSearch.persistedValue,
  }),
  {
    setGlobalSearchPageResults: _setGlobalSearchPageResults,
    setGlobalSearchPageQuery: _setGlobalSearchPageQuery,
    setGlobalSearchPersistedQuery: _setGlobalSearchPersistedQuery,
    setGlobalSearchPersistedResults: _setGlobalSearchPersistedResults,
  },
)(GlobalSearchPage)
