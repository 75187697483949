import { Modal, TextArea } from "@ninjaone/components"

import { useForm } from "js/includes/common/hooks"
import { localizationKey, localized, showSuccessMessage, validations } from "js/includes/common/utils"
import { defaultInheritance } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"

export default function EditDefaultMessageModal({ unmount, defaultMessage, updateMessage, isRequired }) {
  const {
    values: { newDefaultMessage },
    validation,
    onChange,
    validateForm,
  } = useForm({
    fields: {
      newDefaultMessage: defaultMessage?.message ?? "",
    },
    ...(isRequired && {
      validate: {
        newDefaultMessage: validations.required,
      },
    }),
  })

  const handleSave = () => {
    if (!validateForm()) return

    updateMessage({
      message: newDefaultMessage.trim(),
      inheritance: defaultMessage.inheritance ?? defaultInheritance.inheritance,
    })
    showSuccessMessage(localized("Default message updated successfully."))
    unmount()
  }

  return (
    <Modal
      buttons={[
        {
          type: "primary",
          onClick: handleSave,
          labelToken: localizationKey("Update"),
        },
      ]}
      titleGroup={{
        titleToken: localizationKey("Edit default message"),
      }}
      unmount={unmount}
      cancelable
      {...(validation.message.newDefaultMessage && {
        footerAlertMessage: {
          titleToken: localizationKey("Message required"),
          labelToken: localizationKey(
            "A custom message has been defined for one or more languages. In order to continue, a default message must be provided for unconfigured languages as well.",
          ),
        },
      })}
    >
      <TextArea
        labelToken={localizationKey("Message")}
        value={newDefaultMessage}
        rows={2}
        resize="none"
        onChange={e => onChange("newDefaultMessage", e.target.value)}
        useCharacterCount={false}
        errorMessage={validation.message.newDefaultMessage}
        required={isRequired}
      />
    </Modal>
  )
}
