import { connect } from "react-redux"
import { PureComponent } from "react"
import { includes, pluck } from "ramda"
import qs from "qs"

import { Modal, Select, Text } from "@ninjaone/components"
import { spacing, typography } from "@ninjaone/tokens"

import Loading from "js/includes/components/Loading"

import { getMdmWebToken } from "js/includes/common/client"
import {
  localized,
  localizationKey,
  showErrorMessage,
  reportErrorAndShowMessage,
  mapAndroidConnectionOptions,
} from "js/includes/common/utils"
import { loadGoogleIframe } from "js/includes/common/utils/mdmGoogleIframe"
import { Box, Flex } from "js/includes/components/Styled"
import { AndroidPolicyAppsApplicationSources as ApplicationSources } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"

const mapApplicationPolicy = ({ packageName, distributionChannel }, connectionId) => ({
  canBeManaged: false,
  packageName,
  installType: "PREINSTALLED",
  applicationSource: ApplicationSources.PLAY_STORE,
  defaultPermissionPolicy: "PERMISSION_POLICY_UNSPECIFIED",
  permissionGrants: [],
  distributionChannel,
  disabled: false,
  connectedWorkAndPersonalApp: "CONNECTED_WORK_AND_PERSONAL_APP_UNSPECIFIED",
  autoUpdateMode: "AUTO_UPDATE_MODE_UNSPECIFIED",
  connectionId,
})

class MobileApplicationsSelectorModal extends PureComponent {
  constructor(props) {
    super(props)
    const { applicationPolicy, androidEnterpriseConnections } = this.props
    const selectedPackages = pluck("packageName")(applicationPolicy)
    const newMappedConnections = mapAndroidConnectionOptions(androidEnterpriseConnections)
    this.state = {
      selectedApps: selectedPackages,
      connectionOptions: newMappedConnections,
      selectedEnterpriseConnectionId: newMappedConnections[0]?.value,
      loadingWebTokens: false,
    }
  }

  setMountedState = state => {
    this._isMounted && this.setState(state)
  }

  componentDidMount() {
    this._isMounted = true
    const { androidEnterpriseConnections } = this.props
    this.loadIframe(androidEnterpriseConnections[0]?.id)
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  async loadIframe(connectionId) {
    try {
      this.setMountedState({ loadingWebTokens: true })

      const { search, hostname } = this.props
      const { tokenValue } = await getMdmWebToken(hostname, connectionId)

      this.setMountedState({ loadingWebTokens: false })

      const urlParams = qs.stringify({
        token: tokenValue,
        mode: "SELECT",
        search,
      })

      const iframe = await loadGoogleIframe({
        url: `https://play.google.com/work/embedded/search?${urlParams}`,
        containerId: "google-play-iframe-container",
        attributes: { style: "width: 100%; height:500px", scrolling: "no" },
      })

      iframe.register(
        "onproductselect",
        async selected => {
          const { selectedApps } = this.state
          const { onApplicationSelected } = this.props

          const isAlreadySelected = includes(selected.packageName, selectedApps)

          if (isAlreadySelected) {
            showErrorMessage(localized("This package is already installed"))
          } else {
            const selectedApp = mapApplicationPolicy(selected, connectionId)

            onApplicationSelected(selectedApp)
            this._isMounted &&
              this.setState({
                selectedApps: [...selectedApps, selectedApp.packageName],
              })
          }
        },
        window.gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER,
      )
    } catch (error) {
      this.setMountedState({ loadingWebTokens: false })
      reportErrorAndShowMessage(error, localizationKey("Iframe could not be loaded"))
    }
  }

  handleConnectionChange = connectionId => {
    this.setMountedState({ selectedEnterpriseConnectionId: connectionId })
    this.loadIframe(connectionId)
  }

  getConnectionName(connectionId) {
    const { connectionOptions } = this.state
    return connectionId ? connectionOptions.find(option => option.value === connectionId).labelText : null
  }

  render() {
    const { unmount, loadingAndroidConnections } = this.props
    const { connectionOptions, selectedEnterpriseConnectionId, loadingWebTokens } = this.state
    const connectionName = this.getConnectionName(selectedEnterpriseConnectionId)
    return (
      <Modal
        submittable
        titleGroup={{ titleToken: localizationKey("Add New Package") }}
        unmount={unmount}
        size="lg"
        dialogClassName="modal-lg modal-form-groups"
      >
        <Box position="absolute" width="450px" top="3%" right="7%">
          <Select
            disabled={loadingAndroidConnections}
            options={connectionOptions}
            labelRenderer={() => (
              <Flex gap={spacing[2]} alignItems="center" overflow="hidden">
                <Box>
                  <Text type="headingS" fontWeight={typography.fontWeight.semiBold}>
                    {localized("Android Connection:")}
                  </Text>
                </Box>
                <Text type="headingS" key={connectionName}>
                  {connectionName}
                </Text>
              </Flex>
            )}
            value={selectedEnterpriseConnectionId}
            triggerAriaLabel={localized("Android Enterprise Connection")}
            onChange={connection => this.handleConnectionChange(connection)}
            labelId="androidConnections"
          />
        </Box>

        {loadingWebTokens ? (
          <Flex minHeight="500px" justifyContent="center" alignItems="center">
            <Loading />
          </Flex>
        ) : (
          <Box minHeight="500px" id="google-play-iframe-container"></Box>
        )}
      </Modal>
    )
  }
}

export default connect(({ websiteBranding }) => ({
  hostname: websiteBranding.hostname,
}))(MobileApplicationsSelectorModal)
