import { pluck } from "ramda"
import { fetch, fetchJson, removeSpaces, user } from "js/includes/common/utils"
import { readLocalFileAsUrl } from "js/includes/ticketing/editor/shared/utils"
import { languageCodes } from "js/includes/common/utils/languageCodes"

const ZENDESK_NAME_CUSTOM_FIELD_ID = 24243073
const ZENDESK_PHONE_CUSTOM_FIELD_ID = 24284686
const ZENDESK_CATEGORY_CUSTOM_FIELD_ID = 24281146
const ZENDESK_LANGUAGE_CUSTOM_FIELD_ID = 24178656522125
const ZENDESK_CALLBACK_CUSTOM_FIELD_ID = 27519358931597

const supportedZendeskLanguages = [
  "English",
  "German",
  "Italian",
  "French",
  "Spanish",
  "Dutch",
  "Portuguese",
  "Romanian",
  "Polish",
  "Japanese",
]

const getFullLanguageCode = code => {
  const baseCode = code.split("-")[0]
  const language = languageCodes[baseCode.toLowerCase()]
  return supportedZendeskLanguages.includes(language) ? language : "English"
}

export const createZendeskTicket = ({
  ticketPriority,
  ticketSubject,
  ticketBody,
  ticketPhoneNumber,
  ticketCategory,
  imageTokens,
  ticketCallback,
  ticketCc,
}) => {
  const comment = {
    body: ticketBody,
    uploads: imageTokens ? imageTokens : [],
  }

  return fetchJson("/ws/zendesk/ticket", {
    options: {
      method: "POST",
      body: JSON.stringify({
        priority: ticketPriority,
        subject: ticketSubject,
        comment,
        custom_fields: [
          {
            id: ZENDESK_NAME_CUSTOM_FIELD_ID,
            value: user("getName"),
          },
          {
            id: ZENDESK_PHONE_CUSTOM_FIELD_ID,
            value: ticketPhoneNumber,
          },
          {
            id: ZENDESK_CATEGORY_CUSTOM_FIELD_ID,
            value: ticketCategory,
          },
          {
            id: ZENDESK_LANGUAGE_CUSTOM_FIELD_ID,
            value: getFullLanguageCode(navigator.language),
          },
          {
            id: ZENDESK_CALLBACK_CUSTOM_FIELD_ID,
            value: ticketCallback,
          },
        ],
        cc_emails: ticketCc,
      }),
    },
    useSessionPrefix: false,
    redirectUserToLogin: false,
  })
}

export const uploadAttachmentToZendesk = async file => {
  const base64String = await readLocalFileAsUrl(file)
    .then(url => url.replace("data:", "").replace(/^.+,/, ""))
    .catch(error => {
      throw error
    })

  const _fileName = removeSpaces(file.name)

  return fetchJson("/ws/zendesk/upload-attachment", {
    options: {
      method: "POST",
      body: JSON.stringify({
        file: base64String,
        fileName: _fileName,
      }),
    },
    useSessionPrefix: false,
    redirectUserToLogin: false,
  })
}

export const deleteUploadedAttachment = async token => {
  return fetchJson(`/ws/zendesk/delete-attachment/${token}`, {
    options: {
      method: "DELETE",
    },
    useSessionPrefix: false,
    redirectUserToLogin: false,
  })
}

export const zendDeskTicketTemplateUrl = "/zendesk/ticket-template"

export const fetchZendeskTicketTemplates = () => fetchJson(zendDeskTicketTemplateUrl)

export const saveZendeskTicketTemplate = (body, ticketTemplateId) =>
  fetchJson(zendDeskTicketTemplateUrl + (ticketTemplateId ? `/${ticketTemplateId}` : ""), {
    options: { method: ticketTemplateId ? "PUT" : "POST", body: JSON.stringify(body) },
  })

export const setZendeskTicketTemplateAsDefault = ticketTemplateId =>
  fetchJson(`${zendDeskTicketTemplateUrl}/${ticketTemplateId}/default`, { options: { method: "PUT" } })

export const fetchZendeskTicketTemplate = ticketTemplateId =>
  fetchJson(`${zendDeskTicketTemplateUrl}/${ticketTemplateId}`)

export const deleteZendeskTicketTemplate = ticketTemplates =>
  fetchJson(`${zendDeskTicketTemplateUrl}/delete`, {
    options: {
      method: "POST",
      body: JSON.stringify({
        ticketTemplateIds: pluck("id", ticketTemplates),
      }),
    },
  })

export const fetchZendeskFieldValues = () => fetchJson(`${zendDeskTicketTemplateUrl}/field-values`)

export const fetchZendeskClientMapping = id => fetchJson(`/zendesk/client-mapping/${id}`)

export const fetchZendeskClientMappingByPage = paging =>
  fetchJson("/zendesk/client-mapping/paginated", {
    options: {
      method: "POST",
      body: JSON.stringify(paging),
    },
  })

export const saveZendeskClientMapping = (body, clientMappingId) =>
  fetchJson("/zendesk/client-mapping" + (clientMappingId ? `/${clientMappingId}` : ""), {
    options: { method: clientMappingId ? "PUT" : "POST", body: JSON.stringify(body) },
  })

export const deleteZendeskClientMapping = id =>
  fetchJson(`/zendesk/client-mapping/${id}`, {
    options: { method: "DELETE" },
  })

export const getZendeskTicketURL = async ({ nodeId, jobUid }) => {
  const response = await fetch(`/zendesk/ticket/node/${nodeId}/job/${jobUid}`)
  return response.text()
}
